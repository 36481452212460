import { CloudDownloadOutlined } from "@ant-design/icons";
import {
  Col,
  Modal,
  Row,
  Select,
  Tag,
  Tooltip,
  Typography,
  message,
} from "antd";
import React, { Fragment, useEffect, useRef, useState } from "react";
import "./FarmerReport.css";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { apiurls } from "../../resources/APIurls";
import { appconfig } from "../../resources/config";
import { postData } from "../../resources/utils";
import { encode } from "js-base64";
const { Option } = Select;

const FarmerReportDialog = ({
  farmerReportDialog,
  setFarmerReportDialog,
  data,
  cropHis,
  recomndCrop,
  soilTxtData,
  npk,
  health,
  featureType,
  farmersScore,
  farmerDetails,
  setMapLoader,
  basicDetails,
}) => {
  let apikey_s3 = apiurls.find((item) => item.apikey == "aws3");
  const aws_s3_url = `${appconfig.domain[appconfig.environment].aws3}${
    apikey_s3.paths.images
  }`;
  const pdfRef = useRef();
  const [printLoader, setPrintLoader] = useState(false);
  const [crphistry, setcrphistry] = useState([]);
  const [rabiCrop, setRabiCrop] = useState([]);
  const [messageApi, contextHolder] = message.useMessage();
  const loadingMessage = () => {
    messageApi.open({
      type: "loading",
      content: "Your file is being ready, Please wait..",
      duration: 1.5,
    });
  };
  const successMessage = () => {
    messageApi.open({
      type: "success",
      content: "Your file is ready to download.",
      duration: 2,
    });
  };

  const callPdfService = async (dataPDF) => {
    let res = await postData("indexhost", "getPdf", dataPDF, null);
    const linkSource = `data:application/pdf;base64,${res.data.data}`;
    const downloadLink = document.createElement("a");

    const fileName = `${data?.farmerdetails1[0].farmer_name}_${data?.farmerdetails1[0].village_name}_${data?.farmerdetails1[0].subdistrict_name}_${data?.farmerdetails1[0].district_name}_${data?.farmerdetails1[0].state_name}.pdf`;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    messageApi.destroy();
    successMessage();
    setMapLoader(false);
    downloadLink.click();
  };
  const downloadPdf = async () => {
    loadingMessage();
    setMapLoader(true);
    let target = document.getElementById("divToPrint");
    var wrap = document.createElement("div");
    wrap.appendChild(target.cloneNode(true));

    let startHtml = `<html> <head> <link rel='preconnect' href="https://fonts.googleapis.com" /> <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin /> <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;400;500;600;700&display=swap" rel="stylesheet" /> <style> div { font-family: poppins; } p { font-family: poppins; } span { font-family: poppins; } </style> </head> <body>`;
    let endHtml = `</body></html>`;
    let str = startHtml + wrap.innerHTML.replace(/\n|\t/g, " ") + endHtml;
    let data = {
      html: encode(str),
    };

    callPdfService(data);
  };
  const [pieChartOptions, setPieChartOptions] = useState({
    chart: {
      plotBackgroundColor: "#F2FFE4",
      backgroundColor: "#F2FFE4",
      plotBorderWidth: 0,
      plotShadow: false,
      type: "pie",
      height: 180,
      width: 260,
    },
    title: {
      text: "",
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      pointFormat: "{series.name}: <b>{point.y}</b>",
    },

    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: false,
          format: "<b>{point.name}</b> {point.y} ",
        },
      },
    },
    series: [
      {
        name: "",
        colorByPoint: true,
        borderWidth: null,
        data: [
          {
            name: "Good Area",
            y: 11.4,
            color: "#9FC088",
          },
          {
            name: "Moderate Area",
            y: 67.62,
            color: "#E8C07D",
          },
          {
            name: "Poor Area",
            y: 18.1,
            color: "#F55353",
          },
        ],
      },
    ],
  });

  const getCropHistory = (str) => {
    let history = "";
    for (let key in cropHis) {
      if (key.includes(str)) {
        history += `<span style="color:#3B3939; font-weight: 700; font-size:14px;"  >${key}:</span>  <span style="color:#3B3939; font-weight:400; font-size:12px;" > ${
          cropHis[key] != null ? cropHis[key].map((item) => item.name) : "--"
        }</span> <br />`;
      } else {
        // history += `<span> NO DATA FOUND </span>`;
      }
    }
    return history;
  };

  const [selectedFarmer, setSelectedFarmer] = useState({});
  const [farmerInfo, setFarmerInfo] = useState({});
  const [farmerId, setFarmerId] = useState("");

  useEffect(() => {
    if (featureType != "frams" && data.farmerdetails1?.length > 1) {
      //coment
      setSelectedFarmer(data.farmerdetails1[0]);
      setFarmerId(data.farmerdetails1[0].id);
      setFarmerInfo(data.farmerdetails[0]?.farmername);
    }
  }, []);

  const findFarmerId = (val) => {
    const selectedFarmerId = data.farmerdetails1.find(
      (item) => item.id == farmerId
    );

    setSelectedFarmer(selectedFarmerId);
  };

  const findFarmerBasicInfo = () => {
    setFarmerInfo(
      data.farmerdetails.find(
        (info) => info.farmername == selectedFarmer?.farmer_name
      )
    );
  };

  useEffect(() => {
    if (featureType != "farms") {
      findFarmerId();
      findFarmerBasicInfo();
    }
  }, [farmerId]);

  const [cropHealth, setCropHealth] = useState({
    goodArea: 0,
    poorArea: 0,
    moderateArea: 0,
  });
  const getCropHealth = () => {
    let good_area = 0;
    let poor_area = 0;
    let moderate_area = 0;

    // data.crophealth?.forEach((item) => {
    //   good_area += item.GOOD_AREA;
    //   poor_area += item.POOR_AREA;
    //   moderate_area += item.MODERATE_AREA;
    // });

    // comment
    // good_area = health?.good;
    // poor_area = health?.poor;
    // moderate_area = health?.average;

    // setCropHealth({
    //   ...cropHealth,
    //   goodArea: good_area,
    //   poorArea: poor_area,
    //   moderateArea: moderate_area,
    // });
  };

  useEffect(() => {
    let chartObj = { ...pieChartOptions };
    chartObj.series[0].data[0].y = health?.good;
    chartObj.series[0].data[1].y = health?.average;
    chartObj.series[0].data[2].y = health?.poor;

    setPieChartOptions(chartObj);
  }, [health]);

  const randomNumGenerator = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min);
  };

  return (
    <div>
      <Modal
        title=""
        visible={farmerReportDialog}
        // open={farmerReportDialog}
        // visible={true}
        onCancel={() => setFarmerReportDialog(false)}
        footer={null}
        // bodyStyle={{ backgroundColor: "#212121" }}
        bodyStyle={{
          backgroundColor: "#FFFFFF",
          transitionTimingFunction: "cubic-bezier(0.25, 0.1, 0.25, 1)",
        }}
        width={"90%"}
        centered
      >
        {printLoader ? (
          <div className="loader-cont">
            <img src={`${aws_s3_url}/loader2.gif`} width="100px" />

            <p className="back-drop-text-2">
              Your Report is geting ready, Please wait..
            </p>
          </div>
        ) : (
          <Fragment>
            {contextHolder}
            <div style={{ marginTop: "16px" }}>
              <Tooltip title="Download" placement="right">
                <CloudDownloadOutlined
                  // onClick={printDocument}
                  onClick={downloadPdf}
                  style={{
                    // color: "#ffffff",
                    color: "#212121",
                    fontSize: "25px",
                    cursor: "pointer",
                  }}
                />
              </Tooltip>
              {data.farmerdetails1?.length > 1 && (
                <div style={{ display: "flex", justifyContent: "end" }}>
                  <Select
                    defaultValue={data.farmerdetails1[0].farmer_name}
                    style={{
                      width: "30%",
                    }}
                    value={farmerId || undefined}
                    onChange={(val) => {
                      setFarmerId(val);
                    }}
                    // onChange={handleChange}
                  >
                    {data.farmerdetails1.map((item, index) => (
                      <Option key={index} value={item.id}>
                        {item.farmer_name}
                      </Option>
                    ))}
                  </Select>
                </div>
              )}
            </div>
            <div className="reportMainCont" id="divToPrint">
              <div
                id="topCont"
                style={{
                  marginTop: "40px",
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "16px",
                }}
              >
                <img src={`${aws_s3_url}/logo.svg`} width="120px" />
                <img src={`${aws_s3_url}/AgribazaarLogo.svg`} width="120px" />
              </div>

              <div
                style={{
                  backgroundImage: `url(${aws_s3_url}/farmfield.png)`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  // width: "595px",
                  height: "842px",
                  left: "1px",
                }}
              >
                <Typography className="farmrepo">Farm Report</Typography>
                <div
                  style={{ position: "relative", top: "42%", marginLeft: "3%" }}
                >
                  <Typography className="farmhead">Farmer Details</Typography>
                </div>

                {farmerId !== "" && featureType == "farms" ? (
                  // <div className="basicDetailsBox">
                  //   <Row gutter={[10, 10]}>
                  //     <Col span={6} className="gutter-row">
                  //       <div className="inputBox">
                  //         <Typography className={"nutrientsheadings"}>
                  //           Farmer Name
                  //         </Typography>

                  //         <div className="input">
                  //           {selectedFarmer?.farmer_name}
                  //         </div>
                  //       </div>
                  //     </Col>
                  //     <Col span={3} className="gutter-row">
                  //       <div className="inputBox">
                  //         <Typography className={"nutrientsheadings"}>
                  //           KCC
                  //         </Typography>

                  //         <div className="input">No</div>
                  //       </div>
                  //     </Col>
                  //     <Col span={3} className="gutter-row">
                  //       <div className="inputBox">
                  //         <Typography className={"nutrientsheadings"}>
                  //           PMFBY
                  //         </Typography>

                  //         <div className="input">Yes</div>
                  //       </div>
                  //     </Col>
                  //     <Col span={3} className="gutter-row">
                  //       <div className="inputBox">
                  //         <Typography className={"nutrientsheadings"}>
                  //           PMK
                  //         </Typography>

                  //         <div className="input">Yes</div>
                  //       </div>
                  //     </Col>
                  //     <Col span={3} className="gutter-row">
                  //       <div className="inputBox">
                  //         <Typography className={"nutrientsheadings"}>
                  //           AM
                  //         </Typography>

                  //         <div className="input">No</div>
                  //       </div>
                  //     </Col>
                  //     <Col span={3} className="gutter-row">
                  //       <div className="inputBox">
                  //         <Typography className={"nutrientsheadings"}>
                  //           SHC
                  //         </Typography>

                  //         <div className="input">Yes</div>
                  //       </div>
                  //     </Col>
                  //     <Col span={3} className="gutter-row">
                  //       <div className="inputBox">
                  //         <Typography className={"nutrientsheadings"}>
                  //           Mobile
                  //         </Typography>

                  //         <div className="input">{selectedFarmer?.mobile}</div>
                  //       </div>
                  //     </Col>
                  //   </Row>
                  //   <Row gutter={[10, 10]}>
                  //     <Col span={4} className="gutter-row">
                  //       <div className="inputBox">
                  //         <Typography className={"nutrientsheadings"}>
                  //           Khasra Number
                  //         </Typography>

                  //         <div className="input">{selectedFarmer?.khasra}</div>
                  //       </div>
                  //     </Col>
                  //     <Col span={5} className="gutter-row">
                  //       <div className="inputBox">
                  //         <Typography className={"nutrientsheadings"}>
                  //           Village
                  //         </Typography>

                  //         <div className="input">
                  //           {selectedFarmer?.village_name}
                  //         </div>
                  //       </div>
                  //     </Col>
                  //     <Col span={5} className="gutter-row">
                  //       <div className="inputBox">
                  //         <Typography className={"nutrientsheadings"}>
                  //           Tehsil
                  //         </Typography>

                  //         <div className="input">
                  //           {selectedFarmer?.subdistrict_name}
                  //         </div>
                  //       </div>
                  //     </Col>
                  //     <Col span={5} className="gutter-row">
                  //       <div className="inputBox">
                  //         <Typography className={"nutrientsheadings"}>
                  //           District
                  //         </Typography>

                  //         <div className="input">
                  //           {selectedFarmer?.district_name}
                  //         </div>
                  //       </div>
                  //     </Col>
                  //     <Col span={5} className="gutter-row">
                  //       <div className="inputBox">
                  //         <Typography className={"nutrientsheadings"}>
                  //           State
                  //         </Typography>

                  //         <div className="input">
                  //           {selectedFarmer?.state_name}
                  //         </div>
                  //       </div>
                  //     </Col>
                  //   </Row>
                  // </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      marginTop: "36.5%",
                    }}
                  >
                    {/* 1 div-------------------->>>>>>>>>>>>>>>>>>>>> */}
                    <div
                      style={{
                        background: "#364C01",
                        width: "28%",
                        padding: "5px 25px 5px 25px",
                      }}
                    >
                      <div style={{}}>
                        <p className="basicDetailHead">Farmer name</p>
                        <p className="detail">
                          {Object.keys(data.farmerdetails1).length
                            ? data.farmerdetails1[0]?.farmer_name
                            : "NO DATA"}
                        </p>
                      </div>
                      <div style={{}}>
                        <p className="basicDetailHead">State</p>
                        <p className="detail">
                          {Object.keys(data.farmerdetails1).length
                            ? data.farmerdetails1[0]?.state_name
                            : "NO DATA"}
                        </p>
                      </div>
                      <div style={{}}>
                        <p className="basicDetailHead ">District</p>
                        <p className="detail">
                          {Object.keys(data.farmerdetails1).length
                            ? data.farmerdetails1[0]?.district_name
                            : "NO DATA"}
                        </p>
                      </div>
                    </div>
                    <div
                      style={{
                        background: "#364C01",
                        width: "28%",
                        padding: "5px 25px 5px 25px",
                      }}
                    >
                      <div style={{}}>
                        <p className="basicDetailHead">Tehsil</p>
                        <p className="detail">
                          {Object.keys(data.farmerdetails1).length
                            ? data.farmerdetails1[0]?.subdistrict_name
                            : "NO DATA"}
                        </p>
                      </div>
                      <div style={{}}>
                        <p className="basicDetailHead">Khasra Number</p>
                        <p className="detail">
                          {Object.keys(data.farmerdetails1).length
                            ? data.farmerdetails1[0]?.khasra
                            : "NO DATA"}
                        </p>
                      </div>
                      <div style={{}}>
                        <p className="basicDetailHead ">Village</p>
                        <p className="detail">
                          {Object.keys(data.farmerdetails1).length
                            ? data.farmerdetails1[0]?.village_name
                            : "NO DATA"}
                        </p>
                      </div>
                    </div>
                    <div
                      style={{
                        background: "#364C01",
                        width: "28%",
                        padding: "5px 25px 5px 25px",
                      }}
                    >
                      <div style={{}}>
                        <p className="basicDetailHead">Mobile</p>
                        <p className="detail">
                          {Object.keys(data.farmerdetails1).length
                            ? data.farmerdetails1[0]?.mobile
                            : "NO DATA"}
                        </p>
                      </div>
                      <div style={{}}>
                        <p className="basicDetailHead">KCC</p>
                        <p className="detail">
                          {Object.keys(data.farmerdetails).length
                            ? data.farmerdetails[0]?.kisan_credit_card
                            : "NO DATA"}
                        </p>
                      </div>
                      <div style={{}}>
                        <p className="basicDetailHead ">PMFBY</p>
                        <p className="detail">
                          {Object.keys(data.farmerdetails).length
                            ? data.farmerdetails[0]?.pmfby
                            : "NO DATA"}
                        </p>
                      </div>
                    </div>
                    <div
                      style={{
                        background: "#364C01",
                        width: "10%",
                        padding: "5px 17px 5px 18px ",
                      }}
                    >
                      <div style={{}}>
                        <p className="basicDetailHead">PMK</p>
                        <p className="detail">
                          {Object.keys(data.farmerdetails).length
                            ? data.farmerdetails[0]?.pmkisan
                            : "NO DATA"}
                        </p>
                      </div>
                      <div style={{}}>
                        <p className="basicDetailHead">AM</p>
                        <p className="detail">
                          {Object.keys(data.farmerdetails).length
                            ? data.farmerdetails[0]?.agrimachinery
                            : "NO DATA"}
                        </p>
                      </div>
                      <div style={{}}>
                        <p className="basicDetailHead ">SHC</p>
                        <p className="detail">
                          {Object.keys(data.farmerdetails).length
                            ? data.farmerdetails[0]?.soilhealthcard
                            : "NO DATA"}
                        </p>
                      </div>
                    </div>
                  </div>
                ) : featureType != "farms" ? (
                  // latest farm report ---------------------->>>>..>>>>>>>>>>>>>>>>>>>>>>>>>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      marginTop: "36.5%",
                    }}
                  >
                    {/* 1 div-------------------->>>>>>>>>>>>>>>>>>>>> */}
                    <div
                      style={{
                        background: "#364C01",
                        width: "28%",
                        padding: "5px 25px 5px 25px",
                      }}
                    >
                      <div style={{}}>
                        <p className="basicDetailHead">Farmer name</p>
                        <p className="detail">
                          {Object.keys(data.farmerdetails1).length
                            ? data.farmerdetails1[0]?.farmer_name
                            : "NO DATA"}
                        </p>
                      </div>
                      <div style={{}}>
                        <p className="basicDetailHead">State</p>
                        <p className="detail">
                          {Object.keys(data.farmerdetails1).length
                            ? data.farmerdetails1[0]?.state_name
                            : "NO DATA"}
                        </p>
                      </div>
                      <div style={{}}>
                        <p className="basicDetailHead ">District</p>
                        <p className="detail">
                          {Object.keys(data.farmerdetails1).length
                            ? data.farmerdetails1[0]?.district_name
                            : "NO DATA"}
                        </p>
                      </div>
                    </div>
                    <div
                      style={{
                        background: "#364C01",
                        width: "28%",
                        padding: "5px 25px 5px 25px",
                      }}
                    >
                      <div style={{}}>
                        <p className="basicDetailHead">Tehsil</p>
                        <p className="detail">
                          {Object.keys(data.farmerdetails1).length
                            ? data.farmerdetails1[0]?.subdistrict_name
                            : "NO DATA"}
                        </p>
                      </div>
                      <div style={{}}>
                        <p className="basicDetailHead">Khasra Number</p>
                        <p className="detail">
                          {Object.keys(data.farmerdetails1).length
                            ? data.farmerdetails1[0]?.khasra
                            : "NO DATA"}
                        </p>
                      </div>
                      <div style={{}}>
                        <p className="basicDetailHead ">Village</p>
                        <p className="detail">
                          {Object.keys(data.farmerdetails1).length
                            ? data.farmerdetails1[0]?.village_name
                            : "NO DATA"}
                        </p>
                      </div>
                    </div>
                    <div
                      style={{
                        background: "#364C01",
                        width: "28%",
                        padding: "5px 25px 5px 25px",
                      }}
                    >
                      <div style={{}}>
                        <p className="basicDetailHead">Mobile</p>
                        <p className="detail">
                          {Object.keys(data.farmerdetails1).length
                            ? data.farmerdetails1[0]?.mobile
                            : "NO DATA"}
                        </p>
                      </div>
                      <div style={{}}>
                        <p className="basicDetailHead">KCC</p>
                        <p className="detail">
                          {Object.keys(data.farmerdetails).length
                            ? data.farmerdetails[0]?.kisan_credit_card
                            : "NO DATA"}
                        </p>
                      </div>
                      <div style={{}}>
                        <p className="basicDetailHead ">PMFBY</p>
                        <p className="detail">
                          {Object.keys(data.farmerdetails).length
                            ? data.farmerdetails[0]?.pmfby
                            : "NO DATA"}
                        </p>
                      </div>
                    </div>
                    <div
                      style={{
                        background: "#364C01",
                        width: "10%",
                        padding: "5px 17px 5px 18px ",
                      }}
                    >
                      <div style={{}}>
                        <p className="basicDetailHead">PMK</p>
                        <p className="detail">
                          {Object.keys(data.farmerdetails).length
                            ? data.farmerdetails[0]?.pmkisan
                            : "NO DATA"}
                        </p>
                      </div>
                      <div style={{}}>
                        <p className="basicDetailHead">AM</p>
                        <p className="detail">
                          {Object.keys(data.farmerdetails).length
                            ? data.farmerdetails[0]?.agrimachinery
                            : "NO DATA"}
                        </p>
                      </div>
                      <div style={{}}>
                        <p className="basicDetailHead ">SHC</p>
                        <p className="detail">
                          {Object.keys(data.farmerdetails).length
                            ? data.farmerdetails[0]?.soilhealthcard
                            : "NO DATA"}
                        </p>
                      </div>
                    </div>
                  </div>
                ) : (
                  // <div>
                  //   <Row gutter={[10, 10]}>
                  //     <Col span={6} className="gutter-row">
                  //       <div className="inputBox">
                  //         <Typography className={"nutrientsheadings"}>
                  //           Farmer Name
                  //         </Typography>

                  //         <div className="input">{farmerDetails.name}</div>
                  //       </div>
                  //     </Col>
                  //     <Col span={3} className="gutter-row">
                  //       <div className="inputBox">
                  //         <Typography className={"nutrientsheadings"}>
                  //           State
                  //         </Typography>

                  //         <div className="input">
                  //           {farmerDetails.state_name != null
                  //             ? farmerDetails.state_name
                  //             : "NA"}
                  //         </div>
                  //       </div>
                  //     </Col>
                  //     <Col span={3} className="gutter-row">
                  //       <div className="inputBox">
                  //         <Typography className={"nutrientsheadings"}>
                  //           District
                  //         </Typography>

                  //         <div className="input">
                  //           {farmerDetails.district_name != null
                  //             ? farmerDetails.district_name
                  //             : "NA"}
                  //         </div>
                  //       </div>
                  //     </Col>
                  //   </Row>
                  // </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      marginTop: "36.5%",
                    }}
                  >
                    {/* 1 div-------------------->>>>>>>>>>>>>>>>>>>>> */}
                    <div
                      style={{
                        background: "#364C01",
                        width: "28%",
                        padding: "5px 25px 5px 25px",
                      }}
                    >
                      <div style={{}}>
                        <p className="basicDetailHead">Farmer name</p>
                        <p className="detail">
                          {/* {Object.keys(data.farmerdetails1).length
                            ? data.farmerdetails1[0]?.farmer_name
                            : "NO DATA"} */}
                          {farmerDetails?.name ? farmerDetails.name : "N/A"}
                        </p>
                      </div>
                      <div style={{}}>
                        <p className="basicDetailHead">State</p>
                        <p className="detail">
                          {/* {Object.keys(data.farmerdetails1).length
                            ? data.farmerdetails1[0]?.state_name
                            : "NO DATA"} */}
                          {farmerDetails.state_name != null
                            ? farmerDetails.state_name
                            : "NA"}
                        </p>
                      </div>
                      <div style={{}}>
                        <p className="basicDetailHead ">District</p>
                        <p className="detail">
                          {/* {Object.keys(data.farmerdetails1).length
                            ? data.farmerdetails1[0]?.district_name
                            : "NO DATA"} */}
                          {farmerDetails.district_name != null
                            ? farmerDetails.district_name
                            : "NA"}
                        </p>
                      </div>
                    </div>
                    <div
                      style={{
                        background: "#364C01",
                        width: "28%",
                        padding: "5px 25px 5px 25px",
                      }}
                    >
                      {/* <div style={{}}>
                        <p className="basicDetailHead">Tehsil</p>
                        <p className="detail">
                          {Object.keys(data.farmerdetails1).length
                            ? data.farmerdetails1[0]?.subdistrict_name
                            : "NO DATA"}
                        </p>
                      </div> */}
                      {/* <div style={{}}>
                        <p className="basicDetailHead">Khasra Number</p>
                        <p className="detail">
                          {Object.keys(data.farmerdetails1).length
                            ? data.farmerdetails1[0]?.khasra
                            : "NO DATA"}
                        </p>
                      </div> */}
                      {/* <div style={{}}>
                        <p className="basicDetailHead ">Village</p>
                        <p className="detail">
                          {Object.keys(data.farmerdetails1).length
                            ? data.farmerdetails1[0]?.village_name
                            : "NO DATA"}
                        </p>
                      </div> */}
                    </div>
                    <div
                      style={{
                        background: "#364C01",
                        width: "28%",
                        padding: "5px 25px 5px 25px",
                      }}
                    >
                      {/* <div style={{}}>
                        <p className="basicDetailHead">Mobile</p>
                        <p className="detail">
                          {Object.keys(data.farmerdetails1).length
                            ? data.farmerdetails1[0]?.mobile
                            : "NO DATA"}
                        </p>
                      </div> */}
                      {/* <div style={{}}>
                        <p className="basicDetailHead">KCC</p>
                        <p className="detail">
                          {Object.keys(data.farmerdetails).length
                            ? data.farmerdetails[0]?.kisan_credit_card
                            : "NO DATA"}
                        </p>
                      </div> */}
                      {/* <div style={{}}>
                        <p className="basicDetailHead ">PMFBY</p>
                        <p className="detail">
                          {Object.keys(data.farmerdetails).length
                            ? data.farmerdetails[0]?.pmfby
                            : "NO DATA"}
                        </p>
                      </div> */}
                    </div>
                    <div
                      style={{
                        background: "#364C01",
                        width: "10%",
                        padding: "5px 17px 5px 18px ",
                      }}
                    >
                      {/* <div style={{}}>
                        <p className="basicDetailHead">PMK</p>
                        <p className="detail">
                          {Object.keys(data.farmerdetails).length
                            ? data.farmerdetails[0]?.pmkisan
                            : "NO DATA"}
                        </p>
                      </div> */}
                      {/* <div style={{}}>
                        <p className="basicDetailHead">AM</p>
                        <p className="detail">
                          {Object.keys(data.farmerdetails).length
                            ? data.farmerdetails[0]?.agrimachinery
                            : "NO DATA"}
                        </p>
                      </div> */}
                      {/* <div style={{}}>
                        <p className="basicDetailHead ">SHC</p>
                        <p className="detail">
                          {Object.keys(data.farmerdetails).length
                            ? data.farmerdetails[0]?.soilhealthcard
                            : "NO DATA"}
                        </p>
                      </div> */}
                    </div>
                  </div>
                )}
              </div>

              {featureType != "farms" && (
                <>
                  <Typography className="ownershipbox">
                    Ownership and Liabilities
                  </Typography>
                  <div className="hindiDetailsBox">
                    <Typography className="hindiDetails">
                      {data.farmerdetailshindi.length
                        ? data.farmerdetailshindi.map((item) => item.first_name)
                        : "NO DATA"}
                    </Typography>
                  </div>
                </>
              )}
              {/* SOIL PROPERTIES MAIN DIV----------------------->>>>>>>>>>>>>>>... */}
              <div className="featureBox">
                <div className="headBotomLine">
                  <Typography className="detailhead">
                    Soil Properties
                  </Typography>
                </div>

                <Row gutter={[10, 10]}>
                  <Col span={12} className="gutter-row">
                    <div className="inputBox">
                      {" "}
                      <Typography className={"headings"}>
                        Macro Nutrients
                      </Typography>{" "}
                    </div>
                    <div className="soilPropertiesBox">
                      <Row gutter={[10, 10]}>
                        <Col span={8}>
                          <div className="inputPropBox">
                            <Typography className="nutrientsheadings">
                              {/* Nitrogen */}N
                            </Typography>

                            <div className="input">
                              {npk != null && Object.keys(npk).length
                                ? npk.nitrogen + "kg/ha"
                                : "No info available"}
                            </div>
                          </div>
                        </Col>
                        <Col span={8}>
                          <div className="inputPropBox">
                            <Typography className="nutrientsheadings">
                              {/* Phosphorus */}P
                            </Typography>

                            <div className="input">
                              {npk != null && Object.keys(npk).length
                                ? npk.phosphorus + "kg/ha"
                                : "No info available"}
                            </div>
                          </div>
                        </Col>
                        <Col span={8}>
                          <div className="inputPropBox">
                            <Typography className="nutrientsheadings">
                              {/* Potassium */}K
                            </Typography>

                            <div className="input">
                              {npk != null && Object.keys(npk).length
                                ? npk.potassium + "kg/ha"
                                : "No info available"}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col span={12} className="gutter-row">
                    <div className="inputBox">
                      {" "}
                      <Typography className={"headings"}>
                        Micro Nutrients
                      </Typography>
                    </div>
                    <div className="soilPropertiesBox">
                      <Row gutter={[10, 10]}>
                        <Col span={8}>
                          <div className="inputPropBox">
                            <Typography className="nutrientsheadings">
                              Boron
                            </Typography>

                            <div className="input">
                              {npk != null && Object.keys(npk).length
                                ? npk.boron + "ppm"
                                : "No info available"}
                            </div>
                          </div>
                        </Col>
                        <Col span={8}>
                          <div className="inputPropBox">
                            <Typography className="nutrientsheadings">
                              Zinc
                            </Typography>

                            <div className="input">
                              {npk != null && Object.keys(npk).length
                                ? npk.zinc + "ppm"
                                : "No info available"}
                            </div>
                          </div>
                        </Col>
                        <Col span={8}>
                          <div className="inputPropBox">
                            <Typography className="nutrientsheadings">
                              Iron
                            </Typography>

                            <div className="input">
                              {npk != null && Object.keys(npk).length
                                ? npk.iron + "ppm"
                                : "No info available"}
                            </div>
                          </div>
                        </Col>
                        <Col span={8}>
                          <div className="inputPropBox">
                            <Typography className="nutrientsheadings">
                              Sulphur
                            </Typography>

                            <div className="input">
                              {npk != null && Object.keys(npk).length
                                ? npk.sulphur + "ppm"
                                : "No info available"}
                            </div>
                          </div>
                        </Col>
                        <Col span={8}>
                          <div className="inputPropBox">
                            <Typography className="nutrientsheadings">
                              Manganese
                            </Typography>

                            <div className="input">
                              {npk != null && Object.keys(npk).length
                                ? npk.manganese + "ppm"
                                : "No info available"}
                            </div>
                          </div>
                        </Col>
                        <Col span={8}>
                          <div className="inputPropBox">
                            <Typography className="nutrientsheadings">
                              Copper
                            </Typography>
                            <div className="input">
                              {npk != null && Object.keys(npk).length
                                ? npk.copper + "ppm"
                                : "No info available"}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>

                {/* SOIL PROPERTIES------------------------------------------------------------ */}

                {/* <div> */}
                {/* <div> */}
                {/* <div
                  style={{
                    borderTop: "1px solid rgb(230, 230, 230)",
                    borderBottom: "1px solid rgb(230, 230, 230)",
                    display: "flex",
                  }}
                >
                  <div
                    style={{
                      borderRight: "1px solid rgb(230, 230, 230)",
                      width: "50%",
                      padding: "10px",
                    }}
                  >
                    <Typography className="soilProperType">
                      Macro Nutrients
                    </Typography>

                    <div style={{ display: "flex" }}>
                      <div style={{ width: "50%" }}>
                        <p className="basic-detail-key">Nitrogen:</p>
                      </div>
                      <div style={{ width: "50%" }}>
                        <p>
                          {npk != null && Object.keys(npk).length
                            ? npk.nitrogen + "kg/ha"
                            : "No info available"}
                        </p>
                      </div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div style={{ width: "50%" }}>
                        <p className="basic-detail-key">Phosphorus: </p>
                      </div>
                      <div style={{ width: "50%" }}>
                        <p>
                          {npk != null && Object.keys(npk).length
                            ? npk.phosphorus + "kg/ha"
                            : "No info available"}
                        </p>
                      </div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div style={{ width: "50%" }}>
                        <p className="basic-detail-key">Potassium: </p>
                      </div>
                      <div style={{ width: "50%" }}>
                        <p>
                          {npk != null && Object.keys(npk).length
                            ? npk.potassium + "kg/ha"
                            : "No info available"}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      width: "50%",
                      padding: "10px",
                    }}
                  >
                    <Typography className="soilProperType">
                      Micro Nutrients
                    </Typography>
                 
                    <div style={{ display: "flex" }}>
                      <div style={{ width: "50%" }}>
                        <p className="basic-detail-key">Zinc: </p>
                      </div>
                      <div style={{ width: "50%" }}>
                        <p>
                          {npk != null && Object.keys(npk).length
                            ? npk.zinc + "ppm"
                            : "No info available"}
                        </p>
                      </div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div style={{ width: "50%" }}>
                        <p className="basic-detail-key">Iron: </p>
                      </div>
                      <div style={{ width: "50%" }}>
                        <p>
                          {npk != null && Object.keys(npk).length
                            ? npk.iron + "ppm"
                            : "No info available"}
                        </p>
                      </div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div style={{ width: "50%" }}>
                        <p className="basic-detail-key">Sulphur: </p>
                      </div>
                      <div style={{ width: "50%" }}>
                        <p>
                          {npk != null && Object.keys(npk).length
                            ? npk.sulphur + "ppm"
                            : "No info available"}
                        </p>
                      </div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div style={{ width: "50%" }}>
                        <p className="basic-detail-key">Manganese: </p>
                      </div>
                      <div style={{ width: "50%" }}>
                        <p>
                          {npk != null && Object.keys(npk).length
                            ? npk.manganese + "ppm"
                            : "No info available"}
                        </p>
                      </div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div style={{ width: "50%" }}>
                        <p className="basic-detail-key">Copper: </p>
                      </div>
                      <div style={{ width: "50%" }}>
                        <p>
                          {npk != null && Object.keys(npk).length
                            ? npk.copper + "ppm"
                            : "No info available"}
                        </p>
                      </div>
                    </div>

                    <div style={{ display: "flex" }}>
                      <div style={{ width: "50%" }}>
                        <p className="basic-detail-key">Boron: </p>
                      </div>

                      <div style={{ width: "50%" }}>
                        <p>
                          {npk != null && Object.keys(npk).length
                            ? npk.boron + "ppm"
                            : "No info available"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div> */}

                {/* // main div */}
              </div>

              {/* SOIL PROPERTIES----------------------------------------------------------------- */}

              {/* CROP HISTORY -------------------->>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */}
              <div className="featureBox">
                <div className="headBotomLine">
                  <Typography className="detailhead">Crop History</Typography>
                </div>

                <div style={{ display: "flex" }}>
                  <div className="cropBox">
                    <div className="rabikharif">
                      <Typography className="seasonName">kharif</Typography>
                    </div>

                    <Typography
                      className="seasoncrop"
                      dangerouslySetInnerHTML={{
                        __html: getCropHistory("kharif"),
                      }}
                    ></Typography>
                  </div>

                  <div className="cropBox">
                    <div className="rabikharif">
                      <Typography className="seasonName">rabi</Typography>
                    </div>

                    <Typography
                      className="seasoncrop"
                      dangerouslySetInnerHTML={{
                        __html: getCropHistory("rabi"),
                      }}
                    ></Typography>
                  </div>
                </div>
              </div>
              {/* CROP HISTORY ENDS----------------------->>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */}

              {/* CORP HEALTH -------------------------->>>>>>>>>>>>>>>>>>>>>>>. */}
              <div className="featureBox">
                <div className="headBotomLine">
                  <Typography className="detailhead">
                    Crop Health (Rabi 2021-2022)
                  </Typography>
                </div>

                <div
                  className={"highchart"}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "2%",
                  }}
                >
                  <div>
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={pieChartOptions}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "18%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "#9fc088",
                          width: "14px",
                          height: "14px",
                          marginLeft: "5px",
                          borderRadius: "8px",
                        }}
                      ></div>
                      <span className="healthChartDetails">Good Area </span>

                      <span className="healthChartDetails">{health?.good}</span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "#e8c07d",
                          width: "14px",
                          height: "14px",
                          marginLeft: "5px",
                          borderRadius: "8px",
                        }}
                      ></div>
                      <span className="healthChartDetails">Moderate Area </span>

                      <span className="healthChartDetails">
                        {health?.average}
                      </span>
                    </div>
                    {/* <div style={{ display: "flex", alignItems: "center" }}> */}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "#f55353",
                          width: "14px",
                          height: "14px",
                          marginLeft: "5px",
                          borderRadius: "8px",
                        }}
                      ></div>
                      <span className="healthChartDetails">Poor Area </span>

                      <span className="healthChartDetails">{health?.poor}</span>
                    </div>
                  </div>
                </div>
              </div>
              {/* CROP HEALTH ENDS---------------------------------->>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */}
              {/* <div className="sectionHeader">List of Suitable Crops</div> */}

              {/* CROP SECTION ----------------------------->>>>>>>>>>>>>>>>>>>> */}
              <div className="featureBox">
                <div className="headBotomLine">
                  <Typography className="detailhead">
                    List of Suitable Crops
                  </Typography>
                </div>
                <Row gutter={[10, 10]} style={{ marginTop: "10px" }}>
                  <Col span={12} className="gutter-row">
                    <div className="inputBoxCrop">
                      <Typography className={"headings"}>
                        Based on Soil Texture
                      </Typography>
                      <div className="inputcrop">
                        {soilTxtData.data?.recommended_crops?.length ? (
                          soilTxtData.data.recommended_crops.map((item) => (
                            <Tag
                              style={{ margin: "3px", borderRadius: "15px" }}
                              // color="rgb(249, 255, 194)"
                              // color="#C9EBBA"
                            >
                              <Typography style={{ color: "#3B3939" }}>
                                {item}
                              </Typography>
                            </Tag>
                          ))
                        ) : (
                          <span className={"recomendCrop"}>NO DATA</span>
                        )}
                      </div>
                    </div>
                  </Col>
                  <Col span={12} className="gutter-row">
                    <div className="inputBoxCrop">
                      <Typography className={"headings"}>
                        Based on Soil Nutrients
                      </Typography>
                      <div className="inputcrop">
                        {recomndCrop.data?.recommended_crops?.length ? (
                          recomndCrop.data.recommended_crops.map((item) => (
                            <Tag
                              style={{ margin: "3px", borderRadius: "15px" }}
                              // color="rgb(249, 255, 194)"
                              // color="green"
                            >
                              <Typography style={{ color: "#3B3939" }}>
                                {item}
                              </Typography>
                              {/* {item} */}
                            </Tag>
                          ))
                        ) : (
                          <span className={"recomendCrop"}>NO DATA</span>
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Fragment>
        )}
      </Modal>
    </div>
  );
};

export default FarmerReportDialog;
