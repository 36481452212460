import React from "react";

const HindiText = {
  LOGIN: "लॉग इन करें",
  GET_STARTED: "शुरू करें",
  THANK_YOU_FOR_SHOWING_INTEREST: "एग्रीबाजार में रुचि दिखाने के लिए धन्यवाद।",
  PLEASE_ENTER_MOBILE_NUMBER:
    "कृपया आरंभ करने के लिए अपना मोबाइल नंबर दर्ज करें।",
  MOBILE_NO: "मोबाइल नंबर",
  ENTER_YOUR_MOBILE: "अपना मोबाइल नंबर दर्ज करें।",
  SEND_OTP: "OTP भेजें",
  VERIFY_YOUR_NUMBER:
    "को भेजे गए 4 अंकों के ओटीपी को दर्ज करके अपना नंबर सत्यापित करें",
  NOT_YOURS: "अनुपयुक्त?",
  CHANGE_NUMBER: "नंबर बदलें",
  SUBMIT: "सबमिट करें",
  SIGN_UP: "साइन अप करें",
  NO_ACCOUNT:"खाता नहीं है ?",
  NAME:'नाम',
  FIRST_NAME:'पहला नाम',
  USER_TYPE:'उपभोक्ता के प्रकार'


};

export default HindiText;
