import React, { useState, useEffect } from "react";
import Sider from "antd/es/layout/Sider";
import {
  DoubleRightOutlined,
  AuditOutlined,
  UserOutlined,
  PhoneOutlined,
  MailOutlined,
} from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import { Tooltip, Collapse, Button, Typography, Input, Pagination } from "antd";
import "./Farms.css";

const NewFarms = ({
  collapsedBtn,
  updatedCollapsedBtn,
  allFarms,
  farmCount,
  mapLoader,
  setMapLoader,
  setFarmerDetails,
  farmerDetails,
  mapRef,
  searchForFarmMobile,
  searchForKhasra,
  setFarmMobileNum,
  farmMobileNum,
  setFarmName,
  farmName,
  rasterLayer,
  setFarmsPaginataion,
  farmsPaginataion,
  getAllFarms,
  setCurrentPage,
  getFarmerScoreCard_Farms,
  KhasraScreenShort,
}) => {
  const location = useLocation();
  const { Panel } = Collapse;
  const { Search } = Input;
  const [geoLatLngs, setGeoLatLngs] = useState([]);
  const [farmsCount, setFarmsCount] = useState(false);

  const [isDrawer, setIsDrawer] = useState(false);

  useEffect(() => {
    updatedCollapsedBtn(!collapsedBtn);
  }, [updatedCollapsedBtn]);
  useEffect(() => {
    if (location.pathname == "/agribhumi/farms") {
      //   updatedColapsed(!collapsedBtn);
    }
  }, [location.pathname]);

  const getFarmerId = async (key) => {
    setMapLoader(true);
    let apikey = apiurls.find((item) => item.apikey == "geotaggedfarms");
    const res = await axios({
      method: "GET",
      url: `${appconfig.domain[appconfig.environment].geotaggedfarms}${
        apikey.paths.geotagedfarmerdetails
      }`,
      headers: {
        "Client-Name": "IOS_REACT_NATIVE_APP",
        "Client-Token": userToken,
        "X-localization": "en",
      },
      params: {
        customer_type: "web",
        farmer_id: key,
      },
    });
    setMapLoader(false);
    setFarmerDetails(res.data.data);
    setFarmersScore({
      ...farmersScore,
      basic_details: res.data.data,
    });
    if (res.data.data) {
      setSuccessId(true);
    }
  };

  // GET LATLONGS AND SHOW FARM ON MAP WHEN CLICKED ON A GEPTAGGED FARM
  const drawFarmPolygon = (coords) => {
    let farmLatlongs = [];
    mapRef.current.eachLayer(function (layer) {
      if (layer instanceof L.Polygon) {
        mapRef.current.removeLayer(layer);
      }
    });
    if (rasterLayer && mapRef.current.hasLayer(rasterLayer)) {
      mapRef.current.removeLayer(rasterLayer);
    }

    var latlng = [];
    var farmLatlng = [];
    if (coords.length) {
      coords.forEach((item, index) => {
        let arr = [];
        let arr2 = [];
        arr.push(item[1]);

        arr.push(item[0]);
        arr2.push(item[0]);
        arr2.push(item[1]);

        latlng.push(arr);
        farmLatlng.push(arr2);

        if (index == coords.length - 1) {
          let arr3 = [];
          arr3.push(coords[0][0]);
          arr3.push(coords[0][1]);
          farmLatlng.push(arr3);
        }
      });
    } else {
      warning();
    }

    farmLatlongs = farmLatlng;
    var polygon = L.polygon(latlng, {
      fillColor: "transparent",
      weight: 3,
      opacity: 1,
      color: "#001840",
      fillOpacity: 1,
    });
    polygon.addTo(mapRef.current);
    mapRef.current.fitBounds(polygon.getBounds());
  };
  return (
    <Sider
      collapsible={true}
      width={300}
      collapsedWidth={0}
      trigger={null}
      collapsed={collapsedBtn}
      className="rightSider"
      style={{
        // position: "absolute",
        // bottom: 0,
        zIndex: 1000,
        transition: "all 0.2s",
        padding: "0px 15px",
      }}
    >
      <div>
        <DoubleRightOutlined
          onClick={() => updatedCollapsedBtn(!collapsedBtn)}
          className="trigger"
          style={{
            color: "#ffffff",
            padding: 0,
            display: "flex",
            alignItems: "center",
          }}
        />

        <Collapse style={{ color: "#ffffff" }}>
          <Panel
            header={
              <Typography style={{ color: "#FFFFFF" }}>
                search here by name/number
              </Typography>
            }
          >
            <Search
              placeholder="Search by Farm name"
              onChange={(event) => setFarmName(event.target.value)}
              onSearch={(value) => searchForKhasra(value)}
              style={{ width: 200, marginLeft: "10px", marginTop: "0" }}
            />
            <br />
            <Typography style={{ color: "#ffffff", textAlign: "center" }}>
              Or
            </Typography>
            <Search
              placeholder="Search by mobile no."
              onChange={(event) => setFarmMobileNum(event.target.value)}
              onSearch={(value) => searchForFarmMobile(value)}
              style={{ width: 200, marginLeft: "10px" }}
            />
          </Panel>
        </Collapse>
      </div>
      {/* for geotaggedfarms */}
      <div
        style={{ height: "100vh", overflowY: "scroll" }}
        // onScroll={handleScrollEvent}
      >
        <div className={"searchingFarms"}></div>
        {allFarms?.map((item) => (
          <div style={{ marginTop: "16%" }}>
            <div className={"farmsCards"}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "10px",
                }}
              >
                <Tooltip
                  title="Generate Farmer Scorecard"
                  placement="left"
                  color={"#ffffff"}
                  overlayInnerStyle={{ color: "#424242" }}
                >
                  <Button
                    shape="circle"
                    onClick={() => {
                      getFarmerScoreCard_Farms(
                        1,
                        item.state_name,
                        item.district_name,
                        item.village_code,
                        item.khasra,
                        item.geojson
                      );
                      getFarmerScoreCard_Farms(
                        2,
                        item.state_name,
                        item.district_name,
                        item.village_code,
                        item.khasra,
                        item.geojson
                      );
                      getFarmerScoreCard_Farms(
                        3,
                        item.state_name,
                        item.district_name,
                        item.village_code,
                        item.khasra,
                        item.geojson
                      );
                      getFarmerScoreCard_Farms(
                        4,
                        item.state_name,
                        item.district_name,
                        item.village_code,
                        item.khasra,
                        item.geojson
                      );
                      getFarmerScoreCard_Farms(
                        5,
                        item.state_name,
                        item.district_name,
                        item.village_code,
                        item.khasra,
                        item.geojson
                      );
                      KhasraScreenShort(item.geojson);
                    }}
                    icon={<AuditOutlined />}
                  />
                </Tooltip>
              </div>
              <Collapse>
                <Panel
                  header={
                    <Typography className={"cardsHead"}>
                      <span className={"cardSpantag"}>Farm Name : </span>
                      <span className={"farmersFarmName"}>
                        {item.khasra != "" ? item.khasra : "--"}
                      </span>
                    </Typography>
                  }
                  key={item.farmer_id}
                  // onClick={() => getFarmerId(item.farmer_id)}
                >
                  {mapLoader == false && (
                    <>
                      <div className={"colapsefarmersCardDetails2"}>
                        <UserOutlined style={{ marginRight: "3px" }} />
                        {item.name}
                      </div>

                      <div className={"colapsefarmersCardDetails2"}>
                        <PhoneOutlined style={{ marginRight: "3px" }} />
                        {item.phone}
                      </div>

                      <div className={"colapsefarmersCardDetails2"}>
                        <MailOutlined style={{ marginRight: "3px" }} />
                        {item.email}
                      </div>
                    </>
                  )}
                </Panel>
              </Collapse>
              <Tooltip title="Click here to show your farm." placement="bottom">
                <div
                  style={{
                    backgroundColor: " rgb(48, 48, 48)",
                    padding: "6%",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    drawFarmPolygon(item?.geojson);
                    setGeoLatLngs(item?.geojson);
                  }}
                >
                  {item.khasra == "" ? (
                    <Typography className={"colapsefarmersCardDetails"}>
                      <span className={"colapsespantag"}>Khasra </span>
                      <div className={"colapsefarmersDetail"}>
                        <span>--</span>
                      </div>
                    </Typography>
                  ) : (
                    <Typography className={"colapsefarmersCardDetails"}>
                      <span className={"colapsespantag"}>Khasra </span>
                      <div className={"colapsefarmersDetail"}>
                        <span>{item.khasra}</span>
                      </div>
                    </Typography>
                  )}

                  <Typography className={"colapsefarmersCardDetails"}>
                    <span className={"colapsespantag"}>Area </span>
                    <span className={"colapsefarmersDetail"}>
                      {parseFloat(item.area).toFixed(2)} Hec
                    </span>
                  </Typography>
                </div>
              </Tooltip>
            </div>
          </div>
        ))}
      </div>
      {allFarms.length > 0 && (
        <div>
          <Pagination
            className="ant-pagination-item ant-pagination-item"
            size="small"
            showQuickJumper
            defaultCurrent={1}
            total={farmsPaginataion * 10}
            style={{ color: "#ffffff" }}
            onChange={(page, pageSize, event) => {
              setFarmsCount(true);
              // getAllFarms(page, "", "");
              getAllFarms(page * 20 - 20, farmName, farmMobileNum);
              setCurrentPage(page * 20 - 20);
            }}
          />
        </div>
      )}
    </Sider>
  );
};

export default NewFarms;
