import { Layout } from "antd";
import React from "react";
import { Route, Routes } from "react-router-dom";
import "./NavigationLayout.css";
import Protected from "./Potected";
import AppLogout from "./components/AppLogout/AppLogout";
import HomePage from "./components/HomePage/HomePage";
import LoginPage from "./components/LoginPage/LoginPage";
import Navbar from "./components/Navbar/Navbar";
import Signup from "./components/Signup/Signup";
import Dashboard from "./screens/Dashboard/Dashboard";

const { Header, Content } = Layout;

const NavigationLayout = () => {
  return (
    <div style={{ height: "100%", overflowX: "hidden" }}>
      <AppLogout>
        <Layout style={{ height: "100%" }}>
          <Header className={"headerBox"}>
            <Navbar />
          </Header>
          <Content className={"contentBox"}>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/signup" element={<Signup />} />
              <Route
                path="/agribhumi/:id"
                element={<Protected Cmp={Dashboard} />}
              />
            </Routes>
          </Content>
        </Layout>
      </AppLogout>
    </div>
  );
};

export default NavigationLayout;
