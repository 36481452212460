export const appconfig = {
  isEncryption: 1,
  isNafedEncryption: 1,
  environment: "PRODUCTION",
  domain: {
    PRODUCTION: {
      agribazaar: "https://api.agribazaar.com/",
      agribazaar2: "https://api.agribazaar.com/",
      agribhumi: "https://api.agribhumi.com/",
      agribhumi2: "https://api.agribhumi.com/",
      score: "https://api.agribhumi.com/",
      fasttrack: "https://api.agribazaar.com/",
      blog: "https://bloguat.agribazaar.com/",
      enterprise: "https://api.agribhumi.com/",
      geohost: "https://gs.agribazaar.com/",
      indexhost: "https://agribhumi.agribazaar.com/",
      noderefresh: "https://api2.agribazaar.com/",
      nodepost: "https://api3.agribazaar.com/",
      farmservice: "https://farms-service.agribazaar.com",
      soildata: "https://api.agribhumi.com/",
      getfarms: "https://farms-service.agribazaar.com/",
      farmreport: "https://agribhumi.agribazaar.com/",
      geotaggedfarms: "https://farmservicesapi.agribazaar.com/",
      aws3: "https://agribhumi.s3.ap-south-1.amazonaws.com/",
    },
    UAT: {
      agribazaar: "https://apiuat.agribazaar.com/",
      agribazaar2: "https://apiuat.agribazaar.com/",
      agribhumi: "https://apiuat.agribhumi.com/",
      agribhumi2: "https://apiuat.agribhumi.com/",
      score: "https://apiuat.agribhumi.com/",
      fasttrack: "https://apiuat.agribazaar.com/",
      blog: "https://bloguat.agribazaar.com/",
      enterprise: "https://apiuat.agribhumi.com/",
      geohost: "https://gsuat.agribazaar.com/",
      indexhost: "https://agribhumiuat.agribazaar.com/",
      noderefresh: "https://api2uat.agribazaar.com/",
      nodepost: "https://api3uat.agribazaar.com/",
      farmservice: "https://farms-service.agribazaar.com",
      soildata: "https://apiuat.agribhumi.com/",
      getfarms: "https://farms-service.agribazaar.com/",
      geotaggedfarms: "https://farmservicesapi.agribazaar.com/",
      aws3: "https://agribhumi.s3.ap-south-1.amazonaws.com/",
    },
    DEMO: {
      agribazaar: "https://apidemo.agribazaar.com/",
      fasttrack: "https://apidemo.agribazaar.com/",
      blog: "https://blogdemo.agribazaar.com/",
      enterprise: "https://businessdemo.agribazaar.com/",
      geohost: "https://gsdemo.agribazaar.com/",
      indexhost: "https://agribhumidemo.agribazaar.com/",
      noderefresh: "https://api2demo.agribazaar.com/",
      nodepost: "https://api3demo.agribazaar.com/",
    },
    STG: {
      agribazaar: "http://apistg.agribazaar.com/",
      fasttrack: "http://novastg.agribazaar.com/",
      blog: "http://blogstg.agribazaar.com/",
      enterprise: "http://businessstg.agribazaar.com/",
      geohost: "http://gsstg.agribazaar.com/",
      noderefresh: "http://api2uat.agribazaar.com/",
      nodepost: "http://api3uat.agribazaar.com/",
    },
  },
  route: {
    PRODUCTION: "https://www.agribazaar.com/",
    DEMO: "https://demo.agribazaar.com/",
    STG: "http://stg.agribazaar.com/",
    UAT: "http://uat.agribazaar.com/",
  },
  enterprise: {
    PRODUCTION: "https://business.agribazaar.com/#/login",
    UAT: "http://adminuat.agribazaar.com/#/login",
    DEMO: "https://businessdemo.agribazaar.com/#/login",
    STG: "http://adminstg.agribazaar.com/#/login",
  },
  mockserver: "http://localhost:4200",
};
