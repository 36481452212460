import "./CropHistoryModal.css";
import { CloseOutlined } from "@ant-design/icons";
import { Modal, Typography } from "antd";
import React, { useEffect } from "react";
import { apiurls } from "../../../resources/APIurls";
import { appconfig } from "../../../resources/config";
let apikey_s3 = apiurls.find((item) => item.apikey == "aws3");
const aws_s3_url = `${appconfig.domain[appconfig.environment].aws3}${
  apikey_s3.paths.images
}`;

const CropHistoryModal = (props) => {
  let apikey_s3 = apiurls.find((item) => item.apikey == "aws3");
  const aws_s3_url = `${appconfig.domain[appconfig.environment].aws3}${
    apikey_s3.paths.images
  }`;
  const {
    cropHistoryDialog,
    setCropHistoryDialog,
    cropHistory,
    cropHistoryLoader,
    locationFilters,
  } = props;

  useEffect(() => {}, [locationFilters]);
  const getReportCropHistory = (str) => {
    let history = "";

    for (let key in cropHistory) {
      if (key.includes(str)) {
        history += `<span style="color:#3B3939; font-weight: 700; font-size:14px;" >${key} : </span>  <span style="color:#3B3939; font-weight:400; font-size:11px;"  > ${
          cropHistory[key] != null
            ? cropHistory[key].map((item) => item.name)
            : "--"
        }
        </span><br />`;
      }
    }
    return history;
  };

  return (
    <Modal
      title=""
      open={cropHistoryDialog}
      onCancel={() => setCropHistoryDialog(false)}
      footer={null}
      bodyStyle={{ backgroundColor: "#212121 !important" }}
      width={750}
      centered
      maskClosable={true}
      closeIcon={<CloseOutlined style={{ color: "#424242" }} />}
    >
      <div className="agrlogo">
        <img style={{ width: "14%" }} src={`${aws_s3_url}/logo.svg`} />
        <img
          style={{ width: "14%" }}
          src={`${aws_s3_url}/AgribazaarLogo.svg`}
        />
      </div>
      <div style={{ marginTop: "25px" }}>
        {cropHistoryLoader && Object.keys(cropHistory).length === 0 ? (
          <img src={`${aws_s3_url}/loader.gif`} />
        ) : (
          <>
            {/* <div className="crop-history-dialog-header">Crop History</div> */}
            <div>
              <div>
                <div
                  style={{
                    borderTop: "1px solid #ffffff",
                    borderBottom: "1px solid #ffffff",
                    display: "flex",
                    background: "rgb(54, 76, 1)",
                  }}
                >
                  <div
                    style={{
                      borderRight: "1px solid #ffffff",
                      width: "50%",
                      padding: "10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                      }}
                    >
                      <div style={{ width: "50%" }}>
                        <p
                          style={{
                            fontWeight: 600,
                            fontSize: "18px",
                            color: "#FFFFFF",
                          }}
                        >
                          State :{" "}
                        </p>
                      </div>
                      <div style={{ width: "50%" }}>
                        <p style={{ fontSize: "18px", color: "#FFFFFF" }}>
                          {locationFilters?.state?.label
                            ? locationFilters?.state?.label
                            : "N/A"}
                        </p>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                      }}
                    >
                      <div style={{ width: "50%" }}>
                        <p
                          style={{
                            fontWeight: 600,
                            fontSize: "18px",
                            color: "#FFFFFF",
                          }}
                        >
                          District :{" "}
                        </p>
                      </div>
                      <div style={{ width: "50%" }}>
                        <p style={{ fontSize: "18px", color: "#FFFFFF" }}>
                          {locationFilters?.city?.label
                            ? locationFilters?.city?.label
                            : "N/A"}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      borderRight: "1px solid #ffffff",
                      width: "50%",
                      padding: "10px",
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <div style={{ width: "50%" }}>
                        <p
                          style={{
                            fontWeight: 600,
                            fontSize: "18px",
                            color: "#FFFFFF",
                          }}
                        >
                          Village :{" "}
                        </p>
                      </div>
                      <div style={{ width: "50%" }}>
                        <p style={{ fontSize: "18px", color: "#FFFFFF" }}>
                          {locationFilters?.village?.label
                            ? locationFilters?.village?.label
                            : "N/A"}
                        </p>
                      </div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div style={{ width: "50%" }}>
                        <p
                          style={{
                            fontWeight: 600,
                            fontSize: "18px",
                            color: "#FFFFFF",
                          }}
                        >
                          Khasra :{" "}
                        </p>
                      </div>
                      <div style={{ width: "50%" }}>
                        <p style={{ fontSize: "18px", color: "#FFFFFF" }}>
                          {locationFilters?.khasra?.label
                            ? locationFilters?.khasra?.label
                            : "N/A"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="featureBox">
              <div className="headBotomLine">
                <Typography className="detailhead">Crop History</Typography>
              </div>

              <div style={{ display: "flex" }}>
                <div className="cropBox">
                  <div className="rabikharif">
                    <Typography className="seasonName">kharif</Typography>
                  </div>

                  <Typography
                    className="seasoncrop"
                    dangerouslySetInnerHTML={{
                      __html: getReportCropHistory("kharif"),
                    }}
                  ></Typography>
                </div>

                <div className="cropBox">
                  <div className="rabikharif">
                    <Typography className="seasonName">rabi</Typography>
                  </div>

                  <Typography
                    className="seasoncrop"
                    dangerouslySetInnerHTML={{
                      __html: getReportCropHistory("rabi"),
                    }}
                  ></Typography>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

export default CropHistoryModal;
