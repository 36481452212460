import { BrowserRouter } from "react-router-dom";
import "./App.css";
import NavigationLayout from "./NavigationLayout";
import "antd/dist/reset.css";
import axios from "axios";
import { createMemoryHistory } from "history";

function App() {
  const history = createMemoryHistory();

  axios.defaults.headers.common["Authorization"] =
    localStorage.getItem("token");

  return (
    <div className="App">
      <BrowserRouter location={history.location} navigator={history}>
        <NavigationLayout />
      </BrowserRouter>
    </div>
  );
}

export default App;
