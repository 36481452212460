export const SoilDataContent={
    "bulkdensity":"Bulk density in soil data is like measuring how heavy the soil is for a given amount of space. It tells us how tightly packed or compacted the soil is, which can affect plant growth and water movement underground.",
    "cationexchangecapacity":"Cation Exchange Capacity (CEC) in soil data is like the soil's ability to hold onto and exchange positively charged nutrients, such as Ca, K, Mg, etc. It influences the soil's ability to hold onto essential nutrients and provides a buffer against soil acidification.",
    "organiccarbondensity":"Organic carbon density in soil data refers to the amount of carbon contained in organic matter, like plant and animal residues etc within a certain volume or area of soil.",
    "organiccarbonstock":"In general the amount of carbon retained in the soil after the decomposition of the organic content is organic carbon stock. It is mainly responsible for maintaining the soil microbial activity which are directly and indirectly involved in nutrient fixation.",
    "phwater":"Soil Ph, measured in water, indicates whether the soil is acidic (with a lower Ph) or alkaline (with a higher Ph). This characteristic is crucial for understanding its impact on plant growth and nutrient availability.",
    "sandpercent":"Sand percent in soil data refers to the proportion of sand particles present in the soil. It tells us about the soil's texture and drainage capability. Generally the particle size of sand fragments in a soil ranged from 0.05 to 2.0 millimeters in diameter.",
    "siltpercent":"Silt percent in soil data indicates the quantity of silt particles within the soil, which are smaller than sand but larger than clay particles. Generally the particle size of silt in a soil ranged from 2 to 50 micrometers (or 0.002 to 0.05 mm.).",
    "soilorganiccarbon":"Soil organic carbon in soil data refers to the carbon found within organic materials in the soil, such as decomposed plants and animals. This carbon is crucial as it aids in moisture retention, supplies nutrients to plants, and enhances the overall health of the soil.",
    "claypercent":"Clay percent in soil data indicates the proportion of tiny clay particles within the soil. This measurement provides insights into the soil's texture and its capacity to retain water and essential nutrients, crucial for supporting plant growth. Clay particles are generally less than 0.02 millimeters in diameter.",
    "coarsefragment":"Coarse fragments in soil data refer to larger pieces of rocks, stones, or gravel found within the soil."
}