import { CHANGE_LANGUAGE, WEATHER_INFO } from "./action";

const initialState = { language: "english" };
const weatherDetail = {
  
  info:{data:{current_condition:[]}},
  prediction:{data:{weather:[]}},
};



export const Language = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_LANGUAGE:
      return { ...state, language: action.payload };
    default:
      return state;
  }
};

export const weather = (state = weatherDetail , action) => {
  switch (action.type) {
    case WEATHER_INFO:
      return { ...state, info: action.payload, prediction : action.payload };
      break;
      

    default:
      return state;
  }
};
