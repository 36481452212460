import { ArrowLeftOutlined, CloseOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Modal,
  Row,
  Select,
  Spin,
  Tooltip,
  Typography,
} from "antd";
import Input from "rc-input";
import React, { useEffect } from "react";
import "./Advisory.css";
import { apiurls } from "../../../resources/APIurls";
import { appconfig } from "../../../resources/config";
let apikey_s3 = apiurls.find((item) => item.apikey == "aws3");
const aws_s3_url = `${appconfig.domain[appconfig.environment].aws3}${
  apikey_s3.paths.images
}`;

const { Option } = Select;

const AdvisoryModal = (props) => {
  const {
    isModalOpen,
    handleOk,
    handleCancel,
    isCropModelOpen,
    selectAdvisory,
    farmAdvisory,
    setSeasons,
    fertilizerRecomn,
    getNpkValues,
    setNitrogen,
    setPhosphorus,
    setPotassium,
    setSilt,
    setSand,
    setClay,
    selectedCrop,
    soilTexture,
    fertiliedrecommenedCrop,
    fertilizedValues,
    nitrogen,
    phosphorus,
    potassium,
    showCropModal,
    goBack,
    seasons,
    silt,
    sand,
    clay,
    loader,
  } = props;

  useEffect(() => {
    setNitrogen("");
    setPhosphorus("");
    setPotassium("");
  }, [farmAdvisory]);

  const getCropData = () => {
    if (nitrogen !== "" && phosphorus !== "" && potassium !== "") {
      showCropModal();
    } else if (silt !== "" && clay !== "" && sand !== "") {
      showCropModal();
    }
  };

  return (
    <Modal
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      centered="true"
      width={980}
      closeIcon={
        <CloseOutlined style={{ color: "#212121", marginRight: "4px" }} />
      }
      bodyStyle={{
        backgroundColor: "rgb(249, 255, 194)",
        padding: "18px",
      }}
    >
      <div className="agrlogo">
        <img style={{ width: "14%" }} src={`${aws_s3_url}/logo.svg`} />
        <img
          style={{ width: "14%" }}
          src={`${aws_s3_url}/AgribazaarLogo.svg`}
        />
      </div>
      {isCropModelOpen != true ? (
        <div
          style={{
            // marginTop: "4%",
            paddingBottom: "2%",
            // background: "#9ACD32",
          }}
        >
          <Select
            placeholder="Select Farm advisory"
            value={farmAdvisory}
            style={{
              width: "-webkit-fill-available",
              color: "#212121",
              border: "#212121",
            }}
            onChange={(val, option) => selectAdvisory(val)}
          >
            <Option value="crop-selection" style={{ color: "#212121" }}>
              <Tooltip
                title="Recommendation of suitable crops based on the nutrients availble in the soil."
                placement="right"
              >
                Crop Selection (N,P,K)
              </Tooltip>
            </Option>
            <Option value="fertilizer">
              <Tooltip
                title="A fertilizer calculator is a tool that helps farmers to determine the amount of fertilizer they need to apply to their crops or plants."
                placement="right"
              >
                Fertilizer Recommendation
              </Tooltip>
            </Option>
            <Option value="soil-texture">
              <Tooltip
                title="Recommendation of suitable crops based on the soil types and texture."
                placement="right"
              >
                Crop Selection (Soil Texture)
              </Tooltip>
            </Option>
          </Select>

          <div className={"modelInnerDiv"}>
            {farmAdvisory != "" && (
              <>
                <div className={"innerselect"}>
                  {farmAdvisory == "fertilizer" && (
                    <Select
                      placeholder="Crop"
                      value={seasons || undefined}
                      style={{ width: "386px" }}
                      showSearch
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      onChange={(val) => {
                        setSeasons(val);
                      }}
                    >
                      {fertilizerRecomn.map((item, index) => {
                        return (
                          <Option value={item.crop_id} key={index}>
                            {item.crop_name.split("_").join(" ")}
                          </Option>
                        );
                      })}
                    </Select>
                  )}

                  <Typography
                    style={{
                      fontstyle: "normal",
                      fontWeight: "500",
                      fontSize: "12px",
                      lineHeight: "18px",
                      // color: "#FFFFFF",
                      color: "#212121",
                      textTransform: "capitalize",
                    }}
                  >
                    enter details mannualy or
                  </Typography>
                  <Button
                    className={"farmbtn"}
                    style={{
                      // backgroundColor: "#4AAB28",
                      backgroundColor: "rgb(54, 76, 1)",
                      color: "#FFFFFF",
                      border: "none",
                    }}
                    onClick={getNpkValues}
                  >
                    click on your farm
                  </Button>
                </div>
                {farmAdvisory !== "soil-texture" && (
                  <div className={"bottomDiv"}>
                    <div>
                      <Typography
                        className={"bottomtext"}
                        style={{
                          fontWeight: "500",
                          fontSize: "12px",
                          lineHeight: "18px",
                          color: "#424242",
                        }}
                      >
                        Nitrogen(N) (Kg/ha)
                      </Typography>
                      <Input
                        style={{
                          width: "293px",
                          border: "1px solid #d9d9d9",
                          borderRadius: "3px",
                          marginTop: "4px",
                        }}
                        type="number"
                        value={nitrogen}
                        onChange={(e) => setNitrogen(e.target.value)}
                      />
                    </div>
                    <div>
                      <Typography
                        className={"bottomtext"}
                        style={{
                          fontWeight: "500",
                          fontSize: "12px",
                          lineHeight: "18px",
                          color: "#424242",
                        }}
                      >
                        Phosphorus(P) Kg/ha
                      </Typography>
                      <Input
                        style={{
                          width: "293px",
                          border: "1px solid #d9d9d9",
                          borderRadius: "3px",
                          marginTop: "4px",
                        }}
                        type="number"
                        value={phosphorus}
                        onChange={(e) => setPhosphorus(e.target.value)}
                      />
                    </div>
                    <div>
                      <Typography
                        className={"bottomtext"}
                        style={{
                          fontWeight: "500",
                          fontSize: "12px",
                          lineHeight: "18px",
                          color: "#424242",
                        }}
                      >
                        Potassium(K) Kg/ha
                      </Typography>
                      <Input
                        style={{
                          width: "293px",
                          border: "1px solid #d9d9d9",
                          borderRadius: "3px",
                          marginTop: "4px",
                        }}
                        type="number"
                        value={potassium}
                        onChange={(e) => setPotassium(e.target.value)}
                      />
                    </div>
                  </div>
                )}

                {farmAdvisory == "soil-texture" && (
                  <div className={"bottomDiv"}>
                    <div>
                      <Typography
                        className={"bottomtext"}
                        style={{
                          fontWeight: "500",
                          fontSize: "12px",
                          lineHeight: "18px",
                          color: "#424242",
                        }}
                      >
                        Silt(%)
                      </Typography>
                      <Input
                        style={{
                          width: "293px",
                          border: "1px solid #d9d9d9",
                          borderRadius: "3px",
                          marginTop: "4px",
                        }}
                        type="number"
                        value={silt}
                        max={98}
                        onChange={(e) => setSilt(e.target.value)}
                      />
                    </div>
                    <div>
                      <Typography
                        className={"bottomtext"}
                        style={{
                          fontWeight: "500",
                          fontSize: "12px",
                          lineHeight: "18px",
                          color: "#424242",
                        }}
                      >
                        Sand(%)
                      </Typography>
                      <Input
                        style={{
                          width: "293px",
                          border: "1px solid #d9d9d9",
                          borderRadius: "3px",
                          marginTop: "4px",
                        }}
                        type="number"
                        value={sand}
                        max={98}
                        onChange={(e) => setSand(e.target.value)}
                      />
                    </div>
                    <div>
                      <Typography
                        className={"bottomtext"}
                        style={{
                          fontWeight: "500",
                          fontSize: "12px",
                          lineHeight: "18px",
                          color: "#424242",
                        }}
                      >
                        Clay(%)
                      </Typography>
                      <Input
                        style={{
                          width: "293px",
                          border: "1px solid #d9d9d9",
                          borderRadius: "3px",
                          marginTop: "4px",
                        }}
                        type="number"
                        value={clay}
                        max={98}
                        onChange={(e) => setClay(e.target.value)}
                      />
                    </div>
                  </div>
                )}
                {farmAdvisory == "soil-texture" ? (
                  <Typography
                    style={{
                      fontWeight: "500",
                      fontSize: "12px",
                      lineHeight: "18px",
                      // color: "#fcfcfc",
                      color: "#212121",
                    }}
                  >
                    Note:- Arithmetic sum of Silt, Sand & Clay must be Less than
                    or equal to 100.
                  </Typography>
                ) : (
                  <Typography
                    style={{
                      fontWeight: "500",
                      fontSize: "12px",
                      lineHeight: "18px",
                      // color: "#fcfcfc",
                      color: "#212121",
                    }}
                  >
                    This is ML-RS generated data and may not be 100% accurate,
                    if you want to proceed with our data, click on submit
                  </Typography>
                )}

                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "end",
                  }}
                >
                  <Button
                    style={{
                      // backgroundColor: "#4AAB28",
                      backgroundColor: "rgb(54, 76, 1)",
                      color: "#FFFFFF",
                      border: "none",
                    }}
                    // disabled={searchDisabled}
                    // onClick={showCropModal}
                    onClick={getCropData}
                  >
                    {farmAdvisory == "fertilizer"
                      ? "Calculate Fertilizer"
                      : "Recommend crop"}
                  </Button>
                </div>
              </>
            )}
          </div>
        </div>
      ) : (
        <div className={"innerModalContainer"}>
          <Button
            style={{
              position: "absolute",
              left: "4%",
              top: "25px",
              background: "none",
              border: "none",
              // color: "#FFFFFF",
              color: "#212121",
              backgroundColor: "#d5eca6",
              fontWeight: 600,
            }}
            onClick={goBack}
          >
            <ArrowLeftOutlined /> Go Back
          </Button>
          {farmAdvisory == "crop-selection" && (
            <div className={"npkcrop"}>
              {loader == true ? (
                <Spin tip="Loading Data..." size="large"></Spin>
              ) : (
                <Row gutter={24}>
                  {selectedCrop.data !== null ? (
                    selectedCrop.data.recommended_crops.map((item, index) => {
                      return (
                        <Col span={6}>
                          <div className={"cropcol"}>
                            <Typography
                              style={{ paddingLeft: "4%", color: "#212121" }}
                            >
                              {index + 1}.
                            </Typography>
                            <Typography
                              style={{ paddingLeft: "25%", color: "#212121" }}
                            >
                              {item}
                            </Typography>
                          </div>
                        </Col>
                      );
                    })
                  ) : (
                    <div>
                      <Typography
                        style={{
                          color: "black",
                          textAlign: "center",
                          alignItems: "center",
                          textTransform: "capitalize",
                          fontSize: "20px",
                          fontWeight: "500",
                        }}
                      >
                        {selectedCrop.message}
                      </Typography>
                    </div>
                  )}
                </Row>
              )}
            </div>
          )}
          {farmAdvisory == "soil-texture" && (
            <div className={"npkcrop"}>
              {loader == true ? (
                <Spin tip="Loading Data..." size="large"></Spin>
              ) : (
                <Row gutter={24}>
                  {soilTexture.map((item, index) => {
                    return (
                      <Col span={6}>
                        <div className={"cropcol"}>
                          <Typography
                            style={{ marginLeft: "3%", width: "20%" }}
                          >
                            {index + 1}.
                          </Typography>
                          <Typography
                            style={{
                              marginLeft: "10%",
                              width: "80%",
                              textAlign: "initial",
                            }}
                          >
                            {item}
                          </Typography>
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              )}
            </div>
          )}

          {farmAdvisory == "fertilizer" && (
            <div className={"main_div"}>
              {loader == true ? (
                <Spin tip="Loading Data..." size="large"></Spin>
              ) : (
                <>
                  <div
                    style={{
                      background: "rgb(54, 76, 1)",
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      style={{
                        // color: "white",
                        color: "#FFFFFF",
                        fontSize: "20px",
                        fontWeight: "700",
                        padding: "1%",
                        textTransform: "capitalize",
                      }}
                    >
                      {" "}
                      crop :{" "}
                      <span style={{ fontSize: "20px", fontWeight: "400" }}>
                        {" "}
                        {fertiliedrecommenedCrop.crop}{" "}
                      </span>
                    </Typography>
                  </div>
                  {Object.keys(fertilizedValues).length > 0 && (
                    <div style={{ width: "48vw" }}>
                      <div>
                        <div
                          style={{
                            borderTop: "1px solid #ffffff",
                            borderBottom: "1px solid #ffffff",
                            display: "flex",
                            textAlign: "center",
                            marginTop: "4%",
                          }}
                        >
                          <div
                            style={{
                              borderRight: "1px solid #ffffff",
                              // background: "rgb(249, 255, 194)",
                              background: "#d5eca6",
                              width: "100%",
                            }}
                          >
                            <div style={{}}>
                              <div
                                style={{
                                  background: "rgb(54, 76, 1)",
                                }}
                              >
                                <p
                                  style={{
                                    fontWeight: 600,
                                    fontSize: "18px",
                                    color: "#FFFFFF",
                                  }}
                                >
                                  Recomendation 1{" "}
                                </p>
                              </div>
                              <div style={{}}>
                                <p>
                                  <span
                                    style={{
                                      color: "#121212",
                                      textTransform: "capitalize",
                                      fontWeight: 700,
                                      fontSize: "14px",
                                    }}
                                  >
                                    DAP :
                                  </span>{" "}
                                  {fertilizedValues[1].dap?.toFixed(2)} Kg/Ha
                                </p>
                                <p>
                                  <span
                                    style={{
                                      color: "#121212",
                                      textTransform: "capitalize",
                                      fontWeight: 700,
                                      fontSize: "14px",
                                    }}
                                  >
                                    {" "}
                                    Potassium chloride :
                                  </span>{" "}
                                  {fertilizedValues[1].potassium_chloride} Kg/Ha
                                </p>
                                <p>
                                  <span
                                    style={{
                                      color: "#121212",
                                      textTransform: "capitalize",
                                      fontWeight: 700,
                                      fontSize: "14px",
                                    }}
                                  >
                                    Urea :
                                  </span>{" "}
                                  {fertilizedValues[1].urea?.toFixed(2)} Kg/Ha
                                </p>
                              </div>
                            </div>
                            <div
                              style={
                                {
                                  // display: "flex",
                                }
                              }
                            >
                              <div
                                style={{
                                  background: "rgb(54, 76, 1)",
                                }}
                              >
                                <p
                                  style={{
                                    fontWeight: 600,
                                    fontSize: "18px",
                                    color: "#FFFFFF",
                                  }}
                                >
                                  Recomendation 2{" "}
                                </p>
                              </div>

                              <div style={{}}>
                                <p>
                                  <span
                                    style={{
                                      color: "#121212",
                                      textTransform: "capitalize",
                                      fontWeight: 700,
                                      fontSize: "14px",
                                    }}
                                  >
                                    Potassium chloride :
                                  </span>{" "}
                                  {fertilizedValues[2].potassium_chloride} Kg/Ha
                                </p>
                                <p>
                                  <span
                                    style={{
                                      color: "#121212",
                                      textTransform: "capitalize",
                                      fontWeight: 700,
                                      fontSize: "14px",
                                    }}
                                  >
                                    {" "}
                                    SSP :
                                  </span>{" "}
                                  {
                                    fertilizedValues[2].single_super_phosphate
                                  }{" "}
                                  Kg/Ha
                                </p>
                                <p>
                                  <span
                                    style={{
                                      color: "#121212",
                                      textTransform: "capitalize",
                                      fontWeight: 700,
                                      fontSize: "14px",
                                    }}
                                  >
                                    Urea :
                                  </span>{" "}
                                  {fertilizedValues[1].urea?.toFixed(2)} Kg/Ha
                                </p>
                              </div>
                            </div>
                          </div>

                          <div
                            style={{
                              borderRight: "1px solid #ffffff",
                              width: "50%",
                              // background: "rgb(249, 255, 194)",
                              background: "#d5eca6",
                              width: "100%",

                              // padding: "10px",
                            }}
                          >
                            <div
                              style={
                                {
                                  //  display: "flex"
                                }
                              }
                            >
                              <div
                                style={{
                                  background: "rgb(54, 76, 1)",
                                }}
                              >
                                <p
                                  style={{
                                    fontWeight: 600,
                                    fontSize: "18px",
                                    color: "#FFFFFF",
                                  }}
                                >
                                  Recomendation 3{" "}
                                </p>
                              </div>

                              <div style={{}}>
                                <p>
                                  <span
                                    style={{
                                      color: "#121212",
                                      textTransform: "capitalize",
                                      fontWeight: 700,
                                      fontSize: "14px",
                                    }}
                                  >
                                    SSP :
                                  </span>{" "}
                                  {/* {fertilizedValues[1].dap?.toFixed(2)} Kg/Ha */}
                                  {fertilizedValues[3].single_super_phosphate?.toFixed(
                                    2
                                  )}{" "}
                                  Kg/Ha
                                </p>
                                <p>
                                  <span
                                    style={{
                                      color: "#121212",
                                      textTransform: "capitalize",
                                      fontWeight: 700,
                                      fontSize: "14px",
                                    }}
                                  >
                                    {" "}
                                    Potassium sulphate :
                                  </span>{" "}
                                  {fertilizedValues[3].potassium_sulphate?.toFixed(
                                    2
                                  )}{" "}
                                  Kg/Ha
                                </p>
                                <p>
                                  <span
                                    style={{
                                      color: "#121212",
                                      textTransform: "capitalize",
                                      fontWeight: 700,
                                      fontSize: "14px",
                                    }}
                                  >
                                    Urea :
                                  </span>{" "}
                                  {fertilizedValues[1].urea?.toFixed(2)} Kg/Ha
                                </p>
                              </div>
                            </div>
                            <div
                              style={
                                {
                                  //  display: "flex"
                                }
                              }
                            >
                              <div
                                style={{
                                  background: "rgb(54, 76, 1)",
                                }}
                              >
                                <p
                                  style={{
                                    fontWeight: 600,
                                    fontSize: "18px",
                                    color: "#FFFFFF",
                                  }}
                                >
                                  Recomendation 4{" "}
                                </p>
                              </div>

                              <div style={{}}>
                                <p>
                                  <span
                                    style={{
                                      color: "#121212",
                                      textTransform: "capitalize",
                                      fontWeight: 700,
                                      fontSize: "14px",
                                    }}
                                  >
                                    Ammonium sulphate :
                                  </span>{" "}
                                  {fertilizedValues[4].ammonium_sulphate?.toFixed(
                                    2
                                  )}{" "}
                                  Kg/Ha
                                </p>
                                <p>
                                  <span
                                    style={{
                                      color: "#121212",
                                      textTransform: "capitalize",
                                      fontWeight: 700,
                                      fontSize: "14px",
                                    }}
                                  >
                                    {" "}
                                    DAP :
                                  </span>{" "}
                                  {fertilizedValues[4].dap?.toFixed(2)} Kg/Ha
                                </p>
                                <p>
                                  <span
                                    style={{
                                      color: "#121212",
                                      textTransform: "capitalize",
                                      fontWeight: 700,
                                      fontSize: "14px",
                                    }}
                                  >
                                    Potassium chloride :
                                  </span>{" "}
                                  {fertilizedValues[4].potassium_chloride?.toFixed(
                                    2
                                  )}{" "}
                                  Kg/Ha
                                </p>
                              </div>
                              {/* </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          )}
        </div>
      )}
    </Modal>
  );
};

export default AdvisoryModal;
