import * as L from "leaflet";
import React, { useEffect, useRef, useState } from "react";
// import axios from "axios";
import { apiurls } from "../../resources/APIurls";
// import { appconfig } from "../../resources/config";
import {
  DoubleLeftOutlined,
  MenuOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Input,
  Modal,
  Select,
  Typography,
  Layout,
} from "antd";
// import { appconfig } from "../../resources/config";
import axios from "axios";
import "leaflet-draw";
import "leaflet/dist/leaflet.css";
import FarmerScoreCardv3 from "../../components/FarmerScoreCard/FarmerScorecardv3";
import { appconfig } from "../../resources/config";
import { getData, postData } from "../../resources/utils";
import "./Farms.css";
import NewFarms from "./NewFarms";

const Farms = ({ siderFlag, siderFlagController }) => {
  let apikey_s3 = apiurls.find((item) => item.apikey == "aws3");
  const aws_s3_url = `${appconfig.domain[appconfig.environment].aws3}${
    apikey_s3.paths.images
  }`;
  const { Content } = Layout;

  const mapRef = useRef();
  const layerControlRef = useRef(null);
  const [overlayLayer, setOverlayLayer] = useState({});
  const [dropdownLoader, setDropdownLoader] = useState(false);
  const [searchDisable, setSearchDisable] = useState(true);
  const [collapsedBtn, setCollapsedBtn] = useState(false);
  const [mapLoader, setMapLoader] = useState(false);
  const [fetchingFarms, setFetchingFarms] = useState(false);
  const [allFarms, setAllFarms] = useState([]);
  const [farmsPaginataion, setFarmsPaginataion] = useState([]);
  const userToken = localStorage.getItem("token");
  const [farmCount, setFarmCount] = useState(0);
  const [farmerDetails, setFarmerDetails] = useState([]);
  const [farmName, setFarmName] = useState("");
  const [farmMobileNum, setFarmMobileNum] = useState("");
  const [rasterLayer, setRasterLayer] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [yesCheckedState, setYesCheckedState] = useState(false);
  const [noCheckedState, setNoCheckedState] = useState(true);
  const [locationFiltersList, setLocationFiltersList] = useState({
    state: [],
    city: [],
    taluka: [],
    village: [],
    khasra: [],
  });
  const [locationFilters, setLocationFilters] = useState({
    country: null,
    state: null,
    city: null,
    taluka: null,
    village: null,
    khasra: null,
  });
  const [drawControl, setDrawControl] = useState({
    toggle: null,
    polygonDraw: null,
    drawnItems: null,
    area: null,
  });
  const [farmObj, setFarmObj] = useState({
    latlong: null,
    area: null,
    centroid: null,
    name: null,
    email: null,
    phone: null,
    id_type: null,
    id_number: null,
    country_code: null,
    state_code: null,
    district_code: null,
    taluka_code: null,
    village_code: null,
    ownership: null,
    organisation: null,
    registered_by: localStorage.getItem("username"),
    is_validated: 0,
  });
  // console.log("farm---obj----", farmObj);
  const [streetMapImg, setstreetMapImg] = useState({});
  const [farmerLoaderIcon, setFarmerLoaderIcon] = useState(false);
  const [isScoreCardOpen, setIsScoreCardOpen] = useState(false);
  const [farmersScore, setFarmersScore] = useState([]);
  const [farmLandScore, setFarmLandScore] = useState({});
  const [profitLossScore, setProfitLossScore] = useState({});
  const [riskProfileScore, setRiskProfileScore] = useState({});
  const [weatherTrend, setWeatherTrend] = useState({});
  const [scorecardLoader, setScorecardLoader] = useState({
    basicDetail: false,
    farmLandDetail: false,
    riskProfileDetail: false,
  });
  const [insertFarmLoader, setInsertFarmLoader] = useState(false);

  // const noCheckedStateRef = useRef(null);
  // noCheckedStateRef.current = noCheckedState;
  // console.log("nochecked-----------", noCheckedState);

  // const [centers, setCenters] = useState([21.0, 78.0]);
  const [center, setCenter] = useState([21.0, 78.0]);
  // center: [21.0, 78.0],

  const mapAccessToken =
    "pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4NXVycTA2emYycXBndHRqcmZ3N3gifQ.rJcFIG214AriISLbB6B5aw";
  const tileLayerUrl = {
    mapbox:
      "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=" +
      mapAccessToken,
    googlemap: "http://{s}.google.com/vt/",
  };
  const mapLayers = {
    googleStreets: L.tileLayer(
      tileLayerUrl.googlemap + "lyrs=m&x={x}&y={y}&z={z}",
      {
        subdomains: ["mt0", "mt1", "mt2", "mt3"],
      }
    ),
    googleHybrid: L.tileLayer(
      tileLayerUrl.googlemap + "lyrs=s,h&x={x}&y={y}&z={z}",
      {
        subdomains: ["mt0", "mt1", "mt2", "mt3"],
        maxNativeZoom: 40,
        maxZoom: 40,
      }
    ),
    googleTerrain: L.tileLayer(
      tileLayerUrl.googlemap + "lyrs=p&x={x}&y={y}&z={z}",
      {
        subdomains: ["mt0", "mt1", "mt2", "mt3"],
      }
    ),
    mapboxOSM: L.tileLayer(tileLayerUrl.mapbox, {
      id: "mapbox/light-v9",
      tileSize: 512,
      zoomOffset: -1,
    }),
    mapboxSAT: L.tileLayer(tileLayerUrl.mapbox, {
      id: "mapbox/satellite-streets-v9",
      tileSize: 512,
      zoomOffset: -1,
    }),
    btn: "this is it",
  };
  const baseLayer = {
    "Google Hybrid": mapLayers.googleHybrid,
    "Google Street": mapLayers.googleStreets,
    "Google Terrain": mapLayers.googleTerrain,
    "Mapbox Street": mapLayers.mapboxOSM,
    "Mapbox Satellite": mapLayers.mapboxSAT,
  };
  const Zoom_map = 4;
  const initMap = () => {
    mapRef.current = L.map("map", {
      center: center,
      // center: [21.0, 78.0],
      zoom: Zoom_map,
      maxNativeZoom: 40,
      maxZoom: 40,
      // zoomControl: false,
      layers: [mapLayers.googleHybrid],
      // attributionControl: false,
      // interactive: true,
    });

    layerControlRef.current = L.control
      .layers(baseLayer, overlayLayer, { position: "topright" })
      .addTo(mapRef.current);
  };

  const geLocationtList = async (params, stateSetter, loaderSetter) => {
    loaderSetter(true);

    if (stateSetter === "state") {
      setLocationFiltersList({
        state: [],
        city: [],
        taluka: [],
        village: [],
        khasra: [],
      });
    } else if (stateSetter === "city") {
      let obj = {
        ...locationFiltersList,
        city: [],
        taluka: [],
        village: [],
        khasra: [],
      };
      setLocationFiltersList(obj);
    } else if (stateSetter === "taluka") {
      let obj = {
        ...locationFiltersList,
        taluka: [],
        village: [],
        khasra: [],
      };
      setLocationFiltersList(obj);
    } else if (stateSetter === "village") {
      let obj = {
        ...locationFiltersList,
        village: [],
        khasra: [],
      };
      setLocationFiltersList(obj);
    } else {
      let obj = {
        ...locationFiltersList,

        khasra: [],
      };
      setLocationFiltersList(obj);
    }

    const res = await axios.get(
      appconfig.domain[appconfig.environment].agribhumi + "admin/webp" + params
    );
    let obj = { ...locationFiltersList, [stateSetter]: res.data.data };
    setLocationFiltersList(obj);
    loaderSetter(false);
  };

  const handleSearch = () => {
    getOwsLayerData();
  };

  const getIndexLatLngs = (layersData) => {
    let latlngs = [],
      indexCoords = [];
    latlngs = layersData.geometry.coordinates[0];
    latlngs.forEach(function (obj) {
      let ele = "(" + obj[0] + "," + obj[1] + ")";
      indexCoords.push(ele);
    });

    return "[" + indexCoords + "]";
  };

  const enablePolygon = () => {
    let farmObject = { ...farmObj };
    let drawControlObj = { ...drawControl };
    drawControlObj.toggle = true;

    drawControlObj.drawnItems = new L.FeatureGroup();
    mapRef.current.addLayer(drawControlObj.drawnItems);
    let options = {
      position: "bottomleft",
      draw: {
        marker: false,
        circle: false,
        rectangle: false,
        polyline: false,
        circlemarker: false,
        polygon: false,
      },
      edit: {
        featureGroup: drawControlObj.drawnItems,
        remove: false,
      },
    };
    drawControlObj.polygonDraw = new L.Control.Draw(options);
    mapRef.current.addControl(drawControlObj.polygonDraw);
    new L.Draw.Polygon(mapRef.current).enable();

    mapRef.current.on("draw:created", function (e) {
      let layer = e.layer;
      drawControlObj.drawnItems.addLayer(layer);
      drawControlObj.area = L.GeometryUtil.geodesicArea(layer.getLatLngs()[0]);

      farmObject.area = drawControlObj.area / 10000;
      farmObject.centroid = `(${layer.getBounds().getCenter().lng},${
        layer.getBounds().getCenter().lat
      })`;

      farmObject.latlong = getIndexLatLngs(layer.toGeoJSON());
      setFarmObj(farmObject);

      setOpenModal(true);

      // curObj.farmDetail["latlngs"] = layer.toGeoJSON();
      // curObj.farmDetail.farm_area = (curObj.drawControl.area / 10000).toFixed(
      //   2
      // );
      // curObj.farmDetail.farm_center = layer.getBounds().getCenter();
    });
  };

  const insertFarm = async () => {
    setInsertFarmLoader(true);
    let res = await postData("indexhost", "insertFarm", farmObj, null);
    setInsertFarmLoader(false);
    setOpenModal(false);
  };

  const manageUpperCaseString = (str) => {
    let lower = str.toLowerCase();
    return lower.charAt(0).toUpperCase() + lower.slice(1);
  };

  const getOwsLayerData = async () => {
    // setOwsState(true);
    // setMapLoader(true);
    let params = {
      service: "WFS",
      version: "2.0",
      request: "GetFeature",
      outputFormat: "application/json",
      format_options: "callback:getJson",
      SrsName: "EPSG:4326",
    };

    if (locationFilters.city === null) {
      params["typeName"] = "crop_distribution:India_State_Boundary2019";
      params["CQL_FILTER"] = `State_LGD IN (${locationFilters.state.code})`;
    } else if (locationFilters.taluka === null) {
      if (locationFilters.country.code == "2") {
        params["typeName"] = "crop_distribution:nigeria_fct_lga_boundary";
        params["CQL_FILTER"] = `Subdt_LGD IN (${locationFilters.city.code})`;
      } else {
        params["typeName"] = "crop_distribution:India_District_Boundary2019";
        params["CQL_FILTER"] = `Dist_LGD IN (${locationFilters.city.code})`;
      }

      params["selectValue"] = locationFilters.city.code;
    } else if (
      locationFilters.village === null &&
      locationFilters.taluka !== null
    ) {
      if (locationFilters.country.code == "2") {
        params["typeName"] = "crop_distribution:nigeria_fct_lga_ward_boundary";
        params["CQL_FILTER"] = `VILLAGE_CO IN (${locationFilters.taluka.code})`;
      } else {
        params["typeName"] =
          "crop_distribution:India_Sub_District_Boundary2019";
        params["CQL_FILTER"] = `Subdt_LGD IN (${locationFilters.taluka.code})`;
      }

      params["selectValue"] = locationFilters.taluka.code;
    } else if (
      locationFilters.khasra == null &&
      locationFilters.village !== null
    ) {
      let farmObject = {
        ...farmObj,
        country_code: "01",
        state_code: locationFilters.state.code,
        district_code: locationFilters.city.code,
        taluka_code: locationFilters.taluka.code,
        village_code: locationFilters.village.code,
      };
      setFarmObj(farmObject);
      params["typeName"] =
        "crop_distribution:2023_india_" +
        locationFilters.state.label.split(" ").join("_").toLowerCase() +
        "_village_boundary";
      // "_" +
      // locationFilters.city.label.split(" ").join("").toLowerCase() +
      // "_" +
      // locationFilters.taluka.label.split(" ").join("").toLowerCase() +
      // "_village";
      params["CQL_FILTER"] = `Agbr_co IN ('${locationFilters.village.code}')`;
      params["selectValue"] = "0" + locationFilters.village.code;
    } else {
      params["typeName"] =
        "crop_distribution:india_" +
        locationFilters.state.label.split(" ").join("").toLowerCase() +
        "_" +
        locationFilters.city.label.split(" ").join("").toLowerCase() +
        "_" +
        locationFilters.taluka.label.split(" ").join("").toLowerCase() +
        "_cadestral";
      params[
        "CQL_FILTER"
      ] = `Agbr_co IN ('${locationFilters.village.code}') AND KHASRA IN ('${locationFilters.khasra.label}')`;
      params["selectValue"] = locationFilters.village.code;
    }

    const res = await getData("geohost", "ows", params, null);
    if (res.data.features.length > 0) {
      addFeatureLayer(res.data);
      // setPricipalLoader(false);
      // setOwsState(false);
      return new Promise((resolve, reject) => {
        resolve(res.data);
        setRasterLayer(res.data);
      });
    } else {
      warningMessage("No data Avalaibale for the selected Region.");
    }
  };

  const addFeatureLayer = (layerData) => {
    if (layerData.features.length) {
      let layerobj = { ...layerData };
      let featureLyr = [];
      layerobj.features = [];
      for (let i = 0; i < layerData.features.length; i++) {
        layerobj.features = [];
        layerobj.features.push(layerData.features[i]);
        let featureLayer = loadFeatureLayer(layerData).addTo(mapRef.current);
        featureLyr.push(featureLayer);
      }

      function getB() {
        let result = featureLyr[0].getBounds();
        for (let i = 1; i < featureLyr.length; i++) {
          result.extend(featureLyr[i].getBounds());
        }
        return result;
      }

      mapRef.current.fitBounds(getB());
    } else {
    }
  };
  // raster------------------------------------------------------------------->>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.
  const getWmsLayerData = async (params) => {
    if (rasterLayer) {
      if (mapRef.current.hasLayer(rasterLayer)) {
        mapRef.current.removeLayer(rasterLayer);
      }
    }
    let wmsPane = mapRef.current.createPane("wmsPane");
    let screenshotPane = mapRef.current.createPane("dont-include");
    wmsPane.style.zIndex = 250;

    let obj = { ...params, crs: L.CRS.EPSG4326, pane: wmsPane };

    const headers = [
      {
        header: "content-type",
        value: "application/x-www-form-urlencoded",
      },
    ];
    const res = wmsHeaderExtend(
      appconfig.domain[appconfig.environment].geohost + "geoserver/wms",
      obj,
      headers,
      null,
      setMapLoader
    );

    setRasterLayer(res);

    res.addTo(mapRef.current);
  };
  // raster---------------------------------------------------------------------------->>>>>>>>>>>>>>>>>>>>>>>>>>
  const loadFeatureLayer = (geojson) => {
    return L.geoJSON(geojson, {
      style: function () {
        return {
          fillColor: "transparent",
          weight: 3,
          opacity: 1,
          color: "#001840",
          fillOpacity: 1,
        };
      },
      pointToLayer: function (feature, latlng) {},
      onEachFeature: function (feature, layer) {
        layer
          .bindTooltip(layer.feature.properties.KHASRA, {
            className: "label",
            permanent: true,
            direction: "center",
          })
          .openTooltip();
        layer.on({
          click: function (e) {},
          mouseover: function (e) {},
          mouseout: function (e) {},
        });
      },
    });
  };

  useEffect(() => {
    if (locationFilters.city !== null) {
      setSearchDisable(false);
    }
  }, [locationFilters.state, locationFilters.city]);

  useEffect(() => {
    initMap();
    geLocationtList("/pre_country.php", "country", setDropdownLoader);
  }, []);

  //  WHEN WE SELECTED FARMS IN FEATURE getAllFarms FETCH THE GEOTAGGED FARMS IN RIGHT SIDE Drawer.......------->>
  const getAllFarms = async (page, farmName, mobileNum) => {
    setMapLoader(true);
    // let apikey = apiurls.find((item) => item.apikey == "geotaggedfarms");
    setFetchingFarms(true);

    const res = await getData("indexhost", "getGeotaggedFarms", {}, null);

    setAllFarms(res.data.data);
    setFarmCount(res.data);
    setFetchingFarms(false);
    setMapLoader(false);

    // const res = await axios({
    //   method: "GET",
    //   url: `${appconfig.domain[appconfig.environment].geotaggedfarms}${
    //     apikey.paths.geotaggedfarms
    //   }`,
    //   headers: {
    //     "Client-Name": "IOS_REACT_NATIVE_APP",
    //     "Client-Token": userToken,
    //     "X-localization": "en",
    //   },
    //   params: {
    //     customer_type: "web",
    //     offset: page,
    //     limit: 20,
    //     search: farmName,
    //     mobile: mobileNum,
    //     sort: "created_at",
    //     sort_order: "DESC",
    //   },
    // });

    // if (farmName == "" && mobileNum == "") {
    //   setAllFarms(res.data.data);
    //   setFarmsPaginataion(Math.ceil(res.data.recordsTotal / 20));
    // } else if (farmName !== "") {
    //   setAllFarms(res.data.data);
    //   setFarmsPaginataion(Math.ceil(res.data.recordsTotal / 20));
    // } else {
    //   setAllFarms(res.data.data);
    // }

    // setFarmCount(res.data);
    // setFetchingFarms(false);
    // setMapLoader(false);
  };

  // searchForKhasra SEARCH A GEOTAGGED FARM BY ENTERING THE FARM NAME.......--------->>>>>>>>
  const searchForKhasra = (value) => {
    // getAllFarms(0, farmName, farmMobileNum);
    getAllFarms(0, value, farmMobileNum);
  };

  // searchForFarmMobile SEARCH A GEOTAGGED FARM BY ENTERING THE MOBILE NUMBER.......--------->>>>>>>>
  const searchForFarmMobile = (value) => {
    getAllFarms(0, farmName, farmMobileNum);
  };

  const handleInputChange = (val, key) => {
    let farmObject = { ...farmObj, [key]: val };
    setFarmObj(farmObject);
  };

  const getIndexLatLngs_Farms = (latlngs) => {
    let indexCoords = [];

    latlngs.forEach(function (obj) {
      let ele = "(" + obj[0] + "," + obj[1] + ")";
      indexCoords.push(ele);
    });

    return "[" + indexCoords + "]";
  };

  const getFarmerScoreCard_Farms = async (
    arg,
    state,
    district,
    village_code,
    khasra,
    latlngs
  ) => {
    setIsScoreCardOpen(true);
    setScorecardLoader({
      ...scorecardLoader,
      basicDetail: true,
      farmLandDetail: true,
      riskProfileDetail: true,
    });

    let data = {
      state: state.toUpperCase(),
      district: district,
      data: arg,
      latlongpolygon: getIndexLatLngs_Farms(latlngs),
      khasraNo: khasra,
      vcode: village_code,
    };
    // if (arg == "3") {
    //   data["farmarea"] = area;
    // }
    let res = await postData("score", "scorecard_farms", data, null);

    // setFarmersScore({});
    // setFarmersScore({
    //   ...farmersScore,
    //   basic_details: farmerDetails,
    // });
    setScorecardLoader({ ...scorecardLoader, basicDetail: false });

    if (arg == 1) {
      setFarmersScore({});
      setFarmersScore(res.data.data.basic_details);
      setScorecardLoader({ ...scorecardLoader, basicDetail: false });
    } else if (arg == 2) {
      setFarmLandScore({});
      setFarmLandScore(res.data.data);
      setScorecardLoader({ ...scorecardLoader, farmLandDetail: false });
    } else if (arg == 3) {
      setProfitLossScore({});
      setProfitLossScore(res.data.data);
      setScorecardLoader({ ...scorecardLoader, riskProfileDetail: false });
    } else if (arg == 4) {
      setRiskProfileScore({});
      setRiskProfileScore(res.data.data);
    } else {
      setWeatherTrend({});
      setWeatherTrend(res.data.data);
    }

    // if (arg == 1) {
    //   if (res.data.data.basic_details?.length > 0) {
    //     setFarmerId(res.data.data.basic_details[0].ref_id);
    //     setSelectedFarmer(res.data.data.basic_details[0]);
    //   }
    // }

    setFarmerLoaderIcon(false);
    setMapLoader(false);
  };

  const handleScoreCardOk = () => {
    setIsScoreCardOpen(false);
  };
  const handleScoreCardCancel = () => {
    setIsScoreCardOpen(false);
    setFarmersScore({});
    setFarmLandScore({});
    setProfitLossScore({});
  };

  const KhasraScreenShort = async (latlng) => {
    let params = {
      latlong: getIndexLatLngs_Farms(latlng),
      // style: "open-street-map,stamen-terrain",
      style: "open-street-map,satellite",
    };
    const res = await postData("indexhost", "screencapture", params, null);

    if (res.data.data) {
      setstreetMapImg(res.data.data);
    } else {
      // setstreetMapImg("no image");
    }
  };

  const setNoCheck = (e) => {
    // console.log("no----check", e);
    let farmObject = { ...farmObj };
    farmObject.is_validated = 0;

    setFarmObj(farmObject);

    setYesCheckedState(false);
    setNoCheckedState(true);
  };

  const setYesCheck = (e) => {
    // console.log("yes----check", e);
    let farmObject = { ...farmObj };

    farmObject.is_validated = 1;
    setFarmObj(farmObject);
    setYesCheckedState(true);
    setNoCheckedState(false);
  };

  return (
    <Layout>
      <Content>
        {mapLoader && (
          <div className="back-drop">
            <img src={`${aws_s3_url}/loader2.gif`} width="100px" />
            <div className="typewriter">
              <p className="back-drop-text">Loading Data....</p>
            </div>
          </div>
        )}
        <div className="mapHeader">
          {siderFlag && (
            <MenuOutlined
              onClick={() => siderFlagController(!siderFlag)}
              // {!siderFlag ? marginBottom:'3%' : marginBottom:'4%'}
              className="trigger"
              style={{
                color: "#ffffff",
                padding: 0,
                // marginRight: "15px",
                marginRight: "4px",
                marginBottom: "2%",
              }}
            />
          )}
          <Select
            showSearch
            allowClear
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
            placeholder="Select Country*"
            className="dropdown"
            loading={
              dropdownLoader && !locationFiltersList.country?.length
                ? true
                : false
            }
            value={locationFilters.country?.code}
            onChange={(val) => {
              if (val) {
                geLocationtList(
                  `/pre_states.php?action=state&country_id=${val}`,
                  "state",
                  setDropdownLoader
                );
              } else {
                setLocationFilters({
                  ...locationFilters,
                  country: null,
                  state: null,
                  city: null,
                  taluka: null,
                  village: null,
                  khasra: null,
                });
              }
            }}
            onSelect={(val, option) => {
              setLocationFilters({
                ...locationFilters,
                country: { label: option.children, code: val },
                state: null,
                city: null,
                taluka: null,
                village: null,
                khasra: null,
              });
            }}
          >
            {locationFiltersList.country?.length &&
              locationFiltersList.country.map((item, index) => (
                <Option key={index} value={item.country_id}>
                  {item.country_name}
                </Option>
              ))}
          </Select>
          <Select
            showSearch
            allowClear
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
            placeholder="Select State*"
            className="dropdown"
            loading={
              dropdownLoader && !locationFiltersList.state.length ? true : false
            }
            value={locationFilters.state?.code}
            onChange={(val) => {
              if (val) {
                geLocationtList(
                  `/pre_district.php?action=district&state_id=${val}`,
                  "city",
                  setDropdownLoader
                );
              } else {
                setLocationFilters({
                  ...locationFilters,
                  state: null,
                  city: null,
                  taluka: null,
                  village: null,
                  khasra: null,
                });
              }
            }}
            onSelect={(val, option) => {
              setLocationFilters({
                ...locationFilters,
                state: { label: option.children, code: val },
                city: null,
                taluka: null,
                village: null,
                khasra: null,
              });
            }}
          >
            {locationFiltersList.state.length &&
              locationFiltersList.state.map((item, index) => (
                <Option key={index} value={item.state_code}>
                  {item.state_name}
                </Option>
              ))}
          </Select>
          <Select
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
            placeholder={
              locationFilters.country?.code == "2"
                ? "Select LGA*"
                : "Select City*"
            }
            allowClear
            className="dropdown"
            loading={
              dropdownLoader && !locationFiltersList.city.length ? true : false
            }
            value={locationFilters.city?.code}
            onChange={(val) => {
              if (val) {
                geLocationtList(
                  `/pre_taluk.php?action=taluk&district_id=${val}`,
                  "taluka",
                  setDropdownLoader
                );
              } else {
                setLocationFilters({
                  ...locationFilters,
                  city: null,
                  taluka: null,
                  village: null,
                  khasra: null,
                });
              }
            }}
            onSelect={(val, option) => {
              setLocationFilters({
                ...locationFilters,
                city: {
                  label: option.children,
                  code: val,
                },
                taluka: null,
                village: null,
                khasra: null,
              });
            }}
          >
            {locationFiltersList.city.length &&
              locationFiltersList.city.map((item, index) => (
                <Option key={index} value={item.district_code}>
                  {item.district_name}
                </Option>
              ))}
          </Select>
          <Select
            allowClear
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
            loading={
              dropdownLoader && !locationFiltersList.taluka?.length
                ? true
                : false
            }
            placeholder={
              locationFilters.country?.code == "2"
                ? "Select Ward"
                : "Select Taluka"
            }
            className="dropdown"
            value={locationFilters.taluka?.code}
            onChange={(val) => {
              if (val) {
                geLocationtList(
                  `/pre_village.php?action=village&taluk_id=${val}`,
                  "village",
                  setDropdownLoader
                );
              } else {
                setLocationFilters({
                  ...locationFilters,
                  taluka: null,
                  village: null,
                  khasra: null,
                });
              }
            }}
            onSelect={(val, option) => {
              setLocationFilters({
                ...locationFilters,
                taluka: { label: option.children, code: val },

                village: null,
                khasra: null,
              });
            }}
          >
            {locationFiltersList.taluka.length &&
              locationFiltersList.taluka.map((item, index) => (
                <Option key={index} value={item.taluk_code}>
                  {item.taluk_name}
                </Option>
              ))}
          </Select>
          <Select
            allowClear
            showSearch
            loading={
              dropdownLoader && !locationFiltersList.village?.length
                ? true
                : false
            }
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
            className="dropdown"
            placeholder="Select Village"
            value={locationFilters.village?.code}
            onChange={(val) => {
              if (val) {
                geLocationtList(
                  `/new_khasra_list.php?village_code=${val}`,
                  "khasra",
                  setDropdownLoader
                );
              } else {
                setLocationFilters({
                  ...locationFilters,
                  village: null,
                  khasra: null,
                });
              }
            }}
            onSelect={(val, option) => {
              setLocationFilters({
                ...locationFilters,
                village: { label: option.children, code: val },
                khasra: null,
              });
            }}
          >
            {locationFiltersList.village.length &&
              locationFiltersList.village.map((item, index) => (
                <Option key={index} value={item.agbr_village_code}>
                  {manageUpperCaseString(item.village_name)}
                </Option>
              ))}
          </Select>

          <Select
            allowClear
            showSearch
            loading={
              dropdownLoader && !locationFiltersList.khasra?.length
                ? true
                : false
            }
            filterOption={(input, option) =>
              option.children
                .toString()
                .toLowerCase()
                .includes(input.toString().toLowerCase())
            }
            className="dropdown"
            placeholder="Select Khasra"
            value={locationFilters.khasra?.label}
            onChange={(val) => {
              if (!val) {
                setLocationFilters({
                  ...locationFilters,
                  khasra: null,
                });
              }
            }}
            onSelect={(val, option) => {
              if (val) {
                setLocationFilters({
                  ...locationFilters,
                  khasra: { label: option.children, code: val },
                });
              } else {
                setLocationFilters({
                  ...locationFilters,
                  khasra: null,
                });
              }
            }}
          >
            {locationFiltersList.khasra.length &&
              locationFiltersList.khasra.map((item, index) => (
                <Option key={index} value={item.id}>
                  {item.khasra}
                </Option>
              ))}
          </Select>
          <Button
            shape="circle"
            icon={<SearchOutlined />}
            className="resetBtn"
            onClick={handleSearch}
            style={{ marginLeft: "2%" }}
            disabled={searchDisable}
          />
          <Button
            className="resetBtn"
            onClick={enablePolygon}
            style={{ marginLeft: "2%" }}
          >
            Add farm{" "}
          </Button>
          {/* <Button
                style={{ marginLeft: "0px", marginRight: "24px" }}
                shape="circle"
                icon={<ReloadOutlined />}
                className="resetBtn"
                onClick={resetFilters}
                disabled={searchDisable}
              /> */}
          <div>
            {collapsedBtn && (
              <DoubleLeftOutlined
                className="trigger"
                onClick={() => {
                  setCollapsedBtn(!collapsedBtn);
                  getAllFarms(0, "", "");
                }}
                style={{
                  color: "#ffffff",
                  position: "absolute",
                  right: 0,
                  bottom: "12px",
                  marginBottom: "0.5%",
                }}
              />
            )}
          </div>
        </div>
        <div
          id="map"
          style={{
            // height: "calc(100vh - 64px)",
            height: "calc(92vh - 64px)",
          }}
        ></div>
      </Content>
      <NewFarms
        collapsedBtn={collapsedBtn}
        updatedCollapsedBtn={setCollapsedBtn}
        allFarms={allFarms}
        farmCount={farmCount}
        setMapLoader={setMapLoader}
        mapLoader={mapLoader}
        farmerDetails={farmerDetails}
        setFarmerDetails={setFarmerDetails}
        mapRef={mapRef}
        searchForKhasra={searchForKhasra}
        searchForFarmMobile={searchForFarmMobile}
        setFarmName={setFarmName}
        farmName={farmName}
        setFarmMobileNum={setFarmMobileNum}
        farmMobileNum={farmMobileNum}
        rasterLayer={rasterLayer}
        setFarmsPaginataion={setFarmsPaginataion}
        farmsPaginataion={farmsPaginataion}
        getAllFarms={getAllFarms}
        setCurrentPage={setCurrentPage}
        getFarmerScoreCard_Farms={getFarmerScoreCard_Farms}
        KhasraScreenShort={KhasraScreenShort}
      />
      <Modal
        title=""
        open={openModal}
        onOk={insertFarm}
        onCancel={() => setOpenModal(false)}
        confirmLoading={insertFarmLoader}
      >
        <div>
          <div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div style={{ width: "48%" }}>
                <p style={{ margin: "0px" }}>Name*</p>
                <Input
                  placeholder="Name"
                  className="inputField"
                  onChange={(e) => handleInputChange(e.target.value, "name")}
                />
              </div>
              <div style={{ width: "48%" }}>
                <p style={{ margin: "0px" }}> Email*</p>
                <Input
                  placeholder="Email"
                  className="inputField"
                  onChange={(e) => handleInputChange(e.target.value, "email")}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div style={{ width: "48%" }}>
                <p style={{ margin: "0px" }}>Mobile*</p>
                <Input
                  placeholder="Mobile"
                  className="inputField"
                  onChange={(e) => handleInputChange(e.target.value, "phone")}
                />
              </div>
              <div style={{ width: "48%" }}>
                <p style={{ margin: "0px" }}>Khasra*</p>
                <Input
                  placeholder="Khasra"
                  className="inputField"
                  onChange={(e) => handleInputChange(e.target.value, "khasra")}
                />
              </div>
            </div>
          </div>
          <div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div style={{ width: "48%" }}>
                <p style={{ margin: "0px" }}>State*</p>
                <Input
                  placeholder="State"
                  value={locationFilters.state?.label}
                  disabled={true}
                  className="inputField"
                />
              </div>
              <div style={{ width: "48%" }}>
                <p style={{ margin: "0px" }}>District*</p>
                <Input
                  placeholder="District"
                  value={locationFilters.city?.label}
                  disabled={true}
                  className="inputField"
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div style={{ width: "48%" }}>
                <p style={{ margin: "0px" }}>Taluka/Block*</p>
                <Input
                  placeholder="Taluka"
                  value={locationFilters.taluka?.label}
                  disabled={true}
                  className="inputField"
                />
              </div>
              <div style={{ width: "48%" }}>
                <p style={{ margin: "0px" }}>Village*</p>
                <Input
                  placeholder="Village"
                  value={locationFilters.village?.label}
                  disabled={true}
                  className="inputField"
                />
              </div>
            </div>
          </div>
          <div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div>
                <p style={{ margin: "0px" }}>ID Proof</p>
                <Select
                  style={{ width: 200 }}
                  onChange={(val) => handleInputChange(val, "id_type")}
                  className="inputField"
                  options={[
                    { value: "aadhar", label: "Aadhar no." },
                    { value: "voterid", label: "Voter's ID" },
                    { value: "dl", label: "Driving License" },
                    { value: "pan", label: "Pan Card", disabled: true },
                  ]}
                />
              </div>
              <div style={{ width: "68%" }}>
                <p style={{ margin: "0px" }}>ID Number</p>
                <Input
                  placeholder="ID number"
                  className="inputField"
                  onChange={(e) =>
                    handleInputChange(e.target.value, "id_number")
                  }
                />
              </div>
            </div>
          </div>
          <div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div>
                <p style={{ margin: "0px" }}>Ownership</p>
                <Select
                  style={{ width: 200 }}
                  placeholder="Ownership"
                  onChange={(val) => handleInputChange(val, "ownership")}
                  className="inputField"
                  options={[
                    { value: "single", label: "Single" },
                    { value: "joint", label: "Joint" },
                    { value: "rented", label: "Rented" },
                  ]}
                />
              </div>
              <div style={{ width: "68%" }}>
                <p style={{ margin: "0px" }}>Organisation</p>
                <Input
                  placeholder="Organisation"
                  className="inputField"
                  onChange={(e) =>
                    handleInputChange(e.target.value, "organisation")
                  }
                />
              </div>
            </div>
            <div className="revenueBox">
              <Typography>Details Verified With Revenue Records ?</Typography>
              <div style={{}}>
                <Checkbox onChange={setYesCheck}>Yes </Checkbox>
              </div>
              <div style={{}}>
                <Checkbox
                  // defaultChecked={noCheckedState}
                  checked={noCheckedState}
                  onChange={setNoCheck}
                >
                  No{" "}
                </Checkbox>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {farmerLoaderIcon !== true && (
        <Modal
          open={isScoreCardOpen}
          onOk={handleScoreCardOk}
          onCancel={handleScoreCardCancel}
          footer={null}
          centered="true"
          width={"90%"}
          bodyStyle={{
            backgroundColor: "#ffffff",
          }}
        >
          <FarmerScoreCardv3
            basicDetails={farmersScore}
            cropHistory={farmLandScore}
            profitLoss={profitLossScore}
            riskProfile={riskProfileScore}
            weatherTrend={weatherTrend}
            featureType={""}
            googgleImages={streetMapImg}
            setMapLoader={setMapLoader}
          />
        </Modal>
      )}
    </Layout>
  );
};

export default Farms;
