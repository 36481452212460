export const legendColor = {
  1: "#246E00",
  2: "#FF00FF",
  3: "#FFD700",
  4: "#00AF47",
  5: "#7FFF00",
  6: "#000000",
  7: "#A66F00",
  8: "#000000",
  9: "#000000",
  10: "#6F6F00",
  11: "#F2DEE9",
  12: "#000000",
  13: "#93910F",
  14: "#E1007B",
  15: "#F78D07",
  16: "#000000",
  17: "#0FEFA9",
  18: "#C86B3A",
  19: "#00A8E2",
  20: "#B84B2C",
  21: "#0000FF",
  22: "#000000",
  23: "#000000",
  24: "#000000",
  25: "#000000",
  26: "#000000",
  27: "#000000",
  28: "#FFA6E2",
  29: "#FFFF00",
  30: "#000000",
  31: "#000000",
  32: "#A6F18B",
  33: "#7C99FF",
  34: "#B3A2D7",
  35: "#FF2525",
  36: "#DBDBDC",
  37: "#000000",
  38: "#000000",
  39: "#000000",
  40: "#000000",
  41: "#12E7ED",
  42: "#5E6287",
  43: "#D1FF00",
  44: "#FFA500",
  45: "#000000",
  46: "#000000",
  47: "#000000",
  48: "#000000",
  49: "#000000",
  50: "#000000",
  51: "#FFFFFF",
  52: "#000000",
  53: "#000000",
  54: "#7E9E65",
  55: "#F0D18D",
  56: "#000000",
  57: "#000000",
  58: "#A020F0",
  59: "#000000",
  60: "#000000",
  61: "#000000",
  62: "#000000",
  63: "#000000",
  64: "#6F0049",
  65: "#000000",
  66: "#000000",
  67: "#000000",
  68: "#000000",
  69: "#D59EBC",
  70: "#000000",
  71: "#D2C687",
  72: "#000000",
  73: "#54785D",
  74: "#000000",
  75: "#000000",
  76: "#000000",
  77: "#000000",
  78: "#000000",
  79: "#000000",
  80: "#000000",
  81: "#000000",
  82: "#FD009E",
  83: "#000000",
  84: "#000000",
  85: "#000000",
  86: "#000000",
  87: "#41077B",
  88: "#000000",
  89: "#000000",
  90: "#000000",
  91: "#000000",
  92: "#000000",
  93: "#000000",
  94: "#000000",
  95: "#000000",
  96: "#000000",
  97: "#000000",
  98: "#000000",
  99: "#000000",
  100: "#000000",
  101: "#000000",
  102: "#000000",
  103: "#000000",
  104: "#000000",
  105: "#000000",
  106: "#000000",
  107: "#000000",
  108: "#000000",
  109: "#000000",
  110: "#000000",
  111: "#000000",
  112: "#000000",
  113: "#000000",
  114: "#000000",
  115: "#000000",
  116: "#000000",
  117: "#722E62",
  118: "#000000",
  119: "#000000",
  120: "#000000",
  121: "#000000",
  123: "#000000",
  124: "#ecde9f",
  122: "#6E0067",
  154: "#006E67",
  165: "#6E0700",
};
