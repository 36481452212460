export const apiurls = [
  {
    apikey: "agribazaar",
    paths: {
      countries: "countries.php",
      sendotp: "admin/webp/send-otp.php",
      commodity: "abcCommodityMaster.php",
      profiledata: "profileData.php",
      addressfrompin: "pincodeDetail.php?pincode=",
      logout: "user_logout.php",
      newlogout: "admin/webp/user_logout.php",
      adminlogin: "user_login.php",
      seedprogram: "admin/webp/crop/abcSeedProgram.php?",
      addfarmer: "crfAPI/frfMobile/addBasicDetails",
      farmerlist: "admin/webp/crop/abcSeedFarmerList.php?",
      farmreqlist: "admin/webp/crop/abcGisRequestMaster.php?",
      seedtask: "admin/webp/crop/abcSeedActivityTaskWeb.php",
      seedquery: "admin/webp/crop/abcFarmerSeedReply.php",
      getroleslist: "admin/webp/bhumiAPI.php?",
      adduser: "admin/webp/bhumiAPI.php?add_user=1",
      edituser: "admin/webp/bhumiAPI.php?approve_user=1",
      getuserlist: "admin/webp/bhumiAPI.php",
      fieldStaffList: "admin/webp/fieldStaffMaster.php?",
      seedProgramActivities: "admin/webp/crop/abcFarmerSeedActivity.php?",
      editfarmer: "/crfAPI/frfMobile/updateBasicDetails",
      groupactivity: "admin/webp/crop/abcFarmerSeedActivity.php",
      getfarms: "admin/webp/pre_government_schemes_statistics.php",
      handllogin: "admin/webp/send-otp-new.php",
      submitotp: "pre_users_new.php",
    },
  },
  {
    apikey: "agribhumi",
    paths: {
      newlogout: "admin/webp/user_logout.php",
    },
  },
  { apikey: "blog", paths: { articles: "api/blogListApi.php" } },
  {
    apikey: "agribazaar2",
    paths: {
      farmerReport: "admin/webp/pre_farmer_details.php",
      handllogin: "admin/webp/send-otp-new.php",
      sendotp: "admin/webp/send-otp-new.php",
      submitotp: "pre_users_new.php",
      logout: "user_logout.php",
    },
  },

  {
    apikey: "enterprise",
    paths: {
      translation: "admin/webp/staticTranslation.php?onload=true",
      seasons: "admin/webp/get.php?action=seasonDropDown",
      states: "admin/webp/pre_states.php?action=state",
      cities: "admin/webp/pre_district.php?action=district&",
      talukas: "admin/webp/pre_taluk.php?action=taluk&",
      villages: "admin/webp/pre_village.php?action=village&",
      // khasralist: "admin/webp/get.php?action=showKhasraDropDown&",
      khasralist: "/admin/webp/new_khasra_list.php?",
      crops: "admin/webp/get.php?action=comDropDown&",
      areadata: "admin/webp/get.php?ac=session",
      crophistory: "api/v2/getCropHistory",
      gtpoints:
        "admin/webp/getGtPointsUserWise.php?action=FeatureCollection&location=",
      khasrafeatures: "admin/webp/get.php?action=getGeoJson&",
      layersName: "admin/webp/getLayersName.php?",
      farmerReport: "admin/webp/pre_farmer_details.php",
      // farmlist: "admin/webp/farmList.php?action=farmList&",

      farmdetail: "admin/webp/farmList.php",
      farmseasons: "admin/webp/get.php?action=season",
      farmcrops: "admin/webp/get.php?action=cropDropDown&",
      indexlegenddata: "admin/webp/",
      khasradetail: "admin/webp/get.php?action=farmerDetails&",
      khasradetailhindi: "admin/webp/get.php?action=farmerRevenueDetails&",
      cropsList: "admin/webp/get.php?action=getCrop",
      npkdetails: "admin/webp/get.php?action=npkDetails&",
      crophistory: "admin/webp/get.php?action=farm_crop_history",
      farmerStatisticsTaluka: "admin/webp/get.php?action=newDashboardTaluka&",
      farmerStatistics:
        "admin/webp/get.php?action=government_schemes_statistics",
      farmerStatisticsKhasra: "admin/webp/get.php?action=getKhasraList&",
    },
  },

  {
    apikey: "farmservice",
    paths: {
      farmlist: "/farm_land/v3/getFarm",
    },
  },
  {
    apikey: "soildata",
    paths: {
      soillegend: "admin/webp/pre_soil_legend.php",
    },
  },

  {
    apikey: "agribhumi2",
    paths: {
      farmerReport: "admin/webp/pre_farmer_details.php",
      soildatalegend: "admin/webp/pre_soil_legend.php",
      fertilizercrop: "admin/webp/pre_crop_name_master.php?type=fertilizer",
      farmerscorecard: "admin/webp/new_farmer_details.php",
      locationlist: "admin/webp",
      getfarms: "admin/webp/pre_government_schemes_statistics.php",
    },
  },
  {
    apikey: "farmreport",
    paths: {
      cropHistory: "api/v2/getCropHistory",
      cropHealth: "/api/v2/getCropHealth",
      npk: "/api/v2/getSoilNutritions",
      // recomndCrop: "api/v2/getNPKLabelCrops",
      soilCropRecomnd: "/api/v2/getRectangle",
      getPdf: "/api/v2/getPdf",

      // getClaySand: "/api/v2/getSoilProperties",
      // getSoilText: "/api/v2/getSoilTextureCrops",
    },
  },
  {
    apikey: "score",
    paths: {
      scorecard: "admin/webp/farmer_score_card.php",
      scorecard_farms: "admin/webp/farmer_farm_geotag.php",
      validation: "admin/webp/farmer_farm_geotag.php",
    },
  },
  {
    apikey: "getfarms",
    paths: {
      farmsList: "farm_land/v3/getFarm",
      geotaggedfarms: "api/v1/farm/getFarms",
    },
  },
  {
    apikey: "geohost",
    paths: {
      ows: "geoserver/crop_distribution/ows",
      wms: "geoserver/wms",
      checkcontrollayer:
        "geoserver/rest/workspaces/crop_distribution/datastores/",
    },
  },
  {
    apikey: "geotaggedfarms",
    paths: {
      allfarms: "api/documentation/v1#/Farms/getFarms",
      geotaggedfarms: "api/v1/farm/getFarms",
      geotagedfarmerdetails: "api/v1/farm/getFarmerDetails",
    },
  },
  {
    apikey: "indexhost",
    paths: {
      indexdata: "api/v2/getAnalytics",
      indexlegenddata: "api/v1ndvi",
      farmindexdata: "api/v1farmer",
      cropClassificationP1: "api/v1pred1",
      cropClassificationP2: "api/v1pred2",
      fertilizerrecomendation: "api/v2/getFertilizerRecommendation",
      soilTexture: "api/v1soil",
      npksval: "api/v1npk",
      soiltextval: "api/v1soilpercent",
      getfertilizercrop: "api/v1pred2",
      soiltextadvisory: "api/v2/getSoilTextureCrops",
      npkadvisry: "api/v2/getNPKLabelCrops",
      vitallatlongs: "api/v2/getAnalytics",
      vihistogram: "api/v1ndvi",
      crophistry: "api/v2/getCropHistory",
      cropYield: "api/v2/getCropYield",
      cropHealth: "api/v2/getCropHealth",
      seasons: "api/v2/getAllSeasons",
      screencapture: "api/v2/getFarmImage",
      // new
      cropHistory: "api/v2/getCropHistory",
      // cropHealth: "/api/v2/getCropHealth",
      npk: "/api/v2/getSoilNutritions",
      recomndCrop: "api/v2/getNPKLabelCrops",
      soilCropRecomnd: "/api/v2/getRectangle",
      getClaySand: "/api/v2/getSoilProperties",
      getSoilText: "/api/v2/getSoilTextureCrops",
      getPdf: "/api/v2/getPdf",
      insertFarm: "/api/v2/insertFarmGeoTagInfo",
      getGeotaggedFarms: "api/v2/getFarmGeoTagInfo",
    },
  },
  {
    apikey: "openapi",
    paths: {
      location: "//ipinfo.io/geo",
      addressfrompin: "//api.postalpincode.in/pincode/",
      weatherdata:
        "https://api.worldweatheronline.com/premium/v1/weather.ashx?key=9cafb9b2355c47998ce73234230305&format=json&",
    },
  },
  {
    apikey: "fasttrack",
    paths: {
      clientlist: "crfAPI/arfSelfRegistration/getClientTypeList?pl=1",
      register: "crfAPI/selfRegistration/registerUser",
      arfregister: "crfAPI/arfSelfRegistration/registerClient?pl=1",
      address: "crfAPI/postSelfRegistration/addAddressDetails",
      awsupload: "crfAPI/selfRegistration/uploadImage/1",
      postdocs: "crfAPI/postSelfRegistration/addDocsDetails",
      arfpostdocs: "crfAPI/arfSelfRegistration/addDocsDetails",
      commoditylist: "crfAPI/selfRegistration/getCommodityList",
      addcommodityinterested:
        "crfAPI/postSelfRegistration/addCommoditiesInterested",
      profiledetail: "crfAPI/profileUser/getprofileDataForProfileUser",
      basicdetail: "crfAPI/profileUser/getBasicDataForProfileUser",
      authorizedetail: "crfAPI/profileUser/getAuthorizedDataForProfileUser",
      companydetail: "crfAPI/profileUser/getCompanyDataForProfileUser",
      bankdetail: "crfAPI/profileUser/getBankDataForProfileUser",
    },
  },
  {
    apikey: "aws3",
    paths: {
      images: "assets/images",
    },
  },
];
