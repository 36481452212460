import axios, * as Axios from "axios";
import { apiurls } from "./APIurls";
import { appconfig } from "./config";
import EnglishText from "./multilingual/english";
import HindiText from "./multilingual/hindi";
import * as L from "leaflet";

export const getString = (str, lang) => {
  if (lang === "english") {
    return EnglishText[str];
  } else {
    return HindiText[str];
  }
};

const geturl = (apiname, pathname) => {
  if (apiname == "openapi") {
    return apiurls.filter((x) => x.apikey === apiname)[0].paths[pathname] || "";
  } else {
    return (
      appconfig.domain[appconfig.environment][apiname] +
      apiurls.find((x) => x.apikey === apiname).paths[pathname]
    );
  }
};

export const request = {
  get: async (url, params, callback) => {
    const res = await Axios.get(url + params);

    if (callback !== null) {
      callback(res);
    }
  },
};

export const getData = async (apiname, path, params, callback) => {
  // const res = await Axios.get(geturl(apiname, path), { params });
  const res = await axios({
    method: "GET",
    url: geturl(apiname, path),
    params: params,
  });

  if (callback !== null) {
    callback();
  }
  return new Promise((resolve, reject) => {
    resolve(res);
  });
};

export const postData = async (apiname, path, params, callback) => {
  try {
    const res = await Axios({
      method: "POST",
      url: geturl(apiname, path),
      data: { ...params },
    });
    if (callback !== null) {
      callback();
    }
    return new Promise((resolve, reject) => {
      resolve(res);
    });
  } catch (err) {
    return err.response;
  }
};

export const initWMSHeader = () => {
  let wmsHeaders = {};
  let wmsAbort = null;

  L.TileLayer.WMSHeader = L.TileLayer.WMS.extend({
    initialize: function (url, options, headers, abort) {
      L.TileLayer.WMS.prototype.initialize.call(this, url, options);
      wmsHeaders = headers;
      wmsAbort = abort;
    },
    createTile(coords, done) {
      const url = this.getTileUrl(coords);
      const obj = { img: null, reader: null };
      obj.img = document.createElement("img");
      obj.img.setAttribute("role", "presentation");
      fetchImage(
        url,
        (resp) => {
          obj.reader = new FileReader();
          obj.reader.onload = () => {
            if (obj.reader.result.indexOf("data:image/png") > -1) {
              obj.img.src = obj.reader.result;
            }
          };
          obj.reader.readAsDataURL(resp);
          done(null, obj.img);
        },
        wmsHeaders,
        wmsAbort
      );
      return obj.img;
    },
  });
};

const fetchImage = async (url, callback, headers, abort) => {
  let _headers = {};
  if (headers) {
    headers.forEach((h) => {
      _headers[h.header] = h.value;
    });
  }
  const controller = new AbortController();
  const signal = controller.signal;
  if (abort) {
    abort.subscribe(() => {
      controller.abort();
    });
  }
  const [url1, urlparams] = url.split("?");
  const f = await fetch(url1, {
    method: "POST",
    body: urlparams,
    headers: _headers,
    mode: "cors",
    signal: signal,
  });
  const blob = await f.blob();
  callback(blob);
};

export const wmsHeaderExtend = (url, options, headers, abort, setLoader) => {
  // initWMSHeader();

  var basemap = new L.TileLayer.WMSHeader(url, options, headers, abort);
  basemap.on("loading", function (event) {
    setLoader(true);
  });
  basemap.on("load", function (event) {
    setLoader(false);
  });
  return basemap;
  // return new L.TileLayer.WMSHeader(url, options, headers, abort);
};

export const calculateRemainingTime = () => {
  const loginTime = localStorage.getItem("loginTime");
  if (!loginTime) return 0;
  const currentTime = new Date().getTime();
  const twentyFourHours = 8 * 60 * 60 * 1000; // 8 hourse
  const remainingTime = twentyFourHours - (currentTime - loginTime);
  return remainingTime > 0 ? remainingTime : 0;
};
