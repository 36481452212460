import "./Drawer.css"
import {
  AuditOutlined,
  DoubleRightOutlined,
  DownOutlined,
} from "@ant-design/icons";
import {
  Button,
  Collapse,
  Input,
  Select,
  Tooltip,
  Typography,
  message,
} from "antd";
import Sider from "antd/es/layout/Sider";
import axios from "axios";
import React, { useEffect, useState } from "react";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { apiurls } from "../../resources/APIurls";
import { appconfig } from "../../resources/config";
import { SoilDataContent } from "../../resources/SoilDrawerContent";
import ImageComponent from "../ImageSoil/ImageComponent";
import { ImageDataContent } from "../../resources/SoilDrawerContent/ImageDataContent";
import { SoilMapHeading } from "../../resources/SoilDrawerContent/SoilMapHeading";
const { Panel } = Collapse;
const { Option } = Select;

const Drawer = (props) => {
  const { Search } = Input;
  let apikey_s3 = apiurls.find((item) => item.apikey == "aws3");
  const aws_s3_url = `${appconfig.domain[appconfig.environment].aws3}${
    apikey_s3.paths.images
  }`;
  const commonTypographyStyle = {
    color: "#212121",
    fontFamily: "Poppins",
    marginTop: "5px",
  };
  const {
    warning,
    collapsed,
    allFarms,
    featureType,
    mapRef,
    selectedSoilNutrient,
    L,
    selectedCommodity,
    setSelectedCommodity,
    setDeletedCommodity,
    acerageVal,
    acerageValLoader,
    acerageValDetail,
    selectedCommoditiesList,
    cropHealthLoader,
    pieChartOptions,
    farmerStatistics2,
    handleScrollEvent,
    updatedColapsed,
    farmCount,
    rasterLayer,
    getFarmerScoreCard_Farms,
    farmerDetails,
    setFarmerDetails,
    scorecard,
    setFarmersScore,
    farmersScore,
    getFarmerReportData_Farms,
    getLatLong,
    areadetails,
    areadata,
    searchForFarmMobile,
    searchForKhasra,
    setFarmMobileNum,
    setFarmName,
    yeildInfo,
    KhasraScreenShort,
    yeildLoader,
  } = props;

  const [isDrawer, setIsDrawer] = useState(false);
  const [mapLoader, setMapLoader] = useState(false);
  console.log("yeildInfo-------------------", yeildInfo);

  useEffect(() => {
    if (featureType == "farms") {
      setIsDrawer(true);
    } else {
      setIsDrawer(false);
    }
  }, [featureType]);
  const [messageApi, contextHolder] = message.useMessage();

  // GENERATE A ALERT MESSAGE WHEN DATA IS MISSING
  const warningMessage = () => {
    messageApi.open({
      type: "warning",
      content: "No data Avalaibale for the selected Region.",
      duration: 8,
    });
  };
  // GET LATLONGS AND SHOW FARM ON MAP WHEN CLICKED ON A GEPTAGGED FARM
  const drawFarmPolygon = (coords) => {
    let farmLatlongs = [];
    mapRef.current.eachLayer(function (layer) {
      if (layer instanceof L.Polygon) {
        mapRef.current.removeLayer(layer);
      }
    });
    if (rasterLayer && mapRef.current.hasLayer(rasterLayer)) {
      mapRef.current.removeLayer(rasterLayer);
    }

    var latlng = [];
    var farmLatlng = [];
    if (coords.length) {
      coords.forEach((item, index) => {
        let arr = [];
        let arr2 = [];
        arr.push(item.latitude);

        arr.push(item.longitude);
        arr2.push(item.longitude);
        arr2.push(item.latitude);

        latlng.push(arr);
        farmLatlng.push(arr2);

        if (index == coords.length - 1) {
          let arr3 = [];
          arr3.push(coords[0].longitude);
          arr3.push(coords[0].latitude);
          farmLatlng.push(arr3);
        }
      });
    } else {
      warning();
    }

    farmLatlongs = farmLatlng;
    var polygon = L.polygon(latlng, {
      fillColor: "transparent",
      weight: 3,
      opacity: 1,
      color: "#001840",
      fillOpacity: 1,
    });
    polygon.addTo(mapRef.current);
    mapRef.current.fitBounds(polygon.getBounds());
  };
  useEffect(() => {
    if (featureType == "farms") {
      updatedColapsed(!collapsed);
    }
  }, [featureType, selectedSoilNutrient]);

  // FOR STYLING
  const farmStyle = {
    position: "fixed",
    backgroundColor: "#212121",
    height: "12%",
    // width: "-webkit-fill-available",
    zIndex: "1000",
    top: "11%",
  };
  const withOutFarm = {
    position: "fixed",
    backgroundColor: "#212121",
    height: "48px",
    width: "-webkit-fill-available",
    zIndex: "1000",
    top: "64px",
    display: "flex",
    alignItems: "center",
  };
  const userToken = localStorage.getItem("token");

  const [successId, setSuccessId] = useState(false);
  const [geoLatLngs, setGeoLatLngs] = useState([]);

  // GET A SPECIFIC FARMER ID WHEN CLICK ON GEOTAGGED FARMS
  const getFarmerId = async (key) => {
    setMapLoader(true);
    let apikey = apiurls.find((item) => item.apikey == "geotaggedfarms");
    const res = await axios({
      method: "GET",
      url: `${appconfig.domain[appconfig.environment].geotaggedfarms}${
        apikey.paths.geotagedfarmerdetails
      }`,
      headers: {
        "Client-Name": "IOS_REACT_NATIVE_APP",
        "Client-Token": userToken,
        "X-localization": "en",
      },
      params: {
        customer_type: "web",
        farmer_id: key,
      },
    });
    setMapLoader(false);
    setFarmerDetails(res.data.data);
    setFarmersScore({
      ...farmersScore,
      basic_details: res.data.data,
    });
    if (res.data.data) {
      setSuccessId(true);
    }
  };

  return (
    <Sider
      collapsible={true}
      width={featureType == "farms" ? 350 : 250}
      collapsedWidth={0}
      trigger={null}
      collapsed={collapsed}
      className="rightSider"
    >
      <div className="rightSiderCont">
        <div style={isDrawer == true ? farmStyle : withOutFarm}>
          <DoubleRightOutlined
            onClick={() => updatedColapsed(!collapsed)}
            className="trigger"
            style={{
              color: "#ffffff",
              padding: 0,
              display: "flex",
              alignItems: "center",
            }}
          />
          {featureType == "farms" && (
            <>
              <Typography style={{ color: "#FFFFFF" }}>
                Total Farms :
                <span style={{ color: "#FFFFFF" }}>
                  {farmCount?.recordsTotal}
                </span>
              </Typography>
            </>
          )}
        </div>
        {featureType == "farms" ? (
          <div
            style={{ height: "100vh", overflowY: "scroll", marginTop: "32%" }}
            // onScroll={handleScrollEvent}
          >
            <div className={"searchingFarms"}>
              <Collapse id="sidebar" style={{ color: "#ffffff" }}>
                <Panel
                  header={
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-around",
                      }}
                    >
                      <Typography style={{ color: "#FFFFFF" }}>
                        search here by name/number.
                      </Typography>
                      <DownOutlined style={{ color: "#ffffff" }} />
                    </div>
                  }
                >
                  <Search
                    placeholder="Search by Farm name"
                    onChange={(event) => setFarmName(event.target.value)}
                    onSearch={(value) => searchForKhasra(value)}
                    style={{ marginLeft: "10px", marginTop: "0" }}
                  />
                  <br />
                  <Typography style={{ color: "#ffffff", textAlign: "center" }}>
                    Or
                  </Typography>
                  <Search
                    placeholder="Search by mobile no."
                    onChange={(event) => setFarmMobileNum(event.target.value)}
                    onSearch={(value) => searchForFarmMobile(value)}
                    style={{ marginLeft: "10px" }}
                  />
                </Panel>
              </Collapse>
            </div>
            {allFarms?.map((item) => (
              <div style={{ marginTop: "16%" }}>
                <div className={"farmsCards"}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "10px",
                    }}
                  >
                    <Tooltip
                      title="Generate Farmer Scorecard"
                      placement="left"
                      color={"#ffffff"}
                      overlayInnerStyle={{ color: "#424242" }}
                    >
                      <Button
                        shape="circle"
                        onClick={() => {
                          // getFarmerId(item.farmer_id);
                          getFarmerScoreCard_Farms(
                            1,
                            item.state_name,
                            item.district_name,
                            1,
                            item.khasra_number,
                            item.land_area,
                            item.geo_fencing_details
                          );
                          getFarmerScoreCard_Farms(
                            2,
                            // item.land_area,
                            // item.state_name,
                            // item.district_name,
                            // item.geo_fencing_details
                            item?.state_name,
                            item.district_name,
                            1,
                            item.khasra_number,
                            item.land_area,
                            item.geo_fencing_details
                          );
                          getFarmerScoreCard_Farms(
                            3,
                            item.state_name,
                            item.district_name,
                            1,
                            item.khasra_number,
                            item.land_area,
                            item.geo_fencing_details
                          );
                          getFarmerScoreCard_Farms(
                            4,
                            item.state_name,
                            item.district_name,
                            1,
                            item.khasra_number,
                            item.land_area,
                            item.geo_fencing_details
                          );
                          getFarmerScoreCard_Farms(
                            5,
                            item.state_name,
                            item.district_name,
                            1,
                            item.khasra_number,
                            item.land_area,
                            item.geo_fencing_details
                          );
                          KhasraScreenShort(item.geo_fencing_details);
                        }}
                        icon={<AuditOutlined />}
                      />
                    </Tooltip>
                    <Tooltip
                      title="Generate Farm Report"
                      placement="left"
                      color={"#ffffff"}
                      overlayInnerStyle={{ color: "#424242" }}
                    >
                      <Button
                        shape="circle"
                        onClick={() => {
                          getFarmerId(item.farmer_id);
                          let latslong = [];
                          getFarmerReportData_Farms(
                            // item.state_name,
                            // item.district_name,
                            // item.geo_fencing_details
                            item.state_name,
                            item.district_name,
                            item.geo_fencing_details
                            // latslong,
                          );
                        }}
                        icon={<AuditOutlined />}
                      />
                    </Tooltip>
                  </div>
                  <Collapse>
                    <Panel
                      header={
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Typography className={"cardsHead"}>
                            <span className={"cardSpantag"}>Farm Name : </span>
                            <span className={"farmersFarmName"}>
                              {item.farm_grid_name ? item.farm_grid_name : "--"}
                            </span>
                          </Typography>
                          <DownOutlined style={{ color: "#ffffff" }} />
                        </div>
                      }
                      key={item.farmer_id}
                      onClick={() => getFarmerId(item.farmer_id)}
                    >
                      {mapLoader == false && (
                        <>
                          <Typography className={"colapsefarmersCardDetails"}>
                            <span className={"colapsespantag"}>
                              Farmer Name{" "}
                            </span>

                            {Object.keys(farmerDetails).length ? (
                              // <span className={"colapsefarmersDetail"}>
                              //   {farmerDetails.customer?.id == item.farmer_id
                              //     ? farmerDetails.customer?.name
                              //     : "--"}
                              // </span>
                              <span className={"colapsefarmersDetail"}>
                                {farmerDetails?.id == item.farmer_id
                                  ? farmerDetails?.name
                                  : "--"}
                              </span>
                            ) : (
                              <span className={"colapsefarmersDetail"}>--</span>
                            )}
                          </Typography>
                          <Typography className={"colapsefarmersCardDetails"}>
                            <span className={"colapsespantag"}>
                              Mobile number{" "}
                            </span>{" "}
                            {Object.keys(farmerDetails).length ? (
                              <span className={"colapsefarmersDetail"}>
                                {farmerDetails?.id == item.farmer_id
                                  ? farmerDetails?.mobile
                                  : "--"}
                              </span>
                            ) : (
                              // <span className={"colapsefarmersDetail"}>
                              //   {farmerDetails.customer?.id == item.farmer_id
                              //     ? farmerDetails.customer?.mobile
                              //     : "--"}
                              // </span>
                              <span className={"colapsefarmersDetail"}>--</span>
                            )}
                          </Typography>
                          <Typography className={"colapsefarmersCardDetails"}>
                            <span className={"colapsespantag"}>State </span>

                            {Object.keys(farmerDetails).length ? (
                              <span className={"colapsefarmersDetail"}>
                                {farmerDetails?.id == item.farmer_id
                                  ? farmerDetails?.state_name
                                  : "--"}
                              </span>
                            ) : (
                              <span className={"colapsefarmersDetail"}>--</span>
                            )}
                          </Typography>
                        </>
                      )}
                    </Panel>
                  </Collapse>
                  <Tooltip
                    title="Click here to show your farm."
                    placement="bottom"
                  >
                    <div
                      style={{
                        backgroundColor: " rgb(48, 48, 48)",
                        padding: "6%",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        drawFarmPolygon(item?.geo_fencing_details);
                        setGeoLatLngs(item?.geo_fencing_details);
                      }}
                    >
                      {item.khasra_number == "" ? (
                        <Typography className={"colapsefarmersCardDetails"}>
                          <span className={"colapsespantag"}>Khasra </span>
                          <div className={"colapsefarmersDetail"}>
                            <span>--</span>
                          </div>
                        </Typography>
                      ) : (
                        <Typography className={"colapsefarmersCardDetails"}>
                          <span className={"colapsespantag"}>Khasra </span>
                          <div className={"colapsefarmersDetail"}>
                            <span>{item.khasra_number}</span>
                          </div>
                        </Typography>
                      )}

                      <Typography className={"colapsefarmersCardDetails"}>
                        <span className={"colapsespantag"}>Area </span>
                        <span className={"colapsefarmersDetail"}>
                          {parseFloat(item.land_area).toFixed(2)} Hec
                        </span>
                      </Typography>
                    </div>
                  </Tooltip>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <>
            {featureType === "soil-data" ? (
              <div>
               <div>
               <Typography
                 className="soil-data-drawer-txt"
                >
                  {SoilDataContent[selectedSoilNutrient]}
                </Typography>
               </div>
               <div>
               <Typography
                 className="soil-data-map-heading"
                 style={{marginTop:"1rem"}}
                >
                  {SoilMapHeading[selectedSoilNutrient]}
                </Typography>
                <ImageComponent selectedSoilNutrient={selectedSoilNutrient} />
                <Typography
                 className="soil-data-content-map"
                >
                 <span style={{fontWeight:"bold"}}>Semantics:</span>  {ImageDataContent[selectedSoilNutrient]}
                </Typography>
               </div>
              </div>
            ) : (
              <div style={{ marginTop: "22%" }}>
                <div className="siderSection">
                  <p className="siderSectionTitle">Filters</p>
                  <Select
                    mode="multiple"
                    allowClear
                    getPopupContainer={(trigger) => trigger.parentElement}
                    style={{ width: "100%" }}
                    placeholder="Please select commodity"
                    value={selectedCommodity}
                    showArrow
                    onChange={(val, option) => {
                      setSelectedCommodity(val);
                    }}
                    onDeselect={(val) => {
                      setDeletedCommodity(val);
                    }}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {acerageVal.length &&
                      acerageVal.map((item, ind) => {
                        return (
                          <Option key={item.fk_com_id} value={item.fk_com_id}>
                            {item.name}
                          </Option>
                        );
                      })}
                  </Select>
                </div>
                <div className="siderSection">
                  <p className="siderSectionTitle">Crop Information</p>
                  <div className="cropInfoCard">
                    <div className="cropInfoCardSection">
                      <p className="cropInfoCardText">Crop :</p>
                      <div style={{ width: "76%", marginLeft: "3px" }}>
                        <p className="cropInfoCardText">
                          {selectedCommoditiesList.length &&
                            selectedCommoditiesList.join(", ")}
                        </p>
                        {acerageVal.length &&
                          acerageVal.map((item, ind) => {
                            <p className="cropInfoCardText">{item.name}</p>;
                          })}
                      </div>
                    </div>
                    <hr style={{ margin: 0 }} />
                    <div className="cropInfoCardSection">
                      <p className="cropInfoCardText">
                        Area Under Cultivation (Hec) :
                      </p>
                      <div style={{ marginLeft: "4px" }}>
                        <p className="cropInfoCardText">
                          {acerageValLoader
                            ? "Fetching info.."
                            : Math.trunc(
                                acerageValDetail.totalArea
                              ).toLocaleString()}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {selectedCommodity[0] != 0 && (
                  <div className="siderSection">
                    <p className="siderSectionTitle">Crop Health Info</p>

                    <div>
                      {cropHealthLoader && (
                        <img
                          width={"80px"}
                          src={`${aws_s3_url}/crop-health-loader.gif`}
                        />
                      )}

                      {areadata.data != null && cropHealthLoader == false ? (
                        <div>
                          <HighchartsReact
                            highcharts={Highcharts}
                            options={pieChartOptions}
                          />
                        </div>
                      ) : (
                        <div
                          style={{
                            textAlign: "center",
                            borderRadius: "6px",
                            backgroundColor: "#ffffff",
                          }}
                        >
                          <Typography
                            style={{
                              color: "#212121",
                              fontSize: "11px",
                              padding: "10px",
                            }}
                          >
                            {areadata?.message} .{/* no data */}
                          </Typography>
                        </div>
                      )}
                      {/* yeild info container */}
                      <div className="yieldHead">
                        <Typography
                          style={{
                            color: "#ffffff",
                            fontWeight: "600",
                            fontSize: "14px",
                            paddingBottom: "10px",
                          }}
                        >
                          Yield Information
                        </Typography>
                      </div>
                      {yeildLoader ? (
                        <img
                          width={"80px"}
                          src={`${aws_s3_url}/crop-health-loader.gif`}
                        />
                      ) : (
                        <div className="mainYieldContainer">
                          {yeildInfo?.data !== null ? (
                            yeildInfo.map((item, index) => (
                              <div
                                key={index}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <div style={{}}>
                                  <Typography
                                    style={{
                                      ...commonTypographyStyle,
                                      fontSize: "12px",
                                    }}
                                  >
                                    {item?.name} :
                                  </Typography>
                                </div>
                                <Typography
                                  style={{
                                    ...commonTypographyStyle,
                                    fontSize: "10px",
                                    marginLeft: "7px",
                                  }}
                                >
                                  {[
                                    "Underdeveloped Fields",
                                    "Developed Fields",
                                  ].includes(item?.name)
                                    ? 0
                                    : item?.yield}
                                </Typography>
                                <Typography
                                  style={{
                                    ...commonTypographyStyle,
                                    fontSize: "8px",
                                    marginLeft: "4px",
                                  }}
                                >
                                  {item?.unit}
                                </Typography>
                              </div>
                            ))
                          ) : (
                            <Typography
                              style={{
                                ...commonTypographyStyle,
                                fontSize: "11px",
                                padding: "10px",
                              }}
                            >
                              {yeildInfo?.message}.
                            </Typography>
                          )}
                        </div>
                      )}
                      {/* yeild info container */}
                      {/* test----------------------------------- */}
                      <div>
                        <div className={"farmerdetails"}>
                          <div className={"farms"}>
                            <img src={`${aws_s3_url}/farmers.svg`} />

                            <Typography
                              className={"farmerData"}
                              style={{
                                color: "aliceblue",
                                fontSize: "inherit",
                              }}
                            >
                              total number of farmers -{" "}
                              {farmerStatistics2?.data?.farmer}
                            </Typography>
                          </div>
                          <div className={"farms"}>
                            <img src={`${aws_s3_url}/farmlands.svg`} />

                            <Typography
                              className={"farmerData"}
                              style={{
                                color: "aliceblue",
                                fontSize: "inherit",
                              }}
                            >
                              total number of farmlands -{" "}
                              {farmerStatistics2?.data?.khasra}
                            </Typography>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </Sider>
  );
};

export default Drawer;
