import { Button, Modal } from "antd";
import React, { Fragment, useEffect, useState } from "react";
import { createMemoryHistory } from "history";
import "./AppLogout.css";
import { calculateRemainingTime, postData } from "../../resources/utils";

const events = [
  "load",
  "mousemove",
  "mousedown",
  "click",
  "scroll",
  "keypress",
];

const AppLogout = ({ children }) => {
  const history = createMemoryHistory();

  let timer;
  const [modalState, setModalState] = useState(false);

  // this function sets the timer that logs out the user after 60 secs
  const handleLogoutTimer = () => {
    timer = setTimeout(() => {
      // clears any pending timer.
      resetTimer();
      // Listener clean up. Removes the existing event listener from the window
      Object.values(events).forEach((item) => {
        window.removeEventListener(item, resetTimer);
      });
      // logs out user
      if (
        window.location.pathname != "/login" &&
        window.location.pathname != "/" &&
        window.location.pathname != "/signup"
      ) {
        setModalState(true);
      }
    }, 18_00_000); // 36,00,000ms = 1 hour. You can change the time.
  };

  // this resets the timer if it exists.
  const resetTimer = () => {
    if (timer) clearTimeout(timer);
  };

  // when component mounts, it adds an event listeners to the window
  // each time any of the event is triggered, i.e on mouse move, click, scroll, keypress etc, the timer to logout user after 10 secs of inactivity resets.
  // However, if none of the event is triggered within 10 secs, that is app is inactive, the app automatically logs out.
  useEffect(() => {
    Object.values(events).forEach((item) => {
      window.addEventListener(item, () => {
        resetTimer();
        handleLogoutTimer();
        handleAutoLogout();
      });
    });
  }, []);

  const logoutApi = async () => {
    let data = {};
    localStorage.clear();

    await postData("agribazaar", "logout", data, null);
  };

  // logs out user by clearing out auth token in localStorage and redirecting url to /signin page.
  const logoutAction = () => {
    setModalState(false);
    logoutApi();
    window.location.pathname = "/login";
  };

  const logoutActionCustom = async () => {
    let data = {};
    if (localStorage.getItem("token"))
      await postData("agribazaar", "logout", data, null);

    localStorage.clear();
    history.push("/login");
  };

  const handleAutoLogout = () => {
    const remainingTime = calculateRemainingTime();

    if (remainingTime > 0) {
      const logoutTimer = setTimeout(() => {
        logoutAction();
      }, remainingTime);

      // Cleanup the timeout on component unmount
      return () => clearTimeout(logoutTimer);
    } else {
      logoutActionCustom();
    }
  };


  return (
    <Fragment>
      {children}
      <Modal
        open={modalState}
        onOk={logoutAction}
        closeIcon={null}
        closable={false}
        centered="true"
        footer={[
          <Button key="submit" type="primary" onClick={logoutAction}>
            Login
          </Button>,
        ]}
      >
        <h2>Session Expired</h2>
        Your session has been expired due to inactivity. Please login again to
        continue.
      </Modal>
    </Fragment>
  );
};

export default AppLogout;
