import { PhoneOutlined } from "@ant-design/icons";
import { Button, Input, Spin, Typography, message } from "antd";
import axios from "axios";
import React, { useState,useEffect } from "react";
import OtpInput from "react-otp-input";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { apiurls } from "../../resources/APIurls";
import { appconfig } from "../../resources/config";
import { getString } from "../../resources/utils";
import Countdown from "../Timer";
import "./LoginPage.css";

const LoginPage = () => {
  let apikey_s3 = apiurls.find((item) => item.apikey == "aws3");
  const aws_s3_url = `${appconfig.domain[appconfig.environment].aws3}${
    apikey_s3.paths.images
  }`;
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [enterOtp, setEnterOtp] = useState(false);
  const [value, setValue] = useState("");
  const language = useSelector((state) => state.Language.language);
  const [accountPending, setAccountPending] = useState(false);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(30);
  const [loader, setLoader] = useState(false);
  const [formData, setFormData] = useState({
    username: "",
    country_code: "+91",
    isWeb: true,
    send: 1,
  });

  const handleOtpChange = (val) => {
    setOtp(val);
  };

  useEffect(() => {
    if(localStorage.getItem("token")){
      navigate("/agribhumi/discover")
    }
   
  }, []);

  // for sent OPT on mobile number
  const handleLogin = async () => {
    setLoader(true);
    let otppaths = apiurls.find((item) => item.apikey == "agribazaar2");
    const res = await axios({
      method: "POST",
      url: `${appconfig.domain[appconfig.environment].agribhumi}${
        otppaths.paths.sendotp
      }`,
      headers: {
        Authorization:
          "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.Ik5FV0RDfFh8WHwzMTh8WHxYfHxYfFh8MTAwODczfFh8WHw1fFh8WHx8WHxYfGlzQWJjfFh8WHxhZG1pbnxYfFh8MjAyMi0wNS0xOSAxNDoxNToxMyI.blxod_PDzXarHH_ZoU7zEDFxPsDWXKYQUtBLZei-CHI",

        // "access-control-allow-origin": "*",

        //"Access-Control-Max-Age": 1000,
        // "Access-Control-Allow-Headers":
        //   "X-Requested-With, Content-Type, Origin, Cache-Control, Pragma, Authorization, Accept, Accept-Encoding",
        // "Access-Control-Allow-Credentials": true,
        //"Access-Control-Allow-Methods": "PUT, POST, GET, OPTIONS, DELETE",
      },
      data: {
        ...formData,
      },
    });
    if (res.data.type == "success" || !res.error) {
      setEnterOtp(true);
      message.success("OTP sent successfuly.");
      setLoader(false);
    }
  };

  const handleInputChange = (e, type) => {
    let obj = { ...formData };
    obj[type] = parseInt(e.target.value);
    setFormData(obj);
  };
  // for login after enter the OTP
  const handleOtpSubmit = async () => {
    setLoader(true);
    let formObj = {
      username: formData.username,
      country_code: 91,
      otp: otp,
      verify: 1,
      isWeb: true,
      ur: 1,
    };
    let path = apiurls.find((item) => item.apikey == "agribazaar2");
    const res = await axios({
      method: "POST",
      url: `${appconfig.domain[appconfig.environment].agribhumi}${
        path.paths.sendotp
      }`,
      headers: {
        Authorization:
          "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.Ik5FV0RDfFh8WHwzMTh8WHxYfHxYfFh8MTAwODczfFh8WHw1fFh8WHx8WHxYfGlzQWJjfFh8WHxhZG1pbnxYfFh8MjAyMi0wNS0xOSAxNDoxNToxMyI.blxod_PDzXarHH_ZoU7zEDFxPsDWXKYQUtBLZei-CHI",
      },
      data: {
        ...formObj,
      },
    });
    if (!res.data.error) {
      let path = apiurls.find((item) => item.apikey == "agribazaar2");

      const response = await axios({
        method: "POST",

        url: `${appconfig.domain[appconfig.environment].agribhumi}admin/webp/${
          path.paths.submitotp
        }`,
        headers: {
          Authorization:
            "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.Ik5FV0RDfFh8WHwzMTh8WHxYfHxYfFh8MTAwODczfFh8WHw1fFh8WHx8WHxYfGlzQWJjfFh8WHxhZG1pbnxYfFh8MjAyMi0wNS0xOSAxNDoxNToxMyI.blxod_PDzXarHH_ZoU7zEDFxPsDWXKYQUtBLZei-CHI",
        },
        data: {
          mobile_number: formData.username,
          web_access: 2,
        },
      });
      if (!response.data.error && localStorage != null) {
        if (response.data.data.token) {
          localStorage.setItem("token", response.data.data.token);
          localStorage.setItem("user_id", response.data.data.user_id);
          localStorage.setItem("username", response.data.data.username);
          localStorage.setItem("usertype", "");
          localStorage.setItem('loginTime', new Date().getTime());
          axios.defaults.headers.common["Authorization"] = response.data.data.token;
        }
        localStorage.setItem("mobile", formData.username);

        navigate("/agribhumi/discover");
        setLoader(false);
        message.success("You are successfully logged in.");
      } else {
        if (response.data.data.isApproved == 0) {
          setAccountPending(true);
          setEnterOtp(false);
        }
      }
    } else {
      message.error("Please enter valid OTP.");
    }
  };

  return (
    <div className={"mainCont"}>
      {!accountPending && !enterOtp ? (
        <div className={"loginCard"}>
          <Typography className={"cardTitle"}>
            {getString("LOGIN", language)}
          </Typography>
          <div className="inputCont">
            <div>
              <label style={{ color: "#ffffff", fontWeight: "600" }}>
                {getString("ENTER_YOUR_MOBILE", language)}
              </label>
              <Input
                placeholder={getString("ENTER_YOUR_MOBILE", language)}
                className="textInput"
                prefix={<PhoneOutlined className="site-form-item-icon" />}
                onChange={(e) => handleInputChange(e, "username")}
                allowClear
              />
            </div>
          </div>
          <Button onClick={handleLogin} className="loginBtn">
            {loader ? <Spin /> : getString("SEND_OTP", language)}
          </Button>
          <Typography className="forgotPass">
            {getString("NO_ACCOUNT", language)}

            <Link style={{ color: "#ffffff" }} to={"/signup"}>
              {getString("SIGN_UP", language)}
            </Link>
          </Typography>
          <Typography className={"helperText"}>
            {language === "english"
              ? "Facing trouble? Call us on +91-9090397777"
              : "परेशानी का सामना करना पड़ रहा है? हमें +91-9090397777 . पर कॉल करें"}{" "}
            <br />
            {language === "english"
              ? "By signing in I agree with"
              : "साइन इन करके मैं सहमत हूं"}{" "}
            <span style={{ cursor: "pointer" }}>Agribazaar Terms of Use</span>
          </Typography>
        </div>
      ) : enterOtp ? (
        <div className={"loginCard"}>
          <div>
            <Typography className={"cardTitle"}>
              {getString("LOGIN", language)}
            </Typography>
            <Typography className={"enterNum2"}>
              {language === "hindi" && formData.username}
              {getString("VERIFY_YOUR_NUMBER", language)}
              {language === "english" && formData.username}
              <span style={{ marginLeft: "12%" }}>
                {getString("NOT_YOURS", language)}
                <span
                  className={"changeNumber"}
                  onClick={() => setEnterOtp(false)}
                >
                  {getString("CHANGE_NUMBER", language)}
                </span>
              </span>
            </Typography>
            <Typography
              style={{
                marginBottom: "10px",
                fontWeight: "500",
                color: "#ffffff",
              }}
            >
              OTP
            </Typography>
            <OtpInput
              value={otp}
              onChange={handleOtpChange}
              numInputs={4}
              isInputNum={true}
              separator={<span style={{ color: "#424242" }}>--</span>}
              inputStyle={{
                width: "50px",
                height: "30px",
                borderRadius: 4,
                border: "solid 1px #cbcbcb",
                backgroundColor: "#fff",
              }}
            />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                color: "#ffffff",
                marginTop: "10px",
              }}
            >
              <span style={{ marginRight: "3px" }}>Not received?</span>
              {seconds != 0 && (
                <span style={{ margin: "0px 3px" }}>Retry in</span>
              )}

              <Countdown
                minutes={minutes}
                setMinutes={setMinutes}
                seconds={seconds}
                setSeconds={setSeconds}
              />
              {seconds == 0 && (
                <span
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                    fontWeight: "600",
                  }}
                  onClick={handleLogin}
                >
                  Resend OTP<span class="bottom-link-line"></span>
                </span>
              )}
            </div>
            <Button
              className={"loginButton"}
              onClick={() => handleOtpSubmit()}
              disabled={otp.length < 4 ? true : false}
            >
              {loader ? <Spin /> : getString("SUBMIT", language)}
            </Button>
            <Typography className={"helperText"}>
              {language === "english"
                ? "Facing trouble? Call us on +91-9090397777"
                : "परेशानी का सामना करना पड़ रहा है? हमें +91-9090397777 . पर कॉल करें"}
              <br />
              {language === "english"
                ? "By signing in I agree with"
                : "साइन इन करके मैं सहमत हूं"}{" "}
              <span style={{ cursor: "pointer" }}>Agribazaar Terms of Use</span>
            </Typography>
          </div>
        </div>
      ) : (
        <div className={"emptyMessageBox loginCard"}>
          <img src={`${aws_s3_url}/timer.svg`} />
          <Typography className="accountHoldText">
            Your Account Verification is in process.
            <br /> Please wait for approval.
          </Typography>
        </div>
      )}
    </div>
  );
};

export default LoginPage;
