import React from "react";
import ClayPercent from "../../resources/images/clay percent.png";
import BulkDensity from "../../resources/images/Bulk density.png";
import CationExchangeCapacity from "../../resources/images/cation exchange capacity.png";
import CoarseFragment from "../../resources/images/coarse fragment.png";
import OrganicCarbonDensity from "../../resources/images/organic carbon density.png";
import OrganicCarbonStock from "../../resources/images/organic carbon stock.png";
import PhWater from "../../resources/images/ph water.png";
import SandPercent from "../../resources/images/sand percent.png";
import SiltPercent from "../../resources/images/silt percent.png";
import SoilOrganicCarbon from "../../resources/images/soil organic carbon.png";

const ImageComponent = ({ selectedSoilNutrient }) => {
  const images = {
    claypercent: ClayPercent,
    bulkdensity: BulkDensity,
    cationexchangecapacity: CationExchangeCapacity,
    coarsefragment: CoarseFragment,
    organiccarbondensity: OrganicCarbonDensity,
    organiccarbonstock: OrganicCarbonStock,
    phwater: PhWater,
    sandpercent: SandPercent,
    siltpercent: SiltPercent,
    soilorganiccarbon: SoilOrganicCarbon,
  };

  const imageSrc = images[selectedSoilNutrient] || images.clay;
  return (
    <div style={{ maxWidth: "100%" }}>
      <img
        src={imageSrc}
        alt={selectedSoilNutrient}
        style={{ width: "100%", height: "auto" }}
      />
    </div>
  );
};

export default ImageComponent;
