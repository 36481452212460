export const SoilMapHeading ={
    "bulkdensity":"Bulk Density across India",
    "cationexchangecapacity":"Cation exchange capacity across India",
    "organiccarbondensity":"Organic Carbon Density across India",
    "organiccarbonstock":"Organic Carbon Stock across India",
    "phwater":"PH Water across India",
    "sandpercent":"Sand Percent across India",
    "siltpercent":"Silt Percent across India",
    "soilorganiccarbon":"Soil Organic Carbon across India",
    "claypercent":"Clay Percent across India",
    "coarsefragment":"Coarse Fragment across India"
}