import { Typography } from "antd";
import moment from "moment";
import React from "react";
import "./HomePage.css";
import { appconfig } from "../../resources/config";
import { apiurls } from "../../resources/APIurls";

const HomePage = () => {
  let apikey_s3 = apiurls.find((item) => item.apikey === "aws3");
  const aws_s3_url = `${appconfig.domain[appconfig.environment].aws3}${
    apikey_s3.paths.images
  }`;

  const card1 = [
    { img: `${aws_s3_url}/weather.png`, name: "Daily updates" },
    { img: `${aws_s3_url}/prediction.png`, name: "Prediction upto 7 days" },
    { img: `${aws_s3_url}/pasttrend.png`, name: "past trends" },
    { img: `${aws_s3_url}/bellicon.png`, name: "crop specific alerts" },
  ];
  const card2 = [
    { img: `${aws_s3_url}/optical.png`, name: "optical & sar data" },
    { img: `${aws_s3_url}/cloud.png`, name: "Cloud Free Dtata Processing" },
    { img: `${aws_s3_url}/multicrop.png`, name: "Multiple Crop Indices" },
    { img: `${aws_s3_url}/aiPowered.png`, name: "AI Powered Yield Prediction" },
  ];
  const card3 = [
    { img: `${aws_s3_url}/banking.png`, name: "Banking" },
    { img: `${aws_s3_url}/insurance.png`, name: "Insurance" },
    { img: `${aws_s3_url}/seedcompanies.png`, name: "Seed companies" },
    { img: `${aws_s3_url}/farmingcontra.png`, name: "contractua farming" },
  ];
  const card4 = [
    { img: `${aws_s3_url}/farmgeotagging.png`, name: "Farm Geotagging" },
    {
      img: `${aws_s3_url}/ownervalidation.png`,
      name: "Farm To Owner Validation",
    },
    { img: `${aws_s3_url}/farmedgematching.png`, name: "Farm Edge Matching" },
    { img: `${aws_s3_url}/croptrenda.png`, name: "Farm Level Crop Trends" },
  ];
  const bottomCard = [
    {
      img: `${aws_s3_url}/screenshot.svg`,
      text: "Impact of IT Innovation for Growing Indian Agriculture",
    },
    {
      img: `${aws_s3_url}/windwheel.svg`,
      text: "Principles of Regenerative Agriculture",
    },
    {
      img: `${aws_s3_url}/windweel.png`,
      text: "Renewable Energy: A Boon to Indian Farmers",
    },
  ];

  return (
    <div style={{ backgroundColor: "#212121" }}>
      <div className={"topDiv"}>
        <div className={"leftText"}>
          <div>
            <Typography className={"topHead"}>
              WE KEEP AN EYE ON YOUR YIELD WITH OUR{" "}
              <span className={"spanText"}>LATEST TECHNOLOGY</span>
            </Typography>
          </div>

          <div className={"leftdivBotomText"}>
            <Typography className={"leftdivBotomText"}>
              Continuously monitor your fields using high frequency and higher
              resolution satellite imagery. Scout farms efficiently and
              proactively in-season from anywhere before yield impact.
            </Typography>
          </div>
        </div>
        <div className={"rightdiv"}>
          <img src={`${aws_s3_url}/agriDesktop.png`} className={"topImg"} />
        </div>
      </div>
      <div className={"middlediv"}>
        <div className={"middleleftdiv"}>
          <div style={{}}>
            <Typography className={"middlelefttext"}>
              Weather Predictions
            </Typography>
          </div>
          <div className={"leftBotomdiv"}>
            <Typography className={"leftdivBotomText"}>
              Weather forecast for farmers to know when to apply the fertilisers
              and chemicals to avoid crop wastage. Predictions alert about
              extreme changes in atmospheric conditions before hand.
            </Typography>
          </div>
          <div className={"weatherimgdiv"}>
            <img
              src={`${aws_s3_url}/weatherHome.png`}
              className={"weatherimg"}
            />
          </div>
          <div
            style={{
              display: "flex",
              marginTop: "10%",
            }}
          >
            {card1.map((item) => {
              return (
                <div className={"middlelefticons"}>
                  {" "}
                  <img className={"imgicn"} src={item.img} />
                  <Typography className={"icnname"}>{item.name}</Typography>
                </div>
              );
            })}
          </div>
        </div>

        <div className={"middlerightSide"}>
          <div className={"righttop"}>
            <div>
              <div style={{ height: "1%" }}>
                <Typography className={"middlelefttext"}>
                  Crop Monitoring and Forecasting
                </Typography>
              </div>

              <div className={"middlerightbotmtext"}>
                <Typography className={"leftdivBotomText"}>
                  Real-time tracking of farmland using crop monitoring system to
                  implement technical mitigation measures and prevent
                  agricultural market instabilities.
                </Typography>
              </div>

              <div
                style={{
                  display: "flex",
                  marginTop: "4%",
                }}
              >
                {card2.map((item) => {
                  return (
                    <div className={"midRighTopDiv"}>
                      <img className={"midRighTopImg"} src={item.img} />
                      <Typography className={"icnname"}>{item.name}</Typography>
                    </div>
                  );
                })}
              </div>
            </div>
            <div>
              <img
                src={`${aws_s3_url}/satelite.svg`}
                style={{ height: "66%", marginTop: "15%" }}
              />
            </div>
          </div>
          <div className={"rightbottom"}>
            <div className={"bottomright"}>
              <div>
                <Typography className={"middlelefttext"}>
                  Customised Industry Solutions
                </Typography>
              </div>
              <div className={"leftdivBotomdiv"}>
                <Typography className={"leftdivBotomText"}>
                  Industry-specific solutions to support you grow your business
                  with us. Join us to gain customised tech solutions for your
                  firm in multiple sectors at one place.
                </Typography>
              </div>
              <div style={{ width: "20%" }}>
                <img
                  src={`${aws_s3_url}/fields.png`}
                  style={{ width: "280px" }}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  marginTop: "4%",
                }}
              >
                {card3.map((item) => {
                  return (
                    <div className={"middlelefticons"}>
                      <img className={"imgicn"} src={item.img} />
                      <Typography className={"icnname"}>{item.name}</Typography>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className={"bottomleft"}>
              <div>
                <Typography className={"middlelefttext"}>
                  Cadastral Digitisation
                </Typography>
              </div>
              <div className={"leftdivBotomdiv"}>
                <Typography className={"leftdivBotomText"}>
                  Proper digitised record of farm boundaries and subdivision
                  lines of your land. Easily retrievable and accessible
                  cadastral maps for convenience and easy understanding.
                </Typography>
              </div>
              <div style={{ width: "20%" }}>
                <img
                  src={`${aws_s3_url}/coins.png`}
                  style={{ width: "280px" }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  marginTop: "4%",
                }}
              >
                {card4.map((item) => {
                  return (
                    <div className={"middlelefticons"}>
                      <img className={"imgicn"} src={item.img} />
                      <Typography className={"icnname"}>{item.name}</Typography>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={"bottomdiv"}>
        <div style={{ textAlign: "center" }}>
          <Typography className="bottmtext">Product Diaries</Typography>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            marginTop: "4%",
          }}
        >
          {bottomCard.map((item) => {
            return (
              <div className="botmcard">
                <img src={item.img} className="crdimg" />
                <Typography className="cardtxt">{item.text}</Typography>

                <div style={{ marginTop: "3%" }}>
                  <Typography style={{ color: "#ffffff", marginLeft: "3%" }}>
                    {moment().format("MMM Do YY")}
                  </Typography>
                </div>
              </div>
            );
          })}
        </div>

        <div style={{ textAlign: "center", marginTop: "4%" }}>
          <Typography className="bottmtext">Read more articles</Typography>
        </div>
      </div>
    </div>
  );
};
export default HomePage;
