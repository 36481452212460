import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Potected = (props) => {
  let Cmp = props.Cmp;
  const nevigate = useNavigate();

  const userToken = localStorage.getItem("token");

  useEffect(() => {
    if (userToken == null) {
      nevigate("/");
    }
  }, []);

  return (
    <div>
      <Cmp />
    </div>
  );
};

export default Potected;
