import { LogoutOutlined, MenuOutlined } from "@ant-design/icons";
import { Button, Layout, Tooltip, Typography } from "antd";
import React, { useState } from "react";
import {  useNavigate, useParams } from "react-router-dom";
import { apiurls } from "../../resources/APIurls";
import { appconfig } from "../../resources/config";
import { postData } from "../../resources/utils";
import Discover from "../Discover/Discover";
import Farms from "../Farms/Farms";
import "./Dashboard.css";

const { Sider, Content } = Layout;

const Dashboard = () => {
  let apikey_s3 = apiurls.find((item) => item.apikey == "aws3");
  const aws_s3_url = `${appconfig.domain[appconfig.environment].aws3}${
    apikey_s3.paths.images
  }`;
  const [selecetdNav, setSelecetdNav] = useState("Discover");
  const params = useParams();
  
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(true);

  const navLinks = [
    {
      label: "Discover",
      icon: `${aws_s3_url}/discover.svg`,
      link: "/agribhumi/discover",
    },
    {
      label: "Farms",
      icon: `${aws_s3_url}/farms.svg`,
      link: "/agribhumi/farms",
    },
  ];
  

  const logOut = async () => {
    let data = {};
    localStorage.clear();

    let res = await postData("agribazaar", "logout", data, null);
    navigate("/");
  };
  return (
    <Layout>
      <Sider
        collapsedWidth={0}
        trigger={null}
        collapsed={collapsed}
        collapsible={true}
        className="drawer"
        width={80}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "15px 0px",
          }}
        >
          <MenuOutlined
            onClick={() => setCollapsed(!collapsed)}
            className="trigger"
            style={{
              color: "#ffffff",
              padding: 0,
              display: "flex",
              alignItems: "center",
            }}
          />
        </div>
        {navLinks.map((item, index) => (
          <div
            className={
              selecetdNav === item.label ? "selectedNavLinkBox" : "navLinkBox"
            }
            key={index}
            onClick={() => {
              setSelecetdNav(item.label);
              navigate(item.link);
            }}
          >
            <img src={item.icon} alt={item?.label}/>
            <Typography className="navLink">{item.label}</Typography>
          </div>
        ))}
        <Tooltip placement="bottom" title="Log Out">
          <Button
            className={"logOutBtn"}
            icon={<LogoutOutlined />}
            onClick={logOut}
            style={{
              color: "white",
              backgroundColor: "#424242",
              border: "none",
            }}
          ></Button>
        </Tooltip>
      </Sider>
      <Content>
        {params.id === "discover" ? (
          <Discover siderFlag={collapsed} siderFlagController={setCollapsed} />
        ) : params.id === "farms" ? (
          <Farms siderFlag={collapsed} siderFlagController={setCollapsed} />
        ) : (
          <div />
        )}
      </Content>
    </Layout>
  );
};

export default Dashboard;
