export const agribazaarWorkspaces = [
  {
    W_H_NAME: "GIRDHARI LAL JAIN",
    w_H_ADDRES:
      "PLOT NO.G-1-136 F, 1ST PHASE RICO INDUSTRIAL AREA SRI GANGANAGAR, RAJASTHAN  335002",
    BRANCH: "SRI GANGANAGAR",
    State: "RAJASTHAN",
    latitude: 29.8644994,
    Longitude: 73.9310418999999,
  },
  {
    W_H_NAME: "STAR AGRIWAREHOUSING AND COLLATERAL MANAGEMENT LTD",
    w_H_ADDRES:
      "KHASRA NO.587/2┬áVILLAGE RATIKHERA,┬áNEAR RAILWAY STATION, TEHSIL & DISTRICT ASHOK NAGAR, MADHYA PRADESH 473335",
    BRANCH: "INDORE",
    State: "MADHYA PRADESH",
    latitude: 24.600296,
    Longitude: 77.6616769999999,
  },
  {
    W_H_NAME: "M.K. INDUSTRIES",
    w_H_ADDRES: "RIICO AREA JAIPUR ROAD KEKRI RAJASTHAN DISTRICT AJMER 305404",
    BRANCH: "JAIPUR",
    State: "RAJASTHAN",
    latitude: 26.0004473999999,
    Longitude: 75.1664925,
  },
  {
    W_H_NAME: "SHREE BALAJI WAREHOUSE",
    w_H_ADDRES:
      "KHASARA NO. 499/35 SURANA LUNKARANSAR BIKANER RAJASTHAN 334603",
    BRANCH: "BIKANER",
    State: "RAJASTHAN",
    latitude: 28.4594279999999,
    Longitude: 73.705922,
  },
  {
    W_H_NAME: "ABP AGRIWAREHOUSING COMPANY",
    w_H_ADDRES:
      "CHAK 7 HMH HANUMANGARH TOWN, DISTRICT HANUMANGARH TOWN, RAJASTHAN 335513",
    BRANCH: "SRI GANGANAGAR",
    State: "RAJASTHAN",
    latitude: 29.5646282,
    Longitude: 74.3872008,
  },
  {
    W_H_NAME: "AGARWAL UDHYOG",
    w_H_ADDRES:
      "PLOT NO. 1 SPECIAL OLD INDUSTRIAL AREA, BHARATPUR, RAJASTHAN, 321001",
    BRANCH: "ALWAR",
    State: "RAJASTHAN",
    latitude: 27.2424545999999,
    Longitude: 77.5027719999999,
  },
  {
    W_H_NAME: "AKASH INDUSTRIES",
    w_H_ADDRES:
      "H-1-105, RIICO INDUSTRIAL AREA, DISTRICT BARAN, RAJASTHAN 325205",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.1143843,
    Longitude: 76.4530070999999,
  },
  {
    W_H_NAME: "ANOOP INDUSTRIES",
    w_H_ADDRES:
      "PLOT NO.G-410-A-1, IPIA, ROAD NO.6 DISTRICT KOTA,  RAJASTHAN 324005",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.1226446,
    Longitude: 75.8637556999999,
  },
  {
    W_H_NAME: "AGNIHOTRI N.D.T. AND UNIVERSAL ENGINEERING SERVICES",
    w_H_ADDRES: "G-1-300, AGRO FOOD PARK-II, RANPUR KOTA, RAJASTHAN 325003",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.054397,
    Longitude: 75.831928,
  },
  {
    W_H_NAME: "ASSOCIATED IRON & STEEL INDUSTRIES LTD",
    w_H_ADDRES:
      "UNDAWA ROAD, OPP. RAILWAY STATION, RAMGANJMANDI, DISTRICT KOTA, RAJASTHAN 324001",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 24.6421935999999,
    Longitude: 75.9379176,
  },
  {
    W_H_NAME: "MORPAWALA REALCON PRIVATE LIMITED CHAMBER NO. B",
    w_H_ADDRES:
      "CHAMBER NO.B, NH-12 BY PASS, VILLAGE AKTASA, DISTRICT BUNDI, RAJASTHAN 323021",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.3390489,
    Longitude: 75.7046193999999,
  },
  {
    W_H_NAME: "MORPAWALA REALCON PRIVATE LIMITED CHAMBER NO. C",
    w_H_ADDRES:
      "CHAMBER NO.C, NH-12 BY PASS, VILLAGE AKTASA, DISTRICT BUNDI, RAJASTHAN 323021",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.3217952,
    Longitude: 75.7190523,
  },
  {
    W_H_NAME: "MORPAWALA REALCON PRIVATE LIMITED CHAMBER NO. A",
    w_H_ADDRES:
      "CHAMBER NO.A, NH-12 BY PASS, VILLAGE AKTASA, DISTRICT BUNDI, RAJASTHAN 323021",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.3214237,
    Longitude: 75.7199806999999,
  },
  {
    W_H_NAME: "AYUSHI INDUSTRIES",
    w_H_ADDRES: "E-32-A, ROAD NO.2 IPIA, DISTRICT KOTA, RAJASTHAN 324005",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.141055,
    Longitude: 75.8620099,
  },
  {
    W_H_NAME: "BAGREES CEREALS PVT LTD",
    w_H_ADDRES:
      "KHASARA NO 502/503/514/515/517, BIKANER ROAD, VILLAGE BIKASAR, TEHSIL NOKHA, DISTRICT BIKANER, RAJASTHAN 334803.",
    BRANCH: "BIKANER",
    State: "RAJASTHAN",
    latitude: 27.5687943,
    Longitude: 73.4590548999999,
  },
  {
    W_H_NAME: "BAIRATHI WAREHOUSE",
    w_H_ADDRES:
      "VILLAGE HATHNODA, TEHSIL CHOMU, DISTRICT JAIPUR, RAJASTHAN 303807",
    BRANCH: "JAIPUR",
    State: "RAJASTHAN",
    latitude: 27.1992806,
    Longitude: 75.7700025999999,
  },
  {
    W_H_NAME: "BALAJI SOYA PROTEIN PVT.LTD",
    w_H_ADDRES: "G-54, AGRO FOOD PARK RANPUR, KOTA, RAJASTHAN 325003",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.0579709999999,
    Longitude: 75.8405049999999,
  },
  {
    W_H_NAME: "BALIKRUIT WAREHOUSE",
    w_H_ADDRES:
      "SURVEY NO- 36, PLOT NO -1, OPP. - BALAJI ENGINEERING COLLAGE, NEAR MAKHIYALA, DHORAJI ROAD, AT PIPARADI TIMBO, TALUKA & DISTRICT JUNAGADH, GUJARAT 362001",
    BRANCH: "RAJKOT",
    State: "GUJARAT",
    latitude: 21.6345959999999,
    Longitude: 70.4400789999999,
  },
  {
    W_H_NAME: "BANWARI BANSAL (HUF)",
    w_H_ADDRES: "KHASRA NO 281, VILLAGE ALIPUR, DELHI 110036",
    BRANCH: "ALIPUR",
    State: "DELHI",
    latitude: 28.8148066999999,
    Longitude: 77.1408126,
  },
  {
    W_H_NAME: "BHARAT JYOTI DAIRY PRODUCTS LTD",
    w_H_ADDRES:
      "G-142 & 147,E-148 & 152 , AGRO FOOD PARK RANPUR DIST KOTARJ 325003",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.0593654999999,
    Longitude: 75.8379541999999,
  },
  {
    W_H_NAME: "BUNDI AGRIMARKETING YARD PVT. LTD",
    w_H_ADDRES:
      "KHASRA NO. 165, VILLAGE RAMGANJ, KOTA - BUNDI ROAD, TEHSIL & DISTRICT BUNDI, RAJASTHAN 323001",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.3809094,
    Longitude: 75.6702695,
  },
  {
    W_H_NAME: "FARMER FORTUNE INDIA PRIVATE LIMITED, SILOS NO.3",
    w_H_ADDRES:
      "KHASRA NO. 359- 361, VILLAGE DAHRA, TEHSIL BARAN ROAD DISTRICT KOTA, RAJASTHAN 325203",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.1657234999999,
    Longitude: 76.0319053999999,
  },
  {
    W_H_NAME: "FARMER FORTUNE INDIA PRIVATE LIMITED, SILOS NO.4",
    w_H_ADDRES:
      "KHASRA NO. 359- 361, VILLAGE DAHRA, TEHSIL BARAN ROAD DISTRICT KOTA, RAJASTHAN 325203",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.1655979999999,
    Longitude: 76.0318711999999,
  },
  {
    W_H_NAME: "FARMER HARVEST WAREHOUSE",
    w_H_ADDRES:
      "PLOT NO. ┬áF-107, RIICO INDUSTRIAL AREA, SHEOGANJ, DISTRICT SIROHI, RAJASTHAN 307027",
    BRANCH: "JODHPUR",
    State: "RAJASTHAN",
    latitude: 25.1263755999999,
    Longitude: 73.0476031,
  },
  {
    W_H_NAME: "G.R. GOEL WAREHOUSE",
    w_H_ADDRES:
      "KHASRA NO. 43/27, OPP MCD PRIMARY SCHOOL, G T ROAD,  BAKOLI, DELHI, PIN-110036",
    BRANCH: "ALIPUR",
    State: "DELHI",
    latitude: 28.8105394,
    Longitude: 77.1405489999999,
  },
  {
    W_H_NAME: "KANHAIYA LAL",
    w_H_ADDRES:
      "KHASARA NO.111,V.PO.GOKULPURA ,BIKANER BYE PASSROAD  NEAR ANKIT DHARAM KANTA SIKAR RAJASTHAN 332021",
    BRANCH: "JAIPUR",
    State: "RAJASTHAN",
    latitude: 27.569305,
    Longitude: 75.170996,
  },
  {
    W_H_NAME: "KATTA WAREHOUSE",
    w_H_ADDRES:
      "65 TO 69, 94 TO 98 ,GAUTAM NAGAR VISTAR, SARNA DUNGAR, JAIPUR, RAJASTHAN 302012",
    BRANCH: "JAIPUR",
    State: "RAJASTHAN",
    latitude: 26.9971202999999,
    Longitude: 75.7025009999999,
  },
  {
    W_H_NAME: "KOTA AGRO PROTIENS PVT LTD",
    w_H_ADDRES: "G-1-216 B, ROAD NO 5, IPIA, DISTRICT KOTA, RAJASTHAN 324009",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.1251791,
    Longitude: 75.8647842,
  },
  {
    W_H_NAME: "KRISHNA TRADE CORPORATION",
    w_H_ADDRES:
      "PLOT NO.G-223, SUNDLAK RAILWAY STATION ROAD BARAN, RAJASTHAN 325205",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.1212717,
    Longitude: 76.4522482999999,
  },
  {
    W_H_NAME: "LALGARHIA WAREHOUSING PVT .LTD.",
    w_H_ADDRES: "15 ML 6 LNP  ABOHAR BY PASS,SRIGANGANAGAR RAJASTHAN 335001",
    BRANCH: "SRI GANGANAGAR",
    State: "RAJASTHAN",
    latitude: 29.9076287,
    Longitude: 73.9225033999999,
  },
  {
    W_H_NAME: "MAN KANWAR WAREHOUSE",
    w_H_ADDRES: "KHASRA NO. 643/2, GUDA NATHAVATH, BUNDI, RAJASTHAN 323001",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.3904874,
    Longitude: 75.5355344999999,
  },
  {
    W_H_NAME: "STAR AGRI WAREHOUSE",
    w_H_ADDRES:
      "PLOT NO. 25 TO 30, KHASRA NO 74/1 OF VILLAGE. DESURIYA BISHNOIYAN, NEAR ICCHA PURAN BALAJI, ON NAGAUR BYE PASS ROAD, JODHPUR, RAJASTHAN 342003",
    BRANCH: "JODHPUR",
    State: "RAJASTHAN",
    latitude: 26.386071,
    Longitude: 73.0819357999999,
  },
  {
    W_H_NAME: "NAKHATE WAREHOUSE",
    w_H_ADDRES:
      "PLOT NO 26 TO 31, MAUZA KAPSI BURJ, BHANDRA ROAD, KAPSI, TEH KAMPTEE, DISTRICT NAGPUR, 440035  MAHARASHTRA",
    BRANCH: "AKOLA",
    State: "MAHARASHTRA",
    latitude: 21.1303926,
    Longitude: 79.1917155,
  },
  {
    W_H_NAME: "NARBADA DEVI POKRA",
    w_H_ADDRES:
      "KHASRA NO.49, VILLAGE-HATTIPURA INDUSTRIAL, CHITTOR ROAD, BUNDI, RAJASTHAN 323001",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.4118530999999,
    Longitude: 75.5876542,
  },
  {
    W_H_NAME:
      "NATIONAL AGRICULTURAL CO-OPERATIVE MARKETING FEDERATION OF INDIA LTD",
    w_H_ADDRES:
      "AAGRO FOOD PARK, RIICO, SRI GANGANAGAR, DISTRICT SRI GANGANAGAR, RAJASTHAN.-335002",
    BRANCH: "SRI GANGANAGAR",
    State: "RAJASTHAN",
    latitude: 29.8678145999999,
    Longitude: 73.9241925999999,
  },
  {
    W_H_NAME: "NAVEEN SAXENA",
    w_H_ADDRES:
      "F-555, IPIA, ROAD NO.6, RIICO, DISTRICT KOTA, RAJASTHAN 324005",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.1269215999999,
    Longitude: 75.8717266,
  },
  {
    W_H_NAME: "NAVJEEVAN INDUSTRIES",
    w_H_ADDRES:
      "RIICO INDUSTRIAL AREA, JAIPUR ROAD, KEKRI, AJMER, RAJASTHAN 305404",
    BRANCH: "JAIPUR",
    State: "RAJASTHAN",
    latitude: 25.9986612,
    Longitude: 75.1646884,
  },
  {
    W_H_NAME: "P.K INDUSTRIES",
    w_H_ADDRES: "F-19 AGRO FOOD PARK RANPUR ,KOTA RAJASTHAN 325003",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.0594864,
    Longitude: 75.8428837,
  },
  {
    W_H_NAME: "PALIWAL AGROTECH INDUSTRIES",
    w_H_ADDRES:
      "KHASRA NO-75-76 & 77 VILLAGE-KHEDA JAGPURA RANPUR ROAD, KOTA, RAJASTHAN 325003",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.0696565999999,
    Longitude: 75.8622804999999,
  },
  {
    W_H_NAME: "PIYUSH CORIANDER PVT LTD",
    w_H_ADDRES:
      "PREMISES BEARING NO.F-105, AGRO FOOD PARK,RANPUR,DISTRICT-KOTA,RAJASTHAN 325003",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.0578916999999,
    Longitude: 75.8364583,
  },
  {
    W_H_NAME: "PRAMOD AGARWAL",
    w_H_ADDRES:
      "PLOT NO-C-7 BEHIND JHANKAR HOTAL DELHI ROAD, ALWAR, RAJASTHAN 301001",
    BRANCH: "ALWAR",
    State: "RAJASTHAN",
    latitude: 27.5527279999999,
    Longitude: 76.6423869999999,
  },
  {
    W_H_NAME: "PREM CHAND KARNAWAT",
    w_H_ADDRES: "E-33 ROAD NO.2, IPIA DISTRICT KOTA, RAJASTHAN 324005",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.130637,
    Longitude: 75.8549358,
  },
  {
    W_H_NAME: "PUSHKAR WAREHOUSE",
    w_H_ADDRES:
      "SURVEY NO. 254, P1 & P2, PLOT NO.-4,5,6,7 & 8, AT ANIDA (BHALODI) TALUKA GONDAL, DISTRICT RAJKOT, GUJARAT 360311",
    BRANCH: "RAJKOT",
    State: "GUJARAT",
    latitude: 21.968371,
    Longitude: 70.7155779999999,
  },
  {
    W_H_NAME: "R.K. INDUSTRIES",
    w_H_ADDRES:
      "F-35, KUBER INDUSTRIAL AREA, RANPUR, DISTRICT KOTA, RAJASTHAN 325003",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.0623581,
    Longitude: 75.8487267,
  },
  {
    W_H_NAME: "RAJASTHAN AGRO SERVICES",
    w_H_ADDRES:
      "17TH K.M SRIGANGANAGAR ROAD NEAR MAHALAXMI PETROL PUMP, BIKANER RAJASTHAN 334001",
    BRANCH: "BIKANER",
    State: "RAJASTHAN",
    latitude: 28.2453566999999,
    Longitude: 73.3600699999999,
  },
  {
    W_H_NAME: "REKHA & SARIKA GOYAL",
    w_H_ADDRES: "17TH, K..M. SRIGANGANAGAR ROAD, BIKANER RAJASTHAN 334601",
    BRANCH: "BIKANER",
    State: "RAJASTHAN",
    latitude: 28.1662232999999,
    Longitude: 73.3804384,
  },
  {
    W_H_NAME: "SANDEEP┬áTAYAL┬áGODOWN",
    w_H_ADDRES:
      "NEAR AMAR COTTON FACTORY, SAMSHABAD PATTI ROAD, SIRSA,HARYANA 125055",
    BRANCH: "SIRSA",
    State: "HARYANA",
    latitude: 29.5467292999999,
    Longitude: 75.0099555,
  },
  {
    W_H_NAME: "SANMATI INDUSTRIES",
    w_H_ADDRES: "G-49 AGRO FOOD PARK, RANPUR, KOTA, RAJASTHAN 325003",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.0590763,
    Longitude: 75.839826,
  },
  {
    W_H_NAME: "SANTRA & SAMUNDER SINGH WAREHOUSE (CHAMBER NO.2)",
    w_H_ADDRES: "KHASRA NO 257, EXTENDED LAL DORA VILLAGE ALIPUR, DELHI-110036",
    BRANCH: "ALIPUR",
    State: "DELHI",
    latitude: 28.8170211,
    Longitude: 77.1397079999999,
  },
  {
    W_H_NAME: "SAURABH ENTERPRISES",
    w_H_ADDRES:
      "F-555, IPIA, ROAD NO.6, RIICO, DISTRICT KOTA, RAJASTHAN 324005",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.1267733,
    Longitude: 75.8716732999999,
  },
  {
    W_H_NAME: "SAVITHIRIBEN TARACHAND MAHESHVARI",
    w_H_ADDRES:
      "SURVEY NO.-456, KARNI ESTATE, VILLAGE-TUNDAV, TALUKA-UNJHA, DIST-MEHSANA.GJ384170IND",
    BRANCH: "UNJHA",
    State: "GUJARAT",
    latitude: 23.827254,
    Longitude: 72.3448159999999,
  },
  {
    W_H_NAME: "SETHI ENTERPRISES",
    w_H_ADDRES:
      "OPP. MUNMUN INDUSTRIES , SUNDLAK RAILWAY STATION ROAD BARAN RAJASTHAN 325205",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.1210911,
    Longitude: 76.4520571999999,
  },
  {
    W_H_NAME: "SHANTI STONE INDUSTRIES",
    w_H_ADDRES: "E-517, ROAD NO.7 IPIA, DISTRICT KOTA, RAJASTHAN 324005",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.1170432,
    Longitude: 75.8725941,
  },
  {
    W_H_NAME: "SHREE RAM INDUSTRIES",
    w_H_ADDRES: "B-181 & B-181(A), IGC KHARA, BIKANER, RAJASTHAN 334001",
    BRANCH: "BIKANER",
    State: "RAJASTHAN",
    latitude: 28.180534,
    Longitude: 73.384309,
  },
  {
    W_H_NAME: "SHREE RAM WAREHOUSE & LOGISTICS",
    w_H_ADDRES: "KHERABAD ROAD RAMGANJMANDI DISTRICT KOTA, RAJASTHAN 326519",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 24.3732940999999,
    Longitude: 75.8224991,
  },
  {
    W_H_NAME: "SHREE SHAKTI INDUSTRIES",
    w_H_ADDRES: "61 A-62 RIICO INDUSTRIAL AREA KHAIRTHAL RAJASTHAN 301404",
    BRANCH: "ALWAR",
    State: "RAJASTHAN",
    latitude: 27.7973840999999,
    Longitude: 76.6518353,
  },
  {
    W_H_NAME: "SHRI BALAJI ENTERPRISES",
    w_H_ADDRES:
      "GRAM  DEVRIA, PACHPAHAR, BHAWANIMANDI, DISTRICT  JHALWAR ,RAJASTHAN 326502",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 24.3732992,
    Longitude: 75.8224349999999,
  },
  {
    W_H_NAME: "SHRI MOOLCHAND KALYANI BAI IND",
    w_H_ADDRES: "H-1-53, RIICO INDUSTRIAL AREA DISTRICT BARAN RAJASTHAN 325205",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.1144089999999,
    Longitude: 76.4539295,
  },
  {
    W_H_NAME: "SHUBH LAXMI FOODS",
    w_H_ADDRES:
      "C-29-30 IND AREA PHASE 1ST RICCO HANUMANGARH JUCTION, RAJASTHAN 335512",
    BRANCH: "SRI GANGANAGAR",
    State: "RAJASTHAN",
    latitude: 29.6240446,
    Longitude: 74.2722169,
  },
  {
    W_H_NAME: "STAR AGRI WAREHOUSE",
    w_H_ADDRES:
      "PLOT NO G 34 TO 36 & H 37 TO 40, RICCO INDUSTRIAL AREA, SHEOGANJ DISTRICT ┬áSIROHI, RAJASTHAN 307027",
    BRANCH: "JODHPUR",
    State: "RAJASTHAN",
    latitude: 25.1256333,
    Longitude: 73.0513452999999,
  },
  {
    W_H_NAME: "STAR AGRI INFRASTRUCTURE PVT LTD",
    w_H_ADDRES:
      "KHASRA NO.102, 103, 104, 105, 106, 107, INDUSTRIAL AREA, NAGZIRI, DEWAS ROAD, UJJAIN, MADHYA PRADESH 456664",
    BRANCH: "INDORE",
    State: "MADHYA PRADESH",
    latitude: 23.15222,
    Longitude: 75.8205999999999,
  },
  {
    W_H_NAME: "STAR AGRI WAREHOUSING AND COLLATERAL MANAGEMENT LTD",
    w_H_ADDRES:
      "KHASRA NO.338 & 340VILLAGE-DHATURIA HAVELI,DISTRICT-VIDISHA MADHYA PRADESH 464001",
    BRANCH: "BHOPAL",
    State: "MADHYA PRADESH",
    latitude: 23.5454745999999,
    Longitude: 77.8735027,
  },
  {
    W_H_NAME: "STAR AGRIWAREHOUSING AND COLLATERAL MANAGEMENT LTD",
    w_H_ADDRES:
      "KHASRA NUMBER 169/2, 176, VILLAGE BILA BAWDI, GUNA TEHSIL, GUNA, 473001 MADHYA PRADESH",
    BRANCH: "INDORE",
    State: "MADHYA PRADESH",
    latitude: 24.7318211,
    Longitude: 77.3654834,
  },
  {
    W_H_NAME: "STAR AGRIWAREHOUSING AND COLLATERAL MANAGEMENT LTD",
    w_H_ADDRES:
      "KHASRA NO. 50, 51 VILL: GAIRKHEDI, OPPOSITE ADHANI WILLMAR LTD, TEHSIL: SHUJALPUR PIN CODE: 465333 MADHYA PRADESH",
    BRANCH: "INDORE",
    State: "MADHYA PRADESH",
    latitude: 23.3464169999999,
    Longitude: 76.724603,
  },
  {
    W_H_NAME: "STAR AGRI WAREHOUSING & COLLATERAL MANAGEMENT LTD",
    w_H_ADDRES:
      "KHASRA NO.361/52, VILLAGE-HATTIPURA, TEH. & DISTRICT  BUNDI 323001 RAJASTHAN",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.4110756,
    Longitude: 75.5901063,
  },
  {
    W_H_NAME: "STAR AGRIWAREHOUSING & COLLATERAL MANAGEMENT LTD",
    w_H_ADDRES:
      "KHASRA NO. 359 & 360, VILLAGE-DAHRA, TEHSIL-LADPURA, DISTRICT KOTA, RAJASTHAN 325203",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.167083,
    Longitude: 76.033133,
  },
  {
    W_H_NAME: "SUGANDHAM BUILD STATES PVT LTD",
    w_H_ADDRES:
      "A-31- 32,┬áAGRO┬áFOOD┬áPARK RANPUR DISTRICT┬áKOTA, RAJASTHAN 325003",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.1154589,
    Longitude: 75.835773,
  },
  {
    W_H_NAME: "SUN PRIME AGRI SOLUTION PRIVATE LIMITED",
    w_H_ADDRES:
      "KHASRA NO. 44 & 46, VILLAGE-HARIPURA, TEHSIL-BARAN, DISTRICT BARAN, RAJASTHAN 325205",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.1108346,
    Longitude: 76.4280542,
  },
  {
    W_H_NAME: "SWASTIK OIL & RICE MILL",
    w_H_ADDRES: "G-301 , IPIA, ROAD NO. 6, KOTA, RAJASTHAN 324005",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.1245182999999,
    Longitude: 75.8709448999999,
  },
  {
    W_H_NAME: "VASUDHA AGRO INDUSTRIES",
    w_H_ADDRES: "F-214-215 IGC KHARA BIKANER, RAJASTHAN, PIN-334601",
    BRANCH: "BIKANER",
    State: "RAJASTHAN",
    latitude: 28.1807866999999,
    Longitude: 73.3879467,
  },
  {
    W_H_NAME: "VATSAL STONE INDUSTRIES",
    w_H_ADDRES: "F-382, ROAD┬áNO.6, IPIA DISTRICT KOTA, 324005 RAJASTHAN",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.1210473999999,
    Longitude: 75.8692201,
  },
  {
    W_H_NAME: "JUGAL KISHORE AGRO SERVICES",
    w_H_ADDRES:
      "NH 15 SRIGANGANAGAR ROAD, 17 KM STONE, KHARA,BIKANER RAJASTHAN 334601",
    BRANCH: "BIKANER",
    State: "RAJASTHAN",
    latitude: 28.1620016,
    Longitude: 73.3802136,
  },
  {
    W_H_NAME: "STARAGRI WAREHOUSING & COLLATERAL MANAGEMENT LTD",
    w_H_ADDRES:
      "KHASRA NO. 172/25, 172/26 & 172/27, CHAK 2 NGM, VILLAGE-HUSANGSAR, TEHSIL & DISTRICT BIKANER , RAJASTHAN 334001",
    BRANCH: "BIKANER",
    State: "RAJASTHAN",
    latitude: 28.1522697999999,
    Longitude: 73.4041907,
  },
  {
    W_H_NAME: "BHARAT JAIN WAREHOUSE",
    w_H_ADDRES:
      "G-1, 127, SUNDLAK ROAD, RIICO INDUSTRIAL AREA BARAN, RAJASTHAN 325205",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.1159895999999,
    Longitude: 76.4546943999999,
  },
  {
    W_H_NAME: "TIRUPATI AGRO TECH",
    w_H_ADDRES:
      "KHASRA NO. 3763, KISHANGARH ROAD  ALWAR, KHAIRTHAL, RAJASTHAN 301404",
    BRANCH: "ALWAR",
    State: "RAJASTHAN",
    latitude: 27.8028984,
    Longitude: 76.6592562999999,
  },
  {
    W_H_NAME: "TAYAL WAREHOUSE",
    w_H_ADDRES:
      "BEHIND SHANKER COTGIN INDUSTRIES, SAMSHABAD PATTI, AHMEDPUR ROAD, SIRSA, (HARYANA 125055",
    BRANCH: "SIRSA",
    State: "HARYANA",
    latitude: 29.5503307,
    Longitude: 75.0040834999999,
  },
  {
    W_H_NAME: "GOYAL AGRI WAREHOUSING CORPORATION",
    w_H_ADDRES:
      "KHASRA NO 25/48, 25/49, 25/50, 25/52 VILLAGE SALAWAS, TEHSIL LUNI DISTRICT JODHPUR RAJASTHAN  342013",
    BRANCH: "JODHPUR",
    State: "RAJASTHAN",
    latitude: 26.1415578,
    Longitude: 72.9646275,
  },
  {
    W_H_NAME: "RITU SOLANKI WAREHOUSE",
    w_H_ADDRES:
      "KHASRA NO-25/6, VILLAGE SALAWAS, DISTRICT JODHPUR, RAJASTHAN 342013",
    BRANCH: "JODHPUR",
    State: "RAJASTHAN",
    latitude: 26.1415569,
    Longitude: 72.9647908,
  },
  {
    W_H_NAME: "SHEETAL SOLANKI WAREHOUSE",
    w_H_ADDRES:
      "KHASRA NO. 25/6, VILLAGE SALAWAS DISTRICT JODHPUR, RAJASTHAN - 342802 342013",
    BRANCH: "JODHPUR",
    State: "RAJASTHAN",
    latitude: 26.1415678,
    Longitude: 72.9647798,
  },
  {
    W_H_NAME: "JORARAM WAREHOUSE",
    w_H_ADDRES:
      "KHASRA NO.156,157, PLOT NO. 25 & 26, MAHADEV NAGAR,VILLAGE SANGRIYA  DISTRICT JODHPUR, RAJASTHAN 342006",
    BRANCH: "JODHPUR",
    State: "RAJASTHAN",
    latitude: 26.1883467,
    Longitude: 72.9905119,
  },
  {
    W_H_NAME: "VISION ENTERPRISES",
    w_H_ADDRES: "G-279, INDUSTRIAL AREA, IID CENTER, BARAN, RAJASTHAN 325205",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.118894,
    Longitude: 76.4560824999999,
  },
  {
    W_H_NAME: "ABHIRAM MULTI PRODUCTS PRIVATE LIMITED",
    w_H_ADDRES:
      "D.NO: 301, KORNEPADU(V), PULLADIGUNTA, GUNTUR VATTICHERUKURU, ANDHRA PRADESH 522017.",
    BRANCH: "GUNTUR",
    State: "ANDHRA PRADESH",
    latitude: 16.2473962,
    Longitude: 80.395235,
  },
  {
    W_H_NAME: "ADITYA WAREHOUSING CORPORATION",
    w_H_ADDRES:
      "GAT NO. 178/2, AT POST TANANG, TEHSIL MIRAJ, DISTRICT SANGLI, MAHARASHTRA 416410",
    BRANCH: "SANGLI",
    State: "MAHARASHTRA",
    latitude: 16.8463183,
    Longitude: 74.5649767,
  },
  {
    W_H_NAME: "ALKABEN LALITKUMAR SANGHVI",
    w_H_ADDRES:
      "SURVEY NO. 128,129,130,131 SURVEY NO. 161/162, VILLAGE NANDASAN, TALUKA-KADI, DISTRICT MEHSANA, GUJARAT 382706",
    BRANCH: "UNJHA",
    State: "GUJARAT",
    latitude: 23.3759434,
    Longitude: 72.4064199999999,
  },
  {
    W_H_NAME: "ASHOK SHARMA WAREHOUSE",
    w_H_ADDRES: "G-45, AGRO FOOD PARK, RANPUR, KOTA, RAJASTHAN - 325003",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.0586167,
    Longitude: 75.8413867,
  },
  {
    W_H_NAME: "JALAN PRODUCT WAREHOUSE",
    w_H_ADDRES: "H-58 RIICO INDUSTRIAL AREA, BARAN, RAJASTHAN 325205",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.1134686,
    Longitude: 76.4535007,
  },
  {
    W_H_NAME: "MAHIPAL AND SONS",
    w_H_ADDRES:
      "CHAK 3 H.H. MURBA NO. 35, KILLA NO. 6,7,8,9,10,12,13,14,15 & 18 N.H. NO. 15/62 SRIGANGANAGAR RAJASTHAN 335001",
    BRANCH: "SRI GANGANAGAR",
    State: "RAJASTHAN",
    latitude: 29.817739,
    Longitude: 73.8758289999999,
  },
  {
    W_H_NAME: "MANGALCHAND AGGARWAL",
    w_H_ADDRES:
      "10 KRW, SANGARIA ROAD TEH. SADULSHAHAR, DIST SRI GANGANAGARRJ335062IND",
    BRANCH: "SRI GANGANAGAR",
    State: "RAJASTHAN",
    latitude: 29.9008469999999,
    Longitude: 74.1889079,
  },
  {
    W_H_NAME: "STAR AGRI WAREHOUSE GODOWN NO 01",
    w_H_ADDRES:
      "PLOT NO. 5 AND 6, KHASRA NO 74/1 OF VILLAGE DESURIYA BISHNOIYAN,NEAR ICCHA PURAN BALAJI,ON NAGAUR BYE PASS ROAD, JODHPUR, RAJASTHAN 342001",
    BRANCH: "JODHPUR",
    State: "RAJASTHAN",
    latitude: 26.3863008999999,
    Longitude: 73.0820372,
  },
  {
    W_H_NAME: "PREM INDUSTRIES",
    w_H_ADDRES: "E-29, AGRO FOOD PARK, RANPUR, KOTA, RAJASTHAN 325003",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.0595725999999,
    Longitude: 75.8416569,
  },
  {
    W_H_NAME: "RANGOLI CERAMICS PVT. LTD",
    w_H_ADDRES: "CHAK GARBHI, LALGARD, BIKANER RAJASTHAN 334001",
    BRANCH: "BIKANER",
    State: "RAJASTHAN",
    latitude: 28.0547116999999,
    Longitude: 73.3096108999999,
  },
  {
    W_H_NAME: "S.V. CASTING PVT. LTD",
    w_H_ADDRES:
      "OLD INDUSTRIAL AREA, NEAR SHREE OM DHARAM KANTA, OLD DELHI ROAD, ALWAR, 301001",
    BRANCH: "ALWAR",
    State: "RAJASTHAN",
    latitude: 27.5580859999999,
    Longitude: 76.6276739999999,
  },
  {
    W_H_NAME: "SHREE GANESH WAREHOUSING CORPORATION",
    w_H_ADDRES:
      "SURVEY NO. 157/2 , KHANAPUR TRAMBAKPUR, GRAMPANCHAYAT JALGAON NAHATE  TALUKA AKOT DISTRICT AKOLA, MAHARASHTRA 444101",
    BRANCH: "AKOLA",
    State: "MAHARASHTRA",
    latitude: 21.0849626,
    Longitude: 77.0375597,
  },
  {
    W_H_NAME: "SHRI KRISHNA AGARWAL",
    w_H_ADDRES:
      "C-9 , 10 BEHIND JHANKAR HOTEL , DELHI ROAD,ALWAR, RAJASTHAN 301001",
    BRANCH: "ALWAR",
    State: "RAJASTHAN",
    latitude: 27.5522847999999,
    Longitude: 76.6417505999999,
  },
  {
    W_H_NAME: "SHRI KRISHNA AGARWAL",
    w_H_ADDRES: "PLOT 33, IN FRONT  JHANKAR HOTEL,ALWAR RAJASTHAN 301001",
    BRANCH: "ALWAR",
    State: "RAJASTHAN",
    latitude: 27.5543511,
    Longitude: 76.6429643999999,
  },
  {
    W_H_NAME: "SRINIVAS ENGINEERING & ALLIED WORKS PRIVATE LIMITED",
    w_H_ADDRES: "PLOT NO. D-89,MIDC NANDED\nMH\n431602\nIND",
    BRANCH: "SANGLI",
    State: "MAHARASHTRA",
    latitude: 19.1067299999999,
    Longitude: 77.3288266999999,
  },
  {
    W_H_NAME: "STAR AGRIINFRASTRUCTURE PVT LTD.",
    w_H_ADDRES:
      "KHASRA NO.421/1 VILL- GOVINDPUR,\nDATIA BADONI MP\n475661\nIND",
    BRANCH: "BHOPAL",
    State: "MADHYA PRADESH",
    latitude: 25.6818087999999,
    Longitude: 78.4079423,
  },
  {
    W_H_NAME: "STAR AGRIWAREHOUSING AND COLLATERAL MANAGEMENT LTD",
    w_H_ADDRES:
      "C/O STAR AGRIINFRASTRUCTURE PVT. LTD.,KHASARA NO.139/2 & 139/3 VILLAGE KADOLIA UBHARI, TEHSIL & DIST. HARDA 461331",
    BRANCH: "BHOPAL",
    State: "MADHYA PRADESH",
    latitude: 22.3203907,
    Longitude: 77.05696,
  },
  {
    W_H_NAME: "VAIBHAV WAREHOUSE",
    w_H_ADDRES:
      "BHANWARGARH ROAD, GRAM-NAHARGARH,TEHSIL-KISHANGANJ, DISTRICT  BARAN, RAJASTHAN  325218",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 24.9346445999999,
    Longitude: 76.8373744999999,
  },
  {
    W_H_NAME: "AGRISTAR LOGISTIC SOLUTIONS LLP",
    w_H_ADDRES:
      "SURVEY NO.216/1/3, VILLAGE JIVAJIPUR, PH NO.59, TEHSIL GANJBASODA, DISTRICT VIDISHA, MADHYA PRADESH 464221",
    BRANCH: "BHOPAL",
    State: "MADHYA PRADESH",
    latitude: 23.8324046,
    Longitude: 77.9580287999999,
  },
  {
    W_H_NAME: "ASHWIN OIL MILL",
    w_H_ADDRES:
      "GUT NO.47, MOUJE LAKHMAPUR, TALUKA AKOT, DISTRICT AKOLA MAHARASHTRA 444101",
    BRANCH: "AKOLA",
    State: "MAHARASHTRA",
    latitude: 21.1210448,
    Longitude: 77.0563724999999,
  },
  {
    W_H_NAME: "FARMER FORTUNE INDIA PRIVATE LIMITED, SILOS NO.1",
    w_H_ADDRES:
      "KHASRA NO. 359- 361, VILLAGE DAHRA, TEHSIL BARAN ROAD DISTRICT KOTA, RAJASTHAN 325203",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.1657234999999,
    Longitude: 76.0319053999999,
  },
  {
    W_H_NAME: "FARMER FORTUNE INDIA PRIVATE LIMITED, SILOS NO.2",
    w_H_ADDRES:
      "KHASRA NO. 359- 361, VILLAGE DAHRA, TEHSIL BARAN ROAD DISTRICT KOTA, RAJASTHAN 325203",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.1655979999999,
    Longitude: 76.0318711999999,
  },
  {
    W_H_NAME: "GIRNAR AGRO",
    w_H_ADDRES: "F-24,AGRO FOOD-PARK RANPUR KOTA RAJASTHAN - 325003",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.0603044,
    Longitude: 75.8439376999999,
  },
  {
    W_H_NAME: "KANTABEN RANCHODBHAI PATEL",
    w_H_ADDRES:
      "R.S.N 630, AT. JETLVASNA , TALUKA VISNAGAR, DISTRICT MEHSANA, GUJARAT 384315",
    BRANCH: "UNJHA",
    State: "GUJARAT",
    latitude: 23.7290153,
    Longitude: 72.3848243999999,
  },
  {
    W_H_NAME: "MITESHKUMAR FARSURAM THAKKAR",
    w_H_ADDRES: "VAGHEL ROAD, HARIJ, GUJARAT, 384240",
    BRANCH: "UNJHA",
    State: "GUJARAT",
    latitude: 23.6690694999999,
    Longitude: 71.9169009999999,
  },
  {
    W_H_NAME: "PARTH AGRO INDUSTRIAL PARK",
    w_H_ADDRES:
      "Survey No.-1160, Paiki-1, At-Kamli, Taluka-Unjha, Dist-Mehsana, Gujarat\nGJ\n384170\nIND",
    BRANCH: "UNJHA",
    State: "GUJARAT",
    latitude: 23.8481084999999,
    Longitude: 72.3749784999999,
  },
  {
    W_H_NAME: "SAU RAMABAI NANDLAL AGARWAL",
    w_H_ADDRES:
      "PLOT NO. 3, 4, 5, SURVEY NO. 157/1, AT. KHANPUR, TRIMBAKPUR, TALUKA AKOT, DISTRICT AKOLA,  MAHARASHTRA 444101",
    BRANCH: "AKOLA",
    State: "MAHARASHTRA",
    latitude: 21.0850353999999,
    Longitude: 77.0375018,
  },
  {
    W_H_NAME: "ANAND AGRO",
    w_H_ADDRES:
      "GUT NO 502, TAKALI KUMBKARAN, TALUKA & DIST PARBHANI, PIN 431401.",
    BRANCH: "SANGLI",
    State: "MAHARASHTRA",
    latitude: 19.2891420999999,
    Longitude: 76.7281104,
  },
  {
    W_H_NAME: "RANVIR SINGH RANA GODOWN NO 2",
    w_H_ADDRES: "KHASRA NO.106/131,VILLAGE KHERA KALAN.DELHI 110082",
    BRANCH: "ALIPUR",
    State: "DELHI",
    latitude: 28.7776430999999,
    Longitude: 77.1240686999999,
  },
  {
    W_H_NAME: "GOYAL AGRI WAREHOUSING CORPORATION",
    w_H_ADDRES:
      "KHASRA NO 25/16 VILLAGE SALAWAS TEHSIL LUNI DISTRICT JODHPUR 342001 RAJASTHAN",
    BRANCH: "JODHPUR",
    State: "RAJASTHAN",
    latitude: 26.1426799999999,
    Longitude: 72.9652482999999,
  },
  {
    W_H_NAME: "B D AGRO TECH",
    w_H_ADDRES:
      "OPP. TAYAL WAREHOUSE, VILLAGE SAMSHABAD, TEHSIL & DIST SIRSA HARYANA125055",
    BRANCH: "SIRSA",
    State: "HARYANA",
    latitude: 29.55002457,
    Longitude: 75.0040989,
  },
  {
    W_H_NAME: "MR. RAJESH KUMAR PARTNER  M/S RAJ COMMODITY",
    w_H_ADDRES:
      "F 43 RIICO INDUSTRIAL AREA, SURATGARH DISTRICT SRIGANGANAGAR,  RAJASTHAN 335804",
    BRANCH: "SRI GANGANAGAR",
    State: "RAJASTHAN",
    latitude: 29.3086348,
    Longitude: 73.9114574999999,
  },
  {
    W_H_NAME: "AGGARWAL MILL",
    w_H_ADDRES:
      "BEHIND C R CHHABRA INDUSTRIES, RIICO INDUSTRIAL AREA ANOOPGARH, PIN-335701.",
    BRANCH: "SRI GANGANAGAR",
    State: "RAJASTHAN",
    latitude: 29.1957767,
    Longitude: 73.2207599999999,
  },
  {
    W_H_NAME: "MISHRA WAREHOUSE",
    w_H_ADDRES:
      "PLOT NO.36-37,MAA VAISHNAV NAGAR, 1ST SARNA DUGAR, IND AREA BENAR ROAD, JAIPUR 302012",
    BRANCH: "JAIPUR",
    State: "RAJASTHAN",
    latitude: 26.9990413999999,
    Longitude: 75.7050547,
  },
  {
    W_H_NAME: "K.MOHANA RAO ESTATES PVT LTD",
    w_H_ADDRES:
      "D.NO: 9/290, NEAR VIGNAN UNIVERSITY, VADLAMUDI, CHEBROLU(M),  GUNTUR DIST.-522213.",
    BRANCH: "GUNTUR",
    State: "ANDHRA PRADESH",
    latitude: 16.2316717,
    Longitude: 80.5443149999999,
  },
  {
    W_H_NAME: "KAPOOR CHAND GUPTA WAREHOUSE",
    w_H_ADDRES:
      "PLOT NO.17, MAA VAISHNAV NAGAR-1ST SARNA DUNGAR IND. AREA BENAR ROAD, JAIPUR 302012, RAJASTHAN",
    BRANCH: "JAIPUR",
    State: "RAJASTHAN",
    latitude: 26.9991252,
    Longitude: 75.7049705,
  },
  {
    W_H_NAME: "GOYAL TRADERS",
    w_H_ADDRES:
      "PLOT NO. F-34, J-65, J-66, RIICO INDUSTRIAL AREA, SURATGARH, RAJASTHAN, PIN-335804.",
    BRANCH: "SRI GANGANAGAR",
    State: "RAJASTHAN",
    latitude: 29.3455328999999,
    Longitude: 73.663442,
  },
  {
    W_H_NAME: "URMILA SINGH WAREHOUSE",
    w_H_ADDRES:
      "BALAJI NAGAR KE PASS, MANGROL BY PASS ROAD, BARAN (RAJASTHAN), PIN-325205.",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.114056,
    Longitude: 76.5043565999999,
  },
  {
    W_H_NAME: "RATHOR INDUSTRIES",
    w_H_ADDRES:
      "G-510 II, ROAD NO. 7, IPIA, KOTA, DISTRICT KOTA, RAJASTHAN 324005",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.1147443,
    Longitude: 75.8696977,
  },
  {
    W_H_NAME: "MURARI LAL & COMPANY",
    w_H_ADDRES: "F-17 RIICO INDUSTRIAL AREA BARAN ( RAJ.), PIN-325205.",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.1109696,
    Longitude: 76.4523786,
  },
  {
    W_H_NAME: "VIRAAJ AGROTECH",
    w_H_ADDRES: "G-226 IID CENTRE BARAN RAJASTHAN-325205",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.1170779,
    Longitude: 76.4528741999999,
  },
  {
    W_H_NAME: "MAHIPAL AND SONS",
    w_H_ADDRES:
      "CHAK 3 H.H. MURBA NO. 35, KILLA NO.6,7,8,9,10,12,13,14,15 & 18 N.H. NO. 15/62 SRI GANGANAGAR 335001 RAJASTHAN",
    BRANCH: "SRI GANGANAGAR",
    State: "RAJASTHAN",
    latitude: 29.817739,
    Longitude: 73.8758289999999,
  },
  {
    W_H_NAME: "PREMCHAND RUKMANI BAI",
    w_H_ADDRES:
      "H-1-55 A  RIICO INDUSTRIAL AREA, KOTA ROAD, BARAN (RAJASTHAN), PIN-325205.",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.1141171,
    Longitude: 76.4535706,
  },
  {
    W_H_NAME: "HITESH KUMAR GOYAL",
    w_H_ADDRES: "F-21, RIICO INDUSTRIAL AREA, BARAN, RAJASTHAN 325205",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.1109210999999,
    Longitude: 76.4537229,
  },
  {
    W_H_NAME: "SUMITRA AGRO INDUSTRIES",
    w_H_ADDRES:
      "KHASRA NO 1264, 1265, 1265/2, 1264/20, BAPU NAGAR, PALRI, SUMERPUR PALI, RAJASTHAN, PIN-306902.",
    BRANCH: "JODHPUR",
    State: "RAJASTHAN",
    latitude: 25.1069093999999,
    Longitude: 73.0247197,
  },
  {
    W_H_NAME: "OMKAR AGRITECH WAREHOUSE",
    w_H_ADDRES:
      "SURVEY NO.224/A/12, PAIKI-1, OPP.-SHIVDHAM TEMPLE, AT & POST ΓÇô RASANA, TALUKA- DEESA, DISTRICT BANASKANTHA, GUJARAT 385535",
    BRANCH: "UNJHA",
    State: "GUJARAT",
    latitude: 24.2356799999999,
    Longitude: 72.2493079999999,
  },
  {
    W_H_NAME: "SHRI KRISHNA AGARWAL",
    w_H_ADDRES:
      "Plot No.  C-2 AND C-3, BEHIND JHANKAR HOTEL, DELHI ROAD, ALWAR, RAJASTHAN, PIN-301001.",
    BRANCH: "ALWAR",
    State: "RAJASTHAN",
    latitude: 27.5522807999999,
    Longitude: 76.6419283,
  },
  {
    W_H_NAME: "AGRIGATE 01",
    w_H_ADDRES: "MAROL",
    BRANCH: "SANGLI",
    State: "MAHARASHTRA",
    latitude: 19.115525,
    Longitude: 72.8807879999999,
  },
  {
    W_H_NAME: "BANSAL WAREHOUSE",
    w_H_ADDRES:
      "KHASRA NO -33/11/2(4-0), 33/12(4-16), 33/10(4-16)AND 11/1(0-16) VILLAGE - BHORGARH, NARELA, DELHI -110040.",
    BRANCH: "ALIPUR",
    State: "DELHI",
    latitude: 28.8322840999999,
    Longitude: 77.0745995999999,
  },
  {
    W_H_NAME: "KARNI INDUSTRIES",
    w_H_ADDRES:
      "PLOT NO-H -20B, H-20 E D , PHASE II, INDUSTRIAL AREA, SANGARIA, DIST. HANUMANGARH, RAJASTHAN - 335063.",
    BRANCH: "SRI GANGANAGAR",
    State: "RAJASTHAN",
    latitude: 29.7539997,
    Longitude: 74.4667517999999,
  },
  {
    W_H_NAME: "PURUSHOTAM GOYAL",
    w_H_ADDRES:
      "NEAR BASANTI CHOWK,BRAMAH COLONY, SRI GANGANAGAR, DIST. SRI GANGANAGAR (RAJASTHAN), PIN-335001.",
    BRANCH: "SRI GANGANAGAR",
    State: "RAJASTHAN",
    latitude: 29.9084717,
    Longitude: 73.8668782999999,
  },
  {
    W_H_NAME: "SHREE MARUTI WAREHOUSING AND INDUSTRIAL CORPORATION",
    w_H_ADDRES:
      "SURVERY NO - 295/5, AT-MITHI  ROHAR , TALUKA - GANDHIDHAM, DIST.- KUTCH, GUJARAT- 370201",
    BRANCH: "RAJKOT",
    State: "GUJARAT",
    latitude: 23.10073853,
    Longitude: 70.15052903,
  },
  {
    W_H_NAME: "SHAKILA WAREHOUSE",
    w_H_ADDRES:
      "BHADURPURA MATUNDA ROAD BUNDI DISTRICT BUNDI, RAJASTHAN 323001",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.4342882,
    Longitude: 75.6742028,
  },
  {
    W_H_NAME: "FIREX RUBBER INDUSTRIES",
    w_H_ADDRES: "G-462,IPIA  ROAD NO-7,  DISTT- KOTA, RAJASTHAN -324005",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.1178244,
    Longitude: 75.8646142,
  },
  {
    W_H_NAME: "ARJUNA LOGISTICS",
    w_H_ADDRES:
      "PLOT NO. M-69, MIDC GROWTH CENTRE, AKOLA MAHARASHTRA AKOLA 444001",
    BRANCH: "AKOLA",
    State: "MAHARASHTRA",
    latitude: 20.6765460999999,
    Longitude: 77.0635613999999,
  },
  {
    W_H_NAME: "HAREKRISHNA WAREHOUSE",
    w_H_ADDRES:
      "SURVEY NO. 33/1 GAT NO. 102, AT VILLAGE KUMBHARI TALUKA, DISTRICT  AKOLA, MAHARASTRA 444001",
    BRANCH: "AKOLA",
    State: "MAHARASHTRA",
    latitude: 20.6741519999999,
    Longitude: 77.081332,
  },
  {
    W_H_NAME: "RAMESH CHAND PAWAN KUMAR",
    w_H_ADDRES: "E-422-( B ) IPIA  ROAD NO-7  DISTT- KOTA, RAJASTHAN 324005",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.1163422999999,
    Longitude: 75.8677842999999,
  },
  {
    W_H_NAME: "BHARAT JYOTI DAIRY PRODUCT LTD",
    w_H_ADDRES:
      "G-142 & 147, E-148 & 152, AGRO FOOD PARK, RANPUR, KOTA ,RAJASTHAN - 325003",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.0590797999999,
    Longitude: 75.8386959999999,
  },
  {
    W_H_NAME: "PREM AND COMPANY",
    w_H_ADDRES: "G-1-418 A, IPIA  ROAD NO-6,  DISTT- KOTA,RAJ - 324005",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.121236,
    Longitude: 75.8681009999999,
  },
  {
    W_H_NAME: "MAHESH & COMPANY",
    w_H_ADDRES: "B-504  ,IPIA  ROAD NO-6 DISTT- KOTA(RAJASTHAN) 324005",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.1224634,
    Longitude: 75.8624087,
  },
  {
    W_H_NAME: "ROKADESHWAR WAREHOUSE",
    w_H_ADDRES: "PLOT NO -A 34 MIDC AREA, BASMAT ROAD, PARBHANI. PIN: 431401",
    BRANCH: "SANGLI",
    State: "MAHARASHTRA",
    latitude: 19.2674138,
    Longitude: 76.8101635999999,
  },
  {
    W_H_NAME: "BRIJESH TAPARIA WAREHOUSE",
    w_H_ADDRES:
      "H1-32, INDUSTRIAL AREA, HATTIPURA DIST.-BUNDI, RAJASTHAN 323001",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.4031576,
    Longitude: 75.5896276999999,
  },
  {
    W_H_NAME: "KOHINOOR AGRO FOOD CORPORATION",
    w_H_ADDRES:
      "KHATA NO. 125 MURBA NO/P NO. 185/405 KILA NO 6-10/2 AND KAHTA NO3 133 P NO. 184/405 KILA NO. 10 CHAK 15 BGD TEH SRIVIJAYNAGA DISTT SRIGANGANAGAR 335704",
    BRANCH: "SRI GANGANAGAR",
    State: "RAJASTHAN",
    latitude: 29.2748342999999,
    Longitude: 73.5011171,
  },
  {
    W_H_NAME: "SEEMA TAPARIYA",
    w_H_ADDRES:
      "HI-35-C INDUSTRIAL AREA, HATTIPURA DIST.-BUNDI,PIN 323001 RAJASTHAN",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.4033221,
    Longitude: 75.5904414999999,
  },
  {
    W_H_NAME: "PARADISE ACADEMY PVT LTD",
    w_H_ADDRES: "KHASRA NO. 156,157,158 VILLAGE BUDHPUR NORTH DELHI -110036",
    BRANCH: "ALIPUR",
    State: "DELHI",
    latitude: 28.78102,
    Longitude: 77.134645,
  },
  {
    W_H_NAME: "GARG INDUSTRIES",
    w_H_ADDRES:
      "H-1-35(D) INDUSTRIAL AREA, HATTIPURA DIST.-BUNDI, RAJASTHAN 323001",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.4032677,
    Longitude: 75.5905019,
  },
  {
    W_H_NAME: "SHANKAR AGRO WAREHOUSING",
    w_H_ADDRES: "UNDAWA ROAD RAMGANJMANDI DISST KOTA RAJ. PIN 326519",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 24.6349863999999,
    Longitude: 75.933441,
  },
  {
    W_H_NAME: "RAJKUMARI AGARWAL",
    w_H_ADDRES: "MORIJA ROAD, CHOMU, JAIPUR, RAJASTHAN 303702",
    BRANCH: "JAIPUR",
    State: "RAJASTHAN",
    latitude: 27.1608799999999,
    Longitude: 75.7490212,
  },
  {
    W_H_NAME: "SHREE VIKASH INDUSTRIES",
    w_H_ADDRES: "KHASRA NO.160, VILLAGE SARNA DUGAR , JAIPUR -302012 RAJASTHAN",
    BRANCH: "JAIPUR",
    State: "RAJASTHAN",
    latitude: 27.0035649999999,
    Longitude: 75.7058837999999,
  },
  {
    W_H_NAME: "MAMTA AGRO SEEDS CORPORATION",
    w_H_ADDRES:
      "G-110 ,AGRO FOOD PARK RIICO INDUSTRIEAL AREA RANPUR KOTA 325003",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.0625651,
    Longitude: 75.8474132,
  },
  {
    W_H_NAME: "MOTU NANU WAREHOUSE (BHAVESH BHAI)",
    w_H_ADDRES:
      "SURVEY NO 63/2 PIPARDI TIMBA VILLAGE MAKHIYALA DIS- JUNAGADH 362011",
    BRANCH: "RAJKOT",
    State: "GUJARAT",
    latitude: 21.6330606,
    Longitude: 70.4433963,
  },
  {
    W_H_NAME: "HUSSAIN COLD STORAGE",
    w_H_ADDRES: "SY NO 3/3 HIRAPUR MSK MILL ROAD GULBARGA PIN:585105",
    BRANCH: "RAICHUR",
    State: "KARNATAKA",
    latitude: 17.336525,
    Longitude: 76.8086739999999,
  },
  {
    W_H_NAME: "CHANDAN JAIN",
    w_H_ADDRES: "SHRI MAHAVEER NAGAR COLONY,BAGHERA ROAD, KEKRI 305404",
    BRANCH: "JAIPUR",
    State: "RAJASTHAN",
    latitude: 25.982326,
    Longitude: 75.1612181999999,
  },
  {
    W_H_NAME: "PRERNA FOODS",
    w_H_ADDRES: "G1-320 ( C )  ROAD NO-6 IPIA DISTRICT KOTA RAJASTHAN 324005",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.1272206,
    Longitude: 75.868772,
  },
  {
    W_H_NAME: "Takhar Warehouse",
    w_H_ADDRES:
      "2-HH,  2nd , NH-15, Netewala Suratgarh Road Sri Ganganagar-335001 (Rajasthan)",
    BRANCH: "SRI GANGANAGAR",
    State: "RAJASTHAN",
    latitude: 29.8350334,
    Longitude: 73.875123,
  },
  {
    W_H_NAME: "SINGHAL UDYOG GAURI UDYOG",
    w_H_ADDRES: "97A-9 &20 OLD INDSUTRIAL AREA,BHARATPUR, RAJASTHAN 321001",
    BRANCH: "ALWAR",
    State: "RAJASTHAN",
    latitude: 27.2430957999999,
    Longitude: 77.5024133999999,
  },
  {
    W_H_NAME: "ABHIJIT GALAV",
    w_H_ADDRES: "PLOT NO.37 BY PASS ROAD MAIL KHEDI BARAN RAJASTHAN 325205",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.1135556999999,
    Longitude: 76.4988682,
  },
  {
    W_H_NAME: "PREETI JAIN  W/O PRASHANT JAIN",
    w_H_ADDRES:
      "VILLAGE GIROURA KASHGUNJ ROAD NEAR PAC LINE, UTTAR PRADESH  207001",
    BRANCH: "MATHURA",
    State: "UTTAR PRADESH",
    latitude: 27.6018350999999,
    Longitude: 78.6474977999999,
  },
  {
    W_H_NAME: "RAMA DEVI WAREHOUSE",
    w_H_ADDRES: "HATHOURA BUJURG,MOHAMDI ROAD SHAHJANPUR, UTTAR PRADESH 242001",
    BRANCH: "MATHURA",
    State: "UTTAR PRADESH",
    latitude: 27.8826232999999,
    Longitude: 79.9414366999999,
  },
  {
    W_H_NAME: "GSN GODOWN",
    w_H_ADDRES:
      "D.NO-613/3, MAIN ROAD, SRINIVASA COLONY, NALLAPADU, GUNTUR AP-522005",
    BRANCH: "GUNTUR",
    State: "ANDHRA PRADESH",
    latitude: 16.312075,
    Longitude: 80.363,
  },
  {
    W_H_NAME: "LAXMI WAREHOUSE",
    w_H_ADDRES:
      "VILL-DAGRAI UNNAO ROAD DIST- DATIA PIN - 475661 , MADHYA PRADESH",
    BRANCH: "BHOPAL",
    State: "MADHYA PRADESH",
    latitude: 25.657012,
    Longitude: 78.486547,
  },
  {
    W_H_NAME: "ANNAPURNA WAREHOUSE & LOGISTICS",
    w_H_ADDRES: "VILL- LAHAGADUA , CHHINDWARA  MADHYA PRADESH 480001",
    BRANCH: "BHOPAL",
    State: "MADHYA PRADESH",
    latitude: 22.0955,
    Longitude: 78.9811899999999,
  },
  {
    W_H_NAME: "SHRI DHARNIDHAR AGRO INDUSTRIES",
    w_H_ADDRES: "G1-209 IID CENTER RIICO IND AREA BARAN 325205",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.1197294999999,
    Longitude: 76.4529476,
  },
  {
    W_H_NAME: "PARTH AGRO PARK",
    w_H_ADDRES:
      "R S NO. 1160, PAIKI 1 PLOT NO 175 AT KAMALI UNJHA GUJARAT MAHESANA-384170",
    BRANCH: "UNJHA",
    State: "GUJARAT",
    latitude: 23.8467372,
    Longitude: 72.373068,
  },
  {
    W_H_NAME: "M/S. BABU WAREHOUSING PRIVATE LIMITED",
    w_H_ADDRES:
      "D.NO :SURVEY NO-112/A,112/B,113/3,112/C,110/U,111/3M,129/3C,CHINAKONDRUPADU (V),NADIMPALEM (P),PRATHIPADU(M),GUNTUR(DIST),A.P-522019",
    BRANCH: "GUNTUR",
    State: "ANDHRA PRADESH",
    latitude: 16.2159261999999,
    Longitude: 80.3002517999999,
  },
  {
    W_H_NAME: "PANKAJ INDUSTRIES",
    w_H_ADDRES: "G-154, RIICO INDUSTRIAL AREA  BARAN ( RAJ.) PIN 325205",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.1180686,
    Longitude: 76.4548386,
  },
  {
    W_H_NAME: "GURUNANAK WAREHOUSING CORPORATION",
    w_H_ADDRES: "INDUSTRIAL AREA KHEDA, ITARSI 461111",
    BRANCH: "BHOPAL",
    State: "MADHYA PRADESH",
    latitude: 22.6250931,
    Longitude: 77.7795305,
  },
  {
    W_H_NAME: "HARI INDUSTRIES",
    w_H_ADDRES: "F-20,INDUSTRIAL AREA IID CENTER BARAN, RAJASTHAN 325205",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.1109274,
    Longitude: 76.4537167999999,
  },
  {
    W_H_NAME: "GAURI SHANKAR COTTON GINNING AND PRESSING FACTORY",
    w_H_ADDRES:
      "PLOT NO-SP-1  INDUSTRIAL AREA SADULSHAHAR, DISTRICT SRI GANGANAGAR RAJASTHAN 335062",
    BRANCH: "SRI GANGANAGAR",
    State: "RAJASTHAN",
    latitude: 29.9006593,
    Longitude: 74.1785517,
  },
  {
    W_H_NAME: "RK RURAL FARMER'S WAREHOUSE",
    w_H_ADDRES: "SY.NO 538/2 ,AHOBILAM BYE PASS ROAD , ALLAGADDA 518543",
    BRANCH: "KURNOOL",
    State: "ANDHRA PRADESH",
    latitude: 15.1517640999999,
    Longitude: 78.5112409,
  },
  {
    W_H_NAME: "SHREENATH JI INDUSTRIES",
    w_H_ADDRES: "G-225 (D), ROAD NO. 5, IPIA, KOTA RAJASTHAN, 324005",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.1269020999999,
    Longitude: 75.8639682,
  },
  {
    W_H_NAME: "MS BABU TOBACCO (P) LTD",
    w_H_ADDRES:
      "MANGALAGIRI ROAD, AGATHAVARAPPADU PANCHAYATI, GUNTUR, ANDHRA PRADESH-522001",
    BRANCH: "GUNTUR",
    State: "ANDHRA PRADESH",
    latitude: 16.3141618999999,
    Longitude: 80.4688505999999,
  },
  {
    W_H_NAME: "RAMAKRISHNA WAREHOUSING CORPORATION",
    w_H_ADDRES:
      "NH-69, NEAR JIND BABA, RAISALPUR ITARSI  DISTRICT HOSHANGABAD MADHYA  PRADESH 461111",
    BRANCH: "BHOPAL",
    State: "MADHYA PRADESH",
    latitude: 22.64968,
    Longitude: 77.7668599999999,
  },
  {
    W_H_NAME: "RAKESH ASHOK KUMAR DHARIWAL",
    w_H_ADDRES:
      "SURVEY NO. 440, OPPOSITE JAIN DERASAR, BRAHMANWADA, PALANPUR AHMEDABAD HIGHWAY, TALUKA UNJHA, DISTRICT MEHSANA, GUJARAT 384170",
    BRANCH: "UNJHA",
    State: "GUJARAT",
    latitude: 23.858398,
    Longitude: 72.3666939999999,
  },
  {
    W_H_NAME: "HARSHA TAORI WAREHOUSE",
    w_H_ADDRES:
      "KABADIYA ROAD , NEAR SAGAR CHOUPAL CHHINDWARA , MADHYA PRADESH  PIN-480001",
    BRANCH: "BHOPAL",
    State: "MADHYA PRADESH",
    latitude: 22.0683027,
    Longitude: 78.9855641999999,
  },
  {
    W_H_NAME: "PEACOCK CHEMICALS PVT LTD",
    w_H_ADDRES: "1 MILE STONE NAND GAIN ROAD KOSI KALAN ( MATHURA ) 281403",
    BRANCH: "MATHURA",
    State: "UTTAR PRADESH",
    latitude: 27.1727376,
    Longitude: 77.7632188,
  },
  {
    W_H_NAME: "KEDIA WAREHOUSE",
    w_H_ADDRES:
      "NH-31,DAMKA CHOWK,BEHIND OF LAHOTI DHARAMKANTA,GULABBAGH,PUNEA, 854326",
    BRANCH: "PATNA",
    State: "BIHAR",
    latitude: 25.78853,
    Longitude: 87.551422,
  },
  {
    W_H_NAME: "PARTH AGRO INDUSTRIAL PARK",
    w_H_ADDRES:
      "OLD SURVEY NO 504, PAIKI-2,  AT- BRAHMANVADA, TALUKA-UNJHA, DISTRICT MEHSANA, GUJARAT -384170",
    BRANCH: "UNJHA",
    State: "GUJARAT",
    latitude: 23.8470559,
    Longitude: 72.3731731999999,
  },
  {
    W_H_NAME: "D K WAREHOUSE & COLD STORAGE",
    w_H_ADDRES: "VILLAGE PIPRODA KHURD, DISTRICT  GUNA, MADHYA PRADESH 473001",
    BRANCH: "INDORE",
    State: "MADHYA PRADESH",
    latitude: 24.6758099999999,
    Longitude: 77.32254,
  },
  {
    W_H_NAME: "AGRAWAL WAREHOUSE",
    w_H_ADDRES: "AADIWASI TOLA.,GULABAGH, PURNEA BIHAR 854326",
    BRANCH: "PATNA",
    State: "BIHAR",
    latitude: 25.8005229999999,
    Longitude: 87.5453789999999,
  },
  {
    W_H_NAME: "SAI COOLER WAREHOUSE",
    w_H_ADDRES: "68, INDUSTRIAL AREA KHEDA, ITARSI, MADHYA PRADESH 461111",
    BRANCH: "BHOPAL",
    State: "MADHYA PRADESH",
    latitude: 22.6292382,
    Longitude: 77.7767197,
  },
  {
    W_H_NAME: "MANOHAR SHRI AGRO SERVICES",
    w_H_ADDRES: "VILLAGE BADI CHURLAY DEWAS, MADHYA PRADESH 455001",
    BRANCH: "INDORE",
    State: "MADHYA PRADESH",
    latitude: 22.9115417,
    Longitude: 76.2228666999999,
  },
  {
    W_H_NAME: "GUPTA WAREHOUSING & LOGISTICS",
    w_H_ADDRES:
      "VILLAGE ORIYA, TEH- PANANAGAR, JABALPUR, MADHYA PRADESH 482002",
    BRANCH: "BHOPAL",
    State: "MADHYA PRADESH",
    latitude: 23.2251499999999,
    Longitude: 79.8895899999999,
  },
  {
    W_H_NAME: "SURENDER MANN WAREHOUSE",
    w_H_ADDRES:
      "KHASRA NO - 450,VILLAGE - HAMIDHPUR,NEAR HARNARYAN DHARAM KANTA, DELHI - 110036",
    BRANCH: "ALIPUR",
    State: "DELHI",
    latitude: 28.8319261,
    Longitude: 77.1457694,
  },
  {
    W_H_NAME: "MAMTA AGRAWAL WAREHOUSE",
    w_H_ADDRES: "KIRAWALI ROAD ACHNERA AGRA, 283101 UTTAR PRADESH",
    BRANCH: "MATHURA",
    State: "UTTAR PRADESH",
    latitude: 27.1726339,
    Longitude: 77.763204,
  },
  {
    W_H_NAME: "D B AGRO INDUSTRIES",
    w_H_ADDRES: "H1-321-F, IPIA,  DISTRICT KOTA, RAJASTHAN  324005",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.1271860999999,
    Longitude: 75.8698277999999,
  },
  {
    W_H_NAME: "VIPUL AGARWAL",
    w_H_ADDRES:
      "KHASRA NO. HALL 281 RAKWA47 AIRVAKE GRAM MUNGASKA, TEHSIL &  DISTRICT ALWAR, RAJASTHAN 301001",
    BRANCH: "ALWAR",
    State: "RAJASTHAN",
    latitude: 27.5580226,
    Longitude: 76.6326104999999,
  },
  {
    W_H_NAME: "PARTH AGRO PARK",
    w_H_ADDRES:
      "R S NO. 1160, PAIKI 1 PLOT NO 124 & 135 AT KAMALI UNJHA GUJARAT MAHESANA 384170",
    BRANCH: "UNJHA",
    State: "GUJARAT",
    latitude: 23.8479409999999,
    Longitude: 72.375088,
  },
  {
    W_H_NAME: "MUNDADA WAREHOUSE",
    w_H_ADDRES:
      "GUT NO 249 (PART), BHOISAMUDRAGA,  KALLAMB ROAD,LATUR 413512, MAHARASHTRA",
    BRANCH: "SANGLI",
    State: "MAHARASHTRA",
    latitude: 18.4399717,
    Longitude: 76.4752203,
  },
  {
    W_H_NAME: "KHANDELWAL WAREHOUSE",
    w_H_ADDRES:
      "PLOT NO.130, 130/1,MAA UMIYA AUDYOGIK S VASAHAT MARYADIT, MAUZA TARODI (BU), TALUKA KAMPTEE,DISTRICT NAGPUR, 441001, MAHARASHTRA",
    BRANCH: "AKOLA",
    State: "MAHARASHTRA",
    latitude: 21.1273783,
    Longitude: 79.1887317,
  },
  {
    W_H_NAME: "THAKKAR WAREHOUSE -WAREHOUSE NO 1 & 2",
    w_H_ADDRES:
      "182/13, MAA UMIYA AUDYAGIK S VASAHAT MARYADIT, MAUZA TARODI (BU), TALUKA  KAMPTEE, DISTRICT NAGPUR, 441001, MAHARASHTRA",
    BRANCH: "AKOLA",
    State: "MAHARASHTRA",
    latitude: 21.126773,
    Longitude: 79.193482,
  },
  {
    W_H_NAME: "THAKKAR WAREHOUSE- WAREHOUSE NO 1",
    w_H_ADDRES:
      "PLOT NO. 172, MAA UMIYA AUDHYOGIK, SAHAKARI VASAHAT MARYADIT, MAUZA TARODI (BU.), TALUKA  KAMPTEE, DISTRICT NAGPUR, 441001, MAHARASHTRA",
    BRANCH: "AKOLA",
    State: "MAHARASHTRA",
    latitude: 21.1303921999999,
    Longitude: 79.1917164,
  },
  {
    W_H_NAME: "CHHATRAPATI WAREHOUSE CORPORATION",
    w_H_ADDRES:
      "GUT NO 84, INDUSTRIAL ESTATE NO: 3, GHTACHIWADI, BARSHI, MAHARASHTRA 413401",
    BRANCH: "SANGLI",
    State: "MAHARASHTRA",
    latitude: 18.2358037,
    Longitude: 75.7014989,
  },
  {
    W_H_NAME: "RAGHAV AGRO INDUSTRIES",
    w_H_ADDRES:
      "G-46, AGRO FOOD PARK  INDUSTRIEAL AREA RANPUR, DISTRICT KOTA, RAJASTHAN 325003",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.0586674999999,
    Longitude: 75.8412457999999,
  },
  {
    W_H_NAME: "SANTOSH GOYAL",
    w_H_ADDRES:
      "GODOWN NO 1, KHASRA NO 25040~01 & 2505/0.87, GRAM ALWAR, TEHSIL & DISTRICT ALWAR, RAJASTHAN 301001",
    BRANCH: "ALWAR",
    State: "RAJASTHAN",
    latitude: 27.5741599999999,
    Longitude: 76.6451059999999,
  },
  {
    W_H_NAME: "CONCOR DPD NON NOTIFIED",
    w_H_ADDRES:
      "DRT FREIGHT STATION, DRONAGIRI RAIL TERMINAL, SECTOR-2, PLOT NO, 33,34,35, NAVI MUMBAI 400 007 JNPT",
    BRANCH: "SANGLI",
    State: "MAHARASHTRA",
    latitude: 18.9023512999999,
    Longitude: 73.0021505999999,
  },
  {
    W_H_NAME: "CHAND KIRAN",
    w_H_ADDRES:
      "KHASRA NO-2292/0.16,2295/0.29,GRAM-TULHERA,TEHSIL ALWAR, RAJASTHAN 301001",
    BRANCH: "ALWAR",
    State: "RAJASTHAN",
    latitude: 27.576405,
    Longitude: 76.6368616999999,
  },
  {
    W_H_NAME: "SULEKHA JAIN WAREHOUSE",
    w_H_ADDRES:
      "KHASRA NO-1291,MAILKHEDI ROAD BARAN DISTRICT BARAN, RAJASTHAN 325205",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.1221120999999,
    Longitude: 76.4829157,
  },
  {
    W_H_NAME: "KANSAL TRADING COMPANY",
    w_H_ADDRES:
      "E-132 (A1), IPIA, ROAD NO 5,   DISTRICT KOTA, RAJASTHAN 324005",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.1350534,
    Longitude: 75.8607355,
  },
  {
    W_H_NAME: "SALASAR WAREHOUSE",
    w_H_ADDRES:
      "GAT NO 36, GUNDE GAON USMAN NAGAR ROAD , TQ  NANDED, DISTRICT NANDED MAHARASHTRA 431603",
    BRANCH: "SANGLI",
    State: "MAHARASHTRA",
    latitude: 19.1071798999999,
    Longitude: 77.3265784,
  },
  {
    W_H_NAME: "SHIVAM WAREHOUSE",
    w_H_ADDRES: "NEAR A.B.ROAD VILLAGE BILONIYA, GUNA, MADHYA PRADESH 473001",
    BRANCH: "INDORE",
    State: "MADHYA PRADESH",
    latitude: 24.6980299999999,
    Longitude: 77.3425799999999,
  },
  {
    W_H_NAME: "UDGIR WAREHOUSE & COLD STORAGE PVT LTD",
    w_H_ADDRES:
      "34/2 NANDED ROAD SOMNATHPUR, TALUKA UDGIR, DISTRICT LATUR, MAHARASHTRA  413517",
    BRANCH: "SANGLI",
    State: "MAHARASHTRA",
    latitude: 18.4203078,
    Longitude: 77.0984667999999,
  },
  {
    W_H_NAME: "SATYAVIR WAREHOUSE",
    w_H_ADDRES:
      "KHASRA NO 268 & 269 , EXTENDED LAL DORA, VILLAGE- ALIPUR, DELHI 110036",
    BRANCH: "ALIPUR",
    State: "DELHI",
    latitude: 28.802935,
    Longitude: 77.1297732999999,
  },
  {
    W_H_NAME: "AKHANI WAREHOUSE",
    w_H_ADDRES:
      "SURVEY NO.20, GODOWN NO 2 & 3, THARA HARIJ ROAD, AT.PO. THARA, TA. KANKREJ, DISTRICT BANASKANTHA , GUJARAT 385555",
    BRANCH: "UNJHA",
    State: "GUJARAT",
    latitude: 23.9634499,
    Longitude: 71.822056,
  },
  {
    W_H_NAME: "KHURANA WAREHOUSE",
    w_H_ADDRES:
      "KHASRA NO 267 (1-16) & 268 (0-4) , EXTENDED LAL DORA, VILLAGE  ALIPUR, DELHI 110036",
    BRANCH: "ALIPUR",
    State: "DELHI",
    latitude: 28.8029,
    Longitude: 77.1297,
  },
  {
    W_H_NAME: "SUNITA CHANDAK",
    w_H_ADDRES:
      "KHASRA NO 156 & 157 RAM NAGAR SANGARIA ON SALAWAS ROAD JODHPUR RAJASTHAN 342013",
    BRANCH: "JODHPUR",
    State: "RAJASTHAN",
    latitude: 26.1891561999999,
    Longitude: 72.9922368,
  },
  {
    W_H_NAME: "LADBEN WAREHOUSE",
    w_H_ADDRES:
      "KHASRA NO 400-401 GRAM DEVRIYA TEHSIL PACHPAHAR, DISTRICT JHALAWAR, RAJASTHAN 326512",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 24.3749299999999,
    Longitude: 75.8234635999999,
  },
  {
    W_H_NAME: "SAI ENTERPRISES",
    w_H_ADDRES:
      "PLOT NO.C-42, MIDC.TQ. & DISTRICT PARBHANI, MAHARASHTRA 431401",
    BRANCH: "SANGLI",
    State: "MAHARASHTRA",
    latitude: 19.2036118,
    Longitude: 76.9154572,
  },
  {
    W_H_NAME: "SATYAVIR & KHURANA WAREHOUSE",
    w_H_ADDRES:
      "KHASRA NO 267, 268 & 269 , EXTENDED LAL DORA, VILLAGE- ALIPUR, DELHI 110036",
    BRANCH: "ALIPUR",
    State: "DELHI",
    latitude: 28.8029,
    Longitude: 77.1297,
  },
  {
    W_H_NAME: "BILALA PULSES",
    w_H_ADDRES:
      "79/2 HINGANA MAHESHPUR , WASHIM ROAD AKOLA DISTRICT AKOLA MAHARASHTRA 444004",
    BRANCH: "AKOLA",
    State: "MAHARASHTRA",
    latitude: 20.6624574,
    Longitude: 76.9838732,
  },
  {
    W_H_NAME: "M/S MAULI KRUSHI BAZAR WAREHOUSE",
    w_H_ADDRES:
      "PLOT NO -X-04 MIDC INDUSTRI AREA, HINGOLI, MAHARASHTRA  431513",
    BRANCH: "SANGLI",
    State: "MAHARASHTRA",
    latitude: 19.689195,
    Longitude: 77.1113012,
  },
  {
    W_H_NAME: "MPR PROPERTIES PRIVATE LIMITED",
    w_H_ADDRES: "HISAR ROAD, SIRSA, HARYANA 125055",
    BRANCH: "SIRSA",
    State: "HARYANA",
    latitude: 29.5297100999999,
    Longitude: 75.0670376,
  },
  {
    W_H_NAME: "NAVDHA STONE",
    w_H_ADDRES: "B-88, ROAD NO 4, IPIA KOTA  DISTRICT KOTA, RAJASTHAN 324005",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.1353981,
    Longitude: 75.8594935999999,
  },
  {
    W_H_NAME: "SHRI VIJAY KUMAR CHOUDHARY S/O SHRI LEKHRAM CHOUDHARY",
    w_H_ADDRES:
      "SECTOR NO 03, 15 HMH, WARD NO 30 HANUMANGARH TOWN, DISTRICT HANUMANGARH, RAJASTHAN 335513",
    BRANCH: "SRI GANGANAGAR",
    State: "RAJASTHAN",
    latitude: 29.5754829,
    Longitude: 74.343614,
  },
  {
    W_H_NAME: "PRIYA MANGDHANA",
    w_H_ADDRES:
      "VILLAGE RAGHUVEERPURA CHITTOR ROAD  BUNDI DISTRICT BUNDI, RAJASTHAN 323001",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.4223602,
    Longitude: 75.620069,
  },
  {
    W_H_NAME: "UNIVERSAL AGRICHEM PVT LTD",
    w_H_ADDRES: "B-138 ROAD NO 5, IPIA KOTA  DISTRICT KOTA, RAJASTHAN 324005",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.1332252999999,
    Longitude: 75.8609036999999,
  },
  {
    W_H_NAME: "SHRI MOHIT CHOUDHARY S/O SHRI VIJAY SINGH CHOUDHARY",
    w_H_ADDRES:
      "13 HMH , WARD NO.30 , HANUMANGARH TOWN, DISTRICT HANUMANGARH, RAJASTHAN 335513",
    BRANCH: "SRI GANGANAGAR",
    State: "RAJASTHAN",
    latitude: 29.575111,
    Longitude: 74.3438969999999,
  },
  {
    W_H_NAME: "OM SHRI INDUSTRIES",
    w_H_ADDRES: "A-208 -B ,IPIA ROAD NO-5 DISTRICT KOTA, RAJASTHAN 324005",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.126828,
    Longitude: 75.8616839999999,
  },
  {
    W_H_NAME: "SMT MONIKA GOYAL W/O SHRI RAJNISH KUMAR GOYAL",
    w_H_ADDRES:
      "CHAK 12 SBN HANUMANGARH ROAD SANGARIA, DISTRICT HANUMANGARH, RAJASTHAN 335063",
    BRANCH: "SRI GANGANAGAR",
    State: "RAJASTHAN",
    latitude: 29.73886115,
    Longitude: 74.4518338099999,
  },
  {
    W_H_NAME: "SUBHASH CHAND",
    w_H_ADDRES: "VILLAGE -KHUTA KHERA NH-09, BILASPUR, UTTAR PRADESH 244901",
    BRANCH: "MATHURA",
    State: "UTTAR PRADESH",
    latitude: 28.8635316999999,
    Longitude: 79.19289,
  },
  {
    W_H_NAME: "SHREE HARI WAREHOUSE",
    w_H_ADDRES:
      "R.S.NO.-381, 383P, DHORAJI JUNAGADH ROAD, AT-ZALANSAR, TALUKA-JUNAGADH, DISTRICT JUNAGADH, GUAJRAT-362201",
    BRANCH: "RAJKOT",
    State: "GUJARAT",
    latitude: 21.6624345,
    Longitude: 70.4427551999999,
  },
  {
    W_H_NAME: "KISANMITRA WAREHOUSING PVT LTD",
    w_H_ADDRES:
      "D72 ADDITIONAL MIDC LATUR TALUKA DISTRICT LATUR ,MAHARASHTRA, 413512",
    BRANCH: "SANGLI",
    State: "MAHARASHTRA",
    latitude: 18.3794183,
    Longitude: 76.4895417,
  },
  {
    W_H_NAME: "DURGA UDYOGH",
    w_H_ADDRES:
      "PLOT NO. N-170 OPP ROSAI SPICES & AGRO PROCESSOR MIDC PHASE NO. 4 AKOLA, MAHARASHTRA 444005",
    BRANCH: "AKOLA",
    State: "MAHARASHTRA",
    latitude: 20.6613611,
    Longitude: 77.0676447,
  },
  {
    W_H_NAME: "KISHAN CHAND AGRAWAL WAREHOUSE",
    w_H_ADDRES: "KIRAWALI ROAD ACHNERA AGRA, UTTAR PRADESH 283101",
    BRANCH: "MATHURA",
    State: "UTTAR PRADESH",
    latitude: 27.1727561,
    Longitude: 77.7632476,
  },
  {
    W_H_NAME: "RADHE WAREHOUSE",
    w_H_ADDRES: "GODOWN NO 1,  C96- ADDITIONAL MIDC LATUR, MAHARASHTRA 413512",
    BRANCH: "SANGLI",
    State: "MAHARASHTRA",
    latitude: 18.402207,
    Longitude: 76.471929,
  },
  {
    W_H_NAME: "PRATAPWAR WAREHOUSE",
    w_H_ADDRES:
      "GAT NO 228 PARWA TALUKA & DISTRICT PARBHANI, MAHARASHTRA  431401",
    BRANCH: "SANGLI",
    State: "MAHARASHTRA",
    latitude: 19.2727703999999,
    Longitude: 76.7270132999999,
  },
  {
    W_H_NAME: "SHRI SAI NATH WAREHOUSING",
    w_H_ADDRES:
      "39/1/3 VILLAGE - BANIHAR A.B. ROAD SENDHWA DISTRICT BARWANI, MADHYA PRADESH  451666",
    BRANCH: "INDORE",
    State: "MADHYA PRADESH",
    latitude: 21.6754152,
    Longitude: 74.9112556,
  },
  {
    W_H_NAME: "MUMBAI WAREHOUSING",
    w_H_ADDRES:
      "SHOP NO 7 SURVEY NO-45/3A AT SIDDHIVINAYAK ESTATE MARKET TALUKA PIMPRI TAL AND DIST THANE-400612  MUNCIPAL CORPORATION (THANE ZONE-2) MAHARASHTRA",
    BRANCH: "SANGLI",
    State: "MAHARASHTRA",
    latitude: 19.1120605499999,
    Longitude: 73.0563125599999,
  },
  {
    W_H_NAME: "MUMBAI WAREHOUSING.",
    w_H_ADDRES:
      "1010,MOHAMMADIYA ESTATE OLD SURVEY NO 128 NEW SURVEY NO 14 VILLAGE PIMPARI DAHISARMORI THANE NAVI MUMBAI MUNCIPAL CORPORATION (THANE ZONE-2)MAHARASTRA -400612",
    BRANCH: "SANGLI",
    State: "MAHARASHTRA",
    latitude: 19.11097717,
    Longitude: 73.0566177299999,
  },
  {
    W_H_NAME: "RAM KISHAN WAREHOUSE",
    w_H_ADDRES:
      "KHASRA NO 421/2,SITUATED IN THE EXTENDED LAL DORA,VILLAGE - HAMIDPUR,DELHI - 110036",
    BRANCH: "ALIPUR",
    State: "DELHI",
    latitude: 28.8305302999999,
    Longitude: 77.1502945,
  },
  {
    W_H_NAME: "SHRINATH AGRO INDUSTRIES",
    w_H_ADDRES:
      "KHASRA NO 239/11, GRAM SALAWAS TEHSIL LUNI DISTRICT JODHPUR, RAJASTHAN 342013",
    BRANCH: "JODHPUR",
    State: "RAJASTHAN",
    latitude: 26.1454431999999,
    Longitude: 72.9898199,
  },
  {
    W_H_NAME: "MUNESH WAREHOUSE",
    w_H_ADDRES: "KHASRA NO. 49/6, VILLAGE-ALIPUR , DELHI-110036",
    BRANCH: "ALIPUR",
    State: "DELHI",
    latitude: 28.8015657,
    Longitude: 77.1513321,
  },
  {
    W_H_NAME: "EXCEL INDUSTRIES",
    w_H_ADDRES:
      "PLOT NO.-6A, SECTOR NO. -12, KANDLA PORT ROAD, NEAR AMBIKA WEIGHBRIDGE, GANDHIDHAM, KUTCH, GUJARAT 370201",
    BRANCH: "RAJKOT",
    State: "GUJARAT",
    latitude: 23.0984145,
    Longitude: 70.1496027,
  },
  {
    W_H_NAME: "GOPAL RATHI",
    w_H_ADDRES:
      "KHASRA NO 51/25 VILLAGE  SALAWAS, TEHSIL LUNI, JODHPUR, RAJASTHAN 342001",
    BRANCH: "JODHPUR",
    State: "RAJASTHAN",
    latitude: 26.1440971,
    Longitude: 72.9671823999999,
  },
  {
    W_H_NAME: "NIKHIL AGRO SERVICES",
    w_H_ADDRES:
      "17, K.M. STONE SRIGANGANAGAR NEAR MAHALAXMI PETROL PUMP KHARA BIKANER, RAJASTHAN 334001",
    BRANCH: "BIKANER",
    State: "RAJASTHAN",
    latitude: 28.1623215999999,
    Longitude: 73.3770257,
  },
  {
    W_H_NAME: "RUDRAWAR  WAREHOUSE",
    w_H_ADDRES:
      "GUT NO 74 KAUDGAON,PARWA ROAD  TQ & DISTRICT PARBHANI, MAHARASHTRA 431401",
    BRANCH: "SANGLI",
    State: "MAHARASHTRA",
    latitude: 19.2727872,
    Longitude: 76.7270434999999,
  },
  {
    W_H_NAME: "MUMBAI WAREHOUSING",
    w_H_ADDRES:
      "SURVEY NO-74/1 KA GARIB NAWAJ ESTATE BESITE RAJARAM BHATTI DAHISARMORI THANE MAHARASTRA 400612",
    BRANCH: "SANGLI",
    State: "MAHARASHTRA",
    latitude: 19.11581421,
    Longitude: 73.0618775899999,
  },
  {
    W_H_NAME: "SRI LAKSHMI VENKATESWARA RURAL FARMERΓÇÖS WAREHOUSE",
    w_H_ADDRES:
      "SURVEY NO : 190A,BESIDE SUMMER STORAGE TANK,MIDTHUR (V&M), KURNOOL(D) ANDHRA PRADESH 518405",
    BRANCH: "KURNOOL",
    State: "ANDHRA PRADESH",
    latitude: 15.796668,
    Longitude: 78.284175,
  },
  {
    W_H_NAME: "SHASHANK AGRO INDUSTRES",
    w_H_ADDRES:
      "VILLAGE. JAMUKA, NH-24 SITAPUR ROAD SHAHJAHANPUR, UTTAR PRADESH 242001",
    BRANCH: "MATHURA",
    State: "UTTAR PRADESH",
    latitude: 27.8464534,
    Longitude: 79.9863325,
  },
  {
    W_H_NAME: "MUMBAI WAREHOUSING",
    w_H_ADDRES:
      "SURVEY NO-84/4,AT SIDDHIVINAYAK ESTATE MARKET PIMPRI DAHISARMORI TAL, DISTRICT  THANE MAHARASTRA 400612",
    BRANCH: "SANGLI",
    State: "MAHARASHTRA",
    latitude: 19.10754395,
    Longitude: 73.0572808099999,
  },
  {
    W_H_NAME: "BALRAM AGRO INDUSTRIES",
    w_H_ADDRES:
      "KHASRA NO 239/4 VILLAGE SALAWAS TEHSIL, LUNI DISTRICT JODHPUR, RAJASTHAN 342013",
    BRANCH: "JODHPUR",
    State: "RAJASTHAN",
    latitude: 26.1563279,
    Longitude: 72.9862896,
  },
  {
    W_H_NAME: "ALKABEN LALITKUMAR SANGHVI",
    w_H_ADDRES:
      'REVENUE SURVEY NO. 875, PAIKI, OLD SURVEY NO 132,134, 135 PAIKI, GODOWN NO. "B" SITUATED AT  VILLAGE-NANDASAN, TALUKA-KADI, DISTRICT-MEHSANA, GUJARAT 382706',
    BRANCH: "UNJHA",
    State: "GUJARAT",
    latitude: 23.373895,
    Longitude: 72.40568,
  },
  {
    W_H_NAME: "VISHAL FABRICATION",
    w_H_ADDRES:
      "PLOT NO C-31  MIDC  LIMBALA TQ & DISTRICT HINGOLI, MAHARASHTRA  431513",
    BRANCH: "SANGLI",
    State: "MAHARASHTRA",
    latitude: 19.6838075,
    Longitude: 77.100188,
  },
  {
    W_H_NAME: "PAWAN KRISHI UDYOG",
    w_H_ADDRES:
      "49 RIICO INDUSTRIAL AREA SADULSHAHAR, DISTRICT SRI GANGANAGAR. RAJASTHAN 335062",
    BRANCH: "SRI GANGANAGAR",
    State: "RAJASTHAN",
    latitude: 29.8998618,
    Longitude: 74.1819382,
  },
  {
    W_H_NAME: "BHAGWATI ENTERPRISES",
    w_H_ADDRES:
      "F-130, G-138-139, IGC KHARA,  DISTRICT  BIKANER, RAJASTHAN 334001",
    BRANCH: "BIKANER",
    State: "RAJASTHAN",
    latitude: 28.1831307,
    Longitude: 73.3908775999999,
  },
  {
    W_H_NAME: "SHRI JAGANNATH WAREHOUSE",
    w_H_ADDRES:
      "SURVEY NO.256/1/A/1/A, MOUZA- VELA, HINGANGHAT-WARDHA ROAD, TEHSIL- HINGANGHAT, DISTRICT WARDHA, MHARASHTRA.442301",
    BRANCH: "AKOLA",
    State: "MAHARASHTRA",
    latitude: 20.5920975,
    Longitude: 78.7539184999999,
  },
  {
    W_H_NAME: "ABHISHEK AGRO SERVICES",
    w_H_ADDRES:
      "KHASRA NO-904/227, 17.5 KM STONE, N H 15 SRI GANGA NAGAR ROAD, KHARA, BIKANER, RAJASTHAN, 334006",
    BRANCH: "BIKANER",
    State: "RAJASTHAN",
    latitude: 28.1813454999999,
    Longitude: 73.3844892999999,
  },
  {
    W_H_NAME: "VANS SEEDS INDUSTRIES",
    w_H_ADDRES: "SEONI PRAN MOTI  CHHINDWARA, MADHYA PRADESH .480001",
    BRANCH: "BHOPAL",
    State: "MADHYA PRADESH",
    latitude: 22.0660604,
    Longitude: 78.9651999999999,
  },
  {
    W_H_NAME: "SHREE SIDDH OIL PRODUCTS",
    w_H_ADDRES:
      "E-182 BRIJ INDUSTRIAL AREA, NEW RIICO BHARATPUR, RAJASTHAN  321001",
    BRANCH: "ALWAR",
    State: "RAJASTHAN",
    latitude: 27.237441,
    Longitude: 77.5270749,
  },
  {
    W_H_NAME: "POOJA VIKRAM HEDA",
    w_H_ADDRES:
      "GUT NO. 251 AT. KUMBHARI TQ. AKOLA DISTRICT  AKOLA, MAHARASHTRA  444001",
    BRANCH: "AKOLA",
    State: "MAHARASHTRA",
    latitude: 20.6821886,
    Longitude: 77.0780031999999,
  },
  {
    W_H_NAME: "DURGESHWARI INDUSTRIES LTD",
    w_H_ADDRES:
      "GUT NO 180 BRAHMANGOAN GANGAKHED ROAD TQ & DISTRICT PARBHANI, MAHARASHTRA  431401",
    BRANCH: "SANGLI",
    State: "MAHARASHTRA",
    latitude: 19.2390149,
    Longitude: 76.7341057,
  },
  {
    W_H_NAME: "SHAKTI AGRO FOOD PRIVATE LIMITED",
    w_H_ADDRES:
      "15 BGD, SRIVIJAYNAGAR, DISTRICT SRIGANGANAGAR, RAJASTHAN 335704",
    BRANCH: "SRI GANGANAGAR",
    State: "RAJASTHAN",
    latitude: 29.2748044,
    Longitude: 73.500938,
  },
  {
    W_H_NAME: "BIDKAR WAREHOUSE",
    w_H_ADDRES:
      "SURVEY NO.29/2, VILLAGE- KUKABARDI, TAH- HINGANGHAT, DISTRICT WARDHA, MAHARASHTRA.442301",
    BRANCH: "AKOLA",
    State: "MAHARASHTRA",
    latitude: 20.5282112999999,
    Longitude: 78.9004826999999,
  },
  {
    W_H_NAME: "VRAJ YAMUNA",
    w_H_ADDRES:
      "SR. NO 558 PAIKI 3/ PAIKI 1 PLOT NO 14,15,16,19,20, MOTA  UMABADA ROAD GONDAL DISTRICT RAJKOT 360331, GUJARAT",
    BRANCH: "RAJKOT",
    State: "GUJARAT",
    latitude: 21.9979455,
    Longitude: 70.7638216999999,
  },
  {
    W_H_NAME: "NAND KISHORE SINGH WAREHOUSE",
    w_H_ADDRES: "BELBHADRA,CHHAWANI,KUDRA,CHENARI ROAD,DISTRICT KAIMUR 821108",
    BRANCH: "PATNA",
    State: "BIHAR",
    latitude: 25.0088283999999,
    Longitude: 83.8110865,
  },
  {
    W_H_NAME: "TARA DEVI WAREHOUSE",
    w_H_ADDRES:
      "KHASRA NO.643,GRAM-DIWAKRI,TEHSIL-ALWAR, DISTRICT ALWAR 301001, RAJASTHAN",
    BRANCH: "ALWAR",
    State: "RAJASTHAN",
    latitude: 27.546326,
    Longitude: 76.6636819999999,
  },
  {
    W_H_NAME: "NATURE ORGANICS WAREHOUSE",
    w_H_ADDRES:
      "GUT NO. 210,A/P SAWALI, TAL. MIRAJ, DISTRICT SANGLI 416410 MAHARASHTRA",
    BRANCH: "SANGLI",
    State: "MAHARASHTRA",
    latitude: 16.88239,
    Longitude: 74.6419817,
  },
  {
    W_H_NAME: "H R WAREHOUSE",
    w_H_ADDRES:
      "R S NO.26/2, PAIKI, AT SARAGWADA NR RAILWAY FATAK DHORAJI ROAD JUNAGADH, GUJARAT-362001",
    BRANCH: "RAJKOT",
    State: "GUJARAT",
    latitude: 21.5752546199999,
    Longitude: 70.4660339099999,
  },
  {
    W_H_NAME: "MEETU DEVI AGARWAL",
    w_H_ADDRES:
      "KHASRA NO 626/8  & 625/7 '17TH K.M SRIGANGANAGAR ROAD , KHARA, BIKANER - 334006",
    BRANCH: "BIKANER",
    State: "RAJASTHAN",
    latitude: 28.1617457,
    Longitude: 73.3807237,
  },
  {
    W_H_NAME: "NAYNABEN RAJKUMAR PARIKH",
    w_H_ADDRES:
      "S R NO.-213, PAIKI, 1/PAIKY 1 & S R NO.-213 PAIKY-2, PLOT NO.-7, RADHE INDUSTRIAL ZONE VILLAGE GUNDALA DISTRICT RAJKOT GUJARAT-360311",
    BRANCH: "RAJKOT",
    State: "GUJARAT",
    latitude: 21.9557399999999,
    Longitude: 70.747798,
  },
  {
    W_H_NAME: "M/S BANSAL WAREHOUSING",
    w_H_ADDRES:
      "VILLAGE- BHITOUNA , BILRAM  KASGANJ-BAREILLY ROAD SORON GATE, 207123 UTTAR PRADESH",
    BRANCH: "MATHURA",
    State: "UTTAR PRADESH",
    latitude: 27.8268784999999,
    Longitude: 78.6666440999999,
  },
  {
    W_H_NAME: "PARAMOUNT RICE PVT LTD",
    w_H_ADDRES:
      "KHASRA NO-66/2 ,VILLAGE -RAGHUVEERPURA,CHITTOR ROAD BUNDI DISTRICT BUNDI 323001, RAJASTHAN",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.4157917,
    Longitude: 75.6016166999999,
  },
  {
    W_H_NAME: "HIRA AGRO COLD GODOWN NO 1",
    w_H_ADDRES: "PLOT NO.A14, BABLE PHATA MIDC , DHULE 425404, MAHARASHTRA",
    BRANCH: "AKOLA",
    State: "MAHARASHTRA",
    latitude: 21.1226796999999,
    Longitude: 74.7921879999999,
  },
  {
    W_H_NAME: "RAM STONE",
    w_H_ADDRES:
      "PLOT NO-C-330  ( B ) ROAD NO-6  BHAMASHAMANDI ROAD KOTA 324005 RAJASTHAN",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.123203,
    Longitude: 75.8673459999999,
  },
  {
    W_H_NAME: "VASANT GOVINDBHAI BHALODI",
    w_H_ADDRES:
      "S R NO 261 PAIKY 1 & 262/2,PLOT NO-5,VILLAGE-ANIDA,TA-GONDAL, DISTRICT RAJKOT 360311,GUJARAT",
    BRANCH: "RAJKOT",
    State: "GUJARAT",
    latitude: 21.9681919999999,
    Longitude: 70.721507,
  },
  {
    W_H_NAME: "SHANTI ENTERPRISES",
    w_H_ADDRES:
      "PLOT NO.F-244 ,IID CENTER,RIICO INDUSTRIAL AREA BARAN, RAJASTHAN  325205",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.1209279999999,
    Longitude: 76.4626868999999,
  },
  {
    W_H_NAME: "WELLWIN TECHNOSOFT LIMITED",
    w_H_ADDRES: "PLOT NO-,A-228 INDUSTRIAL AREA IPIA KOTA RAJASTHAN 324005",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.1296947999999,
    Longitude: 75.8630557,
  },
  {
    W_H_NAME: "HARMINDER  YADAV",
    w_H_ADDRES:
      "KHASRA NO.194,RAKBA 0.49,VAKE GRAM-DAUDPUR,TEHSIL-ALWAR,DISTRICT ALWAR,301001 RAJASTHAN",
    BRANCH: "ALWAR",
    State: "RAJASTHAN",
    latitude: 27.5648732999999,
    Longitude: 76.6296966999999,
  },
  {
    W_H_NAME: "GOVIND INDUSTRIES",
    w_H_ADDRES: "G-113,AGRO FOODS PARK,  RANPUR, KOTA, RAJASTHAN  325003",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.05827,
    Longitude: 75.8384482999999,
  },
  {
    W_H_NAME: "MR WAREHOUSE",
    w_H_ADDRES:
      "SURVEY NO 282/2/1/1, IN FRONT OF MANGLAM COLD STORAGE GRAM MADI HAVLEI GUNA ASHOK NAGAR 473331, MADHYA PRADESH",
    BRANCH: "INDORE",
    State: "MADHYA PRADESH",
    latitude: 24.60538,
    Longitude: 77.6559072999999,
  },
  {
    W_H_NAME: "SEEMA KHUSHBU AGARWAL",
    w_H_ADDRES:
      "KHASRA NO. 410 VILLAGE - SHRI GOVINDPURA TH. AMER, JAIPUR 303805 RAJASTHAN",
    BRANCH: "JAIPUR",
    State: "RAJASTHAN",
    latitude: 27.1993699999999,
    Longitude: 75.76975,
  },
  {
    W_H_NAME: "WELLWIN TECHNOSOFT LIMITED",
    w_H_ADDRES:
      "PLOT NO-G-65-66 & 67 ,AGRO FOOD PARK ( 1 ) RANPUR KOTA RAJASTHAN 325003",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.0573419999999,
    Longitude: 75.840063,
  },
  {
    W_H_NAME: "SHAKTI  AGRO FOOD PRIVATE LIMITED",
    w_H_ADDRES:
      "CHAK 16 ML, 11 LNP, MURBBA NO 27 KILLA NO 1/2, 10/1, 11/2, 20/1, 21/3, SRI GANGANAGAR 335001, RAJASTHAN",
    BRANCH: "SRI GANGANAGAR",
    State: "RAJASTHAN",
    latitude: 29.8846269999999,
    Longitude: 73.9277761,
  },
  {
    W_H_NAME: "SHREE JEE LOGISTICS",
    w_H_ADDRES: "VILLAGE KHERA JAGPURA, KOTA RAJASTHAN  325003",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.0775937,
    Longitude: 75.8617962,
  },
  {
    W_H_NAME: "BHAVNABEN KAMLESHBHAI PATEL",
    w_H_ADDRES:
      "SURVEY NO.-54/3 PAIKI, SABALPUR CHOKDI, SABALPUR DHORAJI RAOD JUNAGADH 362001, GUJARAT",
    BRANCH: "RAJKOT",
    State: "GUJARAT",
    latitude: 21.5749579999999,
    Longitude: 70.4695279999999,
  },
  {
    W_H_NAME: "NARENDRA KUMAR JAJOO",
    w_H_ADDRES: "VILLAGE DEVPURA, DISTRICT  BUNDI, 323001, RAJASTHAN",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.4038152,
    Longitude: 75.6616834,
  },
  {
    W_H_NAME: "KAMLA DEVI AGARWAL",
    w_H_ADDRES:
      "KHASRA NO 626/7 & 625/9, 17 TH K.M SRIGANGANAGAR ROAD, KHARA, BIKANER 334006, RAJASTHAN",
    BRANCH: "BIKANER",
    State: "RAJASTHAN",
    latitude: 28.1762967999999,
    Longitude: 73.3834427999999,
  },
  {
    W_H_NAME: "ASHUTECH ENGINEERS",
    w_H_ADDRES: "F-279( E ) IPIA  DISTRICT KOTA RAJASTHAN 324005",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.1320707,
    Longitude: 75.8667252999999,
  },
  {
    W_H_NAME: "AMAN ENTERPRISES",
    w_H_ADDRES: "F-45 KUBER INDUSTRIAL AREA RANPUR KOTA RAJASTHAN  325003",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.06381,
    Longitude: 75.8510392999999,
  },
  {
    W_H_NAME: "RAMCHANDRA AGARWAL",
    w_H_ADDRES:
      "KHASRA NO.2504,RAKWA1 AIRVAKENO.2505,RAKWA 87 AIRVAKE KUL DO KITA AIR BARANI UTTAM VAKE,GRAM ALWAR TEHSIL,ALWAR,301001, RAJASTHAN",
    BRANCH: "ALWAR",
    State: "RAJASTHAN",
    latitude: 27.5715758999999,
    Longitude: 76.6414517,
  },
  {
    W_H_NAME: "RAM KISHAN WAREHOUSE, GODOWN NO 2",
    w_H_ADDRES:
      "KHASRA NO 422, SITUATED IN THE EXTENDED LAL DORA, VILLAGE-HAMIDPUR, DELHI 110036",
    BRANCH: "ALIPUR",
    State: "DELHI",
    latitude: 28.8301483,
    Longitude: 77.1493791999999,
  },
  {
    W_H_NAME: "GOVIND WAREHOUSE",
    w_H_ADDRES:
      "KHATA NO 143, 135, KHASRA NO 532, 560, BAHDARPUR,NH-31,BEGUSARAI 851129 BIHAR",
    BRANCH: "PATNA",
    State: "BIHAR",
    latitude: 25.4099779999999,
    Longitude: 86.2027879999999,
  },
  {
    W_H_NAME: "CHOUDHARY WAREHOUSE PROP  RAI CHAND S/O DEVA RAM",
    w_H_ADDRES:
      "2HH 2ND MURBBA NO 17,18 KILLA NO 1 TO 20 , 3 TO 8, 13 TO 18,23,24,25 NETEWALA, SRIGANGANAGAR 335001, RAJASTHAN",
    BRANCH: "SRI GANGANAGAR",
    State: "RAJASTHAN",
    latitude: 29.8326366,
    Longitude: 73.8750296999999,
  },
  {
    W_H_NAME: "SAVITRI WAREHOUSE",
    w_H_ADDRES: "AADIWASHI TOLA,GULABBAGH,PURNEA 854326, BIHAR",
    BRANCH: "PATNA",
    State: "BIHAR",
    latitude: 25.8005229999999,
    Longitude: 87.5453789999999,
  },
  {
    W_H_NAME: "JYOTI PINKEY AGRAWAL WAREHOUSE",
    w_H_ADDRES: "FATEHPUR,THANA-KASBA,DISTRICT PURNEA 854326, BIHAR",
    BRANCH: "PATNA",
    State: "BIHAR",
    latitude: 25.8267069999999,
    Longitude: 87.547004,
  },
  {
    W_H_NAME: "SANJAY AGARWAL",
    w_H_ADDRES:
      "KHASRA NO 625/6  & 626/4 '17TH K.M SRIGANGANAGAR ROAD , KHARA, BIKANER 334006, RAJASTHAN",
    BRANCH: "BIKANER",
    State: "RAJASTHAN",
    latitude: 28.1617457,
    Longitude: 73.3807237,
  },
  {
    W_H_NAME: "VIVEK WAREHOUSE",
    w_H_ADDRES:
      "SURVEY NO 00119, VILL- JAMUHI, TEHSIL- SADAR, SHAHJAHANPUR-242001, UTTAR PRADESH",
    BRANCH: "MATHURA",
    State: "UTTAR PRADESH",
    latitude: 27.8555831,
    Longitude: 79.9663034999999,
  },
  {
    W_H_NAME: "VITRAG WAREHOUSE",
    w_H_ADDRES:
      "GUNDALA RAOD, COLITHAD RAOD, NANA UMAVADA VILLAGE, NEAR ANIDA, TAL.-GONDAL, DISTRICT RAJKOT 360311, GUJARAT",
    BRANCH: "RAJKOT",
    State: "GUJARAT",
    latitude: 21.9842149999999,
    Longitude: 70.724095,
  },
  {
    W_H_NAME: "ALKESHWARI FOODS PVT LTD",
    w_H_ADDRES: "NH-80,WARD NO-02,FARIDABAD,LAKHISARAI, BIHAR 811310",
    BRANCH: "PATNA",
    State: "BIHAR",
    latitude: 25.1937779999999,
    Longitude: 86.0977831,
  },
  {
    W_H_NAME: "MANOHAR COLD STORAGE & AGROTECH",
    w_H_ADDRES:
      "GAT NO-423,KUPWAD-TANANG ROAD,A/P-TANANG,TAL-MIRAJ,DISTRICT SANGLI, MAHARASHTRA.416410",
    BRANCH: "SANGLI",
    State: "MAHARASHTRA",
    latitude: 16.8727003,
    Longitude: 74.6621138999999,
  },
  {
    W_H_NAME: "SHREE BALAJI AGRO",
    w_H_ADDRES: "KHASRA NO 205/2, VILLAGE KHARA,BIKANER 334601, RAJASTHAN",
    BRANCH: "BIKANER",
    State: "RAJASTHAN",
    latitude: 28.1622072999999,
    Longitude: 73.3768452,
  },
  {
    W_H_NAME: "DILIPBHAI N MARAKANA",
    w_H_ADDRES:
      "SR. NO.-558, PAIKI 03/ PAIKI 01, PLOT NO.-2,  MOTA UMABADA RAOD GONDAL DISTRICT RAJKOT-360331, GUJARAT",
    BRANCH: "RAJKOT",
    State: "GUJARAT",
    latitude: 21.997826,
    Longitude: 70.7626819999999,
  },
  {
    W_H_NAME: "M/S KISHOR COTTON & GINNING INDUSTRIES",
    w_H_ADDRES:
      "PLOT NO. A-4&A-5  MIDC LIMBALA  TQ.& DISTRICT HINGOLI, MAHARASHTRA 431513",
    BRANCH: "SANGLI",
    State: "MAHARASHTRA",
    latitude: 19.6912689999999,
    Longitude: 77.109499,
  },
  {
    W_H_NAME: "BABULAL CHANDAK",
    w_H_ADDRES:
      "KHASRA NO 520, 522, 622/521, NEAR NOKHA KRISHI MANDI, TEHSIL, NOKHA, BIKANER 334803, RAJASTHAN",
    BRANCH: "BIKANER",
    State: "RAJASTHAN",
    latitude: 27.5691001,
    Longitude: 73.4598304,
  },
  {
    W_H_NAME: "SHESHRAO N JUDE WAREHOUSE",
    w_H_ADDRES:
      "SURVEY NO.107/3 & 107/2/A/B, MOUZA- KADAJANA, HINGANGHAT-NANDORI ROAD, TAHSIL- HINGANGHAT, DISTRICT WARDHA, 442301, MAHARASHTRA",
    BRANCH: "AKOLA",
    State: "MAHARASHTRA",
    latitude: 20.5387075999999,
    Longitude: 78.8767112999999,
  },
  {
    W_H_NAME: "PARIJAT GRAMIN GODOWN",
    w_H_ADDRES:
      "VILLAGE- SHAHABUDDINPUR , SANDI ROAD , HARDOI-241403 UTTAR PRADESH",
    BRANCH: "MATHURA",
    State: "UTTAR PRADESH",
    latitude: 27.3638000999999,
    Longitude: 80.0686840999999,
  },
  {
    W_H_NAME: "ROSHNI GUPTA WAREHOUSE",
    w_H_ADDRES:
      "KHATA NO-00177 VILLAGE-RASOOLPUR JAHANGANJ , TALUKA- SHAHJAHANPUR (SADAR) DISTRICT- SHAHJAHNPUR, 242001 UTTAR PRADESH",
    BRANCH: "MATHURA",
    State: "UTTAR PRADESH",
    latitude: 27.8654584,
    Longitude: 79.9352583,
  },
  {
    W_H_NAME: "SANTOSH WAREHOUSE",
    w_H_ADDRES: "TEGHRA,SHOGHARA,BARAUNI, DISTRICT BEGUSARAI, 851116 BIHAR",
    BRANCH: "PATNA",
    State: "BIHAR",
    latitude: 25.46791,
    Longitude: 86.0006889999999,
  },
  {
    W_H_NAME: "SREE RAMA RURAL ENTERPRISES",
    w_H_ADDRES:
      "SURVEY NO:119/2, 10 BOLLAVARAM, NANDIKOTKUR MANADAL,DISTRICT KURNOOL , ANDHRA PRADESH  518401",
    BRANCH: "KURNOOL",
    State: "ANDHRA PRADESH",
    latitude: 15.8352766,
    Longitude: 78.2395334999999,
  },
  {
    W_H_NAME: "METRO CAMPUS",
    w_H_ADDRES: "BARSONI,NH-31,GULABBAGH,PURNEA, BIHAR 854326",
    BRANCH: "PATNA",
    State: "BIHAR",
    latitude: 25.79514,
    Longitude: 87.5641899999999,
  },
  {
    W_H_NAME: "ARADHANA RASTOGI WAREHOUSE",
    w_H_ADDRES:
      "KHASRA -549/ 550, MOUJA HALKA, SHAHPUR, DISTRICT BEGUSARAI, BIHAR 851129",
    BRANCH: "PATNA",
    State: "BIHAR",
    latitude: 25.410436,
    Longitude: 86.2154199999999,
  },
  {
    W_H_NAME: "SAURABH AGRAWAL WAREHOUSE",
    w_H_ADDRES:
      "KHASRA /KHATA NO-00289 VILLAGE-CHAKBHITORA , TEH- (SADAR) DISTRICT- SHAHJAHNPUR, 242001 UTTAR PRADESH",
    BRANCH: "MATHURA",
    State: "UTTAR PRADESH",
    latitude: 27.8418683,
    Longitude: 80.0017883,
  },
  {
    W_H_NAME: "JAMMULA RUSHYENDRA MANI GODOWN",
    w_H_ADDRES:
      "S.NO: 620/1A,620/1B2,VEJENDLA VILLAGE,CHEBROLU,MANDAL,DISTRICT GUNTUR, ANDHRA PRADESH 522213",
    BRANCH: "GUNTUR",
    State: "ANDHRA PRADESH",
    latitude: 16.2308163,
    Longitude: 80.5367971,
  },
  {
    W_H_NAME: "SIDDHASHRAM RICE MILLS",
    w_H_ADDRES:
      "IZARI SRIRAM,OPPOSITE POLICE LINE,ITARHI ROAD,BUXAR, 802103 BIHAR",
    BRANCH: "PATNA",
    State: "BIHAR",
    latitude: 25.5166849999999,
    Longitude: 83.9957079999999,
  },
  {
    W_H_NAME: "MAHABIR RICE DALL AND OIL MILLS",
    w_H_ADDRES:
      "PLOT NO- B3 RICCO INDUSTRIAL AREA PHASE 1ST HANUMANGARH JUNCTION, DISTRICT  HANUMANGARH, RAJASTHAN  335512",
    BRANCH: "SRI GANGANAGAR",
    State: "RAJASTHAN",
    latitude: 29.6193122,
    Longitude: 74.2755247999999,
  },
  {
    W_H_NAME: "NEHA GUPTA WAREHOUSE",
    w_H_ADDRES:
      "KHASRA /KHATA NO-00122 VILLAGE-CHAKBHITORA , TEH- (SADAR) DISTRICT- SHAHJAHNPUR PIN -242001, UTTAR PRADESH",
    BRANCH: "MATHURA",
    State: "UTTAR PRADESH",
    latitude: 27.840619,
    Longitude: 80.0026589999999,
  },
  {
    W_H_NAME: "MAZDA BEGUM WAREHOUSE",
    w_H_ADDRES:
      "KHASRA NO 00440, VILLAGE MOHRNIYA, THE JAMUNHA DISTRICT  SHARVASTI,  BAHRAICH , UTTAR PRADESH -271801",
    BRANCH: "MATHURA",
    State: "UTTAR PRADESH",
    latitude: 27.6439747999999,
    Longitude: 81.7198987999999,
  },
  {
    W_H_NAME: "HIND WAREHOUSE",
    w_H_ADDRES:
      "KHASRA NO 1133, NEAR GULAMAU PULIYA, KURNA SHAHBAD ROAD HARDOI, UTTAR PRADESH 241124",
    BRANCH: "MATHURA",
    State: "UTTAR PRADESH",
    latitude: 27.3749116999999,
    Longitude: 80.1446954,
  },
  {
    W_H_NAME: "M/S NIKUNJ WAREHOUSING",
    w_H_ADDRES:
      "PLOT NO. C-6 MIDC AREA, HINGOLI TQ, DISTRICT HINGOLI , MAHARASHTRA 431513",
    BRANCH: "SANGLI",
    State: "MAHARASHTRA",
    latitude: 19.6840839,
    Longitude: 77.1062663999999,
  },
  {
    W_H_NAME: "M/S VINAYAKA RURAL GODOWNS",
    w_H_ADDRES:
      "S.NO:117/3,NEAR RAVIPADU ROAD,ALLAMPURAM,PENTAPADU(M),W.G.D,PENTAPADU, ANDHRA PRADESH 534146",
    BRANCH: "GUNTUR",
    State: "ANDHRA PRADESH",
    latitude: 16.7861845999999,
    Longitude: 81.5811104999999,
  },
  {
    W_H_NAME: "WELLWIN TECHNOSOFT LIMITED",
    w_H_ADDRES:
      "PLOT NO-,C-235 ( B ) INDUSTRIAL AREA, ROAD NO 5,  IPIA KOTA RAJASTHAN  324005",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.1304258999999,
    Longitude: 75.8637803,
  },
  {
    W_H_NAME: "SHRIRAM DAIRY FARM",
    w_H_ADDRES: "PLOT NO.H -4 MIDC  AWDHAN, DISTRICT DHULE, MAHARASHTRA 424006",
    BRANCH: "AKOLA",
    State: "MAHARASHTRA",
    latitude: 20.8516738,
    Longitude: 74.7537252999999,
  },
  {
    W_H_NAME: "SRI LAKSHMI SRINIVASA COTTON TRADERS",
    w_H_ADDRES:
      "SY.NO: 606,D.NO:4/141,NALLAPADU ROAD,NALLAPADU,DISTRICT GUNTUR, ANDHRA PRADESH 522005",
    BRANCH: "GUNTUR",
    State: "ANDHRA PRADESH",
    latitude: 16.3099758,
    Longitude: 80.3628044,
  },
  {
    W_H_NAME: "IDUPULAPADU COTTON MILLS PRIVATE LTD",
    w_H_ADDRES:
      "CINEMA HALL ROAD,GANAPAVARAM,NADENDLA MANDAL,NEAR CHILAKALURIPET,,GUNTUR DISTRICT,GANAPAVARAM,ANDHRA PRADESH 522619",
    BRANCH: "GUNTUR",
    State: "ANDHRA PRADESH",
    latitude: 16.1331881999999,
    Longitude: 80.1777153999999,
  },
  {
    W_H_NAME: "PRAKASH WAREHOUSING",
    w_H_ADDRES:
      "MURBBA NO. 44, P/NO. 184/419, KILLA NO. 3/1, 3/2, 3/3, 3/4, 8/1, 8/2, 13/1, 13/2, 18/1, 18/2, 23/1, 23/2, CHAK  33 GB SRIVIJAYNAGAR, RAJASTHAN  335704",
    BRANCH: "SRI GANGANAGAR",
    State: "RAJASTHAN",
    latitude: 29.2416917,
    Longitude: 73.5047533,
  },
  {
    W_H_NAME: "BHAGESHWARI DEVI WAREHOUSE",
    w_H_ADDRES: "KHASRA NO. 26/23 & 36/3 , VILLAGE-SANNOTH,NARELA,DELHI 110040",
    BRANCH: "ALIPUR",
    State: "DELHI",
    latitude: 28.8174467,
    Longitude: 77.0749282999999,
  },
  {
    W_H_NAME: "RAMDEV AGRI WAREHOUSE",
    w_H_ADDRES:
      "KHASRA NO 25/62 & 25/63 VILLAGE SALAWAS TEHSIL- LUNI DISTRICT JODHPUR RAJASTHAN  342001",
    BRANCH: "JODHPUR",
    State: "RAJASTHAN",
    latitude: 26.1407048999999,
    Longitude: 72.9638893999999,
  },
  {
    W_H_NAME: "NARAYANPRASAD CHANDAK AND SONS",
    w_H_ADDRES:
      "KHASRA NO-520, 522, 622/521, NEAR NOKHA KRISHI MANDI- TEH. NOKHA, BIKANER, RAJASTHAN 334803",
    BRANCH: "BIKANER",
    State: "RAJASTHAN",
    latitude: 27.5689807,
    Longitude: 73.4600192999999,
  },
  {
    W_H_NAME: "HEMA INTERNATIONAL WAREHOUSE",
    w_H_ADDRES:
      "KHASRA NO.77/31,SARITA VIHAR, SALAWAS ROAD,TANAWARA DISTRICT  JODHPUR, RAJASTHAN  342013",
    BRANCH: "JODHPUR",
    State: "RAJASTHAN",
    latitude: 26.1553684,
    Longitude: 72.9825541999999,
  },
  {
    W_H_NAME: "GARGI WAREHOUSE",
    w_H_ADDRES:
      "GAT NO.246 BABHULGAON TQ & DISTRICT NANDED, MAHARASHTRA 431601",
    BRANCH: "SANGLI",
    State: "MAHARASHTRA",
    latitude: 19.0847167,
    Longitude: 77.3160033,
  },
  {
    W_H_NAME: "THAKKAR WAREHOUSE",
    w_H_ADDRES:
      "WAREHOUSE NO. 1,PLOT NO. 181/5, MAA UMIYA AUDHYOGIK SAHAKARI VASAHAT MARYADIT, MAUZA TARODI (BU.), TAL - KAMPTEE,DIST- NAGPUR, 441001, MAHARASHTRA",
    BRANCH: "AKOLA",
    State: "MAHARASHTRA",
    latitude: 21.1287239999999,
    Longitude: 79.1925559999999,
  },
  {
    W_H_NAME: "KISHAN KUMAR GUPTA WAREHOUSE",
    w_H_ADDRES:
      "KHATHA NO 00132 VILLAGE FATEPUR RTOSHANAI PARGNA AKBARPUR KANPUR DEHAT, UTTAR PRADESH 209304",
    BRANCH: "MATHURA",
    State: "UTTAR PRADESH",
    latitude: 26.4176267,
    Longitude: 80.1132132999999,
  },
  {
    W_H_NAME: "SHANTANU YADAV WAREHOUSE",
    w_H_ADDRES:
      "VILLAGE LALPUR SATHNI, PARGNA GHIROR , TEHSIL MAINPURI, UTTAR PRADESH 205001",
    BRANCH: "MATHURA",
    State: "UTTAR PRADESH",
    latitude: 27.2035243,
    Longitude: 78.9608915,
  },
  {
    W_H_NAME: "BRIJESH KUMAR SINGH WAREHOUSE",
    w_H_ADDRES:
      "KHATA NO-00213, VILLAGE- JHANSI, PRAGNA, DHUNS, TEHSIL- MUGALSARAI CHANDAULI, UTTAR PRADESH 232101",
    BRANCH: "MATHURA",
    State: "UTTAR PRADESH",
    latitude: 25.264142,
    Longitude: 83.2163189999999,
  },
  {
    W_H_NAME: "RAJ SNEH AGRO FOODS",
    w_H_ADDRES:
      "PLOT NO F - 3/6, INDUSTRIAL AREA, GAJRAULA -II, DISTRICT AMROHA, UTTAR PRADESH 244235",
    BRANCH: "ALIPUR1",
    State: "UTTAR PRADESH",
    latitude: 28.8735889999999,
    Longitude: 78.2321,
  },
  {
    W_H_NAME: "SRG PRODUCTS LLP",
    w_H_ADDRES:
      "23M ,SIYANA ROAD ,VILLAGE-MILK MOUSAMGARH BULANDSHAHR, UTTAR PRADESH-203001",
    BRANCH: "ALIPUR1",
    State: "UTTAR PRADESH",
    latitude: 28.4184217,
    Longitude: 77.8841199999999,
  },
  {
    W_H_NAME: "RAMLALI WAREHOUSE",
    w_H_ADDRES:
      "KHASRA NO.156 KASERUA KALA SAHSON PRAYAGRAJ, UTTAR PRADESH 221507",
    BRANCH: "MATHURA",
    State: "UTTAR PRADESH",
    latitude: 25.481034,
    Longitude: 81.9770299999999,
  },
  {
    W_H_NAME: "JAIN WAREHOUSE",
    w_H_ADDRES:
      "PLOT NO 19, HSIIDC , NEAR MAHAVIR DHARAM KANTA, GT ROAD, SAMALKHA, DISTRICT  PANIPAT, HARYANA 132101",
    BRANCH: "ALIPUR",
    State: "DELHI",
    latitude: 29.2483,
    Longitude: 77.0079,
  },
  {
    W_H_NAME: "JAI PRAKASH WAREHOUSE",
    w_H_ADDRES:
      "KHASRA NO 883K, VILLAGE-DHEER KHEDA, POST-SARAWA, TEHSIL & DISTRICT MEERUT , UTTAR PRADESH 245206",
    BRANCH: "ALIPUR1",
    State: "UTTAR PRADESH",
    latitude: 28.7641759999999,
    Longitude: 77.7649409999999,
  },
  {
    W_H_NAME: "TULSI PULSES",
    w_H_ADDRES:
      "PLOT NO. X-45 MIDC INDUSTRIAL AREA KHAMGAON DISTRICT BULDHANA, MAHARASHTRA 444312",
    BRANCH: "AKOLA",
    State: "MAHARASHTRA",
    latitude: 20.7316819,
    Longitude: 76.5321079,
  },
  {
    W_H_NAME: "SHYAMSUNDER MOR WAREHOUSE",
    w_H_ADDRES:
      "SURVEY NO. 32/1, MIDC LOHARA, DARAVHA ROAD, TEHSIL & DISTRICT YAVATMAL, MAHARASTRA 445001.",
    BRANCH: "AKOLA",
    State: "MAHARASHTRA",
    latitude: 20.3865631,
    Longitude: 78.0900675999999,
  },
  {
    W_H_NAME: "SURESH KUMAR WAREHOUSE",
    w_H_ADDRES:
      "KHATA NO.00045, VILLAGE HARIPURNAAND,  TEHSIL HALDWANI NAINITAL, UTTARAKHAND 263139",
    BRANCH: "MATHURA 1",
    State: "UTTARAKHAND",
    latitude: 29.17000833,
    Longitude: 79.52036329,
  },
  {
    W_H_NAME: "VIGOUR AGRITECH SEEDS",
    w_H_ADDRES:
      "SURVEY NO. 177, 178, 179/2, 180/4, GRAM - TARANA, NEAR ESSAR PETROL PUMP, SANWER ROAD, TEHSIL- SANWER, DISTRICT  INDORE, MADHYA PRADESH 453551",
    BRANCH: "INDORE",
    State: "MADHYA PRADESH",
    latitude: 22.92482,
    Longitude: 75.85302,
  },
  {
    W_H_NAME: "KRISHNA WAREHOUSE",
    w_H_ADDRES:
      "SURVEY NO.- 168, PLOT NO.-04 & 05, KRISHNA WAREHOUSE, VERAVAL HIGHWAY, KESHOD, SONDARDA KESHOD, JUNAGADH, GUJARAT 362227",
    BRANCH: "RAJKOT",
    State: "GUJARAT",
    latitude: 21.25705753,
    Longitude: 70.2589672699999,
  },
  {
    W_H_NAME: "SAVARIA INDUSTRIES PRIVATE LIMITED",
    w_H_ADDRES:
      "B3, UPSIDC IA PHASE 1 LKO HARDOI ROAD , SANDILA HARDOI, UTTAR PRADESH 241127",
    BRANCH: "MATHURA",
    State: "UTTAR PRADESH",
    latitude: 27.0832228,
    Longitude: 80.4570085,
  },
  {
    W_H_NAME: "GOUTAM WAREHOUSE",
    w_H_ADDRES:
      "GUT NO 152/1/C RATNALI  TALUKA DHARAMABAD, DISTRICT NANDED,MAHARASHTRA 431717",
    BRANCH: "SANGLI",
    State: "MAHARASHTRA",
    latitude: 18.9016367,
    Longitude: 77.8419267,
  },
  {
    W_H_NAME: "MUNDHADA WAREHOUSING CORPORATION",
    w_H_ADDRES:
      "GAT NO. - 504, MEHAKAR ROAD, MALEGAON WASHIM, MAHARASHTRA 444503",
    BRANCH: "AKOLA",
    State: "MAHARASHTRA",
    latitude: 20.2467511,
    Longitude: 76.9799857999999,
  },
  {
    W_H_NAME: "MAHANANDA CHALVA WAREHOUSE,",
    w_H_ADDRES:
      "SY.NO.39 HISSA NO.*9 ,GORNALLI VILLAGE, TQ &  DISTRICT BIDAR, KARNATAKA 585403",
    BRANCH: "RAICHUR",
    State: "KARNATAKA",
    latitude: 17.8764779999999,
    Longitude: 77.5160269999999,
  },
  {
    W_H_NAME: "SIDDHIVINAYAK UDYOG",
    w_H_ADDRES:
      "GUT NO 17/1, AT POST, MALKAPUR, TALUKA UDGIR, DISTRICT LATUR, MAHARASHTRA 413517",
    BRANCH: "SANGLI",
    State: "MAHARASHTRA",
    latitude: 18.407851,
    Longitude: 77.0912589999999,
  },
  {
    W_H_NAME: "SUMAN DEVI PERIWAL W/O SHRI KISHAN PERIWAL",
    w_H_ADDRES: "27 STG   PILIBANGAN,  DISTRICT HANUMANGARH, RAJASTHAN 335803",
    BRANCH: "SRI GANGANAGAR",
    State: "RAJASTHAN",
    latitude: 29.48054574,
    Longitude: 74.0823023199999,
  },
  {
    W_H_NAME: "SHWETA SANDEEP AGRAWAL",
    w_H_ADDRES:
      "SURVEY NO. 148-6 MOUJE KHANAPUR TRAMBAKPUR TALUKA AKOT DISTRICT AKOLA, MAHARASHTRA  444 101",
    BRANCH: "AKOLA",
    State: "MAHARASHTRA",
    latitude: 21.086088,
    Longitude: 77.0384633,
  },
  {
    W_H_NAME: "RAVISH KHAN WAREHOUSE",
    w_H_ADDRES:
      "KHASHRA NO-894,PIPARIYA DULAI, SIRSA ROAD , PURANPUR,PILIBHIT, UTTAR PRADESH 262122",
    BRANCH: "MATHURA",
    State: "UTTAR PRADESH",
    latitude: 28.470005,
    Longitude: 80.1417483,
  },
  {
    W_H_NAME: "MAHAVIR WAREHOUSING",
    w_H_ADDRES:
      "KHATA NO 511,508,512, 515, KHESRA NO 425, 429,426,19, VILLAGE MORIYANWAN,MOUJA-MORIYANWAN,ANCHAL BIKRAM,DISTRICT PATNA, BIHAR 801104",
    BRANCH: "PATNA",
    State: "BIHAR",
    latitude: 25.49358,
    Longitude: 84.859868,
  },
  {
    W_H_NAME: "JAY AMBEY WAREHOUSE",
    w_H_ADDRES:
      "SURVEY NO 82/1/2, 85/1/2, VILLEGE- BERKHEDA TEH. DABRA DISTRICT  GWALIOR, MADHYA PRADESH 475110",
    BRANCH: "BHOPAL",
    State: "MADHYA PRADESH",
    latitude: 25.8370399999999,
    Longitude: 78.2834799999999,
  },
  {
    W_H_NAME: "KAILASH WAREHOUSE",
    w_H_ADDRES:
      "KHASRA NO 453, VILLAGE AKERA CHOUD, TEHSIL AMER, DISTRICT JAIPUR, RAJASTHAN 303701",
    BRANCH: "JAIPUR",
    State: "RAJASTHAN",
    latitude: 27.101863,
    Longitude: 75.7207104999999,
  },
  {
    W_H_NAME: "ALKA KHATRI",
    w_H_ADDRES:
      "KHASRA NO. 1437/870, SURNANA ROAD LUNKARANSAR, BIKANER, RAJASTHAN 334603",
    BRANCH: "BIKANER",
    State: "RAJASTHAN",
    latitude: 28.4731583,
    Longitude: 73.7147583,
  },
  {
    W_H_NAME: "SURESH SINGH YADAV WAREHOUSE",
    w_H_ADDRES:
      "KHASRA NO-594 ,VILLAGE -MADHAU PARGNA MAINPURI TEHSIL MAINPURI, UTTAR PRADESH 205001",
    BRANCH: "MATHURA",
    State: "UTTAR PRADESH",
    latitude: 27.2163311,
    Longitude: 78.9972187,
  },
  {
    W_H_NAME: "GOVIND WAREHOUSING CORPORATION",
    w_H_ADDRES:
      "PLOT  NO.141 TO 144 A-1 TO A-3 KHASRA NO .242, M1 VILLAGE SALAWAS DISTRICT JODHPUR RAJASTHAN 342013",
    BRANCH: "JODHPUR",
    State: "RAJASTHAN",
    latitude: 26.1504929,
    Longitude: 72.9936004999999,
  },
  {
    W_H_NAME: "SUDHEER SHIVRAJ KOLAWAR",
    w_H_ADDRES:
      "GUT NO. 313, DHAKNI, OSMANNAGAR ROAD, TALUKA LOHA, DISTRICT NANDED, MAHARASHTRA 431603",
    BRANCH: "SANGLI",
    State: "MAHARASHTRA",
    latitude: 19.0488439999999,
    Longitude: 77.302107,
  },
  {
    W_H_NAME: "CHANDA WAREHOUSE",
    w_H_ADDRES: "KHASRA NO 42, VILLAGE NALKA  BARAN, RAJASTHAN 325205",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.119405,
    Longitude: 76.4890640999999,
  },
  {
    W_H_NAME: "RAVINDER MANN WAREHOUSE",
    w_H_ADDRES:
      "KHASRA NO. 417 EXTENDED LAL DORA  , VILLAGE- HAMIDPUR,DELHI 110036",
    BRANCH: "ALIPUR",
    State: "DELHI",
    latitude: 28.8307717,
    Longitude: 77.1486960999999,
  },
  {
    W_H_NAME: "THAKKAR ALPESHBHAI PRAVINBHAI",
    w_H_ADDRES:
      "PLOT NO.-114/41-42, GIDC CHANDISAR, TALUKA PALANPUR, DISTRICT BANASKATHA, GUJARAT 385001",
    BRANCH: "UNJHA",
    State: "GUJARAT",
    latitude: 24.2185209999999,
    Longitude: 72.300544,
  },
  {
    W_H_NAME: "ANGOORI DEVI & DEEPSHIKHA",
    w_H_ADDRES: "FAZILKA ROAD ABOHAR DISTRICT  FAZILKA, PUNJAB  152116",
    BRANCH: "BHATINDA",
    State: "PUNJAB",
    latitude: 30.1766633,
    Longitude: 74.177937,
  },
  {
    W_H_NAME: "SHREE KRISHNA ENGINEERING WORKS",
    w_H_ADDRES: "G 84 RIICO INDUSTRIAL AREA KHAIRTHAL, , RAJASTHAN 301404",
    BRANCH: "ALWAR",
    State: "RAJASTHAN",
    latitude: 27.5094859,
    Longitude: 76.6700351,
  },
  {
    W_H_NAME: "GOLDEN BIRD WAREHOUSE",
    w_H_ADDRES:
      "PLOT NO.35 & 38 NEW INDUSTRIAL AREA, SECTOR A, HOSANGABAD ROAD,MANDIDEEP, TEHSIL- GOHARGANJ, DISTRICT RAISEN, MADHYA PRADESH 462046",
    BRANCH: "BHOPAL",
    State: "MADHYA PRADESH",
    latitude: 23.1048499999999,
    Longitude: 77.51394,
  },
  {
    W_H_NAME: "SAI KRIPA WARE HOUSE",
    w_H_ADDRES:
      "SURVEY NO. 66, VILL-KABADIYA CHHINDWARA, MADHYA PRADESH.480001",
    BRANCH: "BHOPAL",
    State: "MADHYA PRADESH",
    latitude: 22.4445152,
    Longitude: 78.59309912,
  },
  {
    W_H_NAME: "SHARMA WAREHOUSE",
    w_H_ADDRES:
      "SURVEY NO. 283/8/2, INFRONT OF SHREEJEE RESORT SONAKHAR,SEONI ROAD CHHINDWARA MADHYA PRADESH 480001",
    BRANCH: "BHOPAL",
    State: "MADHYA PRADESH",
    latitude: 22.0720373,
    Longitude: 78.9982787,
  },
  {
    W_H_NAME: "R P AGRO WAREHOUSING",
    w_H_ADDRES:
      "SURVEY NO.162/1/A/1/1/2/1 & 162/1/A/1/1/2, MOUZA-KADAJANA, KADAJANA-SHEGAON ROAD, TEHSIL HINGANGHAT, DISTRICT WARDHA, MAHARASHTRA  442301",
    BRANCH: "AKOLA",
    State: "MAHARASHTRA",
    latitude: 20.5371594,
    Longitude: 78.8866048999999,
  },
  {
    W_H_NAME: "AJAY AGRO SERVICES",
    w_H_ADDRES:
      "KHASRA NO. 375, 376 AND 377, CHAK GARBHI,BIKANER, RAJASTHAN 334001",
    BRANCH: "BIKANER",
    State: "RAJASTHAN",
    latitude: 28.0558316999999,
    Longitude: 73.3108967,
  },
  {
    W_H_NAME: "GARG WAREHOUSE",
    w_H_ADDRES:
      "SURVEY 59/3/A & 60/1 SADRU COMPOUND, OPP. DILKHUSH HOTEL, BHANDARLI, P.O. DAHISAR, THANE, MAHARASHTRA  400612",
    BRANCH: "SANGLI",
    State: "MAHARASHTRA",
    latitude: 19.1276999,
    Longitude: 73.0541369,
  },
  {
    W_H_NAME: "HARE KRISHNA WAREHOUSE",
    w_H_ADDRES:
      "SURVEY NO. 9/1/2/2, VILLAGE- VAMULIYA PAWAR, WARD NO. 3, NEAR GALLA MANDI, TEH- GOHARGANJ, DISTRICT RAISEN, MADHYA PRADESH 462046",
    BRANCH: "BHOPAL",
    State: "MADHYA PRADESH",
    latitude: 23.0787,
    Longitude: 77.48872,
  },
  {
    W_H_NAME: "A-KLASS DRUGS & PHARMACEUTICALS PVT LTD",
    w_H_ADDRES:
      "PLOT NO D-48 MIDC KHAMGAON DISTTICT BULDHANA, MAHARASHTRA  444303",
    BRANCH: "AKOLA",
    State: "MAHARASHTRA",
    latitude: 20.7318627,
    Longitude: 76.5286655,
  },
  {
    W_H_NAME: "MADARASA E ARABIA ZEENATHUL QURAN COLLEGE",
    w_H_ADDRES:
      "D.NO - 8 - 117,G.T ROAD,GANAPAVARAM (P.O), NEAR CHILAKALURIPETA, DISTRICT GUNTUR,ANDHRA PRADESH 522619",
    BRANCH: "GUNTUR",
    State: "ANDHRA PRADESH",
    latitude: 16.1257907399999,
    Longitude: 80.1816426999999,
  },
  {
    W_H_NAME: "BHAVYA AGRO WAREHOUSE",
    w_H_ADDRES:
      "SURVEY NO.-592/2,, VILLAGE ALDASAN NEAR SHAKTI COTTON INDUSTRY ONTAVA CHAR RASTA PO. ALDESAN TA. KADI DISTRICT MEHSANA, GUJARAT 382715",
    BRANCH: "UNJHA",
    State: "GUJARAT",
    latitude: 23.3318094,
    Longitude: 72.3515519,
  },
  {
    W_H_NAME: "MINITA RUSHABH SHAH",
    w_H_ADDRES: "REVENUE SURVEY NO.392,  DHANERA,BANASKANTHA,GUJARAT 385310",
    BRANCH: "UNJHA",
    State: "GUJARAT",
    latitude: 24.4953539999999,
    Longitude: 72.0102459999999,
  },
  {
    W_H_NAME: "RITA AJIT GUPTA",
    w_H_ADDRES:
      "GUT NO 128 , WADALI SATWAI AKOT DISTRICT AKOLA, MAHARASHTRA  444101",
    BRANCH: "AKOLA",
    State: "MAHARASHTRA",
    latitude: 21.1018787,
    Longitude: 77.017447,
  },
  {
    W_H_NAME: "AHINSA FLOUR MILL PVT LTD",
    w_H_ADDRES: "GRAM-NAYAKHEDA TIKAMGARH, MADHYA PRADESH 472001",
    BRANCH: "BHOPAL",
    State: "MADHYA PRADESH",
    latitude: 24.7693739999999,
    Longitude: 78.7935959999999,
  },
  {
    W_H_NAME: "DEEPAK SAINI WAREHOUSE",
    w_H_ADDRES:
      "KHASRA NO 106/186, 187, VILLAGE-KHERA KALAN, ALIPUR, NORTH DELHI, DELHI-110082",
    BRANCH: "ALIPUR",
    State: "DELHI",
    latitude: 28.777718,
    Longitude: 77.1216443,
  },
  {
    W_H_NAME: "R.S. WAREHOUSE",
    w_H_ADDRES:
      "SURVEY NO. 1895, VILLAGE JOURA, TEHSIL AND DISTRICT MORENA MADHYA PRADESH 476001",
    BRANCH: "BHOPAL",
    State: "MADHYA PRADESH",
    latitude: 26.4759126999999,
    Longitude: 77.9634719,
  },
  {
    W_H_NAME: "MICRON INDIA",
    w_H_ADDRES: "PLOT NO.E-179,INDUSTRIAL AREA,MIA, ALWAR, RAJASTHAN 301001",
    BRANCH: "ALWAR",
    State: "RAJASTHAN",
    latitude: 27.509523,
    Longitude: 76.669988,
  },
  {
    W_H_NAME: "DADAJI LOGISTICS WAREHOUSE",
    w_H_ADDRES:
      "SURVEY NO. 93/1/2,,VILLAGE- BISANKHEDA, HOSANGABAD ROAD, OBEDULLAGANJ, TEHSIL- GOHARGANJ, DISTRICT RAISEN, MADHYA PRADESH 464993",
    BRANCH: "BHOPAL",
    State: "MADHYA PRADESH",
    latitude: 23.02841,
    Longitude: 77.56735,
  },
  {
    W_H_NAME: "CROWN AGRO INDUSTRIES",
    w_H_ADDRES:
      "REVENUE SURVEY NO 30/P-1,P-2,P-3, AT MAKHIYALA TALUKA-JUNAGADH,DISTRICT  JUNAGADH, GUJARAT 362001",
    BRANCH: "RAJKOT",
    State: "GUJARAT",
    latitude: 21.6120344799999,
    Longitude: 70.47158167,
  },
  {
    W_H_NAME: "REENA KHATRI",
    w_H_ADDRES:
      "KHASRA NO. 1437/870, SURNANA ROAD LUNKARANSAR, BIKANER, RAJASTHAN 334603",
    BRANCH: "BIKANER",
    State: "RAJASTHAN",
    latitude: 28.4731583,
    Longitude: 73.7147583,
  },
  {
    W_H_NAME: "KUSHAL GURU WAREHOUSING LLP",
    w_H_ADDRES:
      "KHASRA NO. 304/111 AND KHASRA NO 340/111 VILLAGE RAGHUVEERPURA BUNDI, RAJASTHAN  323001",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.4222056,
    Longitude: 75.6204525999999,
  },
  {
    W_H_NAME: "SONI FLOUR AND OIL MILL",
    w_H_ADDRES:
      "PLOT NO. SP -1C, RIICO INDUSTRIAL AREA SURATGARH, RAJASTHAN  335804",
    BRANCH: "SRI GANGANAGAR",
    State: "RAJASTHAN",
    latitude: 29.3087389,
    Longitude: 73.9115024,
  },
  {
    W_H_NAME:
      "SUMAN DEVI PERIWAL W/O JAGDISH PRASAD PERIWAL, SANJU KUMARI W/O SHYAM SUNDER",
    w_H_ADDRES:
      "PLOT NO-70, SECTOR N0-11 , NEAR DOORDARSHAN RELAY STATION HANUMANGARH JUNCTION, DISTRICT HANUMANGARH, RAJASTHAN 335512",
    BRANCH: "SRI GANGANAGAR",
    State: "RAJASTHAN",
    latitude: 29.6174038999999,
    Longitude: 74.2734037,
  },
  {
    W_H_NAME: "VANRAJ INDUSTRIAL PARK",
    w_H_ADDRES:
      "REVENUE SURVEY NO.-457, PAIKI-2, GONDAL GUNDALA ROAD, TALUKA-GONDAL, DISTRICT RAJKOT, GUJARAT 360311",
    BRANCH: "RAJKOT",
    State: "GUJARAT",
    latitude: 21.958997,
    Longitude: 70.770505,
  },
  {
    W_H_NAME: "RAGHAV INDUSTRIES",
    w_H_ADDRES:
      "PLOT NO A 39 PHASE IST INDUSTRIAL AREA HANUMANGARH JUNCTION,  DISTIRCT  HANUMANGARH, RAJASTHAN 335512",
    BRANCH: "SRI GANGANAGAR",
    State: "RAJASTHAN",
    latitude: 29.6199572999999,
    Longitude: 74.2680615999999,
  },
  {
    W_H_NAME: "KISAN WAREHOUSE",
    w_H_ADDRES:
      "SURVEY NO. 16/4/1 ,16/4/2 VILLAGE - GADMARHI RAMGARHI DISTRICT CHINDWARA, MADHYA PRADESH 480001",
    BRANCH: "BHOPAL",
    State: "MADHYA PRADESH",
    latitude: 22.07794515,
    Longitude: 79.0073673499999,
  },
  {
    W_H_NAME: "SHRI SIDHHI VINAYAK AGRO SEEDS",
    w_H_ADDRES: "KHARGONE ROAD BHIKANGAON, MAHDYA PRADESH 451331",
    BRANCH: "INDORE",
    State: "MADHYA PRADESH",
    latitude: 21.866951,
    Longitude: 75.964763,
  },
  {
    W_H_NAME: "ADITYA GRAIN INDUSTRIES WAREHOUSE",
    w_H_ADDRES:
      "VILLAGE- INDARPUR BALRAMPUR ,ROAD GONDA,  UTTAR PRADESH 271002",
    BRANCH: "MATHURA",
    State: "UTTAR PRADESH",
    latitude: 27.1767983,
    Longitude: 81.9808533,
  },
  {
    W_H_NAME: "K.N.VISHWANATHA",
    w_H_ADDRES:
      "173/A,  B.CHITHANAHALLI-VILLAGE,DAVANAGERE-TQ AND DISTRICT, KARNATAKA 577002",
    BRANCH: "RAICHUR",
    State: "KARNATAKA",
    latitude: 14.5062668,
    Longitude: 75.9256070999999,
  },
  {
    W_H_NAME: "D.C.DODDAGOUDER GODOWN",
    w_H_ADDRES:
      "SY.No: 166/2 A & 166/2,MAGOD ROAD,PB ROAD,RANIBENNUR.HAVERI DISTRICT KARANATAKA 581115",
    BRANCH: "RAICHUR",
    State: "KARNATAKA",
    latitude: 14.5867167,
    Longitude: 75.6555417999999,
  },
  {
    W_H_NAME: "SANDEEP TAYAL   GODOWN",
    w_H_ADDRES:
      "MURBBA NO.60, NEAR AMAR COTTON, DABWALI ROAD, VILLAGE SHAMSHABAD, SIRSA, HARAYANA 125055",
    BRANCH: "SIRSA",
    State: "HARYANA",
    latitude: 29.5465732999999,
    Longitude: 75.0099282999999,
  },
  {
    W_H_NAME: "GANESHAN GODOWN",
    w_H_ADDRES:
      "398/A, 88/4, AVARAGERE-VILLAGE,DAVANAGERE TOWN , KARNATAKA 577002",
    BRANCH: "RAICHUR",
    State: "KARNATAKA",
    latitude: 14.4446119,
    Longitude: 75.9497709,
  },
  {
    W_H_NAME: "MPR PROPERTIES PRIVATE LIMITED",
    w_H_ADDRES:
      "KHEWAT NO. 1181, OPPOSITE OLD BANSAL NURSHING HOSPITAL, HISSAR ROAD, SIRSA, 125055",
    BRANCH: "SIRSA",
    State: "HARYANA",
    latitude: 29.5297653999999,
    Longitude: 75.0669829999999,
  },
  {
    W_H_NAME: "DML WAREHOUSE",
    w_H_ADDRES:
      "UNIT-2, 3, 4, & 5 L.S.249/1, 249/2 & 250/2 ,OPPOSITE SKYWIN SPINNING MILLS, VILLAGE -JIYANA, DISTRICT- RAJKOT, GUJARAT 360023",
    BRANCH: "RAJKOT",
    State: "GUJARAT",
    latitude: 22.4055159999999,
    Longitude: 70.9088079999999,
  },
  {
    W_H_NAME: "AB WAREHOUSING SERVICES",
    w_H_ADDRES:
      "SURVEY NO.29/2, VILLAGE- KUKABARDI, TAH- HINGANGHAT, DISTRICT WARDHA, MAHARASHTRA 442301",
    BRANCH: "AKOLA",
    State: "MAHARASHTRA",
    latitude: 20.5291263,
    Longitude: 78.9007263999999,
  },
  {
    W_H_NAME: "AGRIKA WAREHOUSING",
    w_H_ADDRES:
      "SURVEY NO. 16/2, 16/3, 18/1, VILLAGE- BENAIKI, PATAN ROAD,TEHSIL- PATAN, DISTRICT JABALPUR, MADHYA PRADESH 482002",
    BRANCH: "BHOPAL",
    State: "MADHYA PRADESH",
    latitude: 23.21685,
    Longitude: 79.85016,
  },
  {
    W_H_NAME: "ARSHIT WAREHOUSE",
    w_H_ADDRES:
      "SURVEY NO. 206/2, 209/2, 210 VILLAGE-BARVAIKHEDA, TEHSIL- KHATEGAON, DISTRICT DEWAS MADHYA PRADESH 455336",
    BRANCH: "INDORE",
    State: "MADHYA PRADESH",
    latitude: 22.62581,
    Longitude: 76.83777,
  },
  {
    W_H_NAME: "SHRI DEVI",
    w_H_ADDRES:
      "KHASRA NO. 1437/870, SURNANA ROAD LUNKARANSAR, BIKANER, RAJASTHAN 334603",
    BRANCH: "BIKANER",
    State: "RAJASTHAN",
    latitude: 28.473687,
    Longitude: 73.7134948,
  },
  {
    W_H_NAME: "RUDRAKSHA WAREHOUSE",
    w_H_ADDRES:
      "SURVEY NO.222, GRAM- SIRPUR, PANDHANA ROAD KHANDWA , MADHYA PRADESH 450001",
    BRANCH: "INDORE",
    State: "MADHYA PRADESH",
    latitude: 21.7835424999999,
    Longitude: 76.3372734999999,
  },
  {
    W_H_NAME: "NEW SAI COOLER WAREHOUSING",
    w_H_ADDRES:
      "INDUSTRIAL AREA KHEDA, VILLAGE- SONASAWARI, ITARSI, DISTRICT HOSHANGABAD, MADHYA PRADESH 461111",
    BRANCH: "BHOPAL",
    State: "MADHYA PRADESH",
    latitude: 22.62946,
    Longitude: 77.77697,
  },
  {
    W_H_NAME: "NARSINGHBHAI JESINGBHAI ZANKAT",
    w_H_ADDRES:
      "SURVEY NO.-787/P1/4, AT- SONDARDA, TALUKA ΓÇôKESHOD, DISTRICT JUNAGADH, GUJARAT 362227",
    BRANCH: "RAJKOT",
    State: "GUJARAT",
    latitude: 21.267173,
    Longitude: 70.2567839999999,
  },
  {
    W_H_NAME: "PUNAM GUPTA WAREHOUSE",
    w_H_ADDRES: "KHASRA NO. 106/114 (2-02) , VILLAGE-KHERA KALAN, DELHI-110082",
    BRANCH: "ALIPUR",
    State: "DELHI",
    latitude: 28.7788125,
    Longitude: 77.1205164,
  },
  {
    W_H_NAME: "RAM UDYOG WAREHOUSE",
    w_H_ADDRES:
      "C-111, INDUSTRIAL AREA, ROOMA EXTENSION GENERAL , KANPUR- 208001 (UTTAR PRADESH)",
    BRANCH: "MATHURA",
    State: "UTTAR PRADESH",
    latitude: 26.367338,
    Longitude: 80.4217969999999,
  },
  {
    W_H_NAME: "Vivek Gupta Warehouse",
    w_H_ADDRES:
      "KHASRA NO-12/1(2-17),12/2 (1-17)VILLAGE ΓÇôBUDHPUR,BIZAPUR ΓÇôALIPUR  DELHI-110036",
    BRANCH: "ALIPUR",
    State: "DELHI",
    latitude: 28.7846613,
    Longitude: 77.1321494999999,
  },
  {
    W_H_NAME: "MAHARAJ JI AGRO PRODUCTS PRIVATE LIMITED",
    w_H_ADDRES:
      "C-8/2, UPSIDC INDUSTRIAL ESTATE, MAHARAJGANJ, RAEBARELI-229306",
    BRANCH: "MATHURA",
    State: "UTTAR PRADESH",
    latitude: 26.3687259999999,
    Longitude: 81.275931,
  },
  {
    W_H_NAME: "POORVANCHAL FOOD PRODUCTS PVT. LTD.",
    w_H_ADDRES: "GONDA ROAD, CHITTAURA, BAHRAICH, UP-271801",
    BRANCH: "MATHURA",
    State: "UTTAR PRADESH",
    latitude: 27.5446382999999,
    Longitude: 81.6503266999999,
  },
  {
    W_H_NAME: "BABU LAL S/O HAR LAL , ASISH KUMAR S/O BABU LAL",
    w_H_ADDRES: "27 STG PILIBANGAN DIST HANUMANGARH 335803 RAJASTHAN",
    BRANCH: "SRI GANGANAGAR",
    State: "RAJASTHAN",
    latitude: 29.48395576,
    Longitude: 74.0897784299999,
  },
  {
    W_H_NAME: "SHRI HANUMAN WAREHOUSE",
    w_H_ADDRES:
      "KHEWAT NO.724, BACK SIDE SHANKAR COTGIN, SHAMSHABAD PATTI, SIRSA-125055",
    BRANCH: "SIRSA",
    State: "HARYANA",
    latitude: 29.5500532999999,
    Longitude: 75.0042449999999,
  },
  {
    W_H_NAME: "MUMBAI WAREHOUSING",
    w_H_ADDRES:
      "SURVEY NO 45 HISA NO-05 MOHAMMADIYA ESTATE DAHISARMORI THANE MAHARASTRA -400612",
    BRANCH: "SANGLI",
    State: "MAHARASHTRA",
    latitude: 19.1129868999999,
    Longitude: 73.0565766,
  },
  {
    W_H_NAME: "MOHINI INFRA PVT LTD",
    w_H_ADDRES:
      "SY.NO.55/1, SY NO 55/2, HISSA A/1 OF YERMARAS,SY.NO.55,HISSA 2/C OF YERMARAS,SY.NO254/3 HISSA,AA2 OF HISSA OF YEGNOOR, SY.NO 254/4 OF YEGNOOR OF MOHINI INFRA PVT LTD RAICHUR,KARNATAKA-584102 INDIA",
    BRANCH: "RAICHUR",
    State: "KARNATAKA",
    latitude: 16.2699262,
    Longitude: 77.3556051999999,
  },
  {
    W_H_NAME: "AARTI & ARUNA AGGRAWAL",
    w_H_ADDRES:
      "KHASARA NO. 1048/963, KHASARA NO. 1053/623, VILLAGE-KHARA BIKANER, RAJASTHAN - 334006",
    BRANCH: "BIKANER",
    State: "RAJASTHAN",
    latitude: 28.163398,
    Longitude: 73.3802929999999,
  },
  {
    W_H_NAME: "R.L.HISSARIA COLLATERAL SERVICES",
    w_H_ADDRES:
      " P.NO. . 144/269, MURABBA NO-36, KILLA NO-2,9,12,19,22,CHAK 13 HMH, HANUMANGARH TOWN  HANUMANGARH, RAJASTHAN, 335513 ",
    BRANCH: "SRI GANGANAGAR",
    State: "RAJASTHAN",
    latitude: 29.5841202,
    Longitude: 74.3542982,
  },
  {
    W_H_NAME: "BHAVNABEN SURESHBHAI SUKHVALA",
    w_H_ADDRES:
      "SURVEY NO.-187, PLOT NO.-3, ROLEX INDUSTRIES ZONE, GUNDALA PATIDAL RAOD, OPP. SITALA MAA TEMPLE, VILLAGE ΓÇô GUNDALA, TALUKA ΓÇô GONDAL, DIST.- RAJKOT -360311",
    BRANCH: "RAJKOT",
    State: "GUJARAT",
    latitude: 21.9491429999999,
    Longitude: 70.7290519999999,
  },
  {
    W_H_NAME: "SUMITABEN PARESHBHAI CHOVATIYA",
    w_H_ADDRES:
      "SURVEY NO.-187, PLOT NO.-4, ROLEX INDUSTRIES ZONE, GUNDALA PATIDAL RAOD, OPP. SITALA MAA TEMPLE, VILLAGE ΓÇô GUNDALA, TALUKA ΓÇô GONDAL, DIST.- RAJKOT -360311",
    BRANCH: "RAJKOT",
    State: "GUJARAT",
    latitude: 21.949459,
    Longitude: 70.729177,
  },
  {
    W_H_NAME: "BANSAL WAREHOUSE",
    w_H_ADDRES:
      "KHASRA NO 1010, PLOT NO 33, 34, 35, 36, 37, 38, VILLAGE- ASORA (GOMTI NAGAR), TEHSIL & DIST- HAPUR, UTTAR PRADESH - 245101",
    BRANCH: "ALIPUR1",
    State: "UTTAR PRADESH",
    latitude: 28.758078,
    Longitude: 77.7639501999999,
  },
  {
    W_H_NAME: "SHARDA MODI",
    w_H_ADDRES:
      "KHASARA NO. 1052/623, VILLAGE-KHARA BIKANER, RAJASTHAN - 334601",
    BRANCH: "BIKANER",
    State: "RAJASTHAN",
    latitude: 28.163401,
    Longitude: 73.380295,
  },
  {
    W_H_NAME: "RAJENDRAKUMAR AJITCHAND SETHI WAREHOUSE",
    w_H_ADDRES:
      "GAT NO.295 AT POST. PACHOD BU TQ. PAITHAN DIST.AURANGABAD, 431121",
    BRANCH: "SANGLI",
    State: "MAHARASHTRA",
    latitude: 19.5881016,
    Longitude: 75.6205882999999,
  },
  {
    W_H_NAME: "MEHTA AGRO FOODS",
    w_H_ADDRES:
      "KHASARA NO-1217/26 &1222/26 VILLAGE NALODIYA ,TEHSIL RAMGANJMANDI,DISTT KOTA 326519",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 24.6241667,
    Longitude: 75.9583190999999,
  },
  {
    W_H_NAME: "DIVYA CHETWANI",
    w_H_ADDRES:
      "KHASRA NO 80/10 GRAM SALAWAS TEHSIL LUNI  DISTT JODHPUR RAJ 342802",
    BRANCH: "JODHPUR",
    State: "RAJASTHAN",
    latitude: 26.1548261,
    Longitude: 72.9851752999999,
  },
  {
    W_H_NAME: "M/S H G INDUSTRIES",
    w_H_ADDRES:
      "A 38 PHASE 1ST RICCO INDUSTRIAL AREA HANUMANGARH JUNCTION 335512 DIST . HANUMANGARH RAJASTHAN",
    BRANCH: "SRI GANGANAGAR",
    State: "RAJASTHAN",
    latitude: 29.6203109,
    Longitude: 74.2663679,
  },
  {
    W_H_NAME: "MPR PROPERTIES PRIVATE LIMITED",
    w_H_ADDRES:
      "KHEWAT NO. 1181, OPPOSITE OLD BANSAL NURSHING HOSPITAL, HISSAR ROAD, SIRSA, 125055",
    BRANCH: "SIRSA",
    State: "HARYANA",
    latitude: 29.529691,
    Longitude: 75.066884,
  },
  {
    W_H_NAME: "NISHA GOYAL",
    w_H_ADDRES:
      "KHASRA NO -4698/308 VILLAGE DEI DISST BUNDI RAJ,( PIN 323802 )",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.6775778,
    Longitude: 75.9248649,
  },
  {
    W_H_NAME: "BHAVYA AGRO WAREHOUSE",
    w_H_ADDRES:
      "SURVEY NO.-592/2,  VILLAGE - ALDASAN , NEAR SHAKTI COTTON INDUSTRY ONTAVA CHAR RASTA , PO.- ALDASAN , TA.- KADI , DIST.- MAHESANA 382715",
    BRANCH: "UNJHA",
    State: "GUJARAT",
    latitude: 23.331865,
    Longitude: 72.3513099999999,
  },
  {
    W_H_NAME: "SAPTAGIRI WAREHOUSING",
    w_H_ADDRES: "GAT NO-211,A/P-SAWALI,TAL-MIRAJ,DIST-SANGLI(MH) 416410",
    BRANCH: "SANGLI",
    State: "MAHARASHTRA",
    latitude: 16.882126,
    Longitude: 74.641547,
  },
  {
    W_H_NAME: "SHUBHAM AGRO FOODS",
    w_H_ADDRES:
      "17 ML 11 LNP, MURBA NO 30, KILA NO 6/2, 7, 8 , 9, 10  AGRO FOOD ROAD, SRI GANGANAGAR-335002",
    BRANCH: "SRI GANGANAGAR",
    State: "RAJASTHAN",
    latitude: 29.872433,
    Longitude: 73.9208596999999,
  },
  {
    W_H_NAME: "AKIL KHAN WAREHOUSE",
    w_H_ADDRES:
      "KHASHRA NO-894,PIPARIYA DULAI, SIRSA ROAD , PURANPUR,PILIBHIT-262122( U.P)",
    BRANCH: "MATHURA",
    State: "UTTAR PRADESH",
    latitude: 28.470005,
    Longitude: 80.1417483,
  },
  {
    W_H_NAME: "ASHOK KUMAR GUPTA",
    w_H_ADDRES:
      "KHASRA NO.738/2/0.22,G.MU,ABADI,738/3/0.21 G .MU.ABADI KUL KITA 2 OR KUL RAKBA 0.43 GRAM-RASIDPUR TEHSIL-MAHWA DISTT-DAUSA,RAJASTHAN - 321613",
    BRANCH: "ALWAR",
    State: "RAJASTHAN",
    latitude: 27.0822466999999,
    Longitude: 76.9042033,
  },
  {
    W_H_NAME: "PARTH AGRO INDUSTRIAL PARK",
    w_H_ADDRES:
      "SURVEY NO.-514 PAIKI, PLOT NO.-2, VILLAGE ΓÇô BRAHMANVADA, TALUKA ΓÇô UNJHA, DIST. ΓÇô MEHSANA, GUJARAT-384170",
    BRANCH: "UNJHA",
    State: "GUJARAT",
    latitude: 23.8496072,
    Longitude: 72.3690344999999,
  },
  {
    W_H_NAME: "PARTH AGRO INDUSTRIAL PARK",
    w_H_ADDRES:
      "REVENUE SURVEY NO.-414, PLOT NO.-14, 15 & 16 AT-BRAHMANVADA, TALUKA- UNJHA, DIST.- MEHSANA, GUJARAT-384170",
    BRANCH: "UNJHA",
    State: "GUJARAT",
    latitude: 23.8490199999999,
    Longitude: 72.3712199999999,
  },
  {
    W_H_NAME: "GURUKRUPA WAREHOUSE",
    w_H_ADDRES: "SY NO 354/11,OPP KNNL OFFICE KALABURAGI ROAD AFZALPUR-585301",
    BRANCH: "RAICHUR",
    State: "KARNATAKA",
    latitude: 17.213708,
    Longitude: 76.3921749999999,
  },
  {
    W_H_NAME: "RAHUL UDHYOG",
    w_H_ADDRES:
      "PLOT NO.-216/2+3, GIDC CHANDISAR, TALUKA ΓÇô PALANPUR, DIST.- BANASKATHA-385001",
    BRANCH: "UNJHA",
    State: "GUJARAT",
    latitude: 24.2136844,
    Longitude: 72.2985647,
  },
  {
    W_H_NAME: "SHRI KAN CHAND - SHRI JUGAL KISHOR S/O SHRI CHANDER BHAN",
    w_H_ADDRES:
      "KHATA NO. 22/19, CHAK 50 NGC, PATHR NO. 132/248-5, KILLA NO 15, 16 , SATIPURA, HANUMANGARH JUNCTION DIST. HANUMANGARH  RAJASTHAN, 335512",
    BRANCH: "SRI GANGANAGAR",
    State: "RAJASTHAN",
    latitude: 29.631658,
    Longitude: 74.3253763999999,
  },
  {
    W_H_NAME: "TIRUPATI WAREHOUSE",
    w_H_ADDRES:
      "SURVEY NO. 76/4/2  VILLAGE- URDAN, TEHSIL- BETUL, DIST- BETUL (M.P.) 460001",
    BRANCH: "BHOPAL",
    State: "MADHYA PRADESH",
    latitude: 21.9746899999999,
    Longitude: 77.8692199999999,
  },
  {
    W_H_NAME: "K K WAREHOUSE SHAHBAD BARAN",
    w_H_ADDRES:
      "KHASRA NO-151/3 ,152 VILLAGE BASKHEDA THE,SHAHBAD DISST BARAN PIN-325217",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.1604116,
    Longitude: 77.0392681999999,
  },
  {
    W_H_NAME: "BULBUL CHETWANI",
    w_H_ADDRES:
      "KHASRA NO 80/5 GRAM SALAWAS TEHSIL LUNI DISTRICT JODHPUR 342802",
    BRANCH: "JODHPUR",
    State: "RAJASTHAN",
    latitude: 26.1551239,
    Longitude: 72.9848298999999,
  },
  {
    W_H_NAME: "SUBHAM MAHESWARI COLD STORAGE (P) LTD",
    w_H_ADDRES: "PENUMULI ROAD,RAUKOETA,DUGGIRALA-522330",
    BRANCH: "GUNTUR",
    State: "ANDHRA PRADESH",
    latitude: 16.3283889,
    Longitude: 80.6218376,
  },
  {
    W_H_NAME: "KRISHNA MULTIPLEX PVT LTD.",
    w_H_ADDRES:
      "REVENUE SURVEY NO.163, AT- RAMUN, TALUKA- DEESA, DIST.-BANASKANTHA-385535",
    BRANCH: "UNJHA",
    State: "GUJARAT",
    latitude: 24.4277884999999,
    Longitude: 72.1019462,
  },
  {
    W_H_NAME: "DIPAK TRADING COMPANY",
    w_H_ADDRES:
      "SURVEY NO.-13, PAIKI-1, PETROL PUMP VADAL RAOD, VILLAGE- DOLATPARA,TALUKA- JUNAGADH, DIST.- JUNAGADH, GUJARAT- 362001",
    BRANCH: "RAJKOT",
    State: "GUJARAT",
    latitude: 21.5577137699999,
    Longitude: 70.4643254599999,
  },
  {
    W_H_NAME: "SHREE BALAJI FOOD INDUSTRIES PVT LTD",
    w_H_ADDRES:
      "VILLAGE LALPUR, SIRSHAGANJ ROAD , MAINPURI, UTTAR PRADESH -PIN-205001",
    BRANCH: "MATHURA",
    State: "UTTAR PRADESH",
    latitude: 27.21312,
    Longitude: 78.97477,
  },
  {
    W_H_NAME: "ASHOKBHAI DUNGARSHIBHAI LIMBASIYA",
    w_H_ADDRES:
      "SURVEY NO.-187-P3, PLOT NO.-7, ROLEX INDUSTRIES ZONE, GUNDALA PATIDAL RAOD, OPP. SITALA MAA TEMPLE, VILLAGE ΓÇô GUNDALA, TALUKA ΓÇô GONDAL, DIST.- RAJKOT -360311",
    BRANCH: "RAJKOT",
    State: "GUJARAT",
    latitude: 21.9494995,
    Longitude: 70.7295261,
  },
  {
    W_H_NAME: "SUDHEER FOOD PRODUCTS INDIA LIMITED",
    w_H_ADDRES:
      "KHASRA NO. 218/1 , 219 VILLAGE PAHADPUR MAFI AMANPUR ROAD KASGANJ, KASGANJ, UP-207123",
    BRANCH: "MATHURA",
    State: "UTTAR PRADESH",
    latitude: 27.8080399999999,
    Longitude: 78.6607999999999,
  },
  {
    W_H_NAME: "P B AGRI WAREHOUSE",
    w_H_ADDRES:
      "SURVEY NO.-143/1 ,P1, 143/2, P1, RK INDUSTRIAL ZONE NO.-12, PLOT NO. 19 & 20, WANKANER RAOD, VILLAGE- SANOSARA (KUVADVA), DIST.- RAJKOT 360023",
    BRANCH: "RAJKOT",
    State: "GUJARAT",
    latitude: 22.4232713,
    Longitude: 70.9098383,
  },
  {
    W_H_NAME: "MEERA ENTERPRISE",
    w_H_ADDRES:
      "MINABEN DIIPKUMAR HOTWANI, SURVEY NO.-85/3/PAIKI-2, PLOT NO.-1, VILLAGE - KERALA, TALUKA-JUNAGADH, DIST.-JUNAGADH, GUJARAT-362310",
    BRANCH: "RAJKOT",
    State: "GUJARAT",
    latitude: 21.6424233999999,
    Longitude: 70.459534,
  },
  {
    W_H_NAME: "RAJENDER MIDHA S/O LATE. SHRI ROSHAN LAL MIDHA",
    w_H_ADDRES: "PLOT NO-75 SECTOR NO-11 ,  HANUMANGARH JUNCTION-335512",
    BRANCH: "SRI GANGANAGAR",
    State: "RAJASTHAN",
    latitude: 29.618039,
    Longitude: 74.2741098999999,
  },
  {
    W_H_NAME: "SHREE GANESH WOOD WOOL CO.",
    w_H_ADDRES: "KHASRA NO.438/4946,437/4947, KUMHER, BHARATPUR, 321201",
    BRANCH: "ALWAR",
    State: "RAJASTHAN",
    latitude: 27.3350152,
    Longitude: 77.3687991999999,
  },
  {
    W_H_NAME: "CHAUDHARY UDHYOG",
    w_H_ADDRES: "PLOT NO.104(A),OLD INDUSTRIAL AREA,BHARATPUR,321001",
    BRANCH: "ALWAR",
    State: "RAJASTHAN",
    latitude: 27.2442782,
    Longitude: 77.4978241999999,
  },
  {
    W_H_NAME: "HARIOM WAREHOUSE",
    w_H_ADDRES:
      "SURVEY NO.- 10/1, PLOT NO.-12,14,15,16, 27-A, NATIONAL HIGHWAY, OPP. SHRINATHJI INDUSTRIAL, JAMWADI, TALUKA-GONDAL, DIST.-RAJKOT-360311",
    BRANCH: "RAJKOT",
    State: "GUJARAT",
    latitude: 21.9275634,
    Longitude: 70.7652372999999,
  },
  {
    W_H_NAME: "RAM SINGH WAREHOUSE",
    w_H_ADDRES:
      "KHASRA NO.406/157,RAKBA 0.68,GRAM-DEHLAWAS,TEHSIL-MUNDAWAR,DISTT-ALWAR,301407,RAJ",
    BRANCH: "ALWAR",
    State: "RAJASTHAN",
    latitude: 27.8024823999999,
    Longitude: 76.4843228999999,
  },
  {
    W_H_NAME: "JAYDEEP BHANDRAN",
    w_H_ADDRES:
      "MAIN ROAD, BUS STAND, GAINTA, DISST -  KOTA RAJASTHAN  - 325004",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.5763719,
    Longitude: 76.3120476,
  },
  {
    W_H_NAME: "M R WAREHOUSE",
    w_H_ADDRES:
      "KHASRA NO-155-160,VILLAGE SAKRIYA,TEHSIL NIMBAHERA DISTT CHITTORGARH PIN 312623",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 24.6137818999999,
    Longitude: 74.6569439999999,
  },
  {
    W_H_NAME: "PATEL BHARATKUMAR HARJIBHAI-2",
    w_H_ADDRES:
      "GODOWN NO.-2, R. S.NO.-121/P-1, DEESA DHANERA RAOD, VILLAGE- KANSARI, TALUKA-DEESA, DIST.-BANASKATHA-385535",
    BRANCH: "UNJHA",
    State: "GUJARAT",
    latitude: 24.3218906,
    Longitude: 72.1437825,
  },
  {
    W_H_NAME: "SUHANI WAREHOUSE",
    w_H_ADDRES:
      "PLOT NO - 8, SURVEY NO.-156P 33, OPP. SHREE NATHJI INDUSTRIAL ESTATE, SATDA ROAD, AHMEDABAD HIGHWAY, VILLAGE -SATDA, DIST.-RAJKOT-360023, GUJARAT",
    BRANCH: "RAJKOT",
    State: "GUJARAT",
    latitude: 22.3862697,
    Longitude: 70.9624539,
  },
  {
    W_H_NAME: "THARUNIKA INDUSTRIES",
    w_H_ADDRES:
      "PLOT NO. 97A-1 OLD INDUSTRIAL AREA ,BHARATPUR, RAJASTHAN - 321001",
    BRANCH: "ALWAR",
    State: "RAJASTHAN",
    latitude: 27.2420350999999,
    Longitude: 77.5020837,
  },
  {
    W_H_NAME: "BADAN SINGH",
    w_H_ADDRES:
      "KHASRA NO-4000, 4001, 4139, GRAM - KHAIRTHAL, TEHSIL - KISHANGARHBAS ,DISTT - ALWAR, 301404",
    BRANCH: "ALWAR",
    State: "RAJASTHAN",
    latitude: 27.7966865,
    Longitude: 76.6512297,
  },
  {
    W_H_NAME: "EKLAVYA INDUSTRIES",
    w_H_ADDRES: "F-245, RIICO INDUSTRIAL AREA IID CENTER NEWAI-304021",
    BRANCH: "JAIPUR",
    State: "RAJASTHAN",
    latitude: 26.394466,
    Longitude: 75.9224339999999,
  },
  {
    W_H_NAME: "KAVERI MERCANTILE WAREHOUSING UNIT",
    w_H_ADDRES: "KHASRA NO.184, VILLAGE- SIRPUR, DISTT. KHANDWA (M.P) 450001",
    BRANCH: "INDORE",
    State: "MADHYA PRADESH",
    latitude: 21.7801583,
    Longitude: 76.332682,
  },
  {
    W_H_NAME: "MAHALAKSHMI WAREHOUSE",
    w_H_ADDRES:
      "S.NO.72/3, & 72/4,nalegao Road ,at Malkapur,Tq.Udgir,Dist.Latur-413517",
    BRANCH: "SANGLI",
    State: "MAHARASHTRA",
    latitude: 18.3966099999999,
    Longitude: 77.086917,
  },
  {
    W_H_NAME: "SHREE SHAKUN OILS LIMITED",
    w_H_ADDRES: "E-219,225,226 RIICO INDUSTRIAL AREA,BHARATPUR,321001",
    BRANCH: "ALWAR",
    State: "RAJASTHAN",
    latitude: 27.2410684,
    Longitude: 77.5228239,
  },
  {
    W_H_NAME: "AMAR GURJAR",
    w_H_ADDRES:
      "KHASRA N0-1476/1291 VILLAGE MELKHADI TESHIL BARAN DISST BARAN RAJASTHAN PIN 325205",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.1221856,
    Longitude: 76.4832657999999,
  },
  {
    W_H_NAME: "Nidhi Enterprises",
    w_H_ADDRES:
      "AHMAD ESTATE NEAR DAHISAR NAKA, THAKURPADA, SURVEY NO 99/121 HISSA NO 2 VILLAGE PIMPARI THANE 400612 MH",
    BRANCH: "SANGLI",
    State: "MAHARASHTRA",
    latitude: 19.1070169999999,
    Longitude: 73.05789,
  },
  {
    W_H_NAME: "MASOOM AGRO ENERGY PRODUCTS",
    w_H_ADDRES:
      "N.H-76 BARAN ROAD, VILLAGE TATHED, TEHSIL LADPURA DISTRICT KOTA (RAJASTHAN), PIN-325203",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.1794754,
    Longitude: 76.0007877,
  },
  {
    W_H_NAME: "POOJABEN MEHULBHAI DATTA",
    w_H_ADDRES: "R.S. NO 167/3/P1, PLOT NO.-3, VADAL DIST.-JUNAGADH-362001",
    BRANCH: "RAJKOT",
    State: "GUJARAT",
    latitude: 21.60073495,
    Longitude: 70.4908814199999,
  },
  {
    W_H_NAME: "SHRI KESHAV AGRO PROCESSING CENTER",
    w_H_ADDRES:
      "SURVEY NO 39/1, VILLAGE - DULAKHEDA, NEEMUCH, DIST.- NEEMUCH (M.P) 458441",
    BRANCH: "INDORE",
    State: "MADHYA PRADESH",
    latitude: 24.4524238999999,
    Longitude: 74.9234376,
  },
  {
    W_H_NAME: "SHRI KALPTARU INDUSTRIES",
    w_H_ADDRES: "BEHIND RIICO INDUSTRIAL AREA, JAIPUR ROAD, KEKRI,305404",
    BRANCH: "JAIPUR",
    State: "RAJASTHAN",
    latitude: 25.9942059,
    Longitude: 75.1762037,
  },
  {
    W_H_NAME: "C K WAREHOUSE",
    w_H_ADDRES:
      "VILLAGE DHINWA, TEHSIL-NIMBAHERA, DIST. CHITTORGARH,RAJASTHAN. PIN 312601",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 24.6071803,
    Longitude: 74.6299506,
  },
  {
    W_H_NAME: "LAMA GINNERS",
    w_H_ADDRES:
      "SURVERY NO. 1633 AMALNER , TALUKA AMALNER DIST- JALGAON MAHARASHTRA - 425401",
    BRANCH: "AKOLA",
    State: "MAHARASHTRA",
    latitude: 21.041881,
    Longitude: 75.0353549999999,
  },
  {
    W_H_NAME: "SHWETA SANDEEP AGRAWAL",
    w_H_ADDRES:
      "SURVEY NO .148-6 MOUJE KHANAPUR TRAMBAKPUR  TQ. AKOT DIST ΓÇô AKOLA 444101 MAHARASHTRA",
    BRANCH: "AKOLA",
    State: "MAHARASHTRA",
    latitude: 21.085849,
    Longitude: 77.0373670999999,
  },
  {
    W_H_NAME: "RAINA AGRAWAL",
    w_H_ADDRES:
      "KHASRA NO-1399/1291 VILLAGE MELKHEDI DISST BARAN RAJASTHAN PIN-325205",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.122879,
    Longitude: 76.4819502999999,
  },
  {
    W_H_NAME: "21 ST CENTURY WAREHOUSE UNIT-2",
    w_H_ADDRES:
      "PLOT NO-27 TO 32 ,OF ALIYABAD BLOCK -II ,INDUSTRIAL AREA,BIJAPUR-586101.",
    BRANCH: "RAICHUR",
    State: "KARNATAKA",
    latitude: 16.8625849999999,
    Longitude: 75.7556282999999,
  },
  {
    W_H_NAME: "HARESHBHAI MAGANBHAI RAIYANI",
    w_H_ADDRES:
      "RSN ΓÇô 213 P1/P1, 213 P2, NEW RSN -213/2, PLOT NO ΓÇô 11, VILLAGE ΓÇô GUNDALA, TALUKA- GONDAL, DIST .- RAJKOT 360311",
    BRANCH: "RAJKOT",
    State: "GUJARAT",
    latitude: 21.955423,
    Longitude: 70.747859,
  },
  {
    W_H_NAME: "KAKADIYA JAYANTIBHAI DEVRAJBHAI",
    w_H_ADDRES:
      "SURVEY -352, VILLAGE ΓÇô KUVADAVA, TALUKA ΓÇô RAJKOT  DIST .- RAJKOT 360023, STATE ΓÇô GUJARAT",
    BRANCH: "RAJKOT",
    State: "GUJARAT",
    latitude: 22.3766749999999,
    Longitude: 70.9342429999999,
  },
  {
    W_H_NAME: "KHIMJIBHAI KALABHAI MAKWANA",
    w_H_ADDRES:
      "SURVEY NO ΓÇô 372, VILLAGE ΓÇô GUNDALA, TALUKA- GONDAL,  DIST .- RAJKOT 360311",
    BRANCH: "RAJKOT",
    State: "GUJARAT",
    latitude: 21.959505,
    Longitude: 70.764981,
  },
  {
    W_H_NAME: "SAVITRI DEVI WAREHOUSE",
    w_H_ADDRES:
      "R.S.NO.- 456, PLOT NO.- 1 & 2 , AT .-TUNDAV , TAL.- UNJHA , DIST.- MAHESANA , GUJARAT - 384170",
    BRANCH: "UNJHA",
    State: "GUJARAT",
    latitude: 23.826896,
    Longitude: 72.3449279999999,
  },
  {
    W_H_NAME: "ROHIT INDUSTRIES",
    w_H_ADDRES: "H-1,216-217,IID CENTER RIICO INDUSTRIAL AREA-NEWAI-304021",
    BRANCH: "JAIPUR",
    State: "RAJASTHAN",
    latitude: 26.3915089999999,
    Longitude: 75.923378,
  },
  {
    W_H_NAME: "MAHAVEER INDUSTRIES",
    w_H_ADDRES: "F-79, RIICO INDUSTRIAL AREA, TONK- 304001",
    BRANCH: "JAIPUR",
    State: "RAJASTHAN",
    latitude: 26.184961,
    Longitude: 75.8217549999999,
  },
  {
    W_H_NAME: "VIPIN KUMAR S/O SHYAM LAL",
    w_H_ADDRES:
      "CHAK 11 L.N.P. MURBBA NO 36 KILA NO 5,6,7,13,14 TO 18,19,21,22 TO 25 MURBBA NO 37 KILA NO 1 TO 3,4, SRI GANGANAGAR",
    BRANCH: "SRI GANGANAGAR",
    State: "RAJASTHAN",
    latitude: 29.8819178,
    Longitude: 73.9292937,
  },
  {
    W_H_NAME: "GOKUL WAREHOUSE",
    w_H_ADDRES:
      "RSN -218, P 4, PLOT NO ΓÇô 1, VILLAGE ΓÇô GUNDALA,  TALUKA- GONDAL, DIST .- RAJKOT 360311",
    BRANCH: "RAJKOT",
    State: "GUJARAT",
    latitude: 21.955202,
    Longitude: 70.7475589999999,
  },
  {
    W_H_NAME: "GEETA DEVI WAREHOUSE",
    w_H_ADDRES:
      "PLOT NO.19A,20, SHRI RAMDEV NAGAR,SARNA DUNGAR RICCO AREA,JAIPUR-302012",
    BRANCH: "JAIPUR",
    State: "RAJASTHAN",
    latitude: 26.994775,
    Longitude: 75.7062399999999,
  },
  {
    W_H_NAME: "SHRI GOPALA WAREHOUSE",
    w_H_ADDRES:
      "SURVEY No. 347, VLLAGE-PADWA TEHSIL- KHIRKIYA DIST- HARDA, (M.P.) 461331",
    BRANCH: "BHOPAL",
    State: "MADHYA PRADESH",
    latitude: 22.1295,
    Longitude: 76.8957599999999,
  },
  {
    W_H_NAME: "PASHUPATINATH WAREHOUSE",
    w_H_ADDRES:
      "SURVEY No. 70/2, 71/4, VILLAGE- THADOD, TEHSIL.- MALHARGARH, DIST.- MANDSOUR (M.P) 455336",
    BRANCH: "INDORE",
    State: "MADHYA PRADESH",
    latitude: 24.15568,
    Longitude: 75.01567,
  },
  {
    W_H_NAME: "KUBER WAREHOUSING PVT. LTD.",
    w_H_ADDRES: "PLOT NO E-258,259 AGRO FOOD PARK,SRI GANGANAGAR-335002",
    BRANCH: "SRI GANGANAGAR",
    State: "RAJASTHAN",
    latitude: 29.860035,
    Longitude: 73.924823,
  },
  {
    W_H_NAME: "M.K. INDUSTRIES",
    w_H_ADDRES: "RIICO AREA JAIPUR ROAD,KEKRI 305404",
    BRANCH: "JAIPUR",
    State: "RAJASTHAN",
    latitude: 26.0004969999999,
    Longitude: 75.1669729999999,
  },
  {
    W_H_NAME: "SHREE PARSHVANATH WAREHOUSE",
    w_H_ADDRES:
      "SURVEY NO 349/3/1, 349/3/2, VILLAGE - BAGAKHEDA, TEHSIL- JAORA, DIST.- RATLAM (M.P) 457226",
    BRANCH: "INDORE",
    State: "MADHYA PRADESH",
    latitude: 23.6853599999999,
    Longitude: 75.12971,
  },
  {
    W_H_NAME: "SHRI PATIDAR WAREHOUSE",
    w_H_ADDRES:
      "SURVEY No. 244/1, 246/2, VILLAGE- BHUNYAKHEDI, TEHSIL- MANDSAUR NAGAR, DIST.- MANDSAUR (M.P) 458001",
    BRANCH: "INDORE",
    State: "MADHYA PRADESH",
    latitude: 24.1066299999999,
    Longitude: 75.03785,
  },
  {
    W_H_NAME: "KOTAK NITABEN BHAVESHBHAI",
    w_H_ADDRES:
      "SURVEY  NO.-193 AT KOCHASANA, TAL- DEESA,  DIST.-BANAS KANTHA , KOCHASANA ,DEESA , BANAS KANTHA , DISA MUKHYA DAK GHAR S.O. 385535,GUJARAT, INDIA",
    BRANCH: "UNJHA",
    State: "GUJARAT",
    latitude: 24.4033869999999,
    Longitude: 72.142909,
  },
  {
    W_H_NAME: "POONAM LOGISTICS",
    w_H_ADDRES:
      "SURVEY NO 133/2, VILLAGE KARAMDI, TEHSIL RATLAM NAGAR, DISTT RATLAM MP PIN 457001",
    BRANCH: "INDORE",
    State: "MADHYA PRADESH",
    latitude: 23.30209,
    Longitude: 75.0566599999999,
  },
  {
    W_H_NAME: "KOTAK NITABEN HITESHBHAI",
    w_H_ADDRES:
      "SURVEY  NO.-193 AT KOCHASANA, TAL- DEESA,  DIST.-BANAS KANTHA , KOCHASANA ,DEESA , BANAS KANTHA , DISA MUKHYA DAK GHAR S.O. 385535,GUJARAT, INDIA",
    BRANCH: "UNJHA",
    State: "GUJARAT",
    latitude: 24.4033869999999,
    Longitude: 72.142909,
  },
  {
    W_H_NAME: "GOMTI ENTERPRISES",
    w_H_ADDRES: "E-508 ( A-1 ) INDUSTRIES AREA KOTA RAJASTHAN PIN 324005",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.1234267,
    Longitude: 75.8660153999999,
  },
  {
    W_H_NAME: "SARITA KHANDELWAL WAREHOUSE",
    w_H_ADDRES:
      "Plot No.103,MAA UMIYA AUDYAGIK Sahakari Vasahat Maryadit, Mauza TARODI (BU.), Tal - Kamptee,Dist- NAGPUR, Pincode- 441001, MAHARASHTRA",
    BRANCH: "AKOLA",
    State: "MAHARASHTRA",
    latitude: 21.1277474,
    Longitude: 79.1888682999999,
  },
  {
    W_H_NAME: "PIUSH JAIN WAREHOUSE",
    w_H_ADDRES:
      "KHASRA NO- 869 S ,VILLAGE GIROURA KASGANJ ROAD NEAR PAC LINE,ETAH UTTAR PRADESH  207001",
    BRANCH: "MATHURA",
    State: "UTTAR PRADESH",
    latitude: 27.610485,
    Longitude: 78.6497099999999,
  },
  {
    W_H_NAME: "HAREKRISHNA WAREHOUSE",
    w_H_ADDRES:
      "SURVEY NO, 33/2 GAT NO 102 AT VILLAGE KUMBHARI TQ. DIST - AKOLA 444001 (MH)",
    BRANCH: "AKOLA",
    State: "MAHARASHTRA",
    latitude: 20.6742384,
    Longitude: 77.081553,
  },
  {
    W_H_NAME: "MOHIT KUMAR S/O VISHAMBER DAYAL",
    w_H_ADDRES: "NOHAR ROAD, ELLENABAD, DISTT. SIRSA 125102",
    BRANCH: "SIRSA",
    State: "HARYANA",
    latitude: 29.4270649999999,
    Longitude: 74.6595692,
  },
  {
    W_H_NAME: "ASIAN WAREHOUSING LTD",
    w_H_ADDRES:
      "KHASRA NO-857 TO 861,VILLAGE DEVPURA TEH.& DISST BUNDI,RAJASTHAN 323001",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.4060382999999,
    Longitude: 75.6614133,
  },
  {
    W_H_NAME: "SHRIKIRTI COTSPIN LLP",
    w_H_ADDRES: "A/5 & A/8 M.I.D.C. CHINCHOLI SOLAPUR  413255",
    BRANCH: "SANGLI",
    State: "MAHARASHTRA",
    latitude: 17.7447599999999,
    Longitude: 75.8023699999999,
  },
  {
    W_H_NAME: "SANTOSH WAREHOUSING LIMITED",
    w_H_ADDRES:
      "KAHSRA NO-62,RAJA RAMPUR, SIKANDRABAD, BULANDSHAHAR UP- 203205",
    BRANCH: "MATHURA",
    State: "UTTAR PRADESH",
    latitude: 28.48696,
    Longitude: 77.65992,
  },
  {
    W_H_NAME: "MUNNI  DEVI GODOWN",
    w_H_ADDRES: "KHASRA NO 5539, BAGHERA ROAD , KEKRI 305404",
    BRANCH: "JAIPUR",
    State: "RAJASTHAN",
    latitude: 25.9955448,
    Longitude: 75.1901304,
  },
  {
    W_H_NAME: "BUNTY TRADING COMPANY",
    w_H_ADDRES: "PLOT NO.-H-90, RIICO INDUSTRIAL AREA TONK RAJ.-304001",
    BRANCH: "JAIPUR",
    State: "RAJASTHAN",
    latitude: 26.184868,
    Longitude: 75.8167399999999,
  },
  {
    W_H_NAME: "SHREEJI WAREHOUSE",
    w_H_ADDRES:
      "SURVEY NO ΓÇô 43, P2 PLOT NO -13,14 & 15, AKASH INDUSTRIAL ESTATE OPP .- SHIVKANT PARK AT ANIDA (BHALADI) GUNDALA ROAD, TALUKA ΓÇô GONDAL DIST .- RAJKOT PIN CODE 360311",
    BRANCH: "RAJKOT",
    State: "GUJARAT",
    latitude: 21.966946,
    Longitude: 70.7386479999999,
  },
  {
    W_H_NAME: "CHAPLOT WAREHOUSE",
    w_H_ADDRES:
      "NEAR OLD WEIGHBRIDGE VILLAGE NIMBAHERA DIST. CHITTORGARH RAJASTHAN. PIN 312601",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 24.631087,
    Longitude: 74.690228,
  },
  {
    W_H_NAME: "MADHU BALA GURJAR",
    w_H_ADDRES:
      "KHASARA NO-1291 VILLAGE MELKHEDI TESHIL BARAN DISST BARAN RAJASTHAN PIN 325205",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.1225428,
    Longitude: 76.4826455999999,
  },
  {
    W_H_NAME: "SHREEJI WAREHOUSE",
    w_H_ADDRES:
      "SURVEY NO ΓÇô 43, P2 PLOT NO -11 & 12, AKASH INDUSTRIAL ESTATE OPP .- SHIVKANT PARK AT ANIDA (BHALADI) GUNDALA ROAD, TALUKA ΓÇô GONDAL DIST .- RAJKOT PIN CODE - 360311",
    BRANCH: "RAJKOT",
    State: "GUJARAT",
    latitude: 21.966946,
    Longitude: 70.7386479999999,
  },
  {
    W_H_NAME: "RUKAMANI DEVI",
    w_H_ADDRES: "F-551,INDRAPRASTHA INDUSTRIAL AREA KOTA  RAJ.PIN-324005",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.1262234999999,
    Longitude: 75.8723867,
  },
  {
    W_H_NAME: "JAG MEHAR WAREHOUSE",
    w_H_ADDRES:
      "KHASRA NO 397,EXTENDED LAL DORA OF VILLAGE - HAMIDHPUR,DELHI - 110036",
    BRANCH: "ALIPUR",
    State: "DELHI",
    latitude: 28.8289,
    Longitude: 77.1530999999999,
  },
  {
    W_H_NAME: "SARLA DEVI JAIN WAREHOUSE",
    w_H_ADDRES:
      "H-222, IID CENTER, RIICO INDUSTRIAL AREA, BANSTHALI MODE, NEWAI, DISTRICT TONK, JAIPUR, RAJASTHAN  304020",
    BRANCH: "JAIPUR",
    State: "RAJASTHAN",
    latitude: 26.3917056999999,
    Longitude: 75.9240264999999,
  },
  {
    W_H_NAME: "SHREE BABA MADHAV SHAH WAREHOUSE",
    w_H_ADDRES:
      "SURVEY NO 49/1, 49/2 GRAM - BADRAKHA BERASIA UJJAIN, DIST.- UJJAIN (M.P) 456006",
    BRANCH: "INDORE",
    State: "MADHYA PRADESH",
    latitude: 23.23094,
    Longitude: 75.8494299999999,
  },
  {
    W_H_NAME: "SHREE PARSHAVNATH AGRO SERVICES",
    w_H_ADDRES:
      "SURVEY NO 73/1/1, 73/1/2, VILLAGE -BANNAKHEDA, TEHSIL JAORA, DIST.- RATLAM (M.P) 457226",
    BRANCH: "INDORE",
    State: "MADHYA PRADESH",
    latitude: 23.63962,
    Longitude: 75.112678,
  },
  {
    W_H_NAME: "YADAV WAREHOUSE",
    w_H_ADDRES: "KH. No. 418 & 419 Gram Jarara Khurai Road Sagar MP  470002",
    BRANCH: "BHOPAL",
    State: "MADHYA PRADESH",
    latitude: 23.855625,
    Longitude: 78.6380529999999,
  },
  {
    W_H_NAME: "CHETAN ROHADA PREM CHAND NAGWANI",
    w_H_ADDRES: "B-254 A IPIA ROAD NO-6 KOTA(RAJASTHAN)324005",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.1262335,
    Longitude: 75.8662584,
  },
  {
    W_H_NAME: "SHRI SHYAM KRIPA AGRO INFRA FACILITY WAREHOUSE",
    w_H_ADDRES:
      "SURVEY NO. 1186/1, VILLAGE SEKRA JAGIR TEHSIL DABRA DABRA DIST- GWALIOR, MP PIN - 475110",
    BRANCH: "BHOPAL",
    State: "MADHYA PRADESH",
    latitude: 25.9398799999999,
    Longitude: 78.3132099999999,
  },
  {
    W_H_NAME: "KRISHNA WAREHOUSE",
    w_H_ADDRES:
      "KHASRA NO 794-795-907-911,VILLAGE BADOLI MADHOSINGH TEHSIL-NIMBAHERA, DIST. CHITTORGARH,RAJASTHAN. PIN 312601",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 24.6264998999999,
    Longitude: 74.6537404,
  },
  {
    W_H_NAME: "SHAH AGRO SEED PVT LTD",
    w_H_ADDRES: "G-1-9 ,AGRO FOOD PARK RANPUR KOTA RAJASTHAN  PIN 325003",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.59373,
    Longitude: 75.842793,
  },
  {
    W_H_NAME: "DAMYANTI BAI",
    w_H_ADDRES:
      "KHASRA NO 217/960 ,1011/218,VILLAGE SUNDLAK TEHSIL BARAN DIST BARAN PIN -325205",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.1223765,
    Longitude: 76.4506324,
  },
  {
    W_H_NAME: "ARIHANT AGRIEXPORT PVT LTD",
    w_H_ADDRES:
      "G-223,  INDUSTRIAL AREA, AGRO FOOD PARK PHASE IIND,RANPUR, Kota, Rajasthan, 325003",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.0494583,
    Longitude: 75.838761,
  },
  {
    W_H_NAME: "PARTH AGRO INDUSTRIAL PARK",
    w_H_ADDRES:
      "R.S.NO. 2961 PAIKI, PLOT NO.- 12,HIGHWAY ROAD S.NO.- 559 ,AT-BRAHMANVADA, TALUKA- UNJHA, DIST.- MEHSANA, GUJARAT-384170",
    BRANCH: "UNJHA",
    State: "GUJARAT",
    latitude: 23.847206,
    Longitude: 72.3726,
  },
  {
    W_H_NAME: "GOODWILL ADVANCE CONSTRUCTION COMPANY PRIVATE LIMITED",
    w_H_ADDRES: "B-85,  ROAD NO-4  IPIA  Kota Rajasthan pin 324005",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.1337312,
    Longitude: 75.8586573999999,
  },
  {
    W_H_NAME: "SUN AGRO CORPORATION",
    w_H_ADDRES:
      "PLOT NO.F-260 TO F-264, IID CENTER, RIICO INDUSTRIAL AREA, BARAN,  Rajasthan, 325205",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.1201857,
    Longitude: 76.4515514,
  },
  {
    W_H_NAME: "PRIYANK KRISHI UTPAD BHANDARAN",
    w_H_ADDRES:
      "KHASRA NO-454, VILLAGE - JAMUKA SHAHJAHANPUR UTTAR PRADESH- 242001",
    BRANCH: "MATHURA",
    State: "UTTAR PRADESH",
    latitude: 27.8480829999999,
    Longitude: 79.9869309999999,
  },
  {
    W_H_NAME: "SHAILJA DATTATRAYA GAIKWAD WAREHOUSE",
    w_H_ADDRES: "GUT NO. 156 PARWA  PATHRI ROAD, PARBHANI. PIN: 431401",
    BRANCH: "SANGLI",
    State: "MAHARASHTRA",
    latitude: 19.288588,
    Longitude: 76.7278979999999,
  },
  {
    W_H_NAME: "MR. ESHAN S/O SHRI NARENDER KUMAR",
    w_H_ADDRES:
      "P.NO. 141/270, KILLA NO- 24, 25, CHAK 15 HMH BHADRA KALI ROAD HANUMANGARH TOWN - 335513 DIST. HANUMANGARH ( RAJASTHAN )",
    BRANCH: "SRI GANGANAGAR",
    State: "RAJASTHAN",
    latitude: 29.5806,
    Longitude: 74.3472917,
  },
  {
    W_H_NAME: "MPR PROPERTIES PRIVATE LIMITED",
    w_H_ADDRES:
      "KHEWAT NO. 1181, OPPOSITE OLD BANSAL NURSHING HOSPITAL, HISSAR ROAD, SIRSA, 125055.",
    BRANCH: "SIRSA",
    State: "HARYANA",
    latitude: 29.5286879999999,
    Longitude: 75.0670279999999,
  },
  {
    W_H_NAME: "DAYANAND WAREHOUSE",
    w_H_ADDRES:
      "KHASRA NO.421/1& 423/2, EXTENDED LAL DORA VILLAGE -HAMIDPUR,DELHI-110036",
    BRANCH: "ALIPUR",
    State: "DELHI",
    latitude: 28.8303,
    Longitude: 77.1491999999999,
  },
  {
    W_H_NAME: "FAKRU DEEN WAREHOUSE",
    w_H_ADDRES:
      "KHASRA NO-140,303 GRAM-MUNGASKA,TEHSIL-ALWAR,DISTT-ALWAR,301001",
    BRANCH: "ALWAR",
    State: "RAJASTHAN",
    latitude: 27.5607587999999,
    Longitude: 76.6338705999999,
  },
  {
    W_H_NAME: "PARTH AGRO INDUSTRIAL PARK",
    w_H_ADDRES:
      "R.S.NO.- 1158 PAIKI -1, PLOT NO.- 25 & 48 , AT .-KAMLI , TAL.- UNJHA , DIST.- MAHESANA , GUJARAT - 384170",
    BRANCH: "UNJHA",
    State: "GUJARAT",
    latitude: 23.8471629999999,
    Longitude: 72.374081,
  },
  {
    W_H_NAME: "DHARAM PRAKASH WAREHOUSE",
    w_H_ADDRES:
      "KHASRA NO-519, VILLAGE KHAIRA GRAMIN TEHSIL GONDA- DISTT- GONDA -271002 (U.P)",
    BRANCH: "MATHURA",
    State: "UTTAR PRADESH",
    latitude: 27.1571749999999,
    Longitude: 81.9677539999999,
  },
  {
    W_H_NAME: "AGARWAL WAREHOUSE",
    w_H_ADDRES:
      "KHASRA NO-40 AND 41, VILLAGE -RAJHAUA KHURD , TEHSIL- SADAR  SHAHJAHANPUR UTTAR PRADESH- 242001",
    BRANCH: "MATHURA",
    State: "UTTAR PRADESH",
    latitude: 27.8390899999999,
    Longitude: 80.0068399999999,
  },
  {
    W_H_NAME: "RAGHAV AGRO INDUSTRIES",
    w_H_ADDRES:
      "J-234 ( S ) INDRAPRASTHA INDUSTRIAL AREA KOTA RAJASTHAN PIN-324005",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.1313372999999,
    Longitude: 75.8637345,
  },
  {
    W_H_NAME: "RAJESHBHAI AMARATLAL AKHANI WAREHOUSE",
    w_H_ADDRES:
      "SURVEY NO. 21,PAIKI, MOJE - THARA,TALUKA - KANKREJ,DIST. - BANASKANTHA, GUJARAT 385555",
    BRANCH: "UNJHA",
    State: "GUJARAT",
    latitude: 23.9637132999999,
    Longitude: 71.8223480999999,
  },
  {
    W_H_NAME: "SMT MANJEET KAUR W/O SHRI ROOP SINGH",
    w_H_ADDRES:
      "P. NO-76/117, MURABBA NO-40 , KILLA NO-21 ,22  01 SDS , KARADWALA SADULSHAHAR 335062 DIST SRI GANGANAGAR (RAJASTHAN)",
    BRANCH: "SRI GANGANAGAR",
    State: "RAJASTHAN",
    latitude: 29.9279394,
    Longitude: 74.1774032999999,
  },
  {
    W_H_NAME: "MR. AJAY CHOUDHARY S/O OM PRAKASH",
    w_H_ADDRES:
      "P.NO. 141/270, KILLA NO- 23 , 24, CHAK 15 HMH BHADRA KALI ROAD HANUMANGARH TOWN - 335513 DIST. HANUMANGARH ( RAJASTHAN )",
    BRANCH: "SRI GANGANAGAR",
    State: "RAJASTHAN",
    latitude: 29.5806034999999,
    Longitude: 74.347209,
  },
  {
    W_H_NAME: "BHUTDA WAREHOUSE",
    w_H_ADDRES:
      "Plot No.132,MAA UMIYA AUDYAGIK Sahakari Vasahat Maryadit, Mauza TARODI (BU.), Tal - Kamptee,Dist- NAGPUR, Pincode- 440008, MH",
    BRANCH: "AKOLA",
    State: "MAHARASHTRA",
    latitude: 21.127755,
    Longitude: 79.189823,
  },
  {
    W_H_NAME: "BHARAT INDUSTRIES",
    w_H_ADDRES:
      "KHASARA NO. 46, KHASARA NO.-210/47, VILLAGE-JAKHADWALA, BIKANER RAJ - 334603",
    BRANCH: "BIKANER",
    State: "RAJASTHAN",
    latitude: 28.4581097,
    Longitude: 73.6814638999999,
  },
  {
    W_H_NAME: "SAROJ WAREHOUSE",
    w_H_ADDRES:
      "KHEWAT NO. - 612,613 & 615,KHASRA NO 43/27,VILLAGE -BAKOLI, NARELA, DELHI-110036",
    BRANCH: "ALIPUR",
    State: "DELHI",
    latitude: 28.8100999999999,
    Longitude: 77.1404,
  },
  {
    W_H_NAME: "SMT BIMLA DEVI W/O SHRI NARENDER KUMAR",
    w_H_ADDRES:
      "P.NO. 141/270, KILLA NO- 17,18, CHAK 15 HMH BHADRA KALI ROAD HANUMANGARH TOWN - 335513 DIST. HANUMANGARH ( RAJASTHAN )",
    BRANCH: "SRI GANGANAGAR",
    State: "RAJASTHAN",
    latitude: 29.5810967,
    Longitude: 74.3476186,
  },
  {
    W_H_NAME: "COUPLER ENTERPRISES PVT LTD",
    w_H_ADDRES:
      "KHASRA NO  269 ,KASAR VILLAGE ALANIYA  TEHSIL LADPURA KOTA RAJ-PIN , 325003",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.006671,
    Longitude: 75.9220739999999,
  },
  {
    W_H_NAME: "M/S.BHAKTI ENTERPRISES",
    w_H_ADDRES: "PLOT NO. A 14 MIDC HINGOLI TQ & DISTI. HINGOLI 431513",
    BRANCH: "SANGLI",
    State: "MAHARASHTRA",
    latitude: 19.693144,
    Longitude: 77.1084919999999,
  },
  {
    W_H_NAME:
      "SMT PAYAL BANSAL W/O SHRI JYOTI KUMAR AGGARWAL   GIRDHARI LAL JAIN S/O SHRI RICHHPAL JAIN",
    w_H_ADDRES:
      "PLOT NO G-42,43,44 CHAK 7 E CHHOTI  MURBA NO 38 KILA NO 1,10,11,20,21 SRI GANGANAGAR-335001",
    BRANCH: "SRI GANGANAGAR",
    State: "RAJASTHAN",
    latitude: 29.8872941999999,
    Longitude: 73.8806715999999,
  },
  {
    W_H_NAME: "ANUPREKSHA AGROTECH",
    w_H_ADDRES: "G-1-300, RICCO INDUSTRIAL AREA, BARAN, RAJASTHAN PIN 325205",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.11853,
    Longitude: 76.457919,
  },
  {
    W_H_NAME: "DHARM PAL WAREHOUSE",
    w_H_ADDRES:
      "KHASRA NO.-421/2 EXTENDED LAL DORA ,  VILLAGE -HAMIDPUR ,DELHI-110036",
    BRANCH: "ALIPUR",
    State: "DELHI",
    latitude: 28.8304,
    Longitude: 77.1497999999999,
  },
  {
    W_H_NAME: "PARTH AGRO INDUSTRIAL PARK",
    w_H_ADDRES:
      "R.S.NO.- 1160 PAIKI -1 ,PLOT NO.- 47 TO 49 , AT .-KAMLI , TAL.- UNJHA , DIST.- MAHESANA , GUJARAT - 384170",
    BRANCH: "UNJHA",
    State: "GUJARAT",
    latitude: 23.848521,
    Longitude: 72.3721129999999,
  },
  {
    W_H_NAME: "NATANI AGRO INDUSTRIES",
    w_H_ADDRES: "G-116, AGRO FOOD PARK, RANPUR, KOTA, RAJASTHAN, 325003",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.0581995999999,
    Longitude: 75.8375051999999,
  },
  {
    W_H_NAME: "PUSHKAR WAREHOUSE",
    w_H_ADDRES:
      "SURVEY NO. 253, P1/P1, & 253 P1/P2, PLOT NO.- 17,18,19, AT ANIDA (BHALODI) TALUKA GONDAL, DISTRICT RAJKOT, GUJARAT 360311",
    BRANCH: "RAJKOT",
    State: "GUJARAT",
    latitude: 21.9692399999999,
    Longitude: 70.715546,
  },
  {
    W_H_NAME: "ANIL GUPTA WAREHOUSE",
    w_H_ADDRES:
      "Khasra No 78/220 & 78/221,Extended lal Dora Village Bakoli,Delhi 110036",
    BRANCH: "ALIPUR",
    State: "DELHI",
    latitude: 28.8157,
    Longitude: 77.1397,
  },
  {
    W_H_NAME: "NAND KISHORE S/O MOHAN LAL",
    w_H_ADDRES:
      "KHATOUNI NO. 360/686, VILLAGE BALACHAK, TEHSIL & DISTT TARN TARAN, PUNJAB 143001",
    BRANCH: "AMRITSAR",
    State: "PUNJAB",
    latitude: 31.55329,
    Longitude: 74.8971099999999,
  },
  {
    W_H_NAME: "GOPAL KRISHAN SHARMA",
    w_H_ADDRES:
      "Khasra No.887/1077/1,900,903/1,883/1076 Village& Post-JHIDA ,Patwaz-Samod,The.Chomu,Distt.Jaipur (Raj.)303806",
    BRANCH: "JAIPUR",
    State: "RAJASTHAN",
    latitude: 26.995683,
    Longitude: 75.6909919999999,
  },
  {
    W_H_NAME: "SHREE RAM WAREHOUSE",
    w_H_ADDRES:
      "R.S. NO ΓÇô 218, PAIKEE 2 PLOT NO ΓÇô 3 & 4 AT GUNDALA TALUKA ΓÇô GONDAL DIST- RAJKOT, GUJARAT ΓÇô 360311",
    BRANCH: "RAJKOT",
    State: "GUJARAT",
    latitude: 21.954733,
    Longitude: 70.74813,
  },
  {
    W_H_NAME: "KOTAK LATABEN DINESHBHAI",
    w_H_ADDRES:
      "SURVEY  NO.-193 AT KOCHASANA, TAL.- DEESA,  DIST.-BANASKANTHA, GUJARAT -385535",
    BRANCH: "UNJHA",
    State: "GUJARAT",
    latitude: 24.320643,
    Longitude: 72.1434179999999,
  },
  {
    W_H_NAME: "RAUSHAN WAREHOUSE",
    w_H_ADDRES:
      "KHATA NO - 957, 418 KHESHRA NO - 1177, 1191 MAWA - MALTI, THANA - FULWARZYA, DIST. BEGUSRAI, PIN 851113",
    BRANCH: "PATNA",
    State: "BIHAR",
    latitude: 25.4668099999999,
    Longitude: 86.0041039999999,
  },
  {
    W_H_NAME: "MPR PROPERTIES PRIVATE LIMITED",
    w_H_ADDRES:
      "KHEWAT NO. 2549, VILL. KHERPUR OPPOSITE OLD BANSAL NURSHING HOSPITAL, HISSAR ROAD, SIRSA, 125055.",
    BRANCH: "SIRSA",
    State: "HARYANA",
    latitude: 29.5302376,
    Longitude: 75.0668850999999,
  },
  {
    W_H_NAME: "MANGALMURTI WAREHOUSE",
    w_H_ADDRES:
      "KHASRA NO. 40/4 VILLAGE- ANKABADI, MILANPUR TOL NAKA BETUL (M.P.) 460004",
    BRANCH: "BHOPAL",
    State: "MADHYA PRADESH",
    latitude: 21.86253,
    Longitude: 77.9544099999999,
  },
  {
    W_H_NAME: "SABU LAL DEVKINANDAN",
    w_H_ADDRES: "G1-171,IID CENTER BARAN  RAJASTHAN PIN 325205",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.118758,
    Longitude: 76.4554937999999,
  },
  {
    W_H_NAME: "VIJYASAN AGRO LOGISTICS",
    w_H_ADDRES:
      "SURVE NO. 401/2, VILLAGE- RAISALPUR, NH-69 ITARSI  DIST- HOSHANGABAD (M.P.) 461111",
    BRANCH: "BHOPAL",
    State: "MADHYA PRADESH",
    latitude: 22.6652899999999,
    Longitude: 77.75864,
  },
  {
    W_H_NAME: "KAMLESH RANA WAREHOUSE",
    w_H_ADDRES: "KHASRA NO. 184 & 185, VILLAGE-ALIPUR, DELHI-110036",
    BRANCH: "ALIPUR",
    State: "DELHI",
    latitude: 28.8032067,
    Longitude: 77.1287985999999,
  },
  {
    W_H_NAME: "ANAND UNIQUE WAREHOUSING",
    w_H_ADDRES:
      "KH.NO.118/3, VILLAGE-HATHWAS TEHSIL-PIPARIYA DISTT HOSHANGABAD MP 461775",
    BRANCH: "BHOPAL",
    State: "MADHYA PRADESH",
    latitude: 22.7661699999999,
    Longitude: 78.3352,
  },
  {
    W_H_NAME: "BSWC NATWAR",
    w_H_ADDRES:
      "BIHAR STATE WAREHOUSING CORPORATION,BAZAR SAMITY,NATWAR 802218",
    BRANCH: "PATNA",
    State: "BIHAR",
    latitude: 25.23252,
    Longitude: 84.152015,
  },
  {
    W_H_NAME: "SHAKTI AGRO FOOD PRIVATE LIMITED",
    w_H_ADDRES:
      "P/NO. 184/405, KILLA NO. 1 & P/NO. 185/405 KILLA NO. 1/2, 2/5, CHAK 15 BGD, SRIVIJAYNAGAR 335704",
    BRANCH: "SRI GANGANAGAR",
    State: "RAJASTHAN",
    latitude: 29.2749285,
    Longitude: 73.5008222999999,
  },
  {
    W_H_NAME: "NAVEEN WAREHOUSE",
    w_H_ADDRES:
      "KHASRA NO 234,EXTENDED LAL DORA VILLAGE HAMIDPUR ,DELHI 110036",
    BRANCH: "ALIPUR",
    State: "DELHI",
    latitude: 28.8305,
    Longitude: 77.1436,
  },
  {
    W_H_NAME: "PASHUPATINATH AGRO INDIA PRIVATE LIMITED",
    w_H_ADDRES:
      "KHASRA NO-169, VILLAGE NAYAGAWON MUBARAKPUR, LUCKANOW ROAD HARDOI-UP-241001",
    BRANCH: "MATHURA",
    State: "UTTAR PRADESH",
    latitude: 27.3555467999999,
    Longitude: 80.1649275999999,
  },
  {
    W_H_NAME: "KARUNA SANTOSH DUBE WAREHOUSE",
    w_H_ADDRES: "GUT NO. 44 GUNDEGAON TQ & DIST NANDED. PIN: 431603",
    BRANCH: "SANGLI",
    State: "MAHARASHTRA",
    latitude: 19.0768118999999,
    Longitude: 77.311103,
  },
  {
    W_H_NAME: "ASHWINI WAREHOUSE",
    w_H_ADDRES:
      "SURVEY NO. 133/2, VILLAGE- NAGNA, KATANGI ROAD, TEHSIL- PANAGAR, DIST- JABALPUR  (M.P.) 482002",
    BRANCH: "BHOPAL",
    State: "MADHYA PRADESH",
    latitude: 23.26745405,
    Longitude: 79.87546244,
  },
  {
    W_H_NAME: "ADITYA HRIDAYA WAREHOUSE AND COLD",
    w_H_ADDRES:
      "SURVEY NO-57/1/2, VILLAGE-PIPRODAKHURD, TEH- GUNA, DIST-GUNA- MP 473001",
    BRANCH: "INDORE",
    State: "MADHYA PRADESH",
    latitude: 24.68439666,
    Longitude: 77.3191649999999,
  },
  {
    W_H_NAME: "ASIAN WAREHOUSING LTD",
    w_H_ADDRES:
      "KHASRA NO-857 TO 861,VILLAGE DEVPURA Tehshil. & DISST BUNDI RAJASTHAN PIN 323001",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.4043214,
    Longitude: 75.6613016999999,
  },
  {
    W_H_NAME: "DEVI SHANKAR RATHOR",
    w_H_ADDRES:
      "KHASRA NO. 230 , VILLAGE GORADHANPURA, TEHSIL RAMGANJMANDI  KOTA , RAJASTHAN,PIN  326519",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 24.6368647,
    Longitude: 75.9494083999999,
  },
  {
    W_H_NAME: "ANANDPAL JAIN WAREHOUSE",
    w_H_ADDRES: "KHASRA NO 26/24,VILLAGE SANOTH,DELHI - 110040",
    BRANCH: "ALIPUR",
    State: "DELHI",
    latitude: 28.81719,
    Longitude: 77.07495,
  },
  {
    W_H_NAME: "SARLA TRADING COMPANY",
    w_H_ADDRES:
      "KHASRA NO.70/4 TO 70/11, VILLAGE NANDWAN, THESIL LUNI,JODHPUR, RAJASTHAN,342008",
    BRANCH: "JODHPUR",
    State: "RAJASTHAN",
    latitude: 26.1276176999999,
    Longitude: 72.976629,
  },
  {
    W_H_NAME: "SHIVAM SETIA MANISH SETIA RAGHAV SETIA",
    w_H_ADDRES:
      "VILLAGE BALACHAK, KHATOUNI NO. 284, TEHSIL & DISTT TARN TARAN - 143022",
    BRANCH: "AMRITSAR",
    State: "PUNJAB",
    latitude: 31.5452,
    Longitude: 74.8861299999999,
  },
  {
    W_H_NAME: "HARISHCHANDRASINH MANGALSINH JADEJA",
    w_H_ADDRES:
      "SURVEY NO. 184-P2, PLOT NO. 2, VRAJ - 3, GUNDALA PATIDAL ROAD, OPP. SITALA MAA TEMPLE, VIL. GUNDALA, TAL. GONDAL, DIST. RAJKOT - 360311. (GUJARAT, INDIA)",
    BRANCH: "RAJKOT",
    State: "GUJARAT",
    latitude: 21.954733,
    Longitude: 70.74813,
  },
  {
    W_H_NAME: "RENU MODI",
    w_H_ADDRES:
      "KHASARA NO. 1052/623, VILLAGE-KHARA, BIKANER, RAJASTHAN - 334601",
    BRANCH: "BIKANER",
    State: "RAJASTHAN",
    latitude: 28.163401,
    Longitude: 73.380295,
  },
  {
    W_H_NAME: "SMT RESHMI DEVI  W/O RAM CHAND",
    w_H_ADDRES:
      "KILLA NO 21,22,23,24 SQUARE 15 CHAK 2HH SURATGARH ROAD SRI GANGANAGAR , RAJASTHAN , 335001",
    BRANCH: "SRI GANGANAGAR",
    State: "RAJASTHAN",
    latitude: 29.8350334,
    Longitude: 73.875123,
  },
  {
    W_H_NAME: "MUKESH KHANDELWAL",
    w_H_ADDRES:
      "KHASRA NO 514/2,514/537,515 VILLAGE RAJNAGAR,NAYANORA RAIPURA TESHIL LADPURA KOTA PIN 324001",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.1481767,
    Longitude: 75.9143799999999,
  },
  {
    W_H_NAME: "BHANDARI WAREHOUSE",
    w_H_ADDRES:
      "KHASRA NO 199/1 DUG BHAWANIMANDI  ROAD  SH-19 VILLAGE BAMBORI  TESHIL PAHPAHAR DISST JHALAWAR RAJ, PIN 326512",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 24.3679014,
    Longitude: 75.8240409999999,
  },
  {
    W_H_NAME: "CHEDIPUDI CHANDRA SEKHARA REDDY GODOWN",
    w_H_ADDRES:
      "D.NO.1-193&4-193/3,RAMAHANDRAPURAM PO,MANGALIGIRI MANDAL,GUNTUR DT.GUNTUR-522303",
    BRANCH: "GUNTUR",
    State: "ANDHRA PRADESH",
    latitude: 16.4136576,
    Longitude: 80.6597072,
  },
  {
    W_H_NAME: "M/S KANDA EDIBLE OIL PVT. LTD.",
    w_H_ADDRES:
      "PLOT NO E 341 ARGO FOOD PARK SRI GANGANAGAR 335002  DIST. SRI GANGANAGAR (RAJASTHAN)",
    BRANCH: "SRI GANGANAGAR",
    State: "RAJASTHAN",
    latitude: 29.8644466,
    Longitude: 73.9229981999999,
  },
  {
    W_H_NAME: "SURVASE WAREHOUSE",
    w_H_ADDRES: "195/1/C CHINCHOLIKATI TAL- MOHOL CHINCHOLI KAT SOLAPUR 413213",
    BRANCH: "SANGLI",
    State: "MAHARASHTRA",
    latitude: 17.7472999999999,
    Longitude: 75.7916699999999,
  },
  {
    W_H_NAME: "VAIBHAV LAXMI WAREHOUSE",
    w_H_ADDRES:
      "KHASRA NO 134,135,139,140,VILL.-HATHNODA,THE.-CHOMU,JAIPUR-303807",
    BRANCH: "JAIPUR",
    State: "RAJASTHAN",
    latitude: 27.213826,
    Longitude: 75.74759,
  },
  {
    W_H_NAME: "PUSHKAR WAREHOUSE",
    w_H_ADDRES:
      "SURVEY NO. 253, P1/P1, & 253 P1/P2, PLOT NO.- 14,15,16, AT ANIDA (BHALODI) TALUKA GONDAL, DISTRICT RAJKOT, GUJARAT 360311",
    BRANCH: "RAJKOT",
    State: "GUJARAT",
    latitude: 21.969503,
    Longitude: 70.714674,
  },
  {
    W_H_NAME: "PUNIT WAREHOUSE",
    w_H_ADDRES:
      "BHARKHARA,ANCHAL - RAJPUR, KHATA - 30, KHESRA - 656 DIST - BUXAR-802114",
    BRANCH: "PATNA",
    State: "BIHAR",
    latitude: 25.4212519999999,
    Longitude: 83.8968569999999,
  },
  {
    W_H_NAME: "SHIVAM ENTERPRISES",
    w_H_ADDRES: "CP1-23 INDUSTRIAL AREA BARAN DISST BARAN RAJASTHAN,PIN 325205",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.1098158,
    Longitude: 76.450349,
  },
  {
    W_H_NAME: "PIYUSH INDUSTRIES",
    w_H_ADDRES:
      "CP2-12 ,INDUSTRIAL AREA BARAN DISST BARAN RAJASTHAN PIN 325205",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.1136741999999,
    Longitude: 76.4514281,
  },
  {
    W_H_NAME: "SARVESHWARA FLOUR PRODUCTS PVT LTD",
    w_H_ADDRES: "A-21/1 CHINCHOLI MIDC SOLAPUR 413255",
    BRANCH: "SANGLI",
    State: "MAHARASHTRA",
    latitude: 17.75158,
    Longitude: 75.79949,
  },
  {
    W_H_NAME: "ANITA CHHABRA WAREHOUSE",
    w_H_ADDRES:
      "KHASRA NO.10/9,MIN(0-12)(1-04),VILLAGE - BAKOLI,TESHIL - ALIPUR,DISTT - NORTH DELHI,DELHI - 110036",
    BRANCH: "ALIPUR",
    State: "DELHI",
    latitude: 28.8207999999999,
    Longitude: 77.1357,
  },
  {
    W_H_NAME: "BALAJI AGRO COMPLEX",
    w_H_ADDRES:
      "SURVEY NO. 410/10, 410/11,   NH-69, VILLAGE RAISALPUR  ITARSI, DIST- HOSHANGABAD (M.P.) 461111",
    BRANCH: "BHOPAL",
    State: "MADHYA PRADESH",
    latitude: 22.6657699999999,
    Longitude: 77.75954,
  },
  {
    W_H_NAME: "BALAJI AGRO COMPLEX",
    w_H_ADDRES:
      "SURVEY NO. 531/4, NH-69, VILLAGE RAISALPUR  ITARSI, DIST- HOSHANGABAD (M.P.) 461111",
    BRANCH: "BHOPAL",
    State: "MADHYA PRADESH",
    latitude: 22.6657699999999,
    Longitude: 77.75954,
  },
  {
    W_H_NAME: "BEST OIL & AGRO PRODUCTS PVT LTD",
    w_H_ADDRES: "D.NO 116-21,MAIN ROAD,NALLAPADU,GUNTUR-522005",
    BRANCH: "GUNTUR",
    State: "ANDHRA PRADESH",
    latitude: 16.3101436,
    Longitude: 80.3684783,
  },
  {
    W_H_NAME: "BALAJI AGRO COMPLEX",
    w_H_ADDRES:
      "SURVEY NO. 531/4, NH-69, VILLAGE RAISALPUR  ITARSI, DIST- HOSHANGABAD (M.P.) 461111",
    BRANCH: "BHOPAL",
    State: "MADHYA PRADESH",
    latitude: 22.6657699999999,
    Longitude: 77.75954,
  },
  {
    W_H_NAME: "SHRI JAY DURGE WAREHOUSE & INFRASTRUCTURE",
    w_H_ADDRES:
      "SURVEY NO. 43/3, VILLAGE - CHOWKI HARVANSH, TEHSIL - SEONI MALWA,  DIST- HOSHANGABAD (M.P.) 461223",
    BRANCH: "BHOPAL",
    State: "MADHYA PRADESH",
    latitude: 22.4742,
    Longitude: 77.4818,
  },
  {
    W_H_NAME: "RAVI SHROFF WAREHOUSE",
    w_H_ADDRES: "NEAR POKHARMAL PETROL PUMP,ZERO MILE,GULABBAGH,PURNEA-854326",
    BRANCH: "PATNA",
    State: "BIHAR",
    latitude: 25.789175,
    Longitude: 87.5427069999999,
  },
  {
    W_H_NAME: "BINOD WAREHOUSE",
    w_H_ADDRES:
      "Mouja-BHARKHARA,Anchal-RAJPUR,Khata-30,Khesra-653,655 BUXAR-802114",
    BRANCH: "PATNA",
    State: "BIHAR",
    latitude: 25.4212519999999,
    Longitude: 83.8968569999999,
  },
  {
    W_H_NAME: "MANOJ WAREHOUSE",
    w_H_ADDRES:
      "Mouja-BHARKHARA,Anchal-RAJPUR,Khata-30,Khesra-655,656 BUXAR-802114",
    BRANCH: "PATNA",
    State: "BIHAR",
    latitude: 25.4212519999999,
    Longitude: 83.8968569999999,
  },
  {
    W_H_NAME: "MADHUR AMARNATH AGRO PARK",
    w_H_ADDRES:
      "KH NO.580/13/1,  NEAR HARIYALI KISHAN BAZAR KHURAI ROAD VILL.PATHARIYA HATH DISTT. SAGAR M.P. PIN 470002",
    BRANCH: "BHOPAL",
    State: "MADHYA PRADESH",
    latitude: 23.852643,
    Longitude: 78.679119,
  },
  {
    W_H_NAME: "ABM SHREE MAHALAXMI CORPORATION",
    w_H_ADDRES:
      "R.S.NO.- 258 PAIKI -1 ,GODOWN NO.- 01 , AT .-BRAHMANVADA , TAL.- UNJHA , DIST.- MAHESANA , GUJARAT - 384170",
    BRANCH: "UNJHA",
    State: "GUJARAT",
    latitude: 23.8650209999999,
    Longitude: 72.370151,
  },
  {
    W_H_NAME: "TIRUPATI WAREHOUSING & LOGISTIC CORPORATION",
    w_H_ADDRES:
      "SURVEY NO. 531/4, VILLAGE RAISALPUR,  TEHSIL- ITARSI, DIST- HOSHANGABAD (M.P.) 461111",
    BRANCH: "BHOPAL",
    State: "MADHYA PRADESH",
    latitude: 22.66657,
    Longitude: 77.75967,
  },
  {
    W_H_NAME: "AGGARWAL WAREHOUSE",
    w_H_ADDRES:
      "KHASRA NO. 81/241,TO 245,EXTENSION LAL DORA VILLAGE-BAKOLI, DELHI-110036",
    BRANCH: "ALIPUR",
    State: "DELHI",
    latitude: 28.8215393999999,
    Longitude: 77.1368542999999,
  },
  {
    W_H_NAME: "LILA GOYAL W/O SHIV KUMAR GOYAL",
    w_H_ADDRES:
      "KHEWAT NO.156/1, KHATA NO.189/1, KHASRA NO.66/9-12-19 V.P.O NEOLI KALAN TEHSIL & DISTT HISAR HARYANA-125001",
    BRANCH: "SIRSA",
    State: "HARYANA",
    latitude: 29.1888817,
    Longitude: 75.6394832999999,
  },
  {
    W_H_NAME: "RADHIKA AGRAWAL",
    w_H_ADDRES:
      "KHASRA NO 1399/1291 VILLAGE MELKHADI TEHSIL BARAN DISST BARAN RAJ,PIN 325205",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.1226066,
    Longitude: 76.4823454,
  },
  {
    W_H_NAME: "SHILPI CHHABRA WAREHOUSE",
    w_H_ADDRES: "KHASRA NO. 324/3 VILLAGE-HAMIDPUR, DELHI-110036",
    BRANCH: "ALIPUR",
    State: "DELHI",
    latitude: 28.8236999999999,
    Longitude: 77.1511,
  },
  {
    W_H_NAME: "WELLWIN TECHNOSOFT LIMITED",
    w_H_ADDRES:
      "PLOT NO-G-65-66 & 67 ,AGRO FOOD PARK ( 1 ) RANPUR KOTA RAJ.PIN 325003",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.0563357999999,
    Longitude: 75.8383249999999,
  },
  {
    W_H_NAME: "UMA GANDHI",
    w_H_ADDRES:
      "H1-186 AGRO FOOD PARK PHASE II RANPUR DISST KOTA RAJASTHAN PIN 325003",
    BRANCH: "KOTA",
    State: "RAJASTHAN",
    latitude: 25.0550028,
    Longitude: 75.8356778999999,
  },
  {
    W_H_NAME: "MAMTA ENTERPRISES WAREHOUSE",
    w_H_ADDRES:
      "KHASRA NO.68/8 & 68/11 VILLAGE NANDWAN, THESIL-LUNI,DIST-JODHPUR,RAJASTHAN 342013",
    BRANCH: "JODHPUR",
    State: "RAJASTHAN",
    latitude: 26.1303617,
    Longitude: 72.9767667999999,
  },
  {
    W_H_NAME: "BANSAL WAREHOUSE",
    w_H_ADDRES:
      "Khasra No -33/11/2(4-0),33/12(4-16),33/10(4-16)And 11/1(0-16) Village - Bhorgarh,Narela,Delhi -110040",
    BRANCH: "ALIPUR",
    State: "DELHI",
    latitude: 28.8322,
    Longitude: 77.0745,
  },
  {
    W_H_NAME: "MOHNA DEVI WAREHOUSE",
    w_H_ADDRES:
      "SURVEY NO. 83/2, 82/1/1/2/2, VILLAGE- BERKHEDA TEHSIL- DABRA DIST. GWALIOR MP- 475110",
    BRANCH: "BHOPAL",
    State: "MADHYA PRADESH",
    latitude: 25.8368399999999,
    Longitude: 78.28314,
  },
  {
    W_H_NAME: "SHREE GAJANAN FIBER PVT LTD .",
    w_H_ADDRES:
      "GAT NO. 172/1 NIZAR ROAD AT POST- PALASHI TAL & DIST NANDURBAR MAHARASHTRA - 400001",
    BRANCH: "AKOLA",
    State: "MAHARASHTRA",
    latitude: 21.43225333,
    Longitude: 74.23869833,
  },
  {
    W_H_NAME: "LUVKUSH WAREHOUSING",
    w_H_ADDRES:
      "SURVEY NO. 98/1, VILLAGE- RANI PIPARIYA, TEHSIL- SOHAGPUR, PIPARIYA, DIST- HOSHANGABAD (M.P.) 461771",
    BRANCH: "BHOPAL",
    State: "MADHYA PRADESH",
    latitude: 22.76987,
    Longitude: 78.3117699999999,
  },
  {
    W_H_NAME: "RAJKUMARI GUPTA WAREHOUSE",
    w_H_ADDRES:
      "KAHSRA NO-104-S, VILLAGE- BARTHAR PARGNA- ETAH-SAKEET TEHSIL AND DISTT- ETAH-207001 UTTAR PRADESH",
    BRANCH: "MATHURA",
    State: "UTTAR PRADESH",
    latitude: 27.5774216999999,
    Longitude: 78.6323349,
  },
  {
    W_H_NAME: "MUKTA LOGISTICS",
    w_H_ADDRES:
      "SURVEY NO. 410/3, 410/7  NH-69, VILLAGE RAISALPUR  ITARSI, DIST- HOSHANGABAD (M.P.) 461111",
    BRANCH: "BHOPAL",
    State: "MADHYA PRADESH",
    latitude: 22.65905,
    Longitude: 77.7615299999999,
  },
  {
    W_H_NAME: "SRI KISHAN AGRAWAL WAREHOUSE",
    w_H_ADDRES: "D.NO.66-6-23, STAMBAMPALLY, NARSAMPET ROAD, WARANGAL-506004",
    BRANCH: "SECUNDERABAD",
    State: "TELANGANA",
    latitude: 17.9632271,
    Longitude: 79.6461701,
  },
  {
    W_H_NAME: "MUMBAI WAREHOUSING",
    w_H_ADDRES:
      "Survey No 128 Hissa No 5 new survye no-69 mohammadiya Estate village  Pimpari  Dist-Thane MH -400612",
    BRANCH: "SANGLI",
    State: "MAHARASHTRA",
    latitude: 19.11116,
    Longitude: 73.0562139999999,
  },
  {
    W_H_NAME: "MRS. GARIMA W/O MR. ESHAN CHOUDHARY",
    w_H_ADDRES:
      "P.NO. 141/270, KILLA NO- 13,14  CHAK 15 HMH BHADRA KALI ROAD HANUMANGARH TOWN - 335513 DIST. HANUMANGARH ( RAJASTHAN )",
    BRANCH: "SRI GANGANAGAR",
    State: "RAJASTHAN",
    latitude: 29.5815038,
    Longitude: 74.3474587999999,
  },
  {
    W_H_NAME: "NILKANTH INDUSTRIES",
    w_H_ADDRES: "PLOT NO - 313, GIDC-2, DOLATPARA, JUNAGADH, PIN CODE - 362037",
    BRANCH: "RAJKOT",
    State: "GUJARAT",
    latitude: 21.5617021999999,
    Longitude: 70.4713076,
  },
  {
    W_H_NAME: "PHOOLWATI WAREHOUSE",
    w_H_ADDRES:
      "SURVEY NO.211/1/1/1, VILLAGE- DINARI KHAMARIYA, TEHSIL- MAJHOLI, DISTT JABALPUR MP 483225",
    BRANCH: "BHOPAL",
    State: "MADHYA PRADESH",
    latitude: 23.57317,
    Longitude: 80.12909,
  },
  {
    W_H_NAME: "KISAN WAREHOUSE",
    w_H_ADDRES:
      "SURVEY NO. 16/4/1, 16/4/2, VILLAGE - GADMARHI RAMGARHI DISTT. CHINDWARA MP 480001",
    BRANCH: "BHOPAL",
    State: "MADHYA PRADESH",
    latitude: 22.078347,
    Longitude: 79.0071099999999,
  },
  {
    W_H_NAME: "JINWANI JAIN WAREHOUSE",
    w_H_ADDRES:
      "KHASRA NO-853, VILLAGE- GIROURA, KASGANJ ROAD BEAR PAC LINE TEHSIL AND DISTRICT ETAH , UTTAR PRADESH-207001",
    BRANCH: "MATHURA",
    State: "UTTAR PRADESH",
    latitude: 27.610485,
    Longitude: 78.6497099999999,
  },
  {
    W_H_NAME: "VEDPRAKASH WAREHOUSE",
    w_H_ADDRES:
      "KHASRA NO-180,VILLAGE- NAYAGANW MUBARAKPUR, PARGNA -GOPAMAU TEHSIL - HARDOI LUCKANOW ROAD HARDOI -241001",
    BRANCH: "MATHURA",
    State: "UTTAR PRADESH",
    latitude: 27.356389,
    Longitude: 80.1650049999999,
  },
  {
    W_H_NAME: "KANHAULI WAREHOUSE",
    w_H_ADDRES:
      "PLOT NO-8243/1982,VILL- MOHAMMAD PUR,THANA NO-70,MAUJA-PAINAL,BIHTA PATNA-801111",
    BRANCH: "PATNA",
    State: "BIHAR",
    latitude: 25.5828619999999,
    Longitude: 84.9446729999999,
  },
  {
    W_H_NAME: "SMT SULOCHANA W/O SHREE OM PRAKASH",
    w_H_ADDRES:
      "P.NO. 141/270, KILLA NO- 14,15  CHAK 15 HMH BHADRA KALI ROAD HANUMANGARH TOWN - 335513 DIST. HANUMANGARH ( RAJASTHAN )",
    BRANCH: "SRI GANGANAGAR",
    State: "RAJASTHAN",
    latitude: 29.5814907,
    Longitude: 74.3483568,
  },
  {
    W_H_NAME: "M/S.SANSKRUTI INDUSTRIES",
    w_H_ADDRES: "PLOT NO. C-25,MIDC HINGOLI MH 431514 IND",
    BRANCH: "SANGLI",
    State: "MAHARASHTRA",
    latitude: 19.6860396999999,
    Longitude: 77.1080646,
  },
  {
    W_H_NAME: "SATNAM WAREHOUSE",
    w_H_ADDRES:
      "SURVEY NO.1268/1, VILLAGE KAILWARA KALA, TEHSIL KATNI NAGAR DISTT KATNI, M.P. 483501",
    BRANCH: "BHOPAL",
    State: "MADHYA PRADESH",
    latitude: 23.933311,
    Longitude: 80.3990359999999,
  },
  {
    W_H_NAME: "RIDDHI SIDDHI WAREHOUSE",
    w_H_ADDRES: "GAT NO 178 DEOBHANE TALUKA &DIST DHULE MAHARASHTRA 425405",
    BRANCH: "AKOLA",
    State: "MAHARASHTRA",
    latitude: 21.017205,
    Longitude: 74.78614167,
  },
  {
    W_H_NAME: "SUFIYABIBI ABDUL NABI",
    w_H_ADDRES:
      "OLD SURVEY NO.- 16 AND NEW SURVEY NO- 48, PLOT NO. ΓÇô D, VILLAGE - LALPUR, TALUKA - HIMATNAGAR, HIMATNAGAR VIJAPUR ROAD, DIST.- SABARKANTHA, GUJARAT PIN CODE NO.-383001",
    BRANCH: "UNJHA",
    State: "GUJARAT",
    latitude: 23.6110539999999,
    Longitude: 72.9180929999999,
  },
];
