export const ImageDataContent ={
    "bulkdensity":"Soils with low bulk density are generally more suitable for agriculture, since the high pore space has a greater potential to store water and allow roots to grow more readily. As bulk density increases, resistance to roots increases and the amount of water available to crops decreases.",
    "cationexchangecapacity":"Sandy soils have lower CEC, while clayey soils have higher CEC and the loamy soil CEC content lies in between them. Higher CEC values indicate the capacity to store more nutrients, holding them for later use by plants and preventing them from leaching away.",
    "organiccarbondensity":"It's an important indicator of soil health and fertility because it affects nutrient availability, water retention, and overall soil  structure.",
    "organiccarbonstock":"Adopting use of Organic manures and fertilizers, crop rotations, green manuring crops and other organic agriculture practices, will help us in restoring the Organic Carbon Stock in Indian Soil. Our dependencies on chemicals is also a major reason for the decline of Soil organic content.",
    "phwater":" Liming, addition of organic residues rich in basic cations and crop rotation to interrupt the acidifying effect of leguminous crops increases soil pH. Applying ammonium based fertilizers, urea, sulfur/ferrous sulfate, irrigating with acidifying fertilizers, or using acidifying residues (acid moss, pine needles, sawdust) decrease soil pH. Increasing organic matter increases buffering capacity.",
    "sandpercent":"Sandy soil is mentioned to be warm, dry and light which has the tendency to be low and acidic in nutrients. Soils with a higher percentage of sand tend to drain water more quickly and have a looser texture compared to soils with higher proportions of clay or silt.",
    "siltpercent":"It is considered a moisture and light retentive “type of soil” with a rating of high fertility. It is because the silt soil consists of medium-sized particles, as they hold good moisture and they are well drained. Soils with higher silt percentages typically exhibit a smoother texture and superior water retention compared to sandy soils.",
    "soilorganiccarbon":"The Soil Organic Carbon (SOC) content in India has come down to 0.3 percent from 1 percent in the past 70 years which is a cause of concern for agriculture. Intensive cultivation of crops without providing proper compost to the soil is the reason behind the fall in SOC content.",
    "claypercent":"This kind of soil gets waterlogged easily and holds more nutrients as compared to other soil types..It is difficult for moisture and air to penetrate this soil because of its excellent water-holding capacities.",
    "coarsefragment":"These fragments affect the soil's texture and structure, influencing factors like water drainage and root growth for plants."
}