export const SoilDropDownData = [
  {
    key: 1,
    name: "Sand Percent",
    value: "sandpercent",
  },
  {
    key: 2,
    name: "Silt Percent",
    value: "siltpercent",
  },
  {
    key: 3,
    name: "Clay Percent",
    value: "claypercent",
  },
  {
    key: 4,
    name: "Soil Organic Carbon",
    value: "soilorganiccarbon",
  },
  {
    key: 5,
    name: "Organic Carbon Density",
    value: "organiccarbondensity",
  },
  {
    key: 6,
    name: "Organic Carbon Stock",
    value: "organiccarbonstock",
  },
  {
    key: 7,
    name: "Bulk Density",
    value: "bulkdensity",
  },
  {
    key: 8,
    name: "Cation Exchange Capacity",
    value: "cationexchangecapacity",
  },
  {
    key: 9,
    name: "Ph Water",
    value: "phwater",
  },
  {
    key: 10,
    name: "Coarse Fragment",
    value: "coarsefragment",
  },
];


