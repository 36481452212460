import axios from "axios";

export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";
export const WEATHER_INFO = "WEATHER_INFO";

export const changeLanguage = (lang) => {
  return async (dispatch) => {
    dispatch({ type: "CHANGE_LANGUAGE", payload: lang });
    return;
  };
};

// TO GET WETHER DETAILS FROM WORLD WEATHER API
export const getWeatherDetails = (locations) => {
  const { state, city, taluka, village, khasra } = locations;
  return async (dispatch) => {
    const res = await axios({
      method: "GET",
      url: `https://api.worldweatheronline.com/premium/v1/weather.ashx?key=9cafb9b2355c47998ce73234230305&format=json&q=${locations.state?.label},${locations.city?.label},${locations.state.taluka},${locations.state.village}`,
    });

    dispatch({ type: WEATHER_INFO, payload: res.data });
  };
};
