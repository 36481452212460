import {
  CloseOutlined,
  LeftCircleOutlined,
  RightCircleOutlined,
} from "@ant-design/icons";
import { Button, Modal, Tooltip, Typography } from "antd";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { apiurls } from "../../resources/APIurls";
import { appconfig } from "../../resources/config";
import "./Navbar.css";

const Navbar = () => {
  let apikey_s3 = apiurls.find((item) => item.apikey === "aws3");
  const aws_s3_url = `${appconfig.domain[appconfig.environment].aws3}${
    apikey_s3.paths.images
  }`;
  const locations = useLocation();
  const ref = useRef(null);

  const navigate = useNavigate();
  const [logOutBtn, setLogOutBtn] = useState("");
  const params = useParams();
  const language = useSelector((state) => state.Language.language);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const locationWeatherInfo = useSelector(
    (state) => state.weather.info.data.current_condition
  );
  const weatherPrediction = useSelector(
    (state) => state.weather.prediction.data.weather
  );

  const [weatherStatus, setWeatherStatus] = useState({});

  useEffect(() => {
    if (weatherPrediction?.length) {
      setWeatherStatus(weatherPrediction[0]);
    }
  }, [weatherPrediction]);

  const location = useSelector((state) => state.weather.info.data.request);

  useEffect(() => {
    {
      params == "/agribhumi/:id" ? setLogOutBtn("Logout") : setLogOutBtn("");
    }
  }, [params]);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const getTime = (time) => {
    switch (time) {
      case "0":
        return "12 AM to 3 AM ";
      case "300":
        return "3 AM to 6 AM ";
      case "600":
        return "6 AM to 9 AM ";
      case "900":
        return "9 AM to 12 PM ";
      case "1200":
        return "12 PM to 3 PM ";
      case "1500":
        return "3 PM to 6 PM ";
      case "1800":
        return "6 PM to 9 PM ";

      default:
        return "9 PM to 12 AM";
        break;
    }
  };
  const rightScroll = (scrollOffset) => {
    ref.current.scrollLeft += scrollOffset;
  };
  const setLogin = () => {
    navigate("/login");
  };
  const setSignUp = () => {
    navigate("/signup");
  };
  return (
    <div className={"navbar"}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          height: "100%",
          alignItems: "center",
        }}
      >
        <img src={`${aws_s3_url}/logo.svg`} width="100px" />
        {locations.pathname == "/" && (
          <div className={"lognBtnDiv"}>
            <Button className={"lognBtn"} onClick={setLogin}>
              Login
            </Button>
            <Button className={"lognBtn"} onClick={setSignUp}>
              Sign up
            </Button>
          </div>
        )}

        {locationWeatherInfo?.length > 0 && (
          <div className={"weathercontainer"}>
            <div className={"weatherinfo"}>
              <div className={"atmosphr"}>
                <img
                  className={"iconimg"}
                  src={locationWeatherInfo[0].weatherIconUrl[0].value}
                />
                <Typography className={"weather"}>
                  {locationWeatherInfo[0].weatherDesc[0].value},
                </Typography>
                <Typography className={"weather"}>
                  {locationWeatherInfo[0].temp_C}°C
                </Typography>
              </div>
            </div>
            <div className={"weatherinfo nthchild"}>
              <Typography className="weatherParams">wind</Typography>

              <Typography className="weatherParams">
                {locationWeatherInfo[0].windspeedKmph} km/hr
              </Typography>
            </div>
            <div className={"weatherinfo nthchild "}>
              <Typography className="weatherParams">Precipitation</Typography>
              <Typography className="weatherParams">
                {" "}
                {locationWeatherInfo[0].precipMM} mm
              </Typography>
            </div>
            <div className={"weatherinfo nthchild "}>
              <Typography className="weatherParams">clouds</Typography>
              <Typography className="weatherParams">
                {locationWeatherInfo[0].cloudcover} %{" "}
              </Typography>
            </div>
            <div className={"weatherinfo"}>
              <Typography className="weatherParams">humidity</Typography>
              <Typography className="weatherParams">
                {locationWeatherInfo[0].humidity} %{" "}
              </Typography>
            </div>

            <Tooltip title="Get detailed weather report" placement="left">
              <Button
                type="primary"
                shape="circle"
                onClick={showModal}
                style={{ marginTop: "15px", backgroundColor: "#ffffff" }}
                icon={
                  <img width="18px" src={`${aws_s3_url}/weather-report.png`} />
                }
              />
            </Tooltip>

            <Modal
              open={isModalOpen}
              onOk={handleOk}
              onCancel={handleCancel}
              footer={null}
              // width={795}
              width={"full"}
              centered="true"
              closeIcon={<CloseOutlined style={{ color: "#ffffff" }} />}
              top={0}
              // style={{ padding: "0!important" }}
              className={"modalStyle"}
              bodyStyle={{
                backgroundImage: `url(${aws_s3_url}/Intersect.png)`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                // padding: "0 !important"  ,
                backgroundClip: "padding-box",
              }}
            >
              <div className={"mainContainer"}>
                <div className={"topcontent"}>
                  <div className={"topRight"}>
                    <Typography
                      style={{
                        color: "#FFFFFF",
                      }}
                    >
                      {location[0].query}
                    </Typography>

                    <div className={"temp_box"}>
                      <img
                        style={{
                          height: "27px",
                          width: "27px",
                          borderRadius: "8px",
                          marginTop: "5px",
                          marginRight: "8px",
                        }}
                        src={locationWeatherInfo[0].weatherIconUrl[0].value}
                      />

                      <Typography
                        style={{
                          color: " #FFFFFF",
                        }}
                      >
                        {locationWeatherInfo[0].weatherDesc[0].value},
                      </Typography>

                      <Typography style={{ color: "#FFFFFF" }}>
                        {locationWeatherInfo[0].temp_C}°C
                      </Typography>
                    </div>
                  </div>
                  <div className={"humidity"}>
                    <img
                      style={{
                        width: "29px",
                        height: "29px",
                        borderRadius: "20px",
                      }}
                      src={`${aws_s3_url}/humidity.png`}
                    />
                    <Typography
                      style={{
                        marginLeft: "10px",
                        fontSize: "large",
                        color: " #FFFFFF",
                      }}
                    >
                      humidity : {locationWeatherInfo[0].humidity} %
                    </Typography>
                  </div>
                </div>

                <div className={"hourleydata"}>
                  <div className={"midbox"}>
                    <Typography
                      className={"todaysDate"}
                      style={{
                        textTransform: "capitalize",
                        fontSize: "medium",
                        color: " #FFFFFF",
                        marginTop: "2%",
                      }}
                    >
                      {moment(weatherStatus?.date).format("DD MMM, YYYY")}
                    </Typography>
                  </div>
                  <div className={"scroldiv"}>
                    <Button
                      style={{
                        color: "white",
                        background: "transparent",
                        border: "none",
                      }}
                      onClick={() => rightScroll(-30)}
                    >
                      <LeftCircleOutlined style={{ fontSize: "22px" }} />
                    </Button>
                    <div className={"container-outer"} ref={ref}>
                      {weatherStatus &&
                        Object.keys(weatherStatus).length &&
                        weatherStatus?.hourly?.map((item, index) => {
                          return (
                            <div className={"container-inner"} key={index}>
                              <Typography
                                className={"weeklytime"}
                                style={{ color: " #FFFFFF" }}
                              >
                                {getTime(item.time)}
                              </Typography>
                              <div className={"temp_desc"}>
                                <Typography
                                  className={"wether_details"}
                                  style={{ color: " #FFFFFF" }}
                                >
                                  {item.weatherDesc[0].value},
                                </Typography>
                                <Typography
                                  style={{
                                    fontweight: "500",
                                    fontSize: "18px",
                                    lineHeight: "30px",
                                    color: " #FFFFFF",
                                  }}
                                >
                                  {item.tempC} °C
                                </Typography>
                              </div>

                              <img
                                src={item.weatherIconUrl[0].value}
                                className={"iconimg"}
                              />
                              <div className={"wether_details"}>
                                <Typography style={{ color: " #FFFFFF" }}>
                                  humidity - {item.humidity} %
                                </Typography>
                                <Typography style={{ color: " #FFFFFF" }}>
                                  wind - {item.windspeedKmph} km/hr
                                </Typography>
                                <Typography style={{ color: " #FFFFFF" }}>
                                  clouds - {item.cloudcover} %{" "}
                                </Typography>
                                <Typography
                                  style={{
                                    width: "max-content",
                                    color: " #FFFFFF",
                                  }}
                                >
                                  Thunder - {item.chanceofthunder} % chance
                                </Typography>
                                <Typography style={{ color: " #FFFFFF" }}>
                                  RAIN - {item.chanceofrain} % chance
                                </Typography>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                    <Button
                      style={{
                        color: "white",
                        background: "transparent",
                        border: "none",
                      }}
                      onClick={() => rightScroll(30)}
                    >
                      <RightCircleOutlined style={{ fontSize: "22px" }} />
                    </Button>
                  </div>
                </div>
                <div>
                  <Typography
                    style={{
                      fontWeight: "500",
                      fontSize: "24px",
                      lineHeight: "36px",
                      marginTop: "6px",
                      textTransform: "capitalize",
                      color: "#FFFFFF",
                      marginLeft: "1%",
                    }}
                  >
                    5 days forecast
                  </Typography>
                  <div className={"weeklyContainer"}>
                    {weatherPrediction.map((item, index) => {
                      return (
                        <div
                          className={"weeklyWeather"}
                          key={index}
                          onClick={() => setWeatherStatus(item)}
                        >
                          <Typography style={{ color: " #FFFFFF" }}>
                            {moment(item.date).format("DD MMM, YYYY")}
                          </Typography>
                          <div className={"wetherCondition"}>
                            <img
                              src={`${aws_s3_url}/weeklytemp.png`}
                              className={"temp_img"}
                            />
                            <div className={"max_min_temp"}>
                              <Typography style={{ color: " #FFFFFF" }}>
                                MAX : {item.maxtempC} °C
                              </Typography>
                              <Typography style={{ color: " #FFFFFF" }}>
                                MIN : {item.mintempC} °C
                              </Typography>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </Modal>
          </div>
        )}
        <div className="ab-logo-cont">
          <a
            className="ab-logo-cont"
            style={{ padding: "0px", height: "auto" }}
            href="https://www.agribazaar.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={`${aws_s3_url}/AgribazaarLogo.svg`} width="80px" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
