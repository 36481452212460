import React from "react";

const EnglishText = {
  LOGIN: "Login",
  GET_STARTED: "Get Started",
  THANK_YOU_FOR_SHOWING_INTEREST:
    "Thank you for showing interest in agribazaar.",
  PLEASE_ENTER_MOBILE_NUMBER: "Please enter your mobile number to get started.",
  MOBILE_NO: "Mobile No.",
  ENTER_YOUR_MOBILE: "Enter your mobile no.",
  SEND_OTP: "Send OTP",
  VERIFY_YOUR_NUMBER: "Verify your number by entering the 4 digit OTP sent to:",
  NOT_YOURS: "Not yours?",
  CHANGE_NUMBER: "Change Number",
  SUBMIT: "Submit",
  SIGN_UP: "Sign Up",
  NO_ACCOUNT:"Don't have an account ?",
  NAME:'Name',
  FIRST_NAME:'First Name',
  USER_TYPE:'User Type'

};

export default EnglishText;
